import 'moment/locale/pt-br';
import moment from 'moment';

export const GetOidcConfig = () => {
  return JSON.parse(localStorage.getItem('OidcConfig'));
};

export const LogoutComponent = (userManager) => {
  const args = {
    extraQueryParams: {
      client_id: userManager.settings.client_id,
      logout_uri: userManager.settings.post_logout_redirect_uri,
    },
  };
  userManager.signoutRedirect(args);
};

export const GetUser = () => {
  const oidcConf = GetOidcConfig();
  return JSON.parse(sessionStorage.getItem(oidcConf.chaveArmazenamentoUsuario));
};

export const GetUserData = (user) => {
  if (!user) {
    return {};
  }
  return {
    tokenJwt: user.id_token,
    dataExpiracao: moment.unix(user.expires_at),
    name: `${user.profile.given_name} ${user.profile.family_name}`,
    email: user.profile.email,
    sub: user.profile.sub,
  };
};
