import React from 'react';
import ReactLoading from 'react-loading';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { DARK_CYAN, WHITE } from '../../theme';

const useStyles = makeStyles({
  containerLoading: {
    position: 'absolute',
    height: '100%',
    top: '0',
  },
  fundoLoading: {
    width: '100%',
    height: '100%',
    background: WHITE,
    zIndex: '2',
  },
  loading: {
    position: 'absolute',
    zIndex: '2',
    alignItems: 'center',
    height: '100%',
  },
  reactLoading: {
    backgroundColor: WHITE,
  },
});

const Loading = (props) => {
  const classes = useStyles();
  const { iconSize, alignRight, opacity } = props;
  const size = iconSize || 50;

  return (
    <Grid
      className={classes.containerLoading}
      container
      item
      justifyContent="center"
      id="round-loading"
      data-testid="round-loading"
    >
      <Grid
        container
        item
        className={classes.fundoLoading}
        style={{ opacity: opacity || 0.6 }}
      />
      <Grid
        className={classes.loading}
        container
        item
        justifyContent={alignRight ? 'flex-end' : 'center'}
      >
        <ReactLoading
          type="spin"
          color={DARK_CYAN}
          height={size}
          width={size}
          className={alignRight && classes.reactLoading}
          data-testid="react-loading"
        />
      </Grid>
    </Grid>
  );
};

export default Loading;
