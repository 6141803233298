import React, { useState } from 'react';
import { Edit3, Calendar, BarChart2, ChevronsUp, Users } from 'react-feather';
import { makeStyles, TextField, Typography, Grid } from '@material-ui/core';
import ParametrosIES from '../ParametrosIES';
import UploadCampanha from '../UploadCampanha';
import { DARK_CYAN, GRAY, FONT_FAMILY_REGULAR } from '../../../../theme';
import DropdownDateTimePicker from '../../../../shared/componentes/DropdownDateTimePicker';
import { placeholderDateTimer } from '../../../../shared/util/Utils';
import SelectCampanha from '../../../../shared/componentes/SelectCampanha';
import ObjetivosCampanha from '../ObjetivosCampanha';
import { impactosDaCampanha } from '../../../../shared/util/Constantes';

const useStyles = makeStyles({
  inputLabel: {
    fontSize: '14px',
    color: GRAY,
    fontWeight: '300',
    fontFamily: FONT_FAMILY_REGULAR,
    marginRight: '20px',
    textAlign: 'end',
    marginTop: '14px',
  },
  iconeLabelTituloSelect: {
    color: DARK_CYAN,
    marginRight: '4px',
    marginTop: '14px',
  },
  labelTituloSelect: {
    fontSize: '14px',
    color: DARK_CYAN,
    marginTop: '14px',
  },
  searchField: {
    width: '99%',
    padding: '8px 8px 8px 0px',
  },
  orientacoes: {
    width: '100%',
  },
  container: {
    marginTop: '15px',
  },
  containerDropdownDateTimePickerInicio: {
    width: '17rem',
    marginTop: '10px',
    marginBottom: '12px',
  },
  containerDropdownDateTimePickerTermino: {
    width: '17rem',
    marginTop: '10px',
    marginBottom: '12px',
    marginLeft: '2px',
  },
  containerModeloDeRisco: {
    margin: '6px 0px',
  },
  containerTituloIconeModeloDeRisco: {
    marginBottom: '8px',
  },
});

const AbaCampanhasScapeZero = (props) => {
  const {
    titulo,
    setTitulo,
    dataHoraMinima,
    carregando,
    categoria,
    setCategoria,
    situacao,
    setSituacao,
    formaDeIngresso,
    setFormaDeIngresso,
    perfilAluno,
    setPerfilAluno,
    marca,
    setMarca,
    campus,
    setCampus,
    curso,
    setCurso,
    modalidade,
    setModalidade,
    turno,
    setTurno,
    setDataInicioCampanha,
    dataInicioCampanha,
    setDataTerminoCampanha,
    dataTerminoCampanha,
    dataInicioMatricula,
    setDataInicioMatricula,
    dataTerminoMatricula,
    setDataTerminoMatricula,
    criarCampanhaUpload,
    arquivoUpload,
    setArquivoUpload,
    setModeloDeRisco,
    modeloDeRisco,
    setImpactoDaCampanha,
    impactoDaCampanha,
    setGrupoDeVisualizacao,
    grupoDeVisualizacao,
    listInputFilter,
    listaInputModeloDeRisco,
  } = props;

  const classes = useStyles();
  const [dateTimePickerOpen, setDateTimePickerOpen] = useState(false);
  const [dateTimePickerOpen2, setDateTimePickerOpen2] = useState(false);

  const filtrarListaRisco = () => {
    if (!criarCampanhaUpload && listaInputModeloDeRisco.length > 0) {
      return categoria
        ? listaInputModeloDeRisco.filter((item) =>
            item.CategoryType.includes(categoria)
          )
        : listaInputModeloDeRisco.filter((item) =>
            item.CampaignType.includes('Filtragem')
          );
    }
    return listaInputModeloDeRisco;
  };

  return (
    <Grid
      item
      container
      className={classes.container}
      data-testid="criar-campanha-scape-zero"
    >
      <Grid item container>
        <Grid item container xs={10}>
          <Edit3 size={20} className={classes.iconeLabelTituloSelect} />
          <Typography variant="h5" className={classes.labelTituloSelect}>
            Título
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" className={classes.inputLabel}>
            {titulo.length}/100
          </Typography>
        </Grid>
      </Grid>

      <Grid item container>
        <TextField
          autoFocus
          variant="filled"
          size="small"
          placeholder=" Insira um título para sua campanha"
          inputProps={{
            style: {
              fontWeight: 'normal',
              fontSize: '14px',
              paddingTop: '10px',
              paddingBottom: '8px',
            },
            maxLength: 100,
            'data-testid': 'criar-campanha-scape-zero-titulo-input',
          }}
          className={classes.searchField}
          onChange={(e) => setTitulo(e.target.value)}
          value={titulo}
        />
      </Grid>

      {!criarCampanhaUpload && (
        <ObjetivosCampanha
          filtroObjetivos={listInputFilter.objetivosCampanha}
          carregando={carregando}
          categoria={categoria}
          setCategoria={setCategoria}
          situacao={situacao}
          setSituacao={setSituacao}
          perfilAluno={perfilAluno}
          setPerfilAluno={setPerfilAluno}
          formaDeIngresso={formaDeIngresso}
          setFormaDeIngresso={setFormaDeIngresso}
          dataInicioMatricula={dataInicioMatricula}
          setDataInicioMatricula={setDataInicioMatricula}
          dataTerminoMatricula={dataTerminoMatricula}
          setDataTerminoMatricula={setDataTerminoMatricula}
          modeloDeRisco={modeloDeRisco}
        />
      )}

      <Grid item container className={classes.containerModeloDeRisco}>
        <Grid>
          <Grid
            container
            alignItems="center"
            className={classes.containerTituloIconeModeloDeRisco}
          >
            <BarChart2 size={20} className={classes.iconeLabelTituloSelect} />
            <Typography variant="h5" className={classes.labelTituloSelect}>
              Modelo de Risco
            </Typography>
          </Grid>

          <SelectCampanha
            titulo="Preenchimento obrigatório"
            listaOpcoes={filtrarListaRisco()}
            handleAbrirSimpleDropdown
            carregando={carregando}
            model={modeloDeRisco}
            setModel={setModeloDeRisco}
            top="0"
            left="0"
          />
        </Grid>
        <Grid>
          <Grid
            container
            alignItems="center"
            className={classes.containerTituloIconeModeloDeRisco}
          >
            <ChevronsUp size={20} className={classes.iconeLabelTituloSelect} />
            <Typography variant="h5" className={classes.labelTituloSelect}>
              Impacto da Campanha
            </Typography>
          </Grid>

          <SelectCampanha
            titulo="Preenchimento Obrigatório"
            listaOpcoes={listInputFilter.impactosDaCampanha}
            handleAbrirSimpleDropdown
            carregando={carregando}
            model={impactoDaCampanha}
            setModel={setImpactoDaCampanha}
            valorInicial={impactosDaCampanha.Medio}
            top="0"
            left="0"
          />
        </Grid>
      </Grid>

      <Grid item container>
        <Grid container alignItems="center">
          <Users size={20} className={classes.iconeLabelTituloSelect} />
          <Typography variant="h5" className={classes.labelTituloSelect}>
            Grupo de Visualização
          </Typography>
        </Grid>
        <SelectCampanha
          titulo="Grupo"
          listaOpcoes={listInputFilter.gruposDeVisualizacao}
          handleAbrirSimpleDropdown={false}
          carregando={carregando}
          model={grupoDeVisualizacao}
          setModel={setGrupoDeVisualizacao}
          top="0"
          left="0"
          width="100%"
        />
      </Grid>

      {criarCampanhaUpload && (
        <UploadCampanha arquivo={arquivoUpload} setArquivo={setArquivoUpload} />
      )}

      {!criarCampanhaUpload && (
        <ParametrosIES
          filtroParametrosIES={listInputFilter.parametrosIES}
          carregando={carregando}
          modalidade={modalidade}
          setModalidade={setModalidade}
          marca={marca}
          setMarca={setMarca}
          campus={campus}
          setCampus={setCampus}
          curso={curso}
          setCurso={setCurso}
          turno={turno}
          setTurno={setTurno}
        />
      )}

      <Grid item container direction="row" alignItems="center">
        <Calendar size={20} className={classes.iconeLabelTituloSelect} />
        <Typography variant="h5" className={classes.labelTituloSelect}>
          Data e Hora - Início e Término
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid
          item
          container
          className={classes.containerDropdownDateTimePickerInicio}
        >
          <DropdownDateTimePicker
            mostrarDropdown={dateTimePickerOpen}
            setMostrarDropdown={setDateTimePickerOpen}
            setDataHora={setDataInicioCampanha}
            dataHora={dataInicioCampanha}
            dataHoraMinima={dataHoraMinima}
            inputValueOnInit={false}
            tamanhoTexto="17rem"
            top="32px"
            left="-272px"
            placeholder={placeholderDateTimer()}
          />
        </Grid>
        <Grid
          item
          container
          className={classes.containerDropdownDateTimePickerTermino}
        >
          <DropdownDateTimePicker
            mostrarDropdown={dateTimePickerOpen2}
            setMostrarDropdown={setDateTimePickerOpen2}
            dataHora={dataTerminoCampanha}
            setDataHora={setDataTerminoCampanha}
            tamanhoTexto="17rem"
            top="32px"
            left="-267px"
            inputValueOnInit={false}
            placeholder={placeholderDateTimer()}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AbaCampanhasScapeZero;
