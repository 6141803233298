import {
  ocultarNotificacaoAlerta,
  ocultarNotificacaoSucesso,
  ocultarNotificacaoErro,
} from './Store';
import Store from '../../setup/reducer';

function getIndex(lista, timestamp) {
  for (let i = 0; i < lista.length; i++) {
    if (lista[i].timestamp === timestamp) {
      return i;
    }
  }
  return -1;
}

export const ocultarMensagemAlerta = (timestamp) => {
  const index = getIndex(Store.getState().notificacao.listaAlerta, timestamp);
  if (index >= 0) {
    Store.dispatch(ocultarNotificacaoAlerta(index));
  }
};

export const ocultarMensagemSucesso = (timestamp) => {
  const index = getIndex(Store.getState().notificacao.listaSucesso, timestamp);
  if (index >= 0) {
    Store.dispatch(ocultarNotificacaoSucesso(index));
  }
};

export const ocultarMensagemErro = (timestamp) => {
  const index = getIndex(Store.getState().notificacao.listaErro, timestamp);
  if (index >= 0) {
    Store.dispatch(ocultarNotificacaoErro(index));
  }
};
