import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from '@material-ui/core';

const useStyles = makeStyles({
  row: {
    height: '50px',
  },
});

const LinhaVazia = () => {
  const classes = useStyles();
  return (
    <TableRow
      className={classes.row}
      data-testid="linha-vazia-gestao-mensagens"
    >
      <TableCell />
      <TableCell />
      <TableCell />
    </TableRow>
  );
};

export default LinhaVazia;
