import DashboardService from '../../../services/cockpit/DashboardService';

const buscarIndicadores = async (codigoIes, payload) => {
  const response = await DashboardService.buscarDadosGraficos(
    codigoIes,
    payload
  );
  if (!response) return [];

  const indicadores = response.map((item) => {
    return {
      valorPrimario: item.primary,
      valorSecundario: item.secondary,
    };
  });

  for (let i = 1; i < indicadores.length; i++) {
    if (indicadores[i].valorPrimario !== '0%') {
      return indicadores;
    }
  }
  return [];
};

const buscarArvore = async (codigoIes, payload) => {
  const response = await DashboardService.buscarDadosGraficos(
    codigoIes,
    payload
  );
  if (!response) return [];

  const arvore = response.map((item) => {
    return {
      titulo: item.description,
      valorPrimario: item.primary,
      valorSecundario: item.secondary,
      tooltip: item.tooltip,
    };
  });

  for (let i = 1; i < arvore.length; i++) {
    if (
      Number(arvore[i].valorPrimario) !== 0 &&
      arvore[i].valorSecundario !== '0%'
    ) {
      return arvore;
    }
  }
  return [];
};

export const buscarDadosIndicadores = async (codigoIes, filtro) => {
  const payloadIndicadores = {
    institution_id: codigoIes,
    chart_name: '05_mpe_tree_indexes',
    filters: filtro,
  };
  return buscarIndicadores(payloadIndicadores);
};

export const buscarDadosArvore = async (codigoIes, filtro) => {
  const payloadArvore = {
    institution_id: codigoIes,
    chart_name: '04_mpe_tree',
    filters: filtro,
  };
  return buscarArvore(payloadArvore);
};
