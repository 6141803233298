import axios from '../apis/axiosInstanceScape0Crm';
import { checkResponse } from '../shared/util/Utils';

class AlunoService {
  async buscarAlunos(queryStringParam) {
    return axios
      .get('/campaign_recipients', {
        params: queryStringParam,
      })
      .then(checkResponse);
  }

  async buscarHistoricoAtendimento(queryStringParam) {
    return axios
      .get('/conversation', {
        params: queryStringParam,
      })
      .then(checkResponse);
  }

  async ligarParaAluno(bodyParams) {
    return axios.put('/session', bodyParams).then(checkResponse);
  }

  async registrarInelegiblidadeAluno(bodyStringParam) {
    return axios
      .post('/conversation?type=inelegibilty', bodyStringParam)
      .then(checkResponse);
  }

  async getInfoLgpd(payload) {
    return axios.post('/lgpd', payload).then(checkResponse);
  }

  async obterRecipiente(queryStringParam, studentId) {
    return axios
      .get(`/campaign_recipients/${studentId}`, {
        params: queryStringParam,
      })
      .then(checkResponse);
  }
}

export default new AlunoService();
