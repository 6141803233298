import React from 'react';
import { Typography, Grid, Box, Icon } from '@material-ui/core';

const CardTitle = (props) => {
  const { text, icon } = props;

  return (
    <Grid
      item
      container
      xs={12}
      direction="row"
      alignItems="center"
      wrap="nowrap"
    >
      <Box mr={2} p={1} borderRadius={4} className="cardIconContainer">
        <Icon className="cardIcon" data-testid="icon-card-title">
          {icon}
        </Icon>
      </Box>
      <Typography variant="h3" data-testid="tipografia-card-title">
        {' '}
        {text}{' '}
      </Typography>
    </Grid>
  );
};

export default CardTitle;
