import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Card from '../../../../shared/util/components/Card';
import CardProdutividade from '../../../../shared/util/components/CardProdutividade';
import Graficos from '../../../graficos/views/Graficos';
import { FONT_FAMILY, MEDIUM_GRAY } from '../../../../theme';
import FiltroDesempenho from '../../../filtroDesempenho/views/FiltroDesempenho';
import GraficoProdutividade from '../../../graficos/views/GraficoProdutividade/GraficoProdutividade';
import CardRepositorioPerformance from '../../views/CardRepositorioPerformance';
import {
  deepCopy,
  mensagemBoasVindasHorario,
} from '../../../../shared/util/Utils';
import { INITIAL_STATE as INITIAL_STATE_FILTRO } from '../../../filtroDesempenho/Store';

const useStyles = makeStyles({
  container: {
    width: '100%',
    margin: '0px',
  },
  mensagemBemVindo: {
    padding: '40px 20px 20px 20px !important',
  },
  bomDia: {
    color: MEDIUM_GRAY,
    fontFamily: FONT_FAMILY,
    marginRight: '12px',
  },
  containerDesempenho: {
    maxHeight: '560px',
  },
  containerCardsBemVindo: {
    width: '95%',
    margin: '0px',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  containerCards: {
    width: '97.5%',
    margin: '0px',
  },
  mensagemBemVindoDesempenho: {
    marginTop: '4rem !important',
  },
});

export const DesempenhoHome = (props) => {
  const { usuario } = props;

  const [filtroAplicado, setFiltroAplicado] = useState(
    deepCopy(INITIAL_STATE_FILTRO)
  );

  const classes = useStyles();

  const BLOCKED_SCREEN = process.env.REACT_APP_BLOCKED_SCREEN !== 'true';

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        direction="column"
        className={`${classes.container}`}
      >
        <Grid
          item
          container
          id="welcome-message"
          wrap="nowrap"
          alignItems="flex-start"
          className={`${classes.mensagemBemVindo} ${
            classes.containerCardsBemVindo
          } ${
            BLOCKED_SCREEN
              ? classes.mensagemBemVindo
              : classes.mensagemBemVindoDesempenho
          }`}
        >
          <Typography variant="h1" noWrap className={classes.bomDia}>
            {mensagemBoasVindasHorario()}
          </Typography>
          <Typography variant="h1" noWrap>{`${
            usuario && usuario.name
          }!`}</Typography>
        </Grid>

        <FiltroDesempenho
          filtroAplicado={filtroAplicado}
          setFiltroAplicado={setFiltroAplicado}
        />

        <Grid container spacing={3} className={classes.containerCards}>
          <Grid item md={12} lg={8}>
            <CardRepositorioPerformance />
            <Card className={classes.containerDesempenho}>
              <Graficos filtro={filtroAplicado} />
            </Card>
          </Grid>
          <Grid item md={12} lg={4}>
            <CardProdutividade>
              <GraficoProdutividade filtro={filtroAplicado} />
            </CardProdutividade>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const mapStateToProps = (state) => ({
  usuario: state.login.usuario,
});

export default connect(mapStateToProps)(DesempenhoHome);
