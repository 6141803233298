import {
  Button,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ChevronLeft, Plus, Search, Settings } from 'react-feather';
import CloseIcon from '../../../shared/componentes/CloseIcon';
import CardTitle from '../../../shared/util/components/CardTitle';
import { normalizeText } from '../../../shared/util/Utils';
import {
  DARK_CYAN,
  FONT_FAMILY_SEMIBOLD,
  GRAY,
  LIGHT_SEA_GREEN_HOVER,
  WHITE,
} from '../../../theme';
import ModalAdicionarMembrosGrupo from './ModalAdicionarMembrosGrupo';
import TabelaGerenciarMembros from './TabelaGerenciarMembros';

const useStyles = makeStyles(() => ({
  containerTitle: {
    display: 'grid',
    gridTemplateColumns: 'max-content min-content',
    justifyContent: 'space-between',
    marginBottom: '12px',
  },
  containerVoltar: {
    position: 'relative',
    cursor: 'pointer',
  },
  searchField: {
    width: '395px',
    minWidth: '50px',

    '& .MuiFilledInput-inputMarginDense': {
      paddingTop: '12px',
      paddingBottom: '11px',
    },
  },
  containerTextFielButton: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  containerCardTitle: {
    display: 'grid',
    gridTemplateColumns: 'max-content min-content',
  },
  containerTituloTabela: {
    display: 'grid',
    gridTemplateColumns: 'max-content min-content',
    marginTop: '28px',
  },
  botaoAdicionar: {
    backgroundColor: DARK_CYAN,
    height: '42px',
    width: '210px',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: LIGHT_SEA_GREEN_HOVER,
    },
  },
  textoBotaoAdicionar: {
    fontSize: '14px',
    color: WHITE,
  },
  textoVoltar: {
    fontSize: '18px',
    color: GRAY,
    fontFamily: FONT_FAMILY_SEMIBOLD,
  },
}));

const GerenciarMembros = (props) => {
  const classes = useStyles();

  const {
    usuarioLogado,
    listaPermissoes,
    listaUsuarios,
    setListaUsuarios,
    grupoGerenciado,
    setGrupoGerenciado,
    handleFecharModal,
    buscaGrupos,
    setBuscaGrupos,
    carregando,
  } = props;

  const [listaUsuariosFiltrados, setListaUsuariosFiltrados] = useState([]);
  const [listaUsuariosHierarquiaMenor, setListaUsuariosHierarquiaMenor] =
    useState([]);
  const [modalAdicionarMembrosGrupoAberto, setModalAdicionarMembrosGrupo] =
    useState(false);

  const tituloTabela = () => {
    return `Permissões de Grupos de Visualização - ${grupoGerenciado}`;
  };

  useEffect(() => {
    if (listaUsuarios && usuarioLogado && listaPermissoes) {
      const newListaHierarquiaMenor = [];
      for (const usuario of listaUsuarios) {
        if (
          usuarioLogado.Permissao.HierarchyLevel <=
            listaPermissoes.find(
              (element) =>
                element.PermissionGroupId === usuario.PermissionGroupId
            ).HierarchyLevel &&
          !usuario.VisualizationGroup.includes(grupoGerenciado)
        ) {
          newListaHierarquiaMenor.push(usuario);
        }
      }

      setListaUsuariosHierarquiaMenor(newListaHierarquiaMenor);
    }
  }, [listaUsuarios, usuarioLogado, listaPermissoes, grupoGerenciado]);

  useEffect(() => {
    if (
      buscaGrupos &&
      buscaGrupos.length &&
      buscaGrupos.length > 0 &&
      listaUsuarios &&
      grupoGerenciado
    ) {
      const listaFiltrada = listaUsuarios.filter(
        (element) =>
          normalizeText(element.Login).indexOf(normalizeText(buscaGrupos)) !==
            -1 && element.VisualizationGroup.includes(grupoGerenciado)
      );

      setListaUsuariosFiltrados(listaFiltrada);
    } else {
      const listadoGrupo = listaUsuarios.filter((element) =>
        element.VisualizationGroup.includes(grupoGerenciado)
      );
      setListaUsuariosFiltrados(listadoGrupo);
    }
  }, [buscaGrupos, listaUsuarios, grupoGerenciado]);

  return (
    <>
      <Grid container className={classes.containerTitle} alignItems="center">
        <Grid
          container
          direction="row"
          alignItems="center"
          onClick={() => setGrupoGerenciado('nenhum')}
          className={classes.containerVoltar}
        >
          <ChevronLeft size={24} color={GRAY} />
          <Typography className={classes.textoVoltar}>Voltar</Typography>
        </Grid>

        <CloseIcon
          tooltipTitle="Fechar Permissões de Usuário"
          handleFechar={handleFecharModal}
        />
      </Grid>
      <Divider variant="fullWidth" />
      <Grid container className={classes.containerTitle} alignItems="center">
        <Grid
          container
          alignItems="center"
          className={classes.containerTituloTabela}
        >
          <CardTitle
            text={tituloTabela()}
            icon={<Settings size={24} color={DARK_CYAN} />}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        display="inline"
        className={classes.containerTextFielButton}
      >
        <TextField
          variant="filled"
          value={buscaGrupos}
          onInput={(e) => {
            setBuscaGrupos(e.target.value);
          }}
          size="small"
          placeholder="Pesquise um Nome de Usuário ou Login..."
          InputProps={{
            style: {
              fontWeight: 'normal',
              backgroundColor: 'rgba(158, 171, 181, 0.05)',
              fontSize: '16px',
            },
            endAdornment: <Search size={20} color={GRAY} />,
            'data-testid': 'textfield-gerenciar-membros',
          }}
          className={classes.searchField}
        />
        <Button
          type="submit"
          data-testid="button-adicionar-membros-grupo"
          disableElevation
          startIcon={<Plus size={20} color={WHITE} />}
          className={`${classes.botaoAdicionar} "Add-Btn"`}
          onClick={() => setModalAdicionarMembrosGrupo(true)}
        >
          <Typography className={classes.textoBotaoAdicionar} variant="h6">
            Adicionar Membros
          </Typography>
        </Button>
      </Grid>
      <TabelaGerenciarMembros
        usuarioLogado={usuarioLogado}
        listaPermissoes={listaPermissoes}
        listaUsuarios={listaUsuarios}
        setListaUsuarios={setListaUsuarios}
        listaUsuariosFiltrados={listaUsuariosFiltrados}
        setListaUsuariosFiltrados={setListaUsuariosFiltrados}
        nomeGrupoVisualizacao={grupoGerenciado}
        showFooter={listaUsuariosFiltrados && listaUsuariosFiltrados.length > 0}
        carregando={carregando}
      />
      {modalAdicionarMembrosGrupoAberto && (
        <ModalAdicionarMembrosGrupo
          modalAdicionarMembrosGrupoAberto={modalAdicionarMembrosGrupoAberto}
          setModalAdicionarMembrosGrupo={setModalAdicionarMembrosGrupo}
          listaUsuarios={listaUsuarios}
          listaUsuariosHierarquiaMenor={listaUsuariosHierarquiaMenor}
          setListaUsuariosHierarquiaMenor={setListaUsuariosHierarquiaMenor}
          setListaUsuarios={setListaUsuarios}
          nomeGrupoVisualizacao={grupoGerenciado}
        />
      )}
    </>
  );
};

export default GerenciarMembros;
