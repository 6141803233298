import React from 'react';
import { Grid, Box } from '@material-ui/core';
import '../css-componentes/Card.css';

const CardFiltro = (props) => {
  const { height, children } = props;

  return (
    <Grid item xs={12}>
      <Box className="card">
        <Grid
          container
          direction="row"
          height={height}
          className="cardFiltro"
          data-testid="card-filtro"
        >
          {children}
        </Grid>
      </Box>
    </Grid>
  );
};

export default CardFiltro;
