import React from 'react';
import { Typography, Grid, Box, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MEDIUM_GRAY } from '../../../theme';

const useStyles = makeStyles({
  title: {
    overflowWrap: 'anywhere',
    paddingRight: '20px',
  },
  subtitle: {
    fontSize: '14px',
    color: MEDIUM_GRAY,
  },
});

const CardTitle = (props) => {
  const classes = useStyles();

  const { text, icon, subTitle, style, idName, dataTestid } = props;

  const renderizarSubtitulo = (subTitulo) =>
    subTitulo ? (
      <Typography noWrap variant="body1" className={classes.subtitle}>
        {subTitulo}
      </Typography>
    ) : (
      <></>
    );

  return (
    <Grid
      item
      id={idName}
      container
      xs={12}
      direction="row"
      alignItems="center"
      wrap="nowrap"
      style={style}
    >
      <Box mr={2} p={1} borderRadius={4} className="cardIconContainer">
        <Icon className="cardIcon">{icon}</Icon>
      </Box>
      <Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        wrap="nowrap"
      >
        <Typography
          variant="h3"
          className={classes.title}
          data-testid={dataTestid}
        >
          {' '}
          {text}{' '}
        </Typography>
        {renderizarSubtitulo(subTitle)}
      </Grid>
    </Grid>
  );
};

export default CardTitle;
