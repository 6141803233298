import { React, useEffect, useState } from 'react';
import {
  makeStyles,
  Dialog,
  DialogContent,
  Grid,
  Box,
  Typography,
} from '@material-ui/core';
import { User, Mail } from 'react-feather';
import CardTitle from '../../../shared/util/components/CardTitle';
import CloseIcon from '../../../shared/componentes/CloseIcon';
import Loading from '../../../shared/componentes/Loading';
import ModalConfirmacaoEdicaoRamal from './ModalConfirmacaoEdicaoRamal';
import {
  DARK_CYAN,
  WHITE,
  SHADOW_MODAL,
  LIGHT_CYAN,
  LIGHT_GRAY,
} from '../../../theme';
import GruposVisualizacaoPerfil from './GruposVisualizacaoPerfil';
import ItemGraficoPerfilProdutividade from '../../graficos/views/GraficoProdutividade/ItemGraficoPerfilProdutividade';
import PerfilManager from '../PerfilManager';
import EditarRamalPerfil from './EditarRamalPerfil';
import DadosNaoEncontradosPerfil from './DadosNaoEncontradosPerfil';
import { deepCopy } from '../../../shared/util/Utils';

const useStyles = makeStyles(() => ({
  modal: {
    maxWidth: '948px',
    minHeight: '560px',
    width: '948px',
    backgroundColor: WHITE,
    boxShadow: `0 2px 10px 0 ${SHADOW_MODAL}`,
    borderRadius: '8px',
  },
  backgroundProps: {
    background: 'rgba(0,0,0,0.3)',
  },
  container: {
    height: '100%',
    width: '100%',
    padding: '0 0 16px 0 !important',
    overflow: 'hidden',
  },
  conteudo: {
    padding: '0 30px',
  },
  containerDivider: {
    borderBottom: `1px solid ${LIGHT_GRAY}`,
    marginBottom: '24px',
    padding: '16px',
  },
  box: {
    backgroundColor: LIGHT_CYAN,
    width: '80px',
    height: '80px',
    padding: '20px',
  },
  OutsideBox: {
    backgroundColor: WHITE,
    width: '88px',
    height: '88px',
    boxShadow: '0 2px 12px 0 rgba(0,0,0,0.07), 0 9px 9px 0 rgba(0,0,0,0.05)',
    padding: '4px',
    marginTop: '15px',
    marginBottom: '15px',
  },
  labelTitulo: {
    fontSize: '20px',
    color: DARK_CYAN,
    fontWeight: '800',
  },
  labelSubTituloLadoDireito: {
    fontSize: '20px',
    color: DARK_CYAN,
    fontWeight: '800',
    marginBottom: '20px',
  },
  labelSubTitulo: {
    fontSize: '16px',
    color: DARK_CYAN,
    fontWeight: '700',
  },
  iconeLabel: {
    color: DARK_CYAN,
    marginRight: '10px',
  },
  conteudoParagrafo: {
    fontSize: '16px',
  },
  descricaoOperador: {
    borderTop: `1px solid ${LIGHT_GRAY}`,
    borderBottom: `1px solid ${LIGHT_GRAY}`,
    paddingTop: '20px',
    paddingBottom: '20px',
    marginBottom: '20px',
  },
  flexGrid: {
    display: 'flex',
  },
  borderRight: {
    position: 'relative',
    height: 'fit-content',
    '&:after': {
      content: '""',
      background: LIGHT_GRAY,
      position: 'absolute',
      bottom: '50%',
      transform: 'translateY(50%)',
      right: '0',
      height: '85%',
      width: '1px',
    },
  },
  containerEmail: {
    padding: '8px',
  },
}));

const ModalMeuPerfil = (props) => {
  const { modalAberto, handleFecharModal, login, atualizaUserScape0 } = props;

  const classes = useStyles();

  const [carregando, setCarregando] = useState(false);
  const [ramalEmEdicao, setRamalEmEdicao] = useState(false);
  const [modalConfimacaoAberto, setModalConfimacaoAberto] = useState(false);

  const email = login && login.usuario && login.usuario.email;

  useEffect(() => {
    const getUsuarioLogado = async () => {
      setCarregando(true);
      if (email) {
        const response = await PerfilManager.getUsuario(email);
        if (response) {
          atualizaUserScape0(response.data);
        }
      }
      setCarregando(false);
    };
    if (modalAberto) {
      getUsuarioLogado();
      setRamalEmEdicao(false);
    }
  }, [atualizaUserScape0, email, modalAberto]);

  const cancelarEdicaoRamal = () => {
    handleFecharModal();
    setModalConfimacaoAberto(false);
  };

  const verificarModalCancelar = () => {
    if (ramalEmEdicao) {
      setModalConfimacaoAberto(true);
    } else {
      cancelarEdicaoRamal();
    }
  };

  const handleEditarRamal = async (novoRamal) => {
    const usuarioComRamalAtualizado = deepCopy(login.usuarioScape0);
    usuarioComRamalAtualizado.Ramal = novoRamal;
    const success = await PerfilManager.editarRamal(email, novoRamal);
    if (success) {
      atualizaUserScape0(usuarioComRamalAtualizado);
    }
  };

  const verificarSeUsuarioRealizaAtendimentos = () => {
    return (
      login && login.usuarioScape0 && login.usuarioScape0.HierarchyLevel > 1
    );
  };

  const verificarSeExistemAtendimentosRealizados = () => {
    return (
      login &&
      login.usuarioScape0 &&
      login.usuarioScape0.Perfomance &&
      login.usuarioScape0.Perfomance.FinishedCount > 0
    );
  };

  return (
    <>
      <Dialog
        open={modalAberto}
        onClose={handleFecharModal}
        aria-labelledby="modal-titulo"
        aria-describedby="modal-descricao"
        scroll="body"
        classes={{ paper: classes.modal }}
        BackdropProps={{
          classes: {
            root: classes.backgroundProps,
          },
        }}
      >
        {carregando && <Loading />}
        <DialogContent className={classes.container}>
          <Grid container className={classes.containerDivider}>
            <Grid
              container
              className={classes.containerTitle}
              alignItems="center"
              wrap="nowrap"
            >
              <Grid
                container
                alignItems="center"
                className={classes.containerCardTitle}
              >
                <CardTitle
                  text="Meu Perfil"
                  icon={<User size={24} color={DARK_CYAN} />}
                />
              </Grid>
              <CloseIcon
                tooltipTitle="Fechar Permissões de Usuário"
                handleFechar={() => verificarModalCancelar()}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={6}
            direction="row"
            justifyContent="center"
            className={classes.conteudo}
          >
            <Grid sm={5} item className={classes.borderRight}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.descricaoOperador}
              >
                <Typography>
                  {login &&
                    login.usuarioScape0 &&
                    login.usuarioScape0.GroupsName}
                </Typography>
                <Box borderRadius={50} className={classes.OutsideBox}>
                  <Box borderRadius={50} className={classes.box}>
                    <User style={{ height: 40, width: 40, color: DARK_CYAN }} />
                  </Box>
                </Box>
                <Typography className={classes.labelTitulo}>
                  {login &&
                    login.usuarioScape0 &&
                    login.usuarioScape0.Perfomance &&
                    login.usuarioScape0.Perfomance.OperatorName}
                </Typography>
              </Grid>
              <Grid className={classes.containerEmail}>
                <Grid container direction="row" className="pt-2">
                  <Mail size={20} className={classes.iconeLabel} />
                  <Typography className={classes.labelSubTitulo}>
                    E-Mail
                  </Typography>
                </Grid>
                <Typography
                  className={`${classes.conteudoParagrafo} pt-2 mb-6`}
                >
                  {login && login.usuario && login.usuario.email}
                </Typography>
              </Grid>
              <EditarRamalPerfil
                ramal={
                  login && login.usuarioScape0 && login.usuarioScape0.Ramal
                }
                ramalEmEdicao={ramalEmEdicao}
                setRamalEmEdicao={setRamalEmEdicao}
                handleEditarRamal={handleEditarRamal}
              />
            </Grid>
            <Grid item sm={7}>
              {!verificarSeExistemAtendimentosRealizados() &&
                verificarSeUsuarioRealizaAtendimentos() && (
                  <DadosNaoEncontradosPerfil />
                )}
              {verificarSeExistemAtendimentosRealizados() &&
                verificarSeUsuarioRealizaAtendimentos() && (
                  <>
                    <Typography className={classes.labelSubTituloLadoDireito}>
                      Meus Atendimentos
                    </Typography>
                    <ItemGraficoPerfilProdutividade
                      operatorName={
                        login && login.usuario && login.usuario.name
                      }
                      notFinishedAttemptsCount={
                        login &&
                        login.usuarioScape0 &&
                        login.usuarioScape0.Perfomance &&
                        login.usuarioScape0.Perfomance.NotFinishedAttemptsCount
                      }
                      notFinishedAttemptsPercent={
                        login &&
                        login.usuarioScape0 &&
                        login.usuarioScape0.Perfomance &&
                        login.usuarioScape0.Perfomance
                          .NotFinishedAttemptsPercent
                      }
                      remedyCount={
                        login &&
                        login.usuarioScape0 &&
                        login.usuarioScape0.Perfomance &&
                        login.usuarioScape0.Perfomance.RemedyCount
                      }
                      remedyPercent={
                        login &&
                        login.usuarioScape0 &&
                        login.usuarioScape0.Perfomance &&
                        login.usuarioScape0.Perfomance.RemedyPercent
                      }
                      finishedCount={
                        login &&
                        login.usuarioScape0 &&
                        login.usuarioScape0.Perfomance &&
                        login.usuarioScape0.Perfomance.FinishedCount
                      }
                      finishedPercent={
                        login &&
                        login.usuarioScape0 &&
                        login.usuarioScape0.Perfomance &&
                        login.usuarioScape0.Perfomance.FinishedPercent
                      }
                      generalServiceAverage={
                        login &&
                        login.usuarioScape0 &&
                        login.usuarioScape0.Perfomance &&
                        login.usuarioScape0.Perfomance.GeneralServiceAverage
                      }
                      operatorServiceAverage={
                        login &&
                        login.usuarioScape0 &&
                        login.usuarioScape0.Perfomance &&
                        login.usuarioScape0.Perfomance.OperatorServiceAverage
                      }
                      operatorTotalTime={
                        login &&
                        login.usuarioScape0 &&
                        login.usuarioScape0.Perfomance &&
                        login.usuarioScape0.Perfomance.OperatorTotalTime
                      }
                      operatorTotalTimePercent={
                        login &&
                        login.usuarioScape0 &&
                        login.usuarioScape0.Perfomance &&
                        login.usuarioScape0.Perfomance.OperatorTotalTimePercent
                      }
                    />
                  </>
                )}
              <GruposVisualizacaoPerfil
                gruposDeVisualizacao={
                  login &&
                  login.usuarioScape0 &&
                  login.usuarioScape0.VisualizationGroup
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ModalConfirmacaoEdicaoRamal
        modalAberto={modalConfimacaoAberto}
        setModalAberto={setModalConfimacaoAberto}
        handleBotaoDireito={() => setModalConfimacaoAberto(false)}
        handleBotaoEsquerdo={cancelarEdicaoRamal}
      />
    </>
  );
};

export default ModalMeuPerfil;
