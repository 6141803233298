import React, { useState, useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MEDIUM_GRAY, GRAY, DARK_CYAN } from '../../../theme';
import LinhaCampanha from './LinhaCampanha';
import LinhaVazia from './LinhaVazia';

const useStyles = makeStyles({
  table: {
    marginTop: '30px',
  },
  headerFont: {
    fontSize: '12px',
    color: MEDIUM_GRAY,
  },
  status: {
    width: '10% !important',
  },
  tituloCampanha: {
    width: '26% !important',
  },
  categoria: {
    width: '10% !important',
  },
  total: {
    width: '10% !important',
  },
  atendidos: {
    width: '10% !important',
  },
  aguardando: {
    width: '10% !important',
  },
  evasaoCampanha: {
    width: '10% !important',
  },
  inicioCampanha: {
    width: '6% !important',
  },
  terminoCampanha: {
    width: '6% !important',
  },
  iconsTable: {
    width: '1% !important',
  },
  caption: {
    color: DARK_CYAN,
  },
});

const TabelaCampanhas = (props) => {
  const {
    campanhas,
    handleExclusaoCampanha,
    handleEdicaoCampanha,
    handleAlterarStatusCampanha,
    filtroTela,
    possuiPermissaoCriarCampanha,
    busca,
  } = props;
  const [page, setPage] = useState(0);

  const rowsPerPage = 8;
  const classes = useStyles();

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const getNumPages = () =>
    Math.ceil((campanhas && campanhas.length) / rowsPerPage) - 1;

  const completarItens = (nItems) => {
    const list = [];
    for (let i = 0; i < nItems; i++) {
      const index = i + rowsPerPage - nItems;
      list.push(<LinhaVazia key={index} />);
    }
    return list;
  };

  useEffect(() => {
    setPage(0);
  }, [busca]);

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.status}>
            <Typography align="left" className={classes.headerFont}>
              Status
            </Typography>
          </TableCell>
          <TableCell className={classes.tituloCampanha}>
            <Typography className={classes.headerFont}>
              Título da Campanha
            </Typography>
          </TableCell>
          <TableCell className={classes.categoria}>
            <Typography align="left" className={classes.headerFont}>
              Categoria
            </Typography>
          </TableCell>
          <TableCell className={classes.total}>
            <Typography align="left" className={classes.headerFont}>
              Total
            </Typography>
          </TableCell>
          <TableCell className={classes.atendidos}>
            <Typography align="left" className={classes.headerFont}>
              Atendidos
            </Typography>
          </TableCell>
          <TableCell className={classes.aguardando}>
            <Typography align="left" className={classes.headerFont}>
              Aguardando
            </Typography>
          </TableCell>
          <TableCell className={classes.evasaoCampanha}>
            <Typography align="left" className={classes.headerFont}>
              Evasão
            </Typography>
          </TableCell>
          <TableCell className={classes.inicioCampanha}>
            <Typography align="left" className={classes.headerFont}>
              Inicio
            </Typography>
          </TableCell>
          <TableCell className={classes.terminoCampanha}>
            <Typography align="left" className={classes.headerFont}>
              Termino
            </Typography>
          </TableCell>
          <TableCell className={classes.iconsTable}>
            <Typography align="center" className={classes.headerFont} />
          </TableCell>
          <TableCell className={classes.iconsTable}>
            <Typography align="center" className={classes.headerFont} />
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {campanhas &&
          campanhas
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((campanha, index) => (
              <LinhaCampanha
                idCampanha={campanha.idCampanha}
                tituloCampanha={campanha.tituloCampanha}
                totalAlunos={campanha.totalAlunos}
                totalAtendidos={campanha.totalAtendidos}
                totalAguardando={campanha.totalAguardando}
                totalEvasao={campanha.totalEvasao}
                dataInicio={campanha.dataInicio}
                dataTermino={campanha.dataTermino}
                horaInicio={campanha.horaInicio}
                horaTermino={campanha.horaTermino}
                listaIES={campanha.listaIES}
                listaAluno={campanha.listaAluno}
                tagStatus={campanha.tagStatus}
                categoria={campanha.categoria}
                descricaoCampanha={campanha.descricaoCampanha}
                modeloRisco={campanha.modeloRisco}
                key={campanha.idCampanha}
                tagStatusColor={campanha.tagStatusColor}
                tagCaractereColor={campanha.tagCaractereColor}
                lineNum={index}
                filtroCampanha={campanha.filtro}
                filtroTela={filtroTela}
                handleExclusao={handleExclusaoCampanha}
                handleEdicaoCampanha={handleEdicaoCampanha}
                handleAlterarStatusCampanha={handleAlterarStatusCampanha}
                possuiPermissaoCriarCampanha={possuiPermissaoCriarCampanha}
                prioridade={campanha.prioridade}
                grupoVisualizacao={campanha.grupoVisualizacao}
                mensagensWhatsapp={campanha.mensagensWhatsapp}
              />
            ))}
        {campanhas && campanhas.length
          ? completarItens(
              Math.abs(
                campanhas.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .length - rowsPerPage
              )
            )
          : null}
      </TableBody>

      {campanhas && campanhas.length > 0 && (
        <TableFooter>
          <TableRow>
            <TablePagination
              classes={{ caption: classes.caption }}
              count={campanhas.length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              page={page}
              onPageChange={handleChangePage}
              backIconButtonProps={{
                cursor: 'pointer',
                style: {
                  cursor: 'pointer',
                  marginRight: '16px',
                  color: page === 0 ? GRAY : DARK_CYAN,
                },
                'data-testid': 'previous-page-button',
              }}
              nextIconButtonProps={{
                style: {
                  cursor: 'pointer',
                  marginLeft: '16px',
                  color: page === getNumPages() ? GRAY : DARK_CYAN,
                },
                'data-testid': 'next-page-button',
              }}
              labelDisplayedRows={(obj) =>
                `${obj.from}-${obj.to} de ${obj.count}`
              }
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

export default TabelaCampanhas;
