import React, { useState } from 'react';
import { User } from 'react-feather';
import { Grid, Typography, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DARK_CYAN } from '../../../theme';
import {
  labelSelectCriarCampanha,
  placeholderDatePicker,
} from '../../../shared/util/Constantes';
import SelectCampanha from '../../../shared/componentes/SelectCampanha';
import DropdownDatePicker from '../../../shared/componentes/DropdownDatePicker';

const useStyles = makeStyles({
  subtitulo: {
    fontSize: '14px',
    color: DARK_CYAN,
  },

  iconeSubtitulo: {
    marginTop: '4px',
  },
  containerFiltro: {
    marginTop: '10px',
  },
  containerDropdownDateTimePickerInicio: {
    width: '17rem',
    marginTop: '4px',
  },
  containerDropdownDateTimePickerTermino: {
    width: '17rem',
    marginTop: '4px',
    marginLeft: '2px',
  },
});

const ObjetivosCampanha = (props) => {
  const {
    filtroObjetivos,
    carregando,
    categoria,
    setCategoria,
    situacao,
    setSituacao,
    formaDeIngresso,
    setFormaDeIngresso,
    perfilAluno,
    setPerfilAluno,
    dataInicioMatricula,
    setDataInicioMatricula,
    dataTerminoMatricula,
    setDataTerminoMatricula,
    modeloDeRisco,
  } = props;
  const [dateTimePickerOpen, setDateTimePickerOpen] = useState(false);
  const [dateTimePickerOpen2, setDateTimePickerOpen2] = useState(false);

  const classes = useStyles();

  return (
    <Grid
      item
      container
      direction="row"
      alignItems="flex-end"
      data-testid="container-objetivos-campanha-criar-campanha"
      className={classes.containerFiltro}
    >
      <Icon>
        <User size={18} color={DARK_CYAN} />
      </Icon>
      <Typography noWrap variant="h5" className={classes.subtitulo}>
        Objetivos
      </Typography>
      <Grid item container direction="row" className={classes.containerFiltro}>
        <SelectCampanha
          titulo={labelSelectCriarCampanha.Categoria}
          listaOpcoes={
            modeloDeRisco
              ? filtroObjetivos.Categoria.filter(
                  (item) => item.RiskType.includes(modeloDeRisco) && item
                )
              : filtroObjetivos.Categoria
          }
          handleAbrirSimpleDropdown
          carregando={carregando}
          model={categoria}
          setModel={setCategoria}
        />
        <SelectCampanha
          titulo={labelSelectCriarCampanha.Situacao}
          listaOpcoes={filtroObjetivos.Situacao}
          handleAbrirSimpleDropdown
          carregando={carregando}
          model={situacao}
          setModel={setSituacao}
        />
        <SelectCampanha
          titulo={labelSelectCriarCampanha.Aluno}
          listaOpcoes={filtroObjetivos.Aluno}
          handleAbrirSimpleDropdown
          carregando={carregando}
          model={perfilAluno}
          setModel={setPerfilAluno}
        />
        <SelectCampanha
          titulo={labelSelectCriarCampanha.FormaDeIngresso}
          listaOpcoes={filtroObjetivos.FormaDeIngresso}
          carregando={carregando}
          model={formaDeIngresso}
          setModel={setFormaDeIngresso}
        />
        <Grid
          item
          container
          className={classes.containerDropdownDateTimePickerInicio}
        >
          <DropdownDatePicker
            mostrarDropdown={dateTimePickerOpen}
            setMostrarDropdown={setDateTimePickerOpen}
            data={dataInicioMatricula}
            setData={setDataInicioMatricula}
            top="34px"
            left="-272px"
            placeholder={placeholderDatePicker.placeholderInicio}
          />
        </Grid>
        <Grid
          item
          container
          className={classes.containerDropdownDateTimePickerTermino}
        >
          <DropdownDatePicker
            mostrarDropdown={dateTimePickerOpen2}
            setMostrarDropdown={setDateTimePickerOpen2}
            data={dataTerminoMatricula}
            setData={setDataTerminoMatricula}
            top="34px"
            left="-272px"
            placeholder={placeholderDatePicker.placeholderTermino}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ObjetivosCampanha;
