import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Typography, Grid, Card } from '@material-ui/core';
import { MEDIUM_GRAY, FONT_FAMILY } from '../../../../theme';
import {
  obterFiltroGrafico,
  deepCopy,
  mensagemBoasVindasHorario,
} from '../../../../shared/util/Utils';
import GestaoCampanhasWhatsapp from '../../views/GestaoCampanhasWhatsapp';

const useStyles = makeStyles({
  containerPrincipal: {
    overflowY: 'scroll',
    maxHeight: 'calc(100vh - 48px)',
    scrollbarColor: '#bbbbbe white',
    scrollbarWidth: 'thin',
    width: '100%',
    margin: '0px',
  },
  mensagemBemVindo: {
    marginTop: '5rem',
    width: '95%',
    margin: '0px',
  },
  mensagemBemVindoBlockedScreen: {
    marginTop: '20px',
    width: '95%',
    margin: '0px',
  },
  bomDia: {
    color: MEDIUM_GRAY,
    fontFamily: FONT_FAMILY,
    marginRight: '12px',
  },
  containerCards: {
    width: '95%',
    height: 'fit-content',
  },
  card: {
    width: '100%',
  },
  containerConteudoCards: {
    width: '100%',
    margin: '0px',
  },
});

const CampanhasWhatsappHome = (props) => {
  const { nomeOperator, filter } = props;

  const classes = useStyles();
  const referenciaCampanhas = useRef(null);
  const referenciaDashboard = useRef(null);
  const [filtroAtual, setFiltroAtual] = useState(filter);
  const [filtroAplicado, setFiltroAplicado] = useState(
    obterFiltroGrafico(filter)
  );

  useEffect(() => {
    if (filtroAtual.estadoFiltroAplicado !== filter.estadoFiltroAplicado) {
      const filterCopy = deepCopy(filter);
      setFiltroAtual(filterCopy);
      setFiltroAplicado(obterFiltroGrafico(filterCopy));
    }
  }, [filter, filtroAtual]);

  const BLOCKED_SCREEN = process.env.REACT_APP_BLOCKED_SCREEN !== 'true';

  return (
    <Grid
      container
      spacing={5}
      justifyContent="center"
      direction="row"
      className={classes.containerPrincipal}
      ref={referenciaDashboard}
    >
      <Grid
        item
        container
        wrap="nowrap"
        alignItems="flex-start"
        className={
          BLOCKED_SCREEN
            ? classes.mensagemBemVindo
            : classes.mensagemBemVindoBlockedScreen
        }
      >
        <Typography variant="h1" noWrap className={classes.bomDia}>
          {mensagemBoasVindasHorario()}
        </Typography>
        <Typography variant="h1" noWrap>
          {nomeOperator}
        </Typography>
      </Grid>

      <Grid
        container
        item
        className={classes.containerCards}
        ref={referenciaCampanhas}
      >
        <Card className={classes.card}>
          <Grid
            container
            item
            xs={12}
            className={classes.containerConteudoCards}
            spacing={6}
            justifyContent="space-evenly"
          >
            <GestaoCampanhasWhatsapp filtroTela={filtroAplicado} />
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    nomeOperator:
      state && state.login && state.login.usuario && state.login.usuario.name,
    filter: state.filtro,
  };
};

export default connect(mapStateToProps)(CampanhasWhatsappHome);
