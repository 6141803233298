import { React } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import { Clock, PhoneCall, Repeat } from 'react-feather';
import { DARK_CYAN, GRAY } from '../../../theme';
import ImagemDadosNaoEncontrados from '../../../shared/img/dadosDesatualizados.png';
import SvgPillIcon01 from '../../../shared/img/SvgPillIcon01';

const useStyles = makeStyles(() => ({
  labelSubTituloLadoDireito: {
    fontSize: '20px',
    color: DARK_CYAN,
    fontWeight: '800',
    marginBottom: '20px',
  },
  logo: {
    display: 'block',
    margin: 'auto',
  },
  icone: {
    width: '16px',
    height: '16px',
    color: GRAY,
    marginRight: '9px',
  },
  containerTituloMetrica: {
    marginBottom: '15px',
  },
}));

const DadosNaoEncontradosPerfil = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-around"
      alignItems="center"
      className={classes.containerMetricas}
    >
      <Grid item sm={5} className="mb-4">
        <Typography className={classes.labelSubTituloLadoDireito}>
          Meus Atendimentos
        </Typography>
        <Grid
          container
          alignItems="center"
          className={classes.containerTituloMetrica}
        >
          <HeadsetMicOutlinedIcon className={classes.icone} />
          <Typography>Média por Atendimento</Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          className={classes.containerTituloMetrica}
        >
          <Clock className={classes.icone} />
          <Typography>Tempo Total de Atendimento</Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          className={classes.containerTituloMetrica}
        >
          <SvgPillIcon01 className={classes.icone} />
          <Typography>Remédios</Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          className={classes.containerTituloMetrica}
        >
          <PhoneCall className={classes.icone} />
          <Typography>Atendimentos</Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          className={classes.containerTituloMetrica}
        >
          <Repeat className={classes.icone} />
          <Typography>Tentativas</Typography>
        </Grid>
      </Grid>
      <Grid item sm={7}>
        <img
          src={ImagemDadosNaoEncontrados}
          className={classes.logo}
          alt="ImagemDadosNaoEncontrados"
          width="115"
        />
        <Typography align="center" variant="h4">
          Sem dados a exibir =(
        </Typography>
        <Typography align="center" variant="h5">
          É necessário realizar atendimentos para a exibição destas informações
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DadosNaoEncontradosPerfil;
