import axios from 'axios';
import AxiosWrapper from '../shared/util/AxiosWrapper';
import { handleRequestError } from '../shared/util/RequestUtil';
import { GetOidcConfig } from '../features/login/LoginManager';

const header = {};

const axiosInstance = axios.create({
  headers: header,
  baseURL: process.env.REACT_APP_SCAPE0_CRM_API,
});

axiosInstance.interceptors.request.use(async (config) => {
  const OidcConfig = GetOidcConfig();
  const usuario = JSON.parse(
    sessionStorage.getItem(OidcConfig.chaveArmazenamentoUsuario)
  );

  if (usuario && usuario.id_token) {
    config.headers.Authorization = `Bearer ${usuario.id_token}`;
  }

  return config;
});

export default new AxiosWrapper(axiosInstance, {
  defaultErrorHandler: handleRequestError,
});
