import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import DadosNaoEncontrados from './DadosNaoEncontrados';
import { LIGHT_GRAY, DARK_CYAN } from '../../theme';
import { serializeArrayForPart } from '../util/Utils';

const useStyles = makeStyles({
  containerTituloTooltip: {
    borderBottom: `1px solid ${LIGHT_GRAY}`,
  },
  tituloTooltip: {
    fontSize: '14px',
    marginLeft: '8px',
    color: DARK_CYAN,
  },
  containerTooltip: {
    borderRadius: '8px',
    boxShadow: ' 0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
    minWidth: '300px',
    maxWidth: '450px',
  },
  containerIconeTituloPrincipal: {
    margin: '15px',
  },
  containerDescricao: {
    padding: '15px 15px 15px 15px',
    textAlignLast: 'left',
    marginLeft: '8px',
    maxHeight: '1771px',
    overflow: 'hidden',
  },
  descricao: {
    fontSize: '14px',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    marginLeft: '10px',
    width: '100px',
    maxWidth: '120px',
    maxHeight: '20px',
    textAlign: 'left',
  },
  containerQuadro: {
    paddingBottom: '15',
  },
});

export default function TooltipMensagemMultiple(props) {
  const { titulo, mensagem, icone } = props;
  const possuiDados = mensagem.length > 0;

  let mensagemSerializada = [];
  if (possuiDados) {
    mensagemSerializada = serializeArrayForPart(mensagem, 3);
  }
  const classes = useStyles();

  return (
    <Grid className={classes.containerTooltip}>
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        className={classes.containerTituloTooltip}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.containerIconeTituloPrincipal}
        >
          {icone}
          <Typography className={classes.tituloTooltip} variant="h6">
            {titulo}
          </Typography>
        </Grid>
      </Grid>
      {possuiDados ? (
        <>
          <Grid className={classes.containerQuadro}>
            {mensagemSerializada.map((elementArr, index) => {
              return (
                <Grid
                  container
                  direction="row"
                  className={classes.containerDescricao}
                  key={index}
                >
                  {elementArr.map((element) => {
                    return (
                      <Typography
                        noWrap
                        className={classes.descricao}
                        variant="h4"
                        key={element + index}
                      >
                        {element}
                      </Typography>
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>
        </>
      ) : (
        <DadosNaoEncontrados chaveamentoEstadoVazio={4} />
      )}
    </Grid>
  );
}
