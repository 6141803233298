import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from 'react-feather';
import ImagemDadosNaoEncontrados from '../img/dadosDesatualizados.png';
import { GRAY } from '../../theme';

const useStyles = makeStyles({
  containerPrincipal: {
    display: 'flex',
    alignItems: 'center',
  },
  containerFoto: {
    alignSelf: 'center',
    marginRight: '10px',
  },
  containerInfo: {
    alignSelf: 'center',
    textAlignLast: 'start',
  },
  logo: {
    height: '65%',
  },
  descricaoImagemEstadoVazio: {
    color: GRAY,
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '17px',
  },
  tituloImagemDadosNaoEncontrados: {
    letterSpacing: '0',
    lineHeight: '22px',
    fontSize: '18px',
    color: GRAY,
    marginBottom: '15px',
    marginTop: '15px',
  },
  tituloDadosNaoEncontradosEstiloTres: {
    letterSpacing: '0',
    lineHeight: '22px',
    fontSize: '18px',
    color: GRAY,
    marginTop: '-2px',
    marginBottom: '7px',
  },
  containerTooltipMensagem: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '25px',
    paddingBottom: '25px',
    justifyContent: 'center',
  },
  tituloDadosNaoEncontradosTooltipMsg: {
    letterSpacing: '0',
    lineHeight: '22px',
    fontSize: '18px',
    color: GRAY,
  },
});

export default function DadosNaoEncontrados(props) {
  const classes = useStyles();

  const { chaveamentoEstadoVazio } = props;

  return (
    <>
      {chaveamentoEstadoVazio === 1 && (
        <Grid
          item
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.container}
          container
          id="dados-nao-encontrados-1"
          data-testid="dados-nao-encontrados-1"
        >
          <img
            src={ImagemDadosNaoEncontrados}
            className={classes.logo}
            alt="ImagemDadosNaoEncontrados"
          />
          <Typography
            align="center"
            className={classes.tituloImagemDadosNaoEncontrados}
            variant="h6"
          >
            Sem resultados
          </Typography>
          <Typography
            align="center"
            className={classes.descricaoImagemEstadoVazio}
            variant="h5"
          >
            Não foi possível exibir os dados
          </Typography>
        </Grid>
      )}
      {chaveamentoEstadoVazio === 2 && (
        <Grid
          item
          direction="column"
          justifyContent="center"
          alignItems="center"
          container
          id="dados-nao-encontrados-2"
          data-testid="dados-nao-encontrados-2"
        >
          <Search size={45} color={GRAY} />
          <Typography
            align="center"
            className={classes.tituloImagemDadosNaoEncontrados}
            variant="h6"
          >
            Sem resultados
          </Typography>
          <Typography
            align="center"
            className={classes.descricaoImagemEstadoVazio}
            variant="h5"
          >
            Não foi possível exibir os dados
          </Typography>
        </Grid>
      )}
      {chaveamentoEstadoVazio === 3 && (
        <Grid
          className={classes.containerPrincipal}
          id="dados-nao-encontrados-3"
          data-testid="dados-nao-encontrados-3"
        >
          <Grid>
            <Search size={50} color={GRAY} className={classes.containerFoto} />
          </Grid>
          <Grid className={classes.containerInfo}>
            <Typography
              align="center"
              className={classes.tituloDadosNaoEncontradosEstiloTres}
              variant="h6"
            >
              Sem resultados
            </Typography>
            <Typography
              align="center"
              className={classes.descricaoImagemEstadoVazio}
              variant="h5"
            >
              Não foi possível exibir os dados
            </Typography>
          </Grid>
        </Grid>
      )}
      {chaveamentoEstadoVazio === 4 && (
        <Grid
          className={classes.containerTooltipMensagem}
          id="dados-nao-encontrados-4"
          data-testid="dados-nao-encontrados-4"
        >
          <Grid>
            <Search size={30} color={GRAY} className={classes.containerFoto} />
          </Grid>
          <Grid className={classes.containerInfo}>
            <Typography
              align="center"
              className={classes.tituloDadosNaoEncontradosTooltipMsg}
              variant="h6"
            >
              Ops!
            </Typography>
            <Typography
              align="center"
              className={classes.descricaoImagemEstadoVazio}
              variant="h5"
            >
              Não foi possível exibir os dados
            </Typography>
          </Grid>
        </Grid>
      )}
      {chaveamentoEstadoVazio === 5 && (
        <Grid
          item
          direction="column"
          justifyContent="flex-end"
          alignItems="center"
          className={classes.container}
          container
          id="dados-nao-encontrados-5"
          data-testid="dados-nao-encontrados-5"
        >
          <img
            src={ImagemDadosNaoEncontrados}
            className={classes.logo}
            alt="ImagemDadosNaoEncontrados"
          />
          <Typography
            align="center"
            className={classes.tituloImagemDadosNaoEncontrados}
            variant="h6"
          >
            Sem Resultados
          </Typography>
          <Typography
            style={{ width: '38%' }}
            align="center"
            className={classes.descricaoImagemEstadoVazio}
            variant="h5"
          >
            Tente novamente utilizando outros termos para pesquisa
          </Typography>
        </Grid>
      )}
      {chaveamentoEstadoVazio === 6 && (
        <Grid
          item
          direction="column"
          justifyContent="center"
          alignItems="center"
          container
          id="dados-nao-encontrados-6"
          data-testid="dados-nao-encontrados-6"
        >
          <Search size={45} color={GRAY} />
          <Typography
            align="center"
            className={classes.tituloImagemDadosNaoEncontrados}
            variant="h6"
          >
            Resultado não encontrado
          </Typography>
          <Typography
            align="center"
            className={classes.descricaoImagemEstadoVazio}
            variant="h5"
          >
            Lista de problemas não encontrada
          </Typography>
        </Grid>
      )}
    </>
  );
}
