import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Search } from 'react-feather';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import { makeStyles } from '@material-ui/core/styles';
import CardTitle from '../../../../shared/util/components/CardTitle';
import { GRAY } from '../../../../theme';
import ItemGraficoProdutividade from './ItemGraficoProdutividade';
import GraficosDesempenhoManager from '../../GraficosDesempenhoManager';
import Loading from '../../../../shared/componentes/Loading';
import DadosNaoEncontrados from '../../../../shared/componentes/DadosNaoEncontrados';
import { normalizeText, deepCopy } from '../../../../shared/util/Utils';

const useStyles = makeStyles({
  searchField: {
    width: '90%',
    minWidth: '300px',
    margin: '18px 0px 34px',
  },
  containerTodosItens: {
    overflowY: 'scroll',
    scrollbarColor: '#bbbbbe white',
    scrollbarWidth: 'thin',
    maxHeight: '548px',
    borderTop: '1px solid #E8EEF2',
  },
});

const GraficoProdutividade = (props) => {
  const { filtro } = props;

  const classes = useStyles();

  const [listaAtendentes, setListaAtendentes] = useState([]);
  const [listaAtendentesFiltrados, setListaAtendentesFiltrados] = useState([]);
  const [busca, setBusca] = useState('');
  const [carregando, setCarregando] = useState(false);

  const tituloCardAtendimento = () => {
    return listaAtendentesFiltrados.length !== 0
      ? `Produtividade por atendente (${listaAtendentesFiltrados.length})`
      : `Produtividade por atendente (${listaAtendentes.length})`;
  };

  useEffect(() => {
    async function getGraficoProdutividade() {
      setCarregando(true);
      const atendentes = await GraficosDesempenhoManager.buscarDadosAtendentes(
        deepCopy(filtro)
      );
      setListaAtendentes(atendentes);
      setCarregando(false);
    }
    getGraficoProdutividade();
  }, [filtro]);

  useEffect(() => {
    if (busca && busca.length && busca.length > 0) {
      const listaFiltrada = listaAtendentes.filter(
        (atendente) =>
          normalizeText(atendente.operatorName).indexOf(
            normalizeText(busca)
          ) !== -1
      );

      setListaAtendentesFiltrados(listaFiltrada);
    } else {
      setListaAtendentesFiltrados(listaAtendentes);
    }
  }, [busca, listaAtendentes]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
      id="produtividade-por-atendente-panel"
    >
      {carregando && <Loading />}

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <CardTitle
          text={tituloCardAtendimento()}
          icon={<HeadsetMicOutlinedIcon />}
          style={{ padding: '32px 24px 0px' }}
        />

        <TextField
          variant="filled"
          size="small"
          placeholder="Pesquise por um atendente..."
          onChange={(e) => {
            setBusca(e.target.value);
          }}
          InputProps={{
            style: {
              fontWeight: 'normal',
              backgroundColor: 'rgba(158, 171, 181, 0.05)',
              fontSize: '16px',
            },
            endAdornment: <Search size={20} color={GRAY} />,
          }}
          className={classes.searchField}
        />
      </Grid>
      <Grid container className={classes.containerTodosItens}>
        {listaAtendentesFiltrados &&
          listaAtendentesFiltrados.length > 0 &&
          listaAtendentesFiltrados.map((item, index) => (
            <ItemGraficoProdutividade
              operatorName={item.operatorName}
              notFinishedAttemptsCount={item.notFinishedAttemptsCount}
              notFinishedAttemptsPercent={item.notFinishedAttemptsPercent}
              remedyCount={item.remedyCount}
              remedyPercent={item.remedyPercent}
              finishedCount={item.finishedCount}
              finishedPercent={item.finishedPercent}
              inelegibleCount={item.inelegibleCount}
              inelegiblePercent={item.inelegiblePercent}
              generalServiceAverage={item.generalServiceAverage}
              operatorServiceAverage={item.operatorServiceAverage}
              operatorTotalTime={item.operatorTotalTime}
              operatorTotalTimePercent={item.operatorTotalTimePercent}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            />
          ))}
      </Grid>
      {listaAtendentesFiltrados && listaAtendentesFiltrados.length === 0 && (
        <Grid container justifyContent="flex-end">
          <DadosNaoEncontrados chaveamentoEstadoVazio={1} />
        </Grid>
      )}
    </Grid>
  );
};

export default GraficoProdutividade;
