import * as React from 'react';
import moment from 'moment';
import { StaticDatePicker, LocalizationProvider } from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import {
  Grid,
  ClickAwayListener,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronDown } from 'react-feather';
import { SHADOW_DROPDOWN, WHITE, DARK_CYAN, GRAY } from '../../theme';
import { validarCaractereDropdownDatePicker } from '../util/Utils';

const useStyles = makeStyles({
  containerInterno: {
    position: 'absolute',
    zIndex: '1',
    backgroundColor: WHITE,
    maxHeight: '20rem',
    boxShadow: `0 0 18px -1px ${SHADOW_DROPDOWN}`,
    borderRadius: '8px',
    left: '-20rem',
  },
  containerExterno: {
    position: 'relative',
    height: '0px',
    width: '0px',
  },
  iconChevron: {
    color: (props) => (props.mostrarDropdown ? DARK_CYAN : GRAY),
    marginRight: '10px',
    marginBottom: '3px',
  },
});

export default function DropdownDatePicker(props) {
  const {
    top,
    left,
    mostrarDropdown,
    setMostrarDropdown,
    placeholder,
    data,
    setData,
    bloquearDataNoPassado,
  } = props;
  const [textoDropdownDatePicker, setTextoDropdownDatePicker] =
    React.useState('');
  const classes = useStyles(props);

  const definirData = (newValue) => {
    setData(newValue);
    setTextoDropdownDatePicker(moment(newValue).format('DD/MM/YYYY'));
    setMostrarDropdown(false);
  };

  const handleChange = (event) => {
    if (event.target.value.length > 10) return;
    if (!validarCaractereDropdownDatePicker(event.target.value)) return;
    const dataAlterada = moment(event.target.value, 'DD/MM/YYYY', true);
    if (dataAlterada && dataAlterada.isValid()) {
      definirData(dataAlterada);
    } else {
      setData();
      setTextoDropdownDatePicker(event.target.value);
    }
  };

  return (
    <>
      <TextField
        style={{ width: '98%' }}
        size="medium"
        placeholder={placeholder}
        onChange={(event) => handleChange(event)}
        onClick={() => setMostrarDropdown(true)}
        InputProps={{
          style: {
            paddingLeft: 16,
            paddingTop: 2,
            paddingBottom: 1,
          },
          endAdornment: (
            <InputAdornment position="end">
              <ChevronDown size={18} className={classes.iconChevron} />
            </InputAdornment>
          ),
        }}
        value={textoDropdownDatePicker}
      />
      {mostrarDropdown && (
        <ClickAwayListener onClickAway={() => setMostrarDropdown(false)}>
          <Grid container className={classes.containerExterno}>
            <Grid className={classes.containerInterno} style={{ top, left }}>
              <LocalizationProvider
                dateLibInstance={moment}
                dateAdapter={MomentAdapter}
              >
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  inputFormat="dd/MM/yyyy"
                  value={data}
                  onChange={(dataSelecionada) => definirData(dataSelecionada)}
                  disablePast={bloquearDataNoPassado}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </ClickAwayListener>
      )}
    </>
  );
}
