import DashboardService from '../../../services/cockpit/DashboardService';

const buscarIndicadores = async (codigoIes, payload) => {
  const response = await DashboardService.buscarDadosGraficos(
    codigoIes,
    payload
  );
  if (!response) return [];

  const dadosIndicadores = response.map((item) => {
    return {
      valorPrimario: item.primary,
      valorSecundario: item.secondary,
      titulo: item.description,
      icon: item.icon,
      color: item.color,
    };
  });

  for (let i = 1; i < dadosIndicadores.length; i++) {
    if (
      dadosIndicadores[i].valorPrimario !== '0%' &&
      Number(dadosIndicadores[i].valorSecundario) !== 0
    ) {
      return dadosIndicadores;
    }
  }
  return [];
};

export const buscarDadosIndicadores = async (codigoIes, filter = {}) => {
  const payloadIndicadores = {
    institution_id: codigoIes,
    chart_name: '06_clusters',
    filters: filter,
  };
  return buscarIndicadores(payloadIndicadores);
};
