import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
  TextField,
} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import React, { useEffect, useState } from 'react';
import {
  Check,
  Trash2,
  Type,
  Edit3,
  AlignLeft,
  AlertCircle,
} from 'react-feather';
import WhatsappIcon from '@material-ui/icons/WhatsApp';
import { connect } from 'react-redux';
import CardTitle from '../../../shared/componentes/CardTitle';
import Contador from '../../../shared/componentes/Contador';
import PreVisualizarWhatsApp from './PreVisualizarWhatsapp';
import ParagrafoMenssagemWhatsapp from './ParagrafoMessagemWhatsapp';
import 'emoji-mart/css/emoji-mart.css';
import GestaoMensagensManager from '../GestaoMensagensWhatsappManager';
import TooltipMensagem from '../../../shared/componentes/TooltipMensagem';
import {
  exibirNotificacaoAlerta,
  exibirNotificacaoSucesso,
} from '../../notificacao/Store';
import {
  iconesNotificacao,
  placeholderMensagemWhatsapp,
  mensagemAtualizarMensagemWhatsapp,
} from '../../../shared/util/Constantes';
import {
  DARK_CYAN,
  LIGHT_GRAY,
  LIGHT_SEA_GREEN,
  LIGHT_SEA_GREEN_HOVER,
  TOMATO,
  TOMATO_HOVER,
  WHITE,
  ORANGE,
  DARK_GRAY,
} from '../../../theme';
import ModalConfirmarEnvioMensagem from './ModalConfirmarEnvioMensagemWhatsapp';

const theme = createTheme({
  palette: {
    primary: {
      main: DARK_CYAN,
    },
    error: {
      main: ORANGE,
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        '&.Mui-disabled': {
          backgroundColor: 'rgba(192,192,222,0.15)',
        },
        '&.Mui-focused': {
          backgroundColor: 'rgba(14,167,137,0.05)',
        },
        '&:hover': {
          backgroundColor: 'rgb(250 252 252)',
        },
        backgroundColor: 'rgba(232,238,242,0.2)',
        color: DARK_GRAY,
        fontWeight: '600',
        fontSize: '12px',
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid ${LIGHT_GRAY}`,
        },
        '&:hover': {
          borderBottom: 'none',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid #d9d9d9',
        },
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  containerInputs: {
    overflowY: 'scroll',
    maxHeight: 'calc(90vh - 195px)',
    scrollbarColor: '#bbbbbe white',
    scrollbarWidth: 'thin',
  },
  modal: {
    height: '90vh',
    margin: '5vh',
  },
  scrollModal: {
    overflowY: 'hidden',
  },
  conteudo: {
    height: 'calc(100% - 50px)',
  },
  container: {
    height: '100%',
    width: '100%',
  },
  containerHeader: {
    marginBottom: '12px',
    paddingRight: '16px',
  },
  titulo: {
    width: '60%',
  },
  containerContador: {
    width: '40%',
  },
  filtro: {
    borderRight: `1px solid ${LIGHT_GRAY}`,
    margin: '4px 18px 12px 0',
    width: '70%',
    overflowY: 'scroll',
  },
  backgroundProps: {
    background: 'rgba(0,0,0,0.3)',
  },
  botaoCancelar: {
    backgroundColor: TOMATO,
    color: WHITE,
    height: '100%',
    float: 'right',
    marginRight: '32px',
    '&:hover': {
      backgroundColor: TOMATO_HOVER,
    },
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  botaoAplicar: {
    backgroundColor: LIGHT_SEA_GREEN,
    color: WHITE,
    height: '100%',
    whiteSpace: 'nowrap',
    marginRight: '1.5rem',
    '&:hover': {
      backgroundColor: LIGHT_SEA_GREEN_HOVER,
    },
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  textoBotao: {
    fontSize: '14px',
    color: WHITE,
  },
  textoBotaoCancelar: {
    fontSize: '14px',
    color: WHITE,
    marginTop: '2px',
  },
  iconeLabelTituloSelect: {
    color: DARK_CYAN,
    marginRight: '4px',
  },
  iconeLabelTituloSelectError: {
    color: ORANGE,
    marginRight: '4px',
  },
  iconeAlertTooltip: {
    color: DARK_CYAN,
    marginRight: '18px',
  },
  labelTituloSelect: {
    fontSize: '14px',
    color: DARK_CYAN,
  },
  labelTituloSelectError: {
    fontSize: '14px',
    color: ORANGE,
  },
  cabecalhoConteudo: {
    alignContent: 'left',
  },
  cabecalhoConteudoContainer: {
    paddingTop: '15px',
  },
  reactTooltip: {
    padding: '0px !important',
    opacity: '100% !important',
  },
}));

const ModalFormMensagemWhatsapp = (props) => {
  const {
    notificarSucesso,
    mensagemOriginal,
    modalAberto,
    setModalAberto,
    handleAtualizarMensagem,
    handleCriarMensagem,
  } = props;
  const classes = useStyles();
  const [zenviaTemplate, setZenviaTemplate] = useState('');
  const [caracterRestante, setCaracterRestante] = useState(0);
  const [loading, setLoading] = useState(false);
  const [botaoCriarMensagemValido, setBotaoCriarMensagemValido] =
    useState(false);
  const [mensagem1, setMensagem1] = useState('');
  const [mensagem2, setMensagem2] = useState('');
  const [mensagem3, setMensagem3] = useState('');
  const [verbo, setVerbo] = useState('Criar');
  const [tituloMensagem, setTituloMensagem] = useState('');
  const [modalConfirmarCriarMensagem, setModalConfirmarCriarMensagem] =
    useState(false);

  const validarEAbrirModalConfirmarCriarMensagem = () => {
    setModalConfirmarCriarMensagem(true);
  };

  const enviarCriarMensagem = async () => {
    const result = await GestaoMensagensManager.enviarCriarMensagem({
      tituloMensagem,
      mensagem1,
      mensagem2,
      mensagem3,
    });
    if (result && result.Message) {
      notificarSucesso(result.Message, iconesNotificacao.AtendimentoFinalizado);
      await handleCriarMensagem();
      setModalConfirmarCriarMensagem(false);
      setModalAberto(false);
    }
  };

  const enviarAtualizarMensagem = async () => {
    const result = await GestaoMensagensManager.enviarAtualizarMensagem(
      mensagemOriginal.SK,
      {
        tituloMensagem,
        mensagem1,
        mensagem2,
        mensagem3,
      }
    );
    if (result === null) {
      notificarSucesso(
        mensagemAtualizarMensagemWhatsapp,
        iconesNotificacao.AtendimentoFinalizado
      );
      handleAtualizarMensagem(mensagem1, mensagem2, mensagem3, tituloMensagem);
      setModalConfirmarCriarMensagem(false);
      setModalAberto(false);
    }
  };

  const enviarMensagem = async () => {
    if (mensagemOriginal) {
      await enviarAtualizarMensagem();
      return;
    }
    await enviarCriarMensagem();
    setModalConfirmarCriarMensagem(false);
  };

  useEffect(() => {
    setLoading(true);
    const action = async () => {
      const zenviaTemplateResposta =
        await GestaoMensagensManager.obterZenviaTemplate();
      if (zenviaTemplateResposta) {
        setZenviaTemplate(zenviaTemplateResposta);
      }
      setLoading(false);
    };
    action();
  }, []);

  useEffect(() => {
    if (mensagemOriginal) {
      setTituloMensagem(mensagemOriginal.Title);
      setMensagem1(mensagemOriginal.Message1);
      setMensagem2(mensagemOriginal.Message2);
      setMensagem3(mensagemOriginal.Message3);
      setVerbo('Editar');
    }
  }, [mensagemOriginal]);

  useEffect(() => {
    const getAllMessage = () => {
      return mensagem1 + mensagem2 + mensagem3;
    };

    if ((getAllMessage().match(/\n/g) || []).length > 0) {
      setMensagem1(mensagem1.replace(/\n/g, ''));
      setMensagem2(mensagem2.replace(/\n/g, ''));
      setMensagem3(mensagem3.replace(/\n/g, ''));
    }

    setCaracterRestante(
      GestaoMensagensManager.caracterRestantePosPonderacao(
        getAllMessage(),
        zenviaTemplate
      )
    );
  }, [
    caracterRestante,
    mensagem1,
    mensagem2,
    mensagem3,
    mensagemOriginal,
    zenviaTemplate,
  ]);

  useEffect(() => {
    const botaoCriarMensagemValidoFn = () => {
      let isValid = true;
      [mensagem1, mensagem2, mensagem3].forEach((mensagem) => {
        if (
          mensagem.length === 0 ||
          (mensagem.match(/\s/g) || []).length === mensagem.length
        ) {
          isValid = false;
        }
      });
      if (caracterRestante <= 0 || tituloMensagem.length === 0) {
        isValid = false;
      }
      if (mensagemOriginal) {
        if (
          !(
            tituloMensagem !== mensagemOriginal.Title ||
            mensagem1 !== mensagemOriginal.Message1 ||
            mensagem2 !== mensagemOriginal.Message2 ||
            mensagem3 !== mensagemOriginal.Message3
          )
        ) {
          isValid = false;
        }
      }

      return isValid;
    };

    setBotaoCriarMensagemValido(botaoCriarMensagemValidoFn());
  }, [
    caracterRestante,
    mensagem1,
    mensagem2,
    mensagem3,
    tituloMensagem.length,
    mensagemOriginal,
    tituloMensagem,
  ]);

  return (
    <>
      <Dialog
        open={modalAberto}
        fullWidth
        maxWidth="lg"
        aria-labelledby="modal-titulo"
        aria-describedby="modal-descricao"
        scroll="body"
        classes={{ paper: classes.modal, scrollBody: classes.scrollModal }}
        BackdropProps={{
          classes: {
            root: classes.backgroundProps,
          },
        }}
      >
        <DialogContent className={classes.container}>
          <Grid container wrap="nowrap" className={classes.conteudo}>
            <Grid
              item
              container
              direction="row"
              alignItems="flex-start"
              alignContent="space-between"
              className={classes.filtro}
            >
              <Grid item container>
                <Grid item container alignItems="flex-start">
                  <Grid container className={classes.containerHeader}>
                    <Grid container className={classes.titulo}>
                      <CardTitle
                        text={`${verbo} Mensagem de WhatsApp`}
                        icon={<WhatsappIcon />}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="flex-end"
                      className={classes.containerContador}
                    >
                      <Contador
                        icon={<Type />}
                        total={caracterRestante}
                        afterNumber=""
                        loading={loading}
                        labelUpperNumber="Caracteres"
                        minNumber={0}
                      />
                    </Grid>
                    <Grid container className="mt-4">
                      <Grid container>
                        <Grid item container xs={10}>
                          <Edit3
                            size={20}
                            className={
                              mensagemOriginal &&
                              tituloMensagem !== mensagemOriginal.Title
                                ? classes.iconeLabelTituloSelectError
                                : classes.iconeLabelTituloSelect
                            }
                          />
                          <Typography
                            autoFocus
                            variant="h5"
                            className={
                              mensagemOriginal &&
                              tituloMensagem !== mensagemOriginal.Title
                                ? classes.labelTituloSelectError
                                : classes.labelTituloSelect
                            }
                          >
                            Título
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Grid item container>
                        <ThemeProvider theme={theme}>
                          <TextField
                            fullWidth
                            autoFocus
                            variant="filled"
                            size="small"
                            value={tituloMensagem}
                            className="mt-2 mb-4"
                            error={
                              mensagemOriginal &&
                              tituloMensagem !== mensagemOriginal.Title
                            }
                            onChange={(e) => setTituloMensagem(e.target.value)}
                            placeholder=" Insira um título para mensagem"
                            inputProps={{
                              style: {
                                fontWeight: 'normal',
                                fontSize: '14px',
                                paddingTop: '10px',
                                paddingBottom: '8px',
                              },
                              heigth: '48px',
                              'data-testid':
                                'criar-mensagem-whatsapp-titulo-input',
                            }}
                          />
                        </ThemeProvider>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justifyContent="space-between"
                      className="mb-1"
                    >
                      <Grid item>
                        <Grid container alignItems="center">
                          <AlignLeft
                            size={20}
                            className={
                              mensagemOriginal &&
                              mensagem1 !== mensagemOriginal.Message1
                                ? classes.iconeLabelTituloSelectError
                                : classes.iconeLabelTituloSelect
                            }
                          />
                          <Typography
                            variant="h5"
                            color="error"
                            className={
                              mensagemOriginal &&
                              mensagem1 !== mensagemOriginal.Message1
                                ? classes.labelTituloSelectError
                                : classes.labelTituloSelect
                            }
                          >
                            Primeiro Parágrafo (Bloco de Texto 1)
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="center">
                          <ReactTooltip
                            backgroundColor="white"
                            type="light"
                            id="tooltip-mensagem"
                            className={classes.reactTooltip}
                            arrowColor="rgba(0, 0, 0, 0)"
                            data-testid="mensagem-tooltip-icon"
                          >
                            <TooltipMensagem
                              titulo="Como formatar suas mensagens"
                              icone={
                                <AlertCircle size={20} color={DARK_CYAN} />
                              }
                              mensagem={`Para escrever em itálico, insira o sinal de sublinhado antes e depois do texto: _texto_ \n\n Para escrever em negrito, insira um asterisco antes e depois do texto: *texto* \n\n Para escrever monoespaçado, insira três sinais graves antes e depois do texto: ${'```texto```'}\n\nPara escrever tachado, insira um til antes e depois do texto: ${'~texto~'}`}
                              data-testid="mensagem-tooltip"
                            />
                          </ReactTooltip>
                          <AlertCircle
                            data-tip
                            data-for="tooltip-mensagem"
                            size={20}
                            className={classes.iconeAlertTooltip}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <ParagrafoMenssagemWhatsapp
                      mensagem={mensagem1}
                      setMensagem={setMensagem1}
                      placeholderMensagem={placeholderMensagemWhatsapp}
                      mensagemOriginal={
                        mensagemOriginal && mensagemOriginal.Message1
                      }
                    />
                    <Grid container alignItems="center" className="mb-3">
                      <AlignLeft
                        size={20}
                        className={
                          mensagemOriginal &&
                          mensagem2 !== mensagemOriginal.Message2
                            ? classes.iconeLabelTituloSelectError
                            : classes.iconeLabelTituloSelect
                        }
                      />
                      <Typography
                        variant="h5"
                        className={
                          mensagemOriginal &&
                          mensagem2 !== mensagemOriginal.Message2
                            ? classes.labelTituloSelectError
                            : classes.labelTituloSelect
                        }
                      >
                        Segundo Parágrafo (Bloco de Texto 2)
                      </Typography>
                    </Grid>
                    <ParagrafoMenssagemWhatsapp
                      mensagem={mensagem2}
                      setMensagem={setMensagem2}
                      placeholderMensagem={placeholderMensagemWhatsapp}
                      mensagemOriginal={
                        mensagemOriginal && mensagemOriginal.Message2
                      }
                    />
                    <Grid container alignItems="center" className="mb-3">
                      <AlignLeft
                        size={20}
                        className={
                          mensagemOriginal &&
                          mensagem3 !== mensagemOriginal.Message3
                            ? classes.iconeLabelTituloSelectError
                            : classes.iconeLabelTituloSelect
                        }
                      />
                      <Typography
                        variant="h5"
                        className={
                          mensagemOriginal &&
                          mensagem3 !== mensagemOriginal.Message3
                            ? classes.labelTituloSelectError
                            : classes.labelTituloSelect
                        }
                      >
                        Terceiro Parágrafo (Bloco de Texto 3)
                      </Typography>
                    </Grid>
                    <ParagrafoMenssagemWhatsapp
                      mensagem={mensagem3}
                      setMensagem={setMensagem3}
                      placeholderMensagem={placeholderMensagemWhatsapp}
                      mensagemOriginal={
                        mensagemOriginal && mensagemOriginal.Message3
                      }
                    />
                    <Grid
                      container
                      direction="column"
                      className={classes.cabecalhoConteudoContainer}
                    >
                      <Typography className={classes.cabecalhoConteudo}>
                        {`Para adicionar o nome da IES do aluno, escreva: `}
                        <b>{`{{ies}}`}</b>.
                      </Typography>
                      <Typography className={classes.cabecalhoConteudo}>
                        {`Ex: Meu texto `}
                        <b>{`{{ies}}`}</b>
                        ...
                      </Typography>
                      <Typography className={classes.cabecalhoConteudo}>
                        {` Será enviado como: Meu texto `}
                        <b>UNA</b>...
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4}>
              <PreVisualizarWhatsApp
                paragrafo1={mensagem1}
                paragrafo2={mensagem2}
                paragrafo3={mensagem3}
                zenviaTemplate={zenviaTemplate}
                mensagemOriginal={mensagemOriginal}
                carregando={loading}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            wrap="nowrap"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Button
              variant="contained"
              type="submit"
              disableElevation
              fullWidth
              startIcon={<Trash2 size={18} />}
              className={`${classes.botaoCancelar} "Login-Btn"`}
              onClick={() => setModalAberto(!modalAberto)}
            >
              <Typography className={classes.textoBotaoCancelar} variant="h6">
                Descartar
              </Typography>
            </Button>
            <Button
              variant="contained"
              type="submit"
              disableElevation
              fullWidth
              startIcon={<Check size={18} />}
              onClick={() => validarEAbrirModalConfirmarCriarMensagem()}
              className={`${classes.botaoAplicar} "Login-Btn"`}
              disabled={!botaoCriarMensagemValido}
            >
              <Typography className={classes.textoBotao} variant="h6">
                {verbo === 'Criar'
                  ? 'Cadastrar mensagem'
                  : 'Atualizar Mensagem'}
              </Typography>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>

      <ModalConfirmarEnvioMensagem
        verbo={verbo}
        modalAberto={modalConfirmarCriarMensagem}
        setModalAberto={setModalConfirmarCriarMensagem}
        handleBotaoDireito={enviarMensagem}
      />
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  notificarSucesso: exibirNotificacaoSucesso,
  notificarAlerta: exibirNotificacaoAlerta,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalFormMensagemWhatsapp);
