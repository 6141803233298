import AlunoService from '../../services/AlunoService';
import SessionService from '../../services/SessionService';
import Store from '../../setup/reducer';
import { exibirNotificacaoSucesso } from '../notificacao/Store';
import {
  iconesNotificacao,
  mensagensNotificacaoSucesso,
} from '../../shared/util/Constantes';

export const buscarInfo = async (requesteIndo, idAluno) => {
  const payload = {
    RequestedInfo: requesteIndo,
    StudentId: idAluno,
  };
  return AlunoService.getInfoLgpd(payload);
};

export const encerrarSessao = async (campaignId, studentId) => {
  const queryStringParam = {
    CampaignId: campaignId,
    StudentId: studentId,
  };

  await SessionService.fecharSessao(queryStringParam);
};

function formataNumeroTelefone(numeroTelefone) {
  const telefoneFormatado = numeroTelefone.replace(/\D/g, '');
  if (telefoneFormatado.length >= 12) {
    return telefoneFormatado.replace(/(\d{2})(\d{2})/, '+$1 ($2) ');
  }
  if (telefoneFormatado.length >= 10) {
    return telefoneFormatado.replace(/(\d{2})/, '($1) ');
  }
  return telefoneFormatado;
}

export const ligarParaAluno = async (CampaignId, StudentId, SkipCallbox) => {
  const bodyStringParam = {
    CampaignId,
    StudentId,
    SkipCallbox,
  };
  const response = await AlunoService.ligarParaAluno(bodyStringParam);
  if (response && response.PhoneNumber) {
    Store.dispatch(
      exibirNotificacaoSucesso(
        mensagensNotificacaoSucesso.LigacaoFeitaComSucesso,
        iconesNotificacao.LigacaoIniciada
      )
    );
    response.PhoneNumber = formataNumeroTelefone(response.PhoneNumber);
  }
  return response;
};

export const registrarInelegibilidadeAluno = async (registroConversa) => {
  const response = await AlunoService.registrarInelegiblidadeAluno(
    registroConversa
  );
  if (response !== undefined) {
    Store.dispatch(
      exibirNotificacaoSucesso(
        mensagensNotificacaoSucesso.InelegibilidadeFeitaComSucesso
      )
    );
  }
  return response;
};

export const obterRecipiente = async (idCampanha, idStudent) => {
  const queryStringParam = {
    CampaignId: idCampanha,
  };
  const resposta = await AlunoService.obterRecipiente(
    queryStringParam,
    idStudent
  );

  if (resposta) {
    return {
      Id: resposta.StudentId,
      Nome: resposta.StudentName,
      Marca: resposta.BrandName,
      Campus: resposta.CampusName,
      Curso: resposta.CourseName,
      Matricula: resposta.Registration,
      Turno: resposta.Shift,
      Ingresso: resposta.IntakeCycle,
      Entrada: resposta.EnrollmentType,
      StudentType: resposta.StudentType,
    };
  }
  return null;
};
