import PermissaoUsuarioService from '../../services/PermissaoUsuarioService';
import {
  menuNavegacaoDeTelas,
  hierarquiaPermissoesUsuarios,
} from '../../shared/util/Constantes';

class CabecalhoManager {
  getListaNavegacao(nivelHierarquiaUsuario) {
    const menuNavegacao = [{ Description: menuNavegacaoDeTelas.atendimento }];
    if (
      nivelHierarquiaUsuario <= hierarquiaPermissoesUsuarios.Administrador ||
      nivelHierarquiaUsuario <= hierarquiaPermissoesUsuarios.Gestor
    ) {
      menuNavegacao.push({
        Description: menuNavegacaoDeTelas.desempenho,
      });
    }
    if (nivelHierarquiaUsuario <= hierarquiaPermissoesUsuarios.Lider) {
      menuNavegacao.push({
        Description: menuNavegacaoDeTelas.gestaoDeCampanhas,
      });
    }
    if (nivelHierarquiaUsuario <= hierarquiaPermissoesUsuarios.Administrador) {
      menuNavegacao.push({
        Description: menuNavegacaoDeTelas.gestaoMensagensWhatsapp,
      });
      menuNavegacao.push({
        Description: menuNavegacaoDeTelas.gestaoCampanhasWhatsapp,
      });
    }
    return menuNavegacao;
  }

  possuiPermissaoConfiguracaoUsuarios(nivelHierarquiaUsuario) {
    return nivelHierarquiaUsuario <= hierarquiaPermissoesUsuarios.Lider;
  }

  async getUsuario(perfil) {
    if (perfil) {
      const perfilFormatado = perfil.replaceAll('@', '-').replaceAll('.', '-');
      return PermissaoUsuarioService.getUsuario(perfilFormatado);
    }
  }
}

export default new CabecalhoManager();
