import * as FiltroStore from './Store';
import FiltroService from '../../services/cockpit/FiltroService';
import CriarCampanhaService from '../../services/CriarCampanhaService';
import WhatsappService from '../../services/WhatsappService';
import {
  filtroItens,
  opcaoChatbot,
  LabelObjetivosCampanha,
  MappingObjetivosCampanha,
  impactosDaCampanha,
} from '../../shared/util/Constantes';
import { deepCopy } from '../../shared/util/Utils';

const alunoDefault = ['Calouro', 'Veterano'];
const problemaDefault = ['Financeiro'];

class CriarCampanhaManager {
  async listarOpcoesChatbot() {
    return [
      {
        title: opcaoChatbot.numero70.descricao,
        value: opcaoChatbot.numero70.valor,
        checked: false,
      },
      {
        title: opcaoChatbot.numero71.descricao,
        value: opcaoChatbot.numero71.valor,
        checked: false,
      },
      {
        title: opcaoChatbot.numero99.descricao,
        value: opcaoChatbot.numero99.valor,
        checked: false,
      },
    ];
  }

  async createCampaign(
    tituloCampanha,
    descricaoCampanha,
    objetivos,
    parametrosIES,
    modeloDeRisco,
    impactoDaCampanha,
    grupoDeVisualizacao,
    dadosWhatsapp
  ) {
    const payload = {
      CampaignData: {
        Title: tituloCampanha,
        Description: descricaoCampanha,
        DueTimestamp: objetivos.dataTerminoCampanha,
        StartTimestamp: objetivos.dataInicioCampanha,
        RiskType: modeloDeRisco,
        PriorityLevel: impactoDaCampanha,
        VisualizationGroup:
          this.mapearArrayGruposDeVisualizacao(grupoDeVisualizacao),
        Category: MappingObjetivosCampanha.Categoria[objetivos.categoria],
        CategoryType:
          MappingObjetivosCampanha.TipoCategoria[objetivos.categoria],
      },

      FilterData: this.buildCriarCampanharFilterdata(objetivos, parametrosIES),
      WhatsappData: {
        WhatsappOrganization: dadosWhatsapp.marcaWhatsapp?.Title || null,
        ListWhatsappTrigger: this.mapearArrayMensagensWhatsapp(
          dadosWhatsapp.mensagens
        ),
      },
    };
    return CriarCampanhaService.criarCampanha(payload);
  }

  async createCampaignWhatsapp(
    tituloCampanha,
    objetivos,
    parametrosIES,
    dadosWhatsapp
  ) {
    const payload = {
      CampaignData: {
        Title: tituloCampanha,
        TimeToLive: objetivos.dataInicioCampanha,
        Category: MappingObjetivosCampanha.Categoria[objetivos.categoria],
        CategoryType:
          MappingObjetivosCampanha.TipoCategoria[objetivos.categoria],
        WhatsappOrganization: dadosWhatsapp.marcaWhatsapp?.Title || null,
        WhatsappMessageId: dadosWhatsapp.mensagemWhatsapp.SK,
      },
      FilterData: this.buildCriarCampanharFilterdata(objetivos, parametrosIES),
    };
    const query = { type: 'whatsapp' };
    return CriarCampanhaService.criarCampanhaViaWhatsapp(payload, query);
  }

  async createCampaignWhatsappUpload(
    tituloCampanha,
    dataInicioCampanha,
    arquivoUpload,
    dadosWhatsapp
  ) {
    const payload = {
      CampaignData: {
        Title: tituloCampanha,
        TimeToLive: dataInicioCampanha,
        Encoded64: arquivoUpload.codigoBase64,
        WhatsappOrganization: dadosWhatsapp.marcaWhatsapp?.Title || null,
        WhatsappMessageId: dadosWhatsapp.mensagemWhatsapp.SK,
      },
    };
    const query = { type: 'upload-whatsapp' };
    return CriarCampanhaService.criarCampanhaViaWhatsapp(payload, query);
  }

  async createCampaignUpload(
    tituloCampanha,
    descricaoCampanha,
    dataInicioCampanha,
    dataTerminoCampanha,
    arquivoUpload,
    modeloDeRisco,
    impactoDaCampanha,
    grupoDeVisualizacao,
    dadosWhatsapp
  ) {
    const payload = {
      CampaignData: {
        Title: tituloCampanha,
        Description: descricaoCampanha,
        DueTimestamp: dataTerminoCampanha,
        StartTimestamp: dataInicioCampanha,
        RiskType: modeloDeRisco,
        PriorityLevel: impactoDaCampanha,
        VisualizationGroup:
          this.mapearArrayGruposDeVisualizacao(grupoDeVisualizacao),
        Encoded64: arquivoUpload.codigoBase64,
      },
      WhatsappData: {
        WhatsappOrganization: dadosWhatsapp.marcaWhatsapp?.Title || null,
        ListWhatsappTrigger: this.mapearArrayMensagensWhatsapp(
          dadosWhatsapp.mensagens
        ),
      },
    };
    const query = { type: 'upload' };
    return CriarCampanhaService.criarCampanhaUpload(payload, query);
  }

  formatOptions(array) {
    return array;
  }

  async buscarFiltros(filter) {
    const payload = {
      filters: filter,
    };
    const resp = await FiltroService.buscarFiltros(payload);

    delete Object.assign(resp, {
      FormaDeIngresso: resp.EnrollmentType,
    }).EnrollmentType;

    return {
      parametrosIES: {
        Marca: resp.Brand,
        Modalidade: resp.Modality,
        Campus: resp.Campus,
        Curso: resp.Course,
        Turno: resp.Shift,
      },

      objetivosCampanha: {
        Aluno: [
          {
            Description: LabelObjetivosCampanha.Aluno.Todos,
            Id: '89365df3-61b5-4989-bb9e-6ca2a0c99ebf',
          },
          {
            Description: LabelObjetivosCampanha.Aluno.Calouro,
            Id: '9edf0532-28c0-4ee7-9259-bf582a4b0c61',
          },
          {
            Description: LabelObjetivosCampanha.Aluno.Veterano,
            Id: '226be4db-8c53-4b00-8e16-949f645939c7',
          },
        ],
        Categoria: [
          {
            Description: LabelObjetivosCampanha.Categoria.Acolhimento,
            Id: 'eec666c7-e7fd-4c10-8c26-16789ac850e7',
            RiskType: ['Modelo de Risco Durante'],
          },
          {
            Description: LabelObjetivosCampanha.Categoria.RematriculaTipo1,
            Id: '3992400c-9c0d-4c2e-940e-1c0c29356083',
            RiskType: ['Modelo de Risco Rematrícula'],
          },
          {
            Description: LabelObjetivosCampanha.Categoria.RematriculaTipo2,
            Id: '3992400c-9c0d-4c2e-940e-1c0c29356083',
            RiskType: ['Modelo de Risco Rematrícula'],
          },
          {
            Description: LabelObjetivosCampanha.Categoria.RematriculaTipo3,
            Id: '3992400c-9c0d-4c2e-940e-1c0c29356083',
            RiskType: ['Modelo de Risco Rematrícula'],
          },
          {
            Description: LabelObjetivosCampanha.Categoria.Personalizada,
            Id: '69e5d954-cdc5-42a7-b0a4-48d112d41850',
            RiskType: [
              'Modelo de Risco Rematrícula',
              'Modelo de Risco Durante',
            ],
          },
        ],
        Situacao: [
          {
            Description: LabelObjetivosCampanha.Situacao.Todos,
            Id: '155c5b5b-2654-4cf6-9ddf-f6c86d172cb9',
          },
          {
            Description: LabelObjetivosCampanha.Situacao.PossuiDebito,
            Id: 'a0870ca8-83be-4e89-b2b8-148ad3c46468',
          },
          {
            Description: LabelObjetivosCampanha.Situacao.NaoPossuiDebito,
            Id: 'fbb4b6e7-b832-41ea-87a4-7eeb2a9c27c0',
          },
        ],
        FormaDeIngresso: resp.FormaDeIngresso,
      },
      impactosDaCampanha: [
        {
          checked: false,
          Description: impactosDaCampanha.MuitoBaixo,
          Id: 1,
        },
        {
          checked: false,
          Description: impactosDaCampanha.Baixo,
          Id: 2,
        },
        {
          checked: false,
          Description: impactosDaCampanha.Medio,
          Id: 3,
        },
        {
          checked: false,
          Description: impactosDaCampanha.Alto,
          Id: 4,
        },
        {
          checked: false,
          Description: impactosDaCampanha.MuitoAlto,
          Id: 5,
        },
      ],
      gruposDeVisualizacao: resp.VisualizationGroup,
    };
  }

  async buscarSituacoes(institutionId, operatorId, filter = []) {
    const payload = {
      institution_id: institutionId,
      operator_id: operatorId,
      return_variable: 'situation',
      filters: filter,
    };
    return FiltroService.buscarFiltros(payload);
  }

  buscarAluno() {
    return deepCopy(alunoDefault);
  }

  buscarProblema() {
    return deepCopy(problemaDefault);
  }

  async buscarSituacaoInicial(institutionId, operatorId) {
    const problemas = this.buscarProblema();
    return this.buscarSituacao(institutionId, operatorId, problemas);
  }

  async buscarSituacao(institutionId, operatorId, filters = []) {
    const response = await this.buscarSituacoes(
      institutionId,
      operatorId,
      filters
    );
    const lista = [];

    if (response) {
      response.return_variable.map((element) => lista.push(element.situation));
    }

    return lista.length !== 0 ? this.formatOptions([...new Set(lista)]) : [];
  }

  atualizarFiltro = (lista, descricao) => async (dispatch) => {
    if (descricao === filtroItens.Aluno) {
      dispatch(FiltroStore.atualizarAluno(lista));
    } else if (descricao === filtroItens.Marca) {
      dispatch(FiltroStore.atualizarMarca(lista));
    } else if (descricao === filtroItens.Campus) {
      dispatch(FiltroStore.atualizarCampus(lista));
    } else if (descricao === filtroItens.Curso) {
      dispatch(FiltroStore.atualizarCurso(lista));
    } else if (descricao === filtroItens.Modalidade) {
      dispatch(FiltroStore.atualizarModalidade(lista));
    } else if (descricao === filtroItens.Turno) {
      dispatch(FiltroStore.atualizarTurno(lista));
    } else if (descricao === filtroItens.PeriodoLetivo) {
      dispatch(FiltroStore.atualizarPeriodo(lista));
    } else if (descricao === filtroItens.Ingresso) {
      dispatch(FiltroStore.atualizarIngresso(lista));
    } else if (descricao === filtroItens.Situacao) {
      dispatch(FiltroStore.atualizarSituacao(lista));
    } else if (descricao === filtroItens.Problema) {
      dispatch(FiltroStore.atualizarProblema(lista));
    }
  };

  atualizarAuxiliar = (valor) => async (dispatch) => {
    dispatch(FiltroStore.atualizarAuxiliar(valor));
  };

  atualizarEstadoFiltroCampanha = (valor) => async (dispatch) => {
    dispatch(FiltroStore.atualizarEstadoFiltroCampanha(valor));
  };

  async buscarFiltro(filter = {}) {
    return FiltroService.buscarFiltros(filter);
  }

  joinSelectionArray(selectionArray) {
    const joinnedSelectionArray = [];
    selectionArray.forEach((el) => {
      if (Array.isArray(el.id)) {
        el.id.forEach((idEl) => {
          joinnedSelectionArray.push(idEl);
        });
      } else {
        joinnedSelectionArray.push(el.id);
      }
    });
    return joinnedSelectionArray;
  }

  buildCriarCampanharFilterdata(objetivos, parametrosIES) {
    return {
      BrandId: this.joinSelectionArray(parametrosIES.marca) ?? null,
      CampusId: this.joinSelectionArray(parametrosIES.campus) ?? null,
      CourseId: this.joinSelectionArray(parametrosIES.curso) ?? null,
      Modality: this.joinSelectionArray(parametrosIES.modalidade) ?? null,
      Shift: this.joinSelectionArray(parametrosIES.turno) ?? null,
      EnrollmentType:
        this.joinSelectionArray(objetivos.formaDeIngresso) ?? null,
      StudentType: objetivos.perfilAluno
        ? MappingObjetivosCampanha.Aluno[objetivos.perfilAluno]
        : null,
      HasFinancialDebt: objetivos.situacao
        ? MappingObjetivosCampanha.Situacao[objetivos.situacao]
        : null,
      StartRegistrationDate: objetivos.dataInicioMatricula
        ? objetivos.dataInicioMatricula * 1000
        : null,
      EndRegistrationDate: objetivos.dataTerminoMatricula
        ? objetivos.dataTerminoMatricula * 1000
        : null,
    };
  }

  mapearArrayGruposDeVisualizacao(grupoDeVisualizacao) {
    const gruposDeVisualizacaoTemp = [];
    grupoDeVisualizacao.forEach((el) => {
      gruposDeVisualizacaoTemp.push(el.title);
    });
    return gruposDeVisualizacaoTemp;
  }

  mapearArrayMensagensWhatsapp(dadosMensagem) {
    const whatsappMessages = [];
    dadosMensagem.forEach((el) => {
      if (el.Id) {
        whatsappMessages.push({ MessageId: el.Id, Trigger: el.Gatilho });
      }
    });
    return whatsappMessages;
  }

  async buscarQuantidadeAlunos(parametrosIES, objetivos) {
    const categoria = objetivos.categoria
      ? objetivos.categoria
      : LabelObjetivosCampanha.Categoria.Personalizada;
    const payload = {
      Category: MappingObjetivosCampanha.Categoria[categoria],
      CategoryType: MappingObjetivosCampanha.TipoCategoria[categoria],
      FilterData: this.buildCriarCampanharFilterdata(objetivos, parametrosIES),
    };
    return CriarCampanhaService.buscarQuantidadeAlunos(payload);
  }

  async buscarMensagensWhatsapp() {
    return WhatsappService.listaMensagensWhatsapp();
  }

  async buscarMarcasWhatsapp() {
    return WhatsappService.listaMarcasWhatsapp();
  }
}

export default new CriarCampanhaManager();
