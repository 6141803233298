import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Check } from 'react-feather';
import Loading from '../../../shared/componentes/Loading';
import {
  WHITE,
  BLACK,
  DARK_CYAN,
  DARK_GRAY,
  FONT_FAMILY_REGULAR,
  LIGHT_SEA_GREEN_HOVER,
  TOMATO,
} from '../../../theme';

const useStyles = makeStyles(() => ({
  botaoEsquerdo: {
    color: DARK_GRAY,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px',
  },
  titulo: {
    color: DARK_CYAN,
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '25px',
  },
  backgroundProps: {
    background: 'rgba(192,198,214,0.4)',
  },
  modal: {
    maxWidth: '430px',
    backgroundColor: WHITE,
    boxShadow: '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
    borderRadius: '8px',
    minWidth: '350px',
  },
  containerConteudoDialog: {
    padding: '20px 15px 20px 15px',
  },
  descricao: {
    color: TOMATO,
    marginBottom: '20px',
  },
  segundaDescricao: {
    color: BLACK,
    marginBottom: '20px',
    fontWeight: 'bold',
  },
  segundaDescricaoFontRegular: {
    color: BLACK,
    marginBottom: '20px',
    fontWeight: 'normal',
    fontSize: '16px',
    fontFamily: FONT_FAMILY_REGULAR,
    lineHeight: '24px',
  },
  closeButton: {
    position: 'absolute',
    right: '16px',
    top: '16px',
    color: '#9e9e9e',
  },
  botaoAplicar: {
    backgroundColor: DARK_CYAN,
    color: WHITE,
    height: '80%',
    '&:hover': {
      backgroundColor: LIGHT_SEA_GREEN_HOVER,
    },
    padding: '0px 40px',
  },
  textoBotao: {
    fontSize: '14px',
    color: WHITE,
  },
}));

const ModalConfirmarEnvioMensagem = (props) => {
  const classes = useStyles();

  const [carregando, setCarregando] = useState(false);
  const isUmounted = useRef(false);

  const { modalAberto, setModalAberto, handleBotaoDireito, verbo } = props;

  useEffect(() => {
    return () => {
      isUmounted.current = true;
    };
  }, []);

  const handleBotaoDireitoAsync = async () => {
    setCarregando(true);
    await handleBotaoDireito();
    if (!isUmounted.current) {
      setCarregando(false);
    }
  };

  const handleOnCloseAsync = async (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }

    if (reason === 'escapeKeyDown') {
      return;
    }
    if (!carregando) await setModalAberto(false);
  };

  return (
    <Dialog
      open={modalAberto}
      onClose={handleOnCloseAsync}
      aria-labelledby="modal-titulo"
      aria-describedby="modal-descricao"
      scroll="body"
      classes={{ paper: classes.modal }}
      BackdropProps={{
        classes: {
          root: classes.backgroundProps,
        },
      }}
    >
      {carregando && <Loading />}
      <DialogContent>
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.containerConteudoDialog}
        >
          <Typography className={classes.titulo}>
            {verbo === 'Criar'
              ? 'Confirmação de Cadastro de Mensagem'
              : 'Confirmação de Atualização de Mensagem'}
          </Typography>

          <Grid>
            <Typography variant="body2" className={classes.descricao}>
              {verbo === 'Criar'
                ? 'Tem certeza que deseja cadastrar a mensagem?'
                : 'Tem certeza que deseja atualizar a mensagem?'}
            </Typography>

            <Typography variant="body2" className={classes.segundaDescricao}>
              <span className={classes.segundaDescricaoFontRegular}>
                Utilize a
              </span>{' '}
              Visualização Prévia{' '}
              <span className={classes.segundaDescricaoFontRegular}>
                para conferir todas informações antes de{' '}
                {verbo === 'Criar' ? 'cadastrar' : 'atualizar'} a mensagem.
              </span>
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Button
              className={classes.botaoEsquerdo}
              onClick={() => {
                setModalAberto(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disableElevation
              startIcon={<Check size={18} />}
              onClick={() => {
                handleBotaoDireitoAsync();
              }}
              className={`${classes.botaoAplicar} "Login-Btn"`}
            >
              <Typography className={classes.textoBotao} variant="h6">
                {verbo === 'Criar'
                  ? 'Cadastrar Mensagem'
                  : 'Atualizar Mensagem'}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalConfirmarEnvioMensagem;
