/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

function SvgPillIcon01(props) {
  return (
    <svg
      id="PILL_icon-01_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      width="24px"
      height="24px"
      fill="#9EABB5"
      {...props}
    >
      <style />
      <path d="M20 4a5.991 5.991 0 00-8.48 0L4 11.51C1.66 13.86 1.66 17.66 4 20a5.991 5.991 0 008.48 0L20 12.49c2.34-2.35 2.34-6.15 0-8.49zm-8.93 14.59c-.76.76-1.76 1.17-2.83 1.17s-2.07-.42-2.83-1.17a4.008 4.008 0 010-5.66l3.05-3.05 5.66 5.66-3.05 3.05zm7.52-7.52l-3.05 3.05-5.66-5.66 3.05-3.05c.76-.76 1.76-1.17 2.83-1.17s2.07.42 2.83 1.17a4.008 4.008 0 010 5.66z" />
    </svg>
  );
}

export default SvgPillIcon01;
