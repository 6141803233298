import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core';
import { Upload, Filter } from 'react-feather';
import {
  WHITE,
  BLACK,
  DARK_CYAN,
  DARK_GRAY,
  LIGHT_CYAN,
  LIGHT_GRAY,
} from '../../../theme';

const useStyles = makeStyles(() => ({
  botaoCancelar: {
    color: DARK_GRAY,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px',
  },
  containerBotaoCancelar: {
    paddingTop: '10px',
  },
  titulo: {
    color: DARK_CYAN,
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '25px',
  },
  backgroundProps: {
    background: 'rgba(192,198,214,0.4)',
  },
  modal: {
    maxWidth: '320px',
    backgroundColor: WHITE,
    boxShadow: '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
    borderRadius: '8px',
    minWidth: '320px',
  },
  containerConteudoDialog: {
    padding: '20px 15px 20px 15px',
  },
  descricao: {
    marginBottom: '10px',
    color: BLACK,
  },
  textoIcones: {
    lineHeight: '20px',
    paddingTop: '10px',
  },
  gridIcone: {
    '&:hover': {
      backgroundColor: LIGHT_GRAY,
    },
    cursor: 'pointer',
    paddingTop: '10px',
    paddingBottom: '10px',
    borderRadius: '5px',
    width: '50%',
  },
  boxIcone: {
    height: '40px',
    width: '40px',
    backgroundColor: LIGHT_CYAN,
  },
  corIcones: {
    color: DARK_CYAN,
  },
}));

const ModalOpcaoCriarCampanha = (props) => {
  const classes = useStyles();

  const {
    modalAberto,
    setModalAberto,
    setModalCriarCampanha,
    setCriarCampanhaUpload,
  } = props;

  function abrirModalCriarCampanha(isUpload) {
    setModalAberto(false);
    setCriarCampanhaUpload(isUpload);
    setModalCriarCampanha(true);
  }

  return (
    <Dialog
      open={modalAberto}
      onClose={() => setModalAberto(false)}
      aria-labelledby="modal-titulo"
      aria-describedby="modal-descricao"
      scroll="body"
      classes={{ paper: classes.modal }}
      BackdropProps={{
        classes: {
          root: classes.backgroundProps,
        },
      }}
    >
      <DialogContent>
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.containerConteudoDialog}
        >
          <Grid>
            <Typography className={classes.titulo} align="center">
              Nova Campanha
            </Typography>
            <Typography
              variant="body2"
              align="center"
              className={classes.descricao}
            >
              Você deseja criar uma nova campanha baseada em:
            </Typography>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.gridIcone}
            onClick={() => abrirModalCriarCampanha(false)}
          >
            <Box className={classes.boxIcone} borderRadius={8} p={1}>
              <Filter className={classes.corIcones} />
            </Box>
            <Typography
              variant="body2"
              align="center"
              className={classes.textoIcones}
            >
              Filtragem de Estudantes
            </Typography>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.gridIcone}
            onClick={() => abrirModalCriarCampanha(true)}
          >
            <Box className={classes.boxIcone} borderRadius={8} p={1}>
              <Upload className={classes.corIcones} />
            </Box>
            <Typography
              variant="body2"
              align="center"
              className={classes.textoIcones}
            >
              Upload de Arquivo
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            className={classes.containerBotaoCancelar}
          >
            <Button
              className={classes.botaoCancelar}
              onClick={() => {
                setModalAberto(false);
              }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalOpcaoCriarCampanha;
