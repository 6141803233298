import axios from '../apis/axiosInstanceScape0Crm';
import { checkResponse } from '../shared/util/Utils';

class PermissaoUsuarioService {
  async getListaUsuarios() {
    return axios.get('/operator').then(checkResponse);
  }

  async getUsuario(perfil) {
    return axios.get(`/operator/${perfil}`);
  }

  async deleteUser(operatorId) {
    return axios.delete(`/operator/${operatorId}`);
  }

  async changeUser(operatorId, payload) {
    return axios.put(`/operator/${operatorId}`, payload);
  }

  async changeVisualizationGroup(payload) {
    return axios.put('/operator', payload);
  }

  async adicionarUsuario(email, permissao, grupoDeVisualizacao) {
    return axios.post('/operator', {
      Email: email,
      PermissionGroupId: permissao,
      VisualizationGroup: grupoDeVisualizacao,
    });
  }
}

export default new PermissaoUsuarioService();
