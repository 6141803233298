import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { Activity, Filter, Calendar, Phone, User } from 'react-feather';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import { DARK_CYAN, LIGHT_GRAY } from '../../theme';
import { formatarTimestampParaData } from '../util/Utils';
import SvgPillIcon01 from '../img/SvgPillIcon01';
import DadosNaoEncontrados from './DadosNaoEncontrados';

const useStyles = makeStyles({
  containerTooltip: {
    borderRadius: '8px',
    boxShadow: ' 0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
    width: '560px',
  },
  containerTituloTooltip: {
    borderBottom: `1px solid ${LIGHT_GRAY}`,
  },
  containerIconeTituloPrincipal: {
    margin: '15px',
  },
  containerConteudoFiltros: {
    width: '100%',
    margin: '0',
    padding: '10px',
  },
  conteudoFiltro: {
    marginLeft: '25px',
    lineHeight: '24px',
  },
  tituloTooltip: {
    fontSize: '18px',
    marginLeft: '8px',
    color: DARK_CYAN,
  },
  tituloFiltro: {
    fontSize: '16px',
    marginLeft: '5px',
    color: DARK_CYAN,
  },
  iconeRemedio: {
    fill: DARK_CYAN,
  },
  dadosNaoEncontrados: {
    alignContent: 'center',
    justifyContent: 'center',
  },
});

export default function TooltipFiltroDesempenho(props) {
  const classes = useStyles();

  const {
    dataInicio,
    dataFim,
    campanhas,
    status,
    atendentes,
    problemas,
    remedios,
  } = props;

  const verificarEFormatarPeriodo = () => {
    if (dataInicio && dataFim) {
      return `${formatarTimestampParaData(
        new Date(dataInicio)
      )} - ${formatarTimestampParaData(new Date(dataFim))}`;
    }
    if (dataInicio && !dataFim) {
      return `Á partir de ${formatarTimestampParaData(new Date(dataInicio))}`;
    }
    return `Até ${formatarTimestampParaData(new Date(dataFim))}`;
  };

  const possuiDataInicio = !!dataInicio;
  const possuiDataFim = !!dataFim;
  const possuiCampanha = !!campanhas && campanhas.length > 0;
  const possuiStatus = !!status && status.length > 0;
  const possuiAtendentes = !!atendentes && atendentes.length > 0;
  const possuiProblemas = !!problemas && problemas.length > 0;
  const possuiRemedios = !!remedios && remedios.length > 0;

  return (
    <Grid container className={classes.containerTooltip}>
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        className={classes.containerTituloTooltip}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.containerIconeTituloPrincipal}
        >
          <Filter size={22} color={DARK_CYAN} />
          <Typography className={classes.tituloTooltip} variant="h4">
            Filtros Aplicados
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.containerConteudoFiltros} spacing={3}>
        {!possuiDataInicio &&
        !possuiDataFim &&
        !possuiCampanha &&
        !possuiAtendentes &&
        !possuiProblemas &&
        !possuiStatus &&
        !possuiRemedios ? (
          <Grid container className={classes.dadosNaoEncontrados}>
            <DadosNaoEncontrados chaveamentoEstadoVazio={4} />
          </Grid>
        ) : (
          <>
            <Grid item container>
              {(dataInicio || dataFim) && (
                <Grid container xs={6}>
                  <Grid container alignItems="center" className="mb-1">
                    <Calendar size={20} color={DARK_CYAN} />
                    <Typography
                      className={classes.tituloFiltro}
                      variant="body2"
                    >
                      Período:&nbsp;
                    </Typography>
                  </Grid>
                  <Typography align="left" className={classes.conteudoFiltro}>
                    {verificarEFormatarPeriodo()}
                  </Typography>
                </Grid>
              )}
              {possuiCampanha && (
                <Grid container xs={6}>
                  <Grid container alignItems="center" className="mb-1">
                    <BusinessRoundedIcon
                      style={{
                        color: DARK_CYAN,
                        height: '20px',
                        width: '20px',
                      }}
                    />
                    <Typography
                      className={classes.tituloFiltro}
                      variant="body2"
                    >
                      Campanha:&nbsp;
                    </Typography>
                  </Grid>
                  <Typography align="left" className={classes.conteudoFiltro}>
                    {campanhas
                      .map((campanha) => campanha)
                      .slice(0, 6)
                      .join(', ')
                      .concat(
                        campanhas.length > 6
                          ? ` + ${campanhas.length - 6} Campanhas`
                          : ''
                      )}
                  </Typography>
                </Grid>
              )}
              {possuiStatus && (
                <Grid container className="mt-3" xs={6}>
                  <Grid container alignItems="center" className="mb-1">
                    <Phone size={20} color={DARK_CYAN} />
                    <Typography
                      className={classes.tituloFiltro}
                      variant="body2"
                    >
                      Situação:&nbsp;
                    </Typography>
                  </Grid>
                  <Typography align="left" className={classes.conteudoFiltro}>
                    {status
                      .map((nomeStatus) => nomeStatus)
                      .slice(0, 6)
                      .join(', ')
                      .concat(
                        status.length > 6
                          ? ` + ${status.length - 6} Situações`
                          : ''
                      )}
                  </Typography>
                </Grid>
              )}
              {possuiAtendentes && (
                <Grid container className="mt-3" xs={6}>
                  <Grid container alignItems="center">
                    <User size={20} color={DARK_CYAN} />
                    <Typography
                      className={classes.tituloFiltro}
                      variant="body2"
                    >
                      Atendente:&nbsp;
                    </Typography>
                  </Grid>
                  <Typography align="left" className={classes.conteudoFiltro}>
                    {atendentes
                      .map((operador) => operador)
                      .slice(0, 6)
                      .join(', ')
                      .concat(
                        atendentes.length > 6
                          ? ` + ${atendentes.length - 6} Operadores`
                          : ''
                      )}
                  </Typography>
                </Grid>
              )}
              {possuiProblemas && (
                <Grid container className="mt-3" xs={6}>
                  <Grid container alignItems="center">
                    <Activity size={20} color={DARK_CYAN} />
                    <Typography
                      className={classes.tituloFiltro}
                      variant="body2"
                    >
                      Problema:&nbsp;
                    </Typography>
                  </Grid>
                  <Typography align="left" className={classes.conteudoFiltro}>
                    {problemas
                      .map((problema) => problema)
                      .slice(0, 6)
                      .join(', ')
                      .concat(
                        problemas.length > 6
                          ? ` + ${problemas.length - 6} Problemas`
                          : ''
                      )}
                  </Typography>
                </Grid>
              )}
              {possuiRemedios && (
                <Grid container className="mt-3" xs={6}>
                  <Grid container alignItems="center">
                    <SvgPillIcon01 className={classes.iconeRemedio} />
                    <Typography
                      className={classes.tituloFiltro}
                      variant="body2"
                    >
                      Remédios:&nbsp;
                    </Typography>
                  </Grid>
                  <Typography align="left" className={classes.conteudoFiltro}>
                    {remedios
                      .map((remedio) => remedio)
                      .slice(0, 3)
                      .join(', ')
                      .concat(
                        remedios.length > 3
                          ? ` + ${remedios.length - 3} Remédios`
                          : ''
                      )}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
