import React from 'react';
import {
  Button,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Repeat } from 'react-feather';
import LinhaRepositorioPerformance from './LinhaRespositorioPerformance';
import DadosNaoEncontrados from '../../../shared/componentes/DadosNaoEncontrados';
import { DARK_CYAN } from '../../../theme';

const useStyles = makeStyles({
  containerDadosNaoEncontrados: {
    marginTop: '6rem',
  },
});

const TabelaRepositorioPerformance = (props) => {
  const { listaArquivos, handleAtualizarListaRepositorio } = props;

  const classes = useStyles();
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography align="left" />
            </TableCell>
            <TableCell>
              <Typography align="left">Arquivo</Typography>
            </TableCell>
            <TableCell>
              <Typography align="left">Data de Extração</Typography>
            </TableCell>
            <TableCell>
              <Typography align="left">Validade</Typography>
            </TableCell>
            <TableCell align="right">
              <Button
                name="refresh"
                onClick={() => handleAtualizarListaRepositorio()}
              >
                <Repeat color={DARK_CYAN} />
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listaArquivos.map((arquivo, id) => (
            <LinhaRepositorioPerformance
              key={id}
              lineNum={id}
              titulo={arquivo.Title}
              dataDeExtracao={arquivo.CreationTimestamp}
              validade={arquivo.TimeToLive}
              urlArquivo={arquivo.UrlS3}
              dataInicio={arquivo.StartTimestamp}
              dataFim={arquivo.EndTimestamp}
              campanhas={arquivo.CampaignName}
              status={arquivo.Status}
              atendentes={arquivo.OperatorName}
              problemas={arquivo.ProblemCategoriesName}
              remedios={arquivo.RemediesName}
            />
          ))}
        </TableBody>
      </Table>
      {listaArquivos && listaArquivos.length <= 0 && (
        <Grid container className={classes.containerDadosNaoEncontrados}>
          <DadosNaoEncontrados chaveamentoEstadoVazio={2} />
        </Grid>
      )}
    </>
  );
};

export default TabelaRepositorioPerformance;
