import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { GRAY, MEDIUM_GRAY, DARK_CYAN } from '../../theme';

const useStyles = makeStyles({
  titulo: {
    fontSize: '12px',
    marginLeft: '4px',
    color: DARK_CYAN,
  },
  tituloDesabilitado: {
    fontSize: '12px',
    marginLeft: '4px',
    color: GRAY,
  },
  item: {
    fontSize: '12px',
    marginLeft: '4px',
    color: MEDIUM_GRAY,
    wordWrap: 'break-word',
    textAlign: 'justify',
  },
  icone: {
    color: (props) =>
      props && props.valores && props.valores.length > 0 ? MEDIUM_GRAY : GRAY,
    fontSize: '16px',
  },
  iconeVerde: {
    color: DARK_CYAN,
    fontSize: '16px',
  },
  containerTituloTooltip: {
    marginTop: '8px',
    marginLeft: '8px',
    cursor: 'default',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  containerItem: {
    marginLeft: '24px',
    maxWidth: '90%',
  },
  iconCircle: {
    height: '5px',
    width: '5px',
    marginRight: '6px',
    marginLeft: '5px',
    background: (props) =>
      props.naoExpandir && props.valores && props.valores.length > 0
        ? MEDIUM_GRAY
        : GRAY,
    borderRadius: '50%',
  },
});

const ItemFiltrosPreVisualizar = (props) => {
  const { descricao, valores, naoExpandir } = props;

  const classes = useStyles(props);

  const [expandido, setExpandido] = useState(false);

  useEffect(() => {
    if (!valores || valores.length === 0) {
      setExpandido(false);
    }
  }, [valores]);

  return (
    <>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={`${classes.containerTituloTooltip} ${
            !naoExpandir &&
            valores &&
            valores.length > 0 &&
            classes.cursorPointer
          }`}
          onClick={() =>
            !naoExpandir &&
            valores &&
            valores.length > 0 &&
            setExpandido(!expandido)
          }
        >
          {naoExpandir && <Grid className={classes.iconCircle} />}
          {(!expandido || !valores || valores.length <= 0) && !naoExpandir && (
            <ExpandMoreIcon className={classes.icone} />
          )}
          {expandido && valores && valores.length > 0 && (
            <ExpandLessIcon className={classes.iconeVerde} />
          )}
          <Typography
            className={
              valores && valores.length > 0
                ? classes.titulo
                : classes.tituloDesabilitado
            }
            variant="h6"
            noWrap
          >
            {naoExpandir && valores ? valores : descricao}
          </Typography>
        </Grid>
        {!naoExpandir &&
          expandido &&
          valores &&
          valores.length &&
          valores.length > 0 &&
          valores.map((valor) => (
            <Grid container className={classes.containerItem} key={valor}>
              <Typography className={classes.item} variant="h6">
                {valor}
              </Typography>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default ItemFiltrosPreVisualizar;
