import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Icon, Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Trash2, Filter } from 'react-feather';
import { useHistory } from 'react-router-dom';
import FiltroManager from '../FiltroManager';
import CardFiltro from '../../../../shared/componentes/CardFiltro';
import FiltroItem from './FiltroItem';
import {
  filtroItens,
  idNotificacoesFixas,
  mensagensNotificacaoAlerta,
  textoBotaoNotificacao,
} from '../../../../shared/util/Constantes';
import { deepEqual } from '../../../../shared/util/Utils';
import {
  TOMATO_HOVER,
  LIGHT_SEA_GREEN_HOVER,
  LIGHT_SEA_GREEN,
  TOMATO,
  WHITE,
  LIGHT_GRAY,
} from '../../../../theme';
import { ocultarMensagemAlerta } from '../../../notificacao/NotificacaoManager';
import { exibirNotificacaoAlerta } from '../../../notificacao/Store';

const DEFAULT = 'default';
const EDIT = 'edit';
const APPLIED = 'applied';
const defaultFilter = {};

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    top: '4rem',
    width: '99%',
    zIndex: '4',
  },
  containerCard: {
    flexWrap: 'nowrap',
  },
  containerbotao: {
    height: '62px',
  },
  iconeContainer: {
    padding: '0.5rem',
    borderRight: '1px solid',
    borderColor: `${LIGHT_GRAY} !important`,
  },
  opcoes: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 50px)',
  },
  botaoAplicar: {
    backgroundColor: LIGHT_SEA_GREEN,
    color: WHITE,
    height: '100%',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: LIGHT_SEA_GREEN_HOVER,
    },
  },
  textoBotaoAplicar: {
    fontSize: '14px',
    color: WHITE,
  },
  botaoRemover: {
    backgroundColor: TOMATO,
    color: WHITE,
    height: '100%',
    float: 'right',
    paddingLeft: '28px',
    '&:hover': {
      backgroundColor: TOMATO_HOVER,
    },
  },
  icone: {
    marginBottom: '2px',
    marginLeft: '0.8px',
  },
  hidden: {
    visibility: 'hidden',
  },
});

const Filtro = (props) => {
  const classes = useStyles();

  const {
    estadoFiltroAplicado,
    atualizarFiltro,
    atualizarEstadoFiltro,
    filtroEstaAtualizado,
    filtroAplicado,
    exibeNotificacaoAlerta,
    open,
    internalRef,
  } = props;

  const history = useHistory();
  history.listen((location) => {
    if (location.pathname !== 'cockpit') {
      ocultarMensagemAlerta(idNotificacoesFixas.AlertaAtualizarDadosCockpit);
    }
  });
  const [estadoLimparFiltro, setEstadoLimparFiltro] = useState(false);
  const [buttonState, setButtonState] = useState(DEFAULT);

  const opcoesFiltro = [
    filtroItens.Aluno,
    filtroItens.Marca,
    filtroItens.Campus,
    filtroItens.Modalidade,
    filtroItens.Curso,
    filtroItens.Turno,
    filtroItens.PeriodoLetivo,
    filtroItens.Ingresso,
  ];

  useEffect(() => {
    if (buttonState === DEFAULT) {
      if (!filtroEstaAtualizado) setButtonState(EDIT);
      return;
    }

    if (buttonState === EDIT) {
      if (filtroEstaAtualizado) {
        const filterEmpty = deepEqual(filtroAplicado, defaultFilter);
        setButtonState(filterEmpty ? DEFAULT : APPLIED);
      }
      return;
    }

    if (buttonState === APPLIED) {
      const filterEmpty = deepEqual(filtroAplicado, defaultFilter);

      if (filterEmpty && filtroEstaAtualizado) {
        setButtonState(DEFAULT);
        return;
      }

      if (!filtroEstaAtualizado) {
        setButtonState(EDIT);
      }
    }
  }, [filtroEstaAtualizado, buttonState, filtroAplicado]);

  useEffect(() => {
    ocultarMensagemAlerta(idNotificacoesFixas.AlertaAtualizarDadosCockpit);
    if (!filtroEstaAtualizado) {
      exibeNotificacaoAlerta(
        mensagensNotificacaoAlerta.DadosDesatualizados,
        null,
        false,
        textoBotaoNotificacao.AtualizarDados,
        () => {
          atualizarEstadoFiltro(!estadoFiltroAplicado);
        },
        idNotificacoesFixas.AlertaAtualizarDadosCockpit
      );
    }
  }, [
    filtroEstaAtualizado,
    atualizarEstadoFiltro,
    estadoFiltroAplicado,
    exibeNotificacaoAlerta,
  ]);

  function aplicarFiltro() {
    atualizarEstadoFiltro(!estadoFiltroAplicado);
  }

  function limparFiltro() {
    setEstadoLimparFiltro(!estadoLimparFiltro);
    opcoesFiltro.forEach((name) => atualizarFiltro([], name));
    atualizarEstadoFiltro(!estadoFiltroAplicado);
  }

  return (
    <Grid
      container
      spacing={2}
      className={`${classes.container} ${open ? '' : classes.hidden}`}
      justifyContent="flex-end"
      ref={internalRef}
    >
      <Grid item xs={9} className={classes.containerCard}>
        <CardFiltro>
          <Grid item className={classes.iconeContainer}>
            <Box p={0.5} borderRadius={4} className="cardIconContainerFiltro">
              <Icon>
                <Filter size={20} className={classes.icone} />
              </Icon>
            </Box>
          </Grid>

          <Grid item className={classes.opcoes}>
            {opcoesFiltro.map((title, index) => (
              <FiltroItem
                key={title}
                limparFiltro={estadoLimparFiltro}
                titulo={title}
                lastItem={index === opcoesFiltro.length - 1}
                tooltip
              >
                {title}
              </FiltroItem>
            ))}
          </Grid>
        </CardFiltro>
      </Grid>
      <Grid container spacing={2} item xs={3} direction="row">
        <Grid item xs={9} className={classes.containerbotao}>
          <Button
            variant="contained"
            startIcon={<Filter size={18} />}
            type="submit"
            fullWidth
            disableElevation
            className={`${classes.botaoAplicar} "Login-Btn"`}
            onClick={() => aplicarFiltro()}
            disabled={buttonState !== EDIT}
          >
            <Typography className={classes.textoBotaoAplicar} variant="h6">
              Aplicar Filtro
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={3} className={classes.containerbotao}>
          <Button
            variant="contained"
            startIcon={<Trash2 size={18} />}
            type="submit"
            fullWidth
            disableElevation
            className={`${classes.botaoRemover} "Login-Btn"`}
            onClick={() => limparFiltro()}
            disabled={buttonState === DEFAULT}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    estadoFiltroAplicado: state.filtro.estadoFiltroAplicado,
  };
};

const mapDispatchToProps = {
  atualizarEstadoFiltro: (valor) => FiltroManager.atualizarEstadoFiltro(valor),
  atualizarFiltro: (lista, descricao) =>
    FiltroManager.atualizarFiltro(lista, descricao),
  exibeNotificacaoAlerta: exibirNotificacaoAlerta,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filtro);
