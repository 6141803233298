import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  nomeAtendente: {
    marginTop: '8px',
    width: '80%',
  },
});

const NomeAtendenteGraficoDesempenho = (props) => {
  const classes = useStyles();

  const { title, characterLimit } = props;
  return (
    <Tooltip
      title={title}
      disableHoverListener={title.length <= characterLimit}
    >
      <Typography align="center" variant="h3" className={classes.nomeAtendente}>
        {title.length <= characterLimit
          ? title
          : `${title.substring(0, characterLimit)}...`}
      </Typography>
    </Tooltip>
  );
};

export default NomeAtendenteGraficoDesempenho;
