import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { UserCheck, Phone, UserX, Users } from 'react-feather';
import { connect } from 'react-redux';
import ArvoreRetencao from './ArvoreRetencao';

import CardTitle from '../../../../shared/componentes/CardTitle';
import IndicadorIlustradoCockpit from '../../../../shared/componentes/IndicadorIlustradoCockpit';
import { LIGHT_GRAY } from '../../../../theme';

import * as FluxoRetencaoManager from '../FluxoRetencaoManager';
import { useBreakpoint } from '../../../../shared/util/BreakpointProvider';
import LoadingDashboard from '../../../../shared/componentes/LoadingDashboard';
import DadosNaoEncontrados from '../../../../shared/componentes/DadosNaoEncontrados';

const useStyles = makeStyles({
  containerFluxo: {
    width: '100%',
  },
  containerIndicador: {
    maxWidth: '200px',
  },
  linhaSeparadora: {
    borderRight: '1px solid',
    borderColor: `${LIGHT_GRAY} !important`,
    '@media (max-width:1279px)': {
      borderRight: `0px`,
      borderBottom: `1px solid`,
    },
  },
  containerGraficoArvore: {
    position: 'relative',
    height: '455px',
    borderRadius: '8px',
    overflowX: 'auto',
  },
  containerIndicadores: {
    position: 'relative',
    borderRadius: '8px',
    paddingLeft: '0px !important',
    '@media (max-width:1279px)': {
      paddingLeft: '24px !important',
      paddingTop: '0px !important',
    },
    '@media (max-width:736px)': {
      height: '200px !important',
    },
    '@media (max-width:525px)': {
      height: '290px !important',
    },
  },
  containerImagemDadosNaoEncontrados: {
    '@media (max-width:1279px)': {
      marginTop: '0px',
    },
    '@media (min-width:1280px)': {
      marginTop: '183px',
    },
  },
});

const FluxoRetencao = (props) => {
  const { filtro, codigoIes } = props;
  const classes = useStyles();
  const breakpoints = useBreakpoint();

  const [loading, setLoading] = React.useState(false);
  const [treeData, setTreeData] = React.useState(null);
  const [indicatorData, setIndicatorData] = React.useState(null);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const arvore = await FluxoRetencaoManager.buscarDadosArvore(
        codigoIes,
        filtro
      );
      const indicadores = await FluxoRetencaoManager.buscarDadosIndicadores(
        codigoIes,
        filtro
      );

      setTreeData(arvore);
      setIndicatorData(indicadores);

      setLoading(false);
    };
    getData();
  }, [filtro, codigoIes]);

  return (
    <>
      <Grid item container xs={12} lg={10} className={classes.containerFluxo}>
        <CardTitle text="Fluxo de Retenção" icon={<UserCheck />} />
        <Grid
          item
          container
          direction="row"
          className={`${classes.linhaSeparadora} ${classes.containerGraficoArvore}`}
        >
          {loading && <LoadingDashboard />}
          {treeData && treeData.length > 0 && (
            <ArvoreRetencao treeData={treeData} />
          )}
          {treeData && treeData.length === 0 && (
            <DadosNaoEncontrados chaveamentoEstadoVazio={1} />
          )}
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        lg={2}
        alignItems="flex-start"
        justifyContent="space-evenly"
        className={classes.containerIndicadores}
      >
        {loading && <LoadingDashboard />}

        {indicatorData && indicatorData.length >= 3 && (
          <>
            <Grid item className={classes.containerIndicador}>
              <IndicadorIlustradoCockpit
                horizontal={breakpoints.lg}
                titulo="alunos em Alto Risco contatados"
                icon={<Phone />}
                color="blue"
                valorPrimario={indicatorData[0].valorPrimario}
                valorSecundario={indicatorData[0].valorSecundario}
              />
            </Grid>

            <Grid item className={classes.containerIndicador}>
              <IndicadorIlustradoCockpit
                horizontal={breakpoints.lg}
                titulo="desligamentos antecipados"
                icon={<UserX />}
                color="red"
                valorPrimario={indicatorData[1].valorPrimario}
                valorSecundario={indicatorData[1].valorSecundario}
              />
            </Grid>

            <Grid item className={classes.containerIndicador}>
              <IndicadorIlustradoCockpit
                horizontal={breakpoints.lg}
                titulo="desligamentos evitados"
                icon={<Users />}
                color="green"
                valorPrimario={indicatorData[2].valorPrimario}
                valorSecundario={indicatorData[2].valorSecundario}
              />
            </Grid>
          </>
        )}
        {indicatorData && indicatorData.length === 0 && (
          <Grid
            className={classes.containerImagemDadosNaoEncontrados}
            container
          >
            <DadosNaoEncontrados chaveamentoEstadoVazio={2} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    codigoIes: parseInt(state.login.usuario.iesCode, 10),
  };
};

export default connect(mapStateToProps)(FluxoRetencao);
