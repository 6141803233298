import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TableRow,
  TableCell,
  Radio,
  Grid,
  IconButton,
} from '@material-ui/core';
import { Trash2 } from 'react-feather';
import { DARK_CYAN, GRAY } from '../../../theme';
import ModalConfirmacao from '../../../shared/componentes/ModalConfirmacao';
import { informacoesModais } from '../../../shared/util/Constantes';
import { deepCopy } from '../../../shared/util/Utils';
import ConfiguracoesUsuarioManager from '../ConfiguracoesUsuarioManager';

const useStyles = makeStyles(() => ({
  row: {
    height: '20px',
  },
  tableBody: {
    padding: '6px 8px 4px 8px',
  },
  textoLoginLogado: {
    color: DARK_CYAN,
  },
  disabledButton: {
    color: `${GRAY} !important`,
  },
  radio: {
    color: DARK_CYAN,
  },
  iconeLixeira: {
    cursor: 'pointer',
  },
  iconButton: {
    color: DARK_CYAN,
    cursor: 'pointer',
    '&:disabled': {
      color: GRAY,
    },
  },
}));

const LinhaTabelaPermissaoUsuario = (props) => {
  const {
    usuario,
    listaPermissoes,
    operadorLogado,
    permissaoUsuarioDaLista,
    listaUsuarios,
    setListaUsuarios,
  } = props;

  const classes = useStyles();

  const [abrirModalConfirmacao, setAbrirModalConfirmacao] = useState(false);
  const [abrirModalExclusao, setAbrirModalExclusao] = useState(false);

  const [permissaoSelecionada, setPermissaoSelecionada] = useState({});

  const handleChange = (permissionId, groupName) => {
    setPermissaoSelecionada({
      permissionId,
      operatorId: usuario.OperatorId,
      groupName,
    });

    setAbrirModalConfirmacao(true);
  };

  const excluirUsuario = async () => {
    const resp = await ConfiguracoesUsuarioManager.excluirUsuario(
      usuario.OperatorId
    );

    if (resp) {
      const listaComUsuarioRemovido = listaUsuarios.filter(
        (element) => element.OperatorId !== usuario.OperatorId
      );
      setListaUsuarios(deepCopy(listaComUsuarioRemovido));
    }
    setAbrirModalExclusao(!resp);
  };

  const alterarPermissaoUsuario = async () => {
    const resp = await ConfiguracoesUsuarioManager.alterarPermissaoUsuario(
      usuario.OperatorId,
      permissaoSelecionada.permissionId
    );

    if (resp) {
      const usuarioDaLista = listaUsuarios.find(
        (element) => element.OperatorId === usuario.OperatorId
      );
      usuarioDaLista.PermissionGroupId = permissaoSelecionada.permissionId;
    }

    setAbrirModalConfirmacao(!resp);
  };

  const renderizarLinha = () => {
    const row = [];
    let index = 0;

    listaPermissoes.forEach((element) => {
      row.push(
        <TableCell key={index++} classes={{ body: classes.tableBody }}>
          <Grid container justifyContent="center">
            <Radio
              size="small"
              checked={element.PermissionGroupId === usuario.PermissionGroupId}
              onChange={() =>
                handleChange(element.PermissionGroupId, element.GroupName)
              }
              inputProps={{
                'data-testid': `${element.PermissionGroupId}-radio-button`,
              }}
              value={element.PermissionGroupId}
              disabled={
                operadorLogado.EhOperadorLogado ||
                permissaoUsuarioDaLista.HierarchyLevel <
                  operadorLogado.PermissionGroupId.HierarchyLevel ||
                element.HierarchyLevel <
                  operadorLogado.PermissionGroupId.HierarchyLevel
              }
              classes={{
                disabled: classes.disabledButton,
                root: classes.radio,
              }}
            />
          </Grid>
        </TableCell>
      );
    });
    return row;
  };

  return (
    <>
      <TableRow
        hover
        className={classes.row}
        data-testid="linha-tabela-permissao-usuario"
      >
        <TableCell classes={{ body: classes.tableBody }}>
          <Typography
            variant="body1"
            align="left"
            data-testid="typography-login-linha-tabela-permissao-usuario"
            className={
              operadorLogado.EhOperadorLogado ? classes.textoLoginLogado : ''
            }
          >
            {usuario.Login.toLowerCase()}
          </Typography>
        </TableCell>
        {renderizarLinha()}

        <TableCell classes={{ body: classes.tableBody }}>
          <IconButton
            onClick={() => setAbrirModalExclusao(true)}
            disabled={
              permissaoUsuarioDaLista.HierarchyLevel <
                operadorLogado.PermissionGroupId.HierarchyLevel ||
              operadorLogado.EhOperadorLogado
            }
            data-testid="icon-button-linha-tabela-permissao-usuario"
            className={classes.iconButton}
          >
            <Trash2 size={16} />
          </IconButton>
        </TableCell>
      </TableRow>
      <ModalConfirmacao
        modalAberto={abrirModalConfirmacao}
        setModalAberto={setAbrirModalConfirmacao}
        tituloModal={informacoesModais.ModalAlterarPermissao.Titulo}
        textoBotaoDireito={
          informacoesModais.ModalAlterarPermissao.TextoBotaoDireito
        }
        descricao={informacoesModais.ModalAlterarPermissao.Descricao(
          usuario.Login,
          permissaoUsuarioDaLista.GroupName,
          permissaoSelecionada.groupName
        )}
        textoBotaoEsquerdo={informacoesModais.TextoBotaoCancelar}
        handleBotaoDireito={() => alterarPermissaoUsuario()}
        handleBotaoEsquerdo={() => {
          setAbrirModalConfirmacao(false);
        }}
      />

      <ModalConfirmacao
        modalAberto={abrirModalExclusao}
        setModalAberto={setAbrirModalExclusao}
        tituloModal={informacoesModais.ModalExclusao.Titulo}
        textoBotaoDireito={informacoesModais.ModalExclusao.TextoBotaoDireito}
        descricao={informacoesModais.ModalExclusao.Descricao(usuario.Login)}
        textoBotaoEsquerdo={informacoesModais.TextoBotaoCancelar}
        handleBotaoDireito={() => excluirUsuario()}
        handleBotaoEsquerdo={() => {
          setAbrirModalExclusao(false);
        }}
      />
    </>
  );
};

export default LinhaTabelaPermissaoUsuario;
