import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ReactLoading from 'react-loading';
import DropdownDatePickerTransparent from '../../../shared/componentes/DropdownDatePickerTransparent';
import { BORDA_FILTRO, DARK_CYAN } from '../../../theme';
import { atualizarDataInicial, atualizarDataFinal } from '../Store';
import { opcoesFiltroDesempenho } from '../../../shared/util/Constantes';

const useStyles = makeStyles({
  containerExterno: {
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(158,171,181,0.05)',
    },
  },
  containerInterno: {
    width: '100%',
    borderRight: `1px solid ${BORDA_FILTRO}`,
  },
});

const FiltroDesempenhoItemDatePicker = (props) => {
  const {
    atualizaDataInicial,
    atualizaDataFinal,
    titulo,
    dataInicial,
    dataFinal,
    carregando,
  } = props;

  const classes = useStyles();

  const [dataPickerOpen, setDataPickerOpen] = useState(false);

  const selecionarData = (data) => {
    if (titulo === opcoesFiltroDesempenho.Inicio) {
      atualizaDataInicial(data.toDate());
    } else {
      atualizaDataFinal(data.toDate());
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.containerExterno}
      id="filtro-desempenho-field"
    >
      {carregando && (
        <ReactLoading type="spin" color={DARK_CYAN} height={25} width={25} />
      )}
      {!carregando && (
        <Grid className={classes.containerInterno}>
          <DropdownDatePickerTransparent
            mostrarDropdown={dataPickerOpen}
            setMostrarDropdown={setDataPickerOpen}
            data={
              titulo === opcoesFiltroDesempenho.Inicio ? dataInicial : dataFinal
            }
            setData={selecionarData}
            top="20px"
            left="-60px"
          />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  dataInicial: state.filtroDesempenho.dataInicial,
  dataFinal: state.filtroDesempenho.dataFinal,
});

const mapDispatchToProps = {
  atualizaDataInicial: atualizarDataInicial,
  atualizaDataFinal: atualizarDataFinal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltroDesempenhoItemDatePicker);
