import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import {
  DARK_CYAN,
  ORANGE,
  DARK_GRAY,
  LIGHT_GRAY,
  MEDIUM_GRAY,
} from '../../../../theme';

const useStyles = makeStyles({
  container: {
    marginRight: '15px',
    marginTop: '15px',
    paddingBottom: '3px',
    '&:first-child': {
      marginTop: '5px',
    },
    cursor: 'default',
  },
  bar: {
    borderRadius: '5px',
  },
  containerNomeCurso: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '165px',
  },
  nomeCurso: {
    color: MEDIUM_GRAY,
  },
  valorBarra: {
    fontSize: '12px',
    color: DARK_CYAN,
    lineHeight: '15px',
  },
  containerValorBarra: {
    textAlignLast: 'end',
    width: '30px',
  },
  containerBarraProgresso: {
    padding: '0px 10px 0px 7px',
    width: 'calc(100% - 195px)',
  },
  barraProgresso: {
    width: '100%',
    height: 7,
    borderRadius: 5,
    backgroundColor: LIGHT_GRAY,
  },
  tooltipEvasaoAtual: {
    color: DARK_CYAN,
    margin: '8px 0px 8px 0px',
    fontSize: '14px',
  },
  tooltipEvasaoYTD: {
    color: ORANGE,
    margin: '8px 0px 8px 0px',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  containerTooltip: {
    padding: '8px 18px 8px 20px',
    borderRadius: '8px',
    boxShadow: ' 0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
    maxWidth: '330px',
  },
  reactTooltip: {
    padding: '0px !important',
    opacity: '100% !important',
  },
  tooltipNomeCurso: {
    margin: '8px 0px 16px 0px',
    fontSize: '14px',
    color: DARK_GRAY,
  },
});

export default function DestaqueItens(props) {
  const classes = useStyles();
  const { item } = props;

  return (
    <>
      <Grid
        container
        item
        alignItems="center"
        justifyContent="flex-end"
        className={classes.container}
      >
        <Grid item className={classes.containerNomeCurso}>
          <Typography
            data-tip
            data-for={item.nomeCurso}
            noWrap
            className={classes.nomeCurso}
            variant="h5"
          >
            {item.nomeCurso}
          </Typography>
        </Grid>
        <Grid
          data-tip
          data-for={item.nomeCurso}
          item
          className={classes.containerBarraProgresso}
        >
          <LinearProgress
            classes={{ bar: classes.bar }}
            color="secondary"
            className={classes.barraProgresso}
            variant="determinate"
            value={Number(item.evasaoAtual)}
          />
        </Grid>
        <Grid
          data-tip
          data-for={item.nomeCurso}
          item
          className={classes.containerValorBarra}
        >
          <Typography className={classes.valorBarra} variant="h5">
            {item.evasaoAtual.replace('.', ',')}%
          </Typography>
        </Grid>
      </Grid>
      <ReactTooltip
        id={item.nomeCurso}
        backgroundColor="white"
        type="light"
        className={classes.reactTooltip}
        arrowColor="rgba(0, 0, 0, 0)"
      >
        <Grid className={classes.containerTooltip}>
          <Typography className={classes.tooltipNomeCurso} variant="h6">
            {item.nomeCurso}
          </Typography>
          <Typography className={classes.tooltipEvasaoAtual} variant="h6">
            Evasão Atual: {item.evasaoAtual.replace('.', ',')}% -{' '}
            {item.numeroTotalEstudantes} Alunos
          </Typography>
          <Typography className={classes.tooltipEvasaoYTD} variant="h5">
            Evasão Período Referência: {item.evasaoYtd.replace('.', ',')}% -{' '}
            {item.numeroTotalEstudantesYtd} Alunos
          </Typography>
        </Grid>
      </ReactTooltip>
    </>
  );
}
