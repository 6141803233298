export const SESSION = 'ATENDIMENTO/SESSION';
export const CAMPAIGN = 'ATENDIMENTO/CAMPAIGN';
export const STUDENT = 'ATENDIMENTO/STUDENT';

export const INITIAL_STATE = {
  session: null,
  campaign: null,
  student: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  if (action.type === SESSION) {
    return {
      ...state,
      session: action.payload,
    };
  }
  if (action.type === CAMPAIGN) {
    return {
      ...state,
      campaign: action.payload,
    };
  }
  if (action.type === STUDENT) {
    return {
      ...state,
      student: action.payload,
    };
  }
  return state;
};

export const iniciarSessao = (session) => {
  return {
    type: SESSION,
    payload: session,
  };
};

export const atualizarCampanha = (campanha) => {
  return {
    type: CAMPAIGN,
    payload: campanha,
  };
};

export const atualizarAluno = (aluno) => {
  return {
    type: STUDENT,
    payload: aluno,
  };
};
