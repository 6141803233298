import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Check, PhoneOutgoing, Trash2, User } from 'react-feather';
import { connect } from 'react-redux';
import AbaWhatsappScapeZero from '../../../shared/componentes/AbaWhatsappScapeZero';
import CardTitle from '../../../shared/componentes/CardTitle';
import Contador from '../../../shared/componentes/Contador';
import ModalConfirmacao from '../../../shared/componentes/ModalConfirmacao';
import {
  arrayOpcoesContato,
  iconesNotificacao,
  informacoesModais,
  mensagensNotificacaoAlerta,
  nivelDePrioridade,
  riskTypeValue,
  listaOpcoesModeloDeRisco,
} from '../../../shared/util/Constantes';
import {
  dividirArrayPorDirecao,
  formatarDataParaTimestamp,
  ordenarPorParticaoDeHifen,
} from '../../../shared/util/Utils';
import {
  DARK_CYAN,
  FONT_FAMILY_EXTRABOLD,
  LIGHT_GRAY,
  LIGHT_SEA_GREEN,
  LIGHT_SEA_GREEN_HOVER,
  TOMATO,
  TOMATO_HOVER,
  WHITE,
} from '../../../theme';
import {
  exibirNotificacaoAlerta,
  exibirNotificacaoSucesso,
} from '../../notificacao/Store';
import CriarCampanhaManager from '../CriarCampanhaManager';
import ModalConfirmarCriarCampanha from './ModalConfirmarCriarCampanha';
import AbaCampanhasScapeZero from './scapezero/AbaCampanhasScapeZero';
import PreVisualizarScapeZero from './scapezero/PreVisualizarScapeZero';

const useStyles = makeStyles(() => ({
  containerInputs: {
    overflowY: 'scroll',
    maxHeight: 'calc(90vh - 195px)',
    scrollbarColor: '#bbbbbe white',
    scrollbarWidth: 'thin',
  },
  modal: {
    height: '90vh',
    margin: '5vh',
  },
  scrollModal: {
    overflowY: 'hidden',
  },
  conteudo: {
    height: 'calc(100% - 50px)',
    overflowY: 'hidden',
  },
  container: {
    height: '100%',
    width: '100%',
  },
  containerHeader: {
    marginBottom: '12px',
    paddingRight: '16px',
  },
  titulo: {
    width: '60%',
  },
  containerContador: {
    width: '40%',
  },
  iconeSubtitulo: {
    marginTop: '4px',
  },
  filtro: {
    borderRight: `1px solid ${LIGHT_GRAY}`,
    margin: '4px 18px 12px 0',
    width: '75%',
  },
  containerPreVisualizar: {
    width: '30%',
    marginBottom: '12px',
  },
  backgroundProps: {
    background: 'rgba(0,0,0,0.3)',
  },
  botaoCancelar: {
    backgroundColor: TOMATO,
    color: WHITE,
    height: '100%',
    float: 'right',
    marginRight: '32px',
    '&:hover': {
      backgroundColor: TOMATO_HOVER,
    },
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  botaoAplicar: {
    backgroundColor: LIGHT_SEA_GREEN,
    color: WHITE,
    height: '100%',
    whiteSpace: 'nowrap',
    marginRight: '1.5rem',
    '&:hover': {
      backgroundColor: LIGHT_SEA_GREEN_HOVER,
    },
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  textoBotao: {
    fontSize: '14px',
    color: WHITE,
  },
  textoBotaoCancelar: {
    fontSize: '14px',
    color: WHITE,
    marginTop: '2px',
  },
  containerAbas: {
    width: '100%',
    marginRight: '10px',
  },
  abaNavegacao: {
    color: DARK_CYAN,
    fontSize: '18px',
    fontFamily: FONT_FAMILY_EXTRABOLD,
  },
  hidden: {
    display: 'none',
  },
}));

const CriarCampanha = (props) => {
  const classes = useStyles();

  const {
    handleFecharModal,
    attListaItens,
    notificarAlerta,
    notificarSucesso,
    criarCampanhaUpload,
  } = props;

  const retornaDataHoraMinima = () =>
    moment(new Date()).second(0).millisecond(0).add(30, 'm').toDate();

  const [tituloCampanha, setTituloCampanha] = useState('');
  const [descricaoCampanha, setDescricaoCampanha] = useState('');
  const [modalCancelar, setModalCancelar] = useState(false);
  const [modalConfirmarCriarCampanha, setModalConfirmarCriarCampanha] =
    useState(false);
  const [carregando, setCarregando] = useState(false);
  const dataHoraMinima = useRef(retornaDataHoraMinima());
  const [carregandoQuantidade, setCarregandoQuantidade] = useState();
  const [quantidadeAlunos, setQuantidadeAlunos] = useState();

  const [categoria, setCategoria] = useState('');
  const [situacao, setSituacao] = useState('');
  const [perfilAluno, setPerfilAluno] = useState('');
  const [formaDeIngresso, setFormaDeIngresso] = useState([]);
  const [marca, setMarca] = useState([]);
  const [campus, setCampus] = useState([]);
  const [curso, setCurso] = useState([]);
  const [modalidade, setModalidade] = useState([]);
  const [turno, setTurno] = useState([]);

  const [dataInicioCampanha, setDataInicioCampanha] = useState(null);
  const [dataTerminoCampanha, setDataTerminoCampanha] = useState(null);
  const [dataInicioMatricula, setDataInicioMatricula] = useState(null);
  const [dataTerminoMatricula, setDataTerminoMatricula] = useState(null);

  const [modeloDeRisco, setModeloDeRisco] = useState('');

  const [impactoDaCampanha, setImpactoDaCampanha] = useState('');

  const [grupoDeVisualizacao, setGrupoDeVisualizacao] = useState([]);

  const [arquivoUpload, setArquivoUpload] = useState(false);

  const [abaSelecionada, setAbaSelecionada] = useState(1);

  const [marcaWhatsapp, setMarcaWhatsapp] = useState('');
  const [listaMarcasWhatsapp, setListaMarcasWhatsapp] = useState([]);
  const [switchMarca, setSwitchMarca] = useState(false);

  const [primeiroContato, setPrimeiroContato] = useState(arrayOpcoesContato[2]);
  const [segundoContato, setSegundoContato] = useState(arrayOpcoesContato[4]);
  const [listaMensagens, setListaMensagens] = useState([]);
  const [primeiraMensagem, setPrimeiraMensagem] = useState('');
  const [segundaMensagem, setSegundaMensagem] = useState('');
  const [terceiraMensagem, setTerceiraMensagem] = useState('');

  const [listInputFilter, setListInputFilter] = useState({
    parametrosIES: {
      Modalidade: [],
      Marca: [],
      Campus: [],
      Curso: [],
      Turno: [],
    },
    objetivosCampanha: {
      Aluno: [],
      Categoria: [],
      Situacao: [],
      FormaDeIngresso: [],
    },
    impactosDaCampanha: [],
    gruposDeVisualizacao: [],
  });

  const podeCriarCampanha = () => {
    if (!criarCampanhaUpload && quantidadeAlunos < 1) {
      notificarAlerta(
        mensagensNotificacaoAlerta.CampanhaDevePossuirAlunos,
        null,
        true
      );
      return;
    }
    if (
      moment(dataInicioCampanha).unix() > moment(dataTerminoCampanha).unix()
    ) {
      notificarAlerta(
        mensagensNotificacaoAlerta.CampanhaDataTerminoInvalida,
        null,
        true
      );
      return;
    }
    if (
      dataInicioMatricula &&
      dataTerminoMatricula &&
      moment(dataInicioMatricula).unix() > moment(dataTerminoMatricula).unix()
    ) {
      notificarAlerta(
        mensagensNotificacaoAlerta.CampanhaDataMatriculaInvalida,
        null,
        true
      );
      return;
    }
    if (
      (primeiraMensagem || segundaMensagem || terceiraMensagem) &&
      !(marcaWhatsapp || switchMarca)
    ) {
      notificarAlerta(
        mensagensNotificacaoAlerta.MarcaWhatsappInexistente,
        null,
        true
      );
      return;
    }
    return true;
  };

  const criarCampanha = async () => {
    if (!podeCriarCampanha()) return;
    setCarregando(true);
    const objetivos = {
      categoria,
      situacao,
      formaDeIngresso,
      perfilAluno,
      dataInicioCampanha: formatarDataParaTimestamp(dataInicioCampanha),
      dataTerminoCampanha: formatarDataParaTimestamp(dataTerminoCampanha),
      dataInicioMatricula: formatarDataParaTimestamp(dataInicioMatricula),
      dataTerminoMatricula: formatarDataParaTimestamp(dataTerminoMatricula),
    };
    const parametrosIES = {
      modalidade,
      marca,
      campus,
      curso,
      turno,
    };
    const dadosWhatsapp = {
      marcaWhatsapp,
      mensagens: [],
    };
    if (primeiraMensagem) {
      dadosWhatsapp.mensagens.push({
        Id: primeiraMensagem.SK,
        Gatilho: parseInt(primeiroContato.Title, 10),
      });
    }
    if (segundaMensagem) {
      dadosWhatsapp.mensagens.push({
        Id: segundaMensagem.SK,
        Gatilho: parseInt(segundoContato.Title, 10),
      });
    }
    if (terceiraMensagem) {
      dadosWhatsapp.mensagens.push({ Id: terceiraMensagem.SK, Gatilho: 0 });
    }
    const success = criarCampanhaUpload
      ? await CriarCampanhaManager.createCampaignUpload(
          tituloCampanha,
          descricaoCampanha,
          formatarDataParaTimestamp(dataInicioCampanha),
          formatarDataParaTimestamp(dataTerminoCampanha),
          arquivoUpload,
          riskTypeValue[`${modeloDeRisco}`],
          nivelDePrioridade[`${impactoDaCampanha}`],
          grupoDeVisualizacao,
          dadosWhatsapp
        )
      : await CriarCampanhaManager.createCampaign(
          tituloCampanha,
          descricaoCampanha,
          objetivos,
          parametrosIES,
          riskTypeValue[`${modeloDeRisco}`],
          nivelDePrioridade[`${impactoDaCampanha}`],
          grupoDeVisualizacao,
          dadosWhatsapp
        );
    if (success) {
      handleFecharModal();
      setDescricaoCampanha('');
      setTituloCampanha('');
      notificarSucesso(
        'Campanha criada com sucesso',
        iconesNotificacao.MarcaVerificacao
      );
    }
    attListaItens(true);
    setCarregando(false);
  };

  function cancelarCriacao() {
    handleFecharModal();
    setModalCancelar(false);
  }

  function verificarModalCancelar() {
    if (tituloCampanha || descricaoCampanha) {
      setModalCancelar(true);
    } else {
      cancelarCriacao();
    }
  }

  const validarAbrirModalConfirmarCriarCampanha = () => {
    if (podeCriarCampanha()) {
      setModalConfirmarCriarCampanha(true);
    }
  };

  useEffect(() => {
    const buscarDadosIniciaisDaTela = async () => {
      setCarregando(true);
      const resultFiltros = await CriarCampanhaManager.buscarFiltros();
      if (resultFiltros) {
        setListInputFilter(resultFiltros);
      }
      const resultMensagens =
        await CriarCampanhaManager.buscarMensagensWhatsapp();
      if (resultMensagens) {
        setListaMensagens(ordenarPorParticaoDeHifen(resultMensagens));
      }
      const resultMarcas = await CriarCampanhaManager.buscarMarcasWhatsapp();
      resultMarcas.sort();
      if (resultMarcas) {
        setListaMarcasWhatsapp(
          resultMarcas.map((ies) => {
            return { Title: ies };
          })
        );
      }
      setCarregando(false);
    };
    buscarDadosIniciaisDaTela();
  }, [criarCampanhaUpload, setImpactoDaCampanha, setGrupoDeVisualizacao]);

  useEffect(() => {
    const getData = async () => {
      const parametrosIES = {
        modalidade,
        marca,
        campus,
        curso,
        turno,
      };

      const objetivos = {
        categoria,
        situacao,
        formaDeIngresso,
        perfilAluno,
        dataInicioMatricula: formatarDataParaTimestamp(dataInicioMatricula),
        dataTerminoMatricula: formatarDataParaTimestamp(dataTerminoMatricula),
      };
      setCarregandoQuantidade(true);
      const result = await CriarCampanhaManager.buscarQuantidadeAlunos(
        parametrosIES,
        objetivos
      );
      if (result || result === 0) {
        setQuantidadeAlunos(result);
      }
      setCarregandoQuantidade(false);
    };
    if (!criarCampanhaUpload) {
      getData();
    }
  }, [
    categoria,
    situacao,
    formaDeIngresso,
    perfilAluno,
    modalidade,
    marca,
    campus,
    curso,
    turno,
    dataInicioMatricula,
    dataTerminoMatricula,
    criarCampanhaUpload,
  ]);

  function habilitarBotaoCriarCampanha() {
    const habilitarPorWhatsapp =
      !(primeiraMensagem || segundaMensagem || terceiraMensagem) ||
      switchMarca ||
      marcaWhatsapp;
    return (
      tituloCampanha &&
      descricaoCampanha &&
      modeloDeRisco &&
      grupoDeVisualizacao.length > 0 &&
      (categoria || arquivoUpload) &&
      dataInicioCampanha &&
      dataTerminoCampanha &&
      !carregando &&
      !carregandoQuantidade &&
      habilitarPorWhatsapp
    );
  }

  const handleOnClose = (_event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false;
    }
    if (typeof handleFecharModal === 'function') {
      handleFecharModal();
    }
    return undefined;
  };

  const obtemMarcaPrevisualizar = () => {
    if (switchMarca) {
      return ['IES Destinatário'];
    }
    if (marcaWhatsapp && marcaWhatsapp.Title) {
      return [marcaWhatsapp.Title];
    }
    return '';
  };

  return (
    <>
      <Dialog
        open
        fullWidth
        maxWidth="lg"
        onClose={handleOnClose}
        aria-labelledby="modal-titulo"
        aria-describedby="modal-descricao"
        scroll="body"
        classes={{ paper: classes.modal, scrollBody: classes.scrollModal }}
        BackdropProps={{
          classes: {
            root: classes.backgroundProps,
          },
        }}
      >
        <DialogContent className={classes.container}>
          <Grid container wrap="nowrap" className={classes.conteudo}>
            <Grid
              item
              container
              direction="row"
              alignItems="flex-start"
              alignContent="space-between"
              className={classes.filtro}
            >
              <Grid item container>
                <Grid item container alignItems="flex-start">
                  <Grid container className={classes.containerHeader}>
                    <Grid container className={classes.titulo}>
                      <CardTitle
                        text="Nova Campanha"
                        icon={<PhoneOutgoing />}
                      />
                    </Grid>
                    {!criarCampanhaUpload && (
                      <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                        className={classes.containerContador}
                      >
                        <Contador
                          icon={<User />}
                          total={quantidadeAlunos ?? 0}
                          loading={carregandoQuantidade}
                          afterNumber="Alunos"
                          labelUpperNumber="Alcance"
                          minNumber={0}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Tabs
                  value={abaSelecionada}
                  onChange={(_event, novoValor) => {
                    setAbaSelecionada(novoValor);
                  }}
                  variant="fullWidth"
                  className={classes.containerAbas}
                >
                  <Tab
                    value={1}
                    className={classes.abaNavegacao}
                    classes={{
                      wrapper: classes.iconLabelWrapper,
                    }}
                    label="Dados da Campanha"
                  />

                  <Tab
                    value={2}
                    className={classes.abaNavegacao}
                    label="WhatsApp e Orientações"
                  />
                </Tabs>
                <Grid
                  item
                  container
                  className={
                    abaSelecionada && abaSelecionada === 1
                      ? classes.containerInputs
                      : classes.hidden
                  }
                >
                  <AbaCampanhasScapeZero
                    titulo={tituloCampanha}
                    setTitulo={setTituloCampanha}
                    dataHoraMinima={dataHoraMinima.current}
                    carregando={carregando}
                    categoria={categoria}
                    setCategoria={setCategoria}
                    situacao={situacao}
                    setSituacao={setSituacao}
                    perfilAluno={perfilAluno}
                    setPerfilAluno={setPerfilAluno}
                    formaDeIngresso={formaDeIngresso}
                    setFormaDeIngresso={setFormaDeIngresso}
                    modalidade={modalidade}
                    setModalidade={setModalidade}
                    marca={marca}
                    setMarca={setMarca}
                    campus={campus}
                    setCampus={setCampus}
                    curso={curso}
                    setCurso={setCurso}
                    turno={turno}
                    setTurno={setTurno}
                    setDataInicioCampanha={setDataInicioCampanha}
                    dataInicioCampanha={dataInicioCampanha}
                    setDataTerminoCampanha={setDataTerminoCampanha}
                    dataTerminoCampanha={dataTerminoCampanha}
                    dataInicioMatricula={dataInicioMatricula}
                    setDataInicioMatricula={setDataInicioMatricula}
                    dataTerminoMatricula={dataTerminoMatricula}
                    setDataTerminoMatricula={setDataTerminoMatricula}
                    criarCampanhaUpload={criarCampanhaUpload}
                    arquivoUpload={arquivoUpload}
                    setArquivoUpload={setArquivoUpload}
                    setModeloDeRisco={setModeloDeRisco}
                    modeloDeRisco={modeloDeRisco}
                    setImpactoDaCampanha={setImpactoDaCampanha}
                    impactoDaCampanha={impactoDaCampanha}
                    setGrupoDeVisualizacao={setGrupoDeVisualizacao}
                    grupoDeVisualizacao={grupoDeVisualizacao}
                    listInputFilter={listInputFilter}
                    listaInputModeloDeRisco={listaOpcoesModeloDeRisco}
                  />
                </Grid>
                <Grid
                  item
                  container
                  className={
                    abaSelecionada && abaSelecionada === 2
                      ? classes.containerInputs
                      : classes.hidden
                  }
                >
                  <AbaWhatsappScapeZero
                    carregando={carregando}
                    marcaWhatsapp={marcaWhatsapp}
                    setMarcaWhatsapp={setMarcaWhatsapp}
                    listaMarcasWhatsapp={listaMarcasWhatsapp}
                    primeiroContato={primeiroContato}
                    setPrimeiroContato={setPrimeiroContato}
                    opcoesPrimeiroContato={dividirArrayPorDirecao(
                      'antes',
                      arrayOpcoesContato,
                      segundoContato.Title
                    )}
                    opcoesSegundoContato={dividirArrayPorDirecao(
                      'depois',
                      arrayOpcoesContato,
                      primeiroContato.Title
                    )}
                    segundoContato={segundoContato}
                    setSegundoContato={setSegundoContato}
                    listaMensagens={listaMensagens}
                    primeiraMensagem={primeiraMensagem}
                    setPrimeiraMensagem={setPrimeiraMensagem}
                    segundaMensagem={segundaMensagem}
                    setSegundaMensagem={setSegundaMensagem}
                    terceiraMensagem={terceiraMensagem}
                    setTerceiraMensagem={setTerceiraMensagem}
                    orientacoes={descricaoCampanha}
                    setOrientacoes={setDescricaoCampanha}
                    switchHabilitado={switchMarca}
                    setSwitchHabilitado={setSwitchMarca}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              className={classes.containerPreVisualizar}
            >
              <PreVisualizarScapeZero
                titulo={tituloCampanha}
                orientacoes={descricaoCampanha}
                categoria={categoria}
                situacao={situacao}
                perfilAluno={perfilAluno}
                formaDeIngresso={formaDeIngresso}
                modalidade={modalidade}
                marca={marca}
                campus={campus}
                curso={curso}
                turno={turno}
                dataInicioCampanha={dataInicioCampanha}
                dataTerminoCampanha={dataTerminoCampanha}
                dataInicioMatricula={dataInicioMatricula}
                dataTerminoMatricula={dataTerminoMatricula}
                criarCampanhaUpload={criarCampanhaUpload}
                modeloDeRisco={modeloDeRisco}
                impactoDaCampanha={impactoDaCampanha}
                grupoDeVisualizacao={grupoDeVisualizacao}
                marcaWhatsapp={obtemMarcaPrevisualizar()}
                primeiroContato={primeiroContato.Title}
                segundoContato={segundoContato.Title}
                primeiraMensagem={
                  primeiraMensagem
                    ? `${primeiraMensagem.Message1}\n${primeiraMensagem.Message2}\n${primeiraMensagem.Message3}`
                    : ''
                }
                segundaMensagem={
                  segundaMensagem
                    ? `${segundaMensagem.Message1}\n${segundaMensagem.Message2}\n${segundaMensagem.Message3}`
                    : ''
                }
                terceiraMensagem={
                  terceiraMensagem
                    ? `${terceiraMensagem.Message1}\n${terceiraMensagem.Message2}\n${terceiraMensagem.Message3}`
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            wrap="nowrap"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Button
              variant="contained"
              type="submit"
              disableElevation
              fullWidth
              startIcon={<Trash2 size={18} />}
              className={`${classes.botaoCancelar} "Login-Btn"`}
              onClick={() => verificarModalCancelar()}
            >
              <Typography className={classes.textoBotaoCancelar} variant="h6">
                Descartar
              </Typography>
            </Button>
            <Button
              variant="contained"
              type="submit"
              disableElevation
              fullWidth
              startIcon={<Check size={18} />}
              onClick={() => validarAbrirModalConfirmarCriarCampanha()}
              className={`${classes.botaoAplicar} "Login-Btn"`}
              disabled={!habilitarBotaoCriarCampanha()}
            >
              <Typography className={classes.textoBotao} variant="h6">
                Cadastrar campanha
              </Typography>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <ModalConfirmacao
        modalAberto={modalCancelar}
        setModalAberto={setModalCancelar}
        tituloModal={informacoesModais.ModalCancelarCriacaoDeCampanha.Titulo}
        textoBotaoDireito={
          informacoesModais.ModalCancelarCriacaoDeCampanha.TextoBotaoContinuar
        }
        descricao={informacoesModais.ModalCancelarCriacaoDeCampanha.Descricao}
        segundaDescricao={informacoesModais.SegundaDescricao}
        handleBotaoDireito={cancelarCriacao}
        textoBotaoEsquerdo={informacoesModais.TextoBotaoCancelar}
        handleBotaoEsquerdo={() => setModalCancelar(false)}
      />

      <ModalConfirmarCriarCampanha
        modalAberto={modalConfirmarCriarCampanha}
        setModalAberto={setModalConfirmarCriarCampanha}
        handleBotaoDireito={criarCampanha}
      />
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  notificarSucesso: exibirNotificacaoSucesso,
  notificarAlerta: exibirNotificacaoAlerta,
};

export default connect(mapStateToProps, mapDispatchToProps)(CriarCampanha);
