export const SELECIONA_CAMPANHA = 'SELECIONA_CAMPANHA';

export const INITIAL_STATE = {
  campanhas: [],
  campanhaSelecionada: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  if (action.type === SELECIONA_CAMPANHA) {
    return {
      ...state,
      campanhaSelecionada: action.payload.CampanhaSelecionada,
    };
  }
  return state;
};

export const selecionarCampanha = (campanha) => {
  return {
    type: SELECIONA_CAMPANHA,
    payload: { CampanhaSelecionada: campanha },
  };
};
