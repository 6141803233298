import * as React from 'react';
import moment from 'moment';
import { StaticDatePicker, LocalizationProvider } from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { Grid, ClickAwayListener, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  SHADOW_DROPDOWN,
  WHITE,
  DARK_CYAN,
  DARK_GRAY,
  FONT_FAMILY_REGULAR,
  FONT_FAMILY_BOLD,
} from '../../theme';

const useStyles = makeStyles({
  containerInterno: {
    position: 'absolute',
    zIndex: '1',
    backgroundColor: WHITE,
    maxHeight: '20rem',
    boxShadow: `0 0 18px -1px ${SHADOW_DROPDOWN}`,
    borderRadius: '8px',
    left: '-20rem',
  },
  containerExterno: {
    position: 'relative',
    height: '0px',
    width: '0px',
  },
  data: {
    fontFanmily: FONT_FAMILY_REGULAR,
    color: DARK_GRAY,
  },
  dataSelecionada: {
    fontFamily: FONT_FAMILY_BOLD,
    color: DARK_CYAN,
  },
});

export default function DropdownDatePickerTransparent(props) {
  const {
    top,
    left,
    mostrarDropdown,
    setMostrarDropdown,
    data,
    setData,
    bloquearDataNoPassado,
  } = props;
  const [textoDropdownDatePicker, setTextoDropdownDatePicker] =
    React.useState('');
  const classes = useStyles(props);

  const definirData = (newValue) => {
    setData(newValue);
    setTextoDropdownDatePicker(moment(newValue).format('DD/MM/YYYY'));
    setMostrarDropdown(false);
  };
  return (
    <>
      <Typography
        align="center"
        variant="h5"
        onClick={() => setMostrarDropdown(true)}
        className={classes.dataSelecionada}
      >
        {!textoDropdownDatePicker
          ? moment(data).format('DD/MM/YYYY')
          : textoDropdownDatePicker}
      </Typography>
      {mostrarDropdown && (
        <ClickAwayListener onClickAway={() => setMostrarDropdown(false)}>
          <Grid container className={classes.containerExterno}>
            <Grid className={classes.containerInterno} style={{ top, left }}>
              <LocalizationProvider
                dateLibInstance={moment}
                dateAdapter={MomentAdapter}
              >
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  inputFormat="dd/MM/yyyy"
                  value={data}
                  onChange={(dataSelecionada) => definirData(dataSelecionada)}
                  disablePast={bloquearDataNoPassado}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </ClickAwayListener>
      )}
    </>
  );
}
