import _ from 'lodash';
import WhatsappService from '../../services/WhatsappService';
import {
  limiteCaractereMensagemZenvia,
  folgaSegurancaCaracter,
} from '../../shared/util/Constantes';

const caractersEspeciais = [
  { regexKey: /\{\{ies\}\}/g, value: 8 },
  { regexKey: /\{\{nome\}\}/g, value: 92 },
];

class GestaoMensagensWhatsappManager {
  async obterZenviaTemplate() {
    return WhatsappService.obterZenviaTemplate('FPB');
  }

  caracterRestantePosPonderacao(caracter, zenviaTemplate) {
    return (
      limiteCaractereMensagemZenvia -
      folgaSegurancaCaracter -
      this.ponderacaoCaracterEspecialFn(caracter) -
      (zenviaTemplate
        ? this.ponderacaoCaracterEspecialFn(zenviaTemplate.Message)
        : 100)
    );
  }

  ponderacaoCaracterEspecialFn(caracter) {
    let valorPonderacao = 0;
    caractersEspeciais.forEach((caracterEspecial) => {
      valorPonderacao +=
        (caracter.match(caracterEspecial.regexKey) || []).length *
        caracterEspecial.value;
      caracter.replace(caracterEspecial.regexKey, '');
    });
    return valorPonderacao + _.toArray(caracter).length;
  }

  async enviarAtualizarMensagem(id, mensagem) {
    return WhatsappService.enviarAtualizarMensagemWhatsapp(id, {
      Title: mensagem.tituloMensagem,
      Message1: mensagem.mensagem1,
      Message2: mensagem.mensagem2,
      Message3: mensagem.mensagem3,
    });
  }

  async enviarCriarMensagem(mensagem) {
    return WhatsappService.enviarCriarMensagemWhatsapp({
      Title: mensagem.tituloMensagem,
      Message1: mensagem.mensagem1,
      Message2: mensagem.mensagem2,
      Message3: mensagem.mensagem3,
    });
  }
}

export default new GestaoMensagensWhatsappManager();
