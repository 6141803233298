import React, { useEffect } from 'react';
import { Snackbar, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

import {
  FONT_FAMILY_EXTRABOLD,
  TOMATO,
  OPAQUE_LIGHT_TOMATO,
} from '../../../theme';

const NotificacaoErro = (props) => {
  const {
    mensagemErro,
    ocultarMensagemErro,
    classeSnackBar,
    classeSnackBarText,
    iconeErro,
    posicaoErro,
    timestamp,
  } = props;

  const useStyles = makeStyles({
    snackbarTextBold: {
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: FONT_FAMILY_EXTRABOLD,
      cursor: 'pointer',
    },
    erroSnackBar: {
      backgroundColor: OPAQUE_LIGHT_TOMATO,
      color: `${TOMATO} !important`,
    },
    erroSnackBarText: {
      color: TOMATO,
    },
    posicao: {
      bottom: `${24 + posicaoErro * 56}px !important`,
    },
  });

  const classes = useStyles();

  useEffect(() => {
    const getExpireTime = (timestampParam) => {
      const nowDate = new Date().getTime();
      if (nowDate - timestampParam < 5000)
        return 5000 - (nowDate - timestampParam);
      return 1;
    };
    const interval = setTimeout(() => {
      if (mensagemErro) {
        ocultarMensagemErro(timestamp);
      }
    }, getExpireTime(timestamp));
    return () => {
      clearTimeout(interval);
    };
  }, [mensagemErro, ocultarMensagemErro, timestamp]);

  const mensagemAberta = (mensagemErro && mensagemErro.trim().length) > 0;
  return (
    <Snackbar
      className={classes.posicao}
      open={mensagemAberta}
      onClose={() => ocultarMensagemErro(timestamp)}
      ClickAwayListenerProps={{
        mouseEvent: false,
      }}
    >
      <MuiAlert
        elevation={0}
        icon={iconeErro}
        severity="error"
        className={`${classeSnackBar} ${classes.erroSnackBar}`}
      >
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="center"
          id="error-notification"
        >
          <Grid item>
            <Typography
              id="error-notification-message"
              className={`${classeSnackBarText} ${classes.erroSnackBarText}`}
            >
              {mensagemErro}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className={`${classes.snackbarTextBold} ${classes.erroSnackBarText}`}
              onClick={() => ocultarMensagemErro(timestamp)}
            >
              FECHAR
            </Typography>
          </Grid>
        </Grid>
      </MuiAlert>
    </Snackbar>
  );
};

export default NotificacaoErro;
