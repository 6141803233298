import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  ocultarMensagemSucesso,
  ocultarMensagemErro,
  ocultarMensagemAlerta,
} from '../NotificacaoManager';
import NotificacaoErro from './NotificacaoErro';
import NotificacaoSucesso from './NotificacaoSucesso';
import NotificacaoAlerta from './NotificacaoAlerta';
import IconeNotificacao from './IconeNotificacao';
import {
  iconesNotificacao,
  textoBotaoNotificacao,
} from '../../../shared/util/Constantes';

import {
  FONT_FAMILY_SEMIBOLD,
  DARK_CYAN,
  TOMATO,
  ORANGE,
} from '../../../theme';

const useStyles = makeStyles(() => ({
  snackBar: {
    fontSize: '14px',
    fontWeight: 'bold',
    fill: 'solid',
    boxShadow: '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
  },
  snackbarText: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: FONT_FAMILY_SEMIBOLD,
  },
}));

const Notificacao = (props) => {
  const { listaErros, listaSucessos, listaAlertas } = props;
  const classes = useStyles();

  return (
    <>
      {listaErros &&
        listaErros.map((notificacaoErro, index) => (
          <NotificacaoErro
            key={notificacaoErro.timestamp}
            mensagemErro={notificacaoErro.mensagem}
            ocultarMensagemErro={ocultarMensagemErro}
            classeSnackBar={classes.snackBar}
            classeSnackBarText={classes.snackbarText}
            iconeErro={
              <IconeNotificacao
                chave={notificacaoErro.icone}
                color={TOMATO}
                defaultIcon={iconesNotificacao.ErroPadrao}
              />
            }
            timestamp={notificacaoErro.timestamp}
            posicaoErro={index}
          />
        ))}

      {listaAlertas &&
        listaAlertas.map((notificacaoAlerta, index) => (
          <NotificacaoAlerta
            mensagemAlerta={notificacaoAlerta.mensagem}
            ocultarMensagemAlerta={ocultarMensagemAlerta}
            classeSnackBar={classes.snackBar}
            classeSnackBarText={classes.classeSnackBarText}
            iconeAlerta={
              <IconeNotificacao
                chave={notificacaoAlerta.icone}
                color={ORANGE}
                defaultIcon={iconesNotificacao.AlertaPadrao}
              />
            }
            ocultaAutomaticamente={notificacaoAlerta.ocultaAutomaticamente}
            textoBotaoAlerta={
              notificacaoAlerta.textoBotao || textoBotaoNotificacao.Fechar
            }
            handleSnackBarButton={
              notificacaoAlerta.handlerBotao || ocultarMensagemAlerta
            }
            key={notificacaoAlerta.timestamp}
            timestamp={notificacaoAlerta.timestamp}
            posicaoAlerta={listaErros.length + index}
          />
        ))}

      {listaSucessos &&
        listaSucessos.map((notificacaoSucesso, index) => (
          <NotificacaoSucesso
            key={notificacaoSucesso.timestamp}
            mensagemSucesso={notificacaoSucesso.mensagem}
            ocultarMensagemSucesso={ocultarMensagemSucesso}
            classeSnackBar={classes.snackBar}
            classeSnackBarText={classes.snackbarText}
            iconeSucesso={
              <IconeNotificacao
                chave={notificacaoSucesso.icone}
                color={DARK_CYAN}
              />
            }
            timestamp={notificacaoSucesso.timestamp}
            posicaoSucesso={listaErros.length + listaAlertas.length + index}
          />
        ))}
    </>
  );
};

const mapStateToProps = (state) => ({
  listaErros: state.notificacao.listaErro,
  listaSucessos: state.notificacao.listaSucesso,
  listaAlertas: state.notificacao.listaAlerta,
});

export default connect(mapStateToProps)(Notificacao);
