import { Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { ChevronDown, Trash2 } from 'react-feather';
import ReactLoading from 'react-loading';
import {
  BLACK,
  DARK_CYAN,
  DARK_GRAY,
  FONT_FAMILY_REGULAR,
  GRAY,
  WHITE,
} from '../../theme';

const useStyles = makeStyles({
  selectSimples: {
    width: '100%',
    height: (props) => props.height,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
  },
  titleCard: {
    position: 'absolute',
    alignSelf: 'center',
    left: '10px',
    color: DARK_GRAY,
    width: '75%',
    fontSize: (props) => props.fontSize || '14px',
  },
  disabledTitle: {
    color: GRAY,
  },
  containerSelect: {
    position: 'relative',
    width: (props) => props.width || '100%',
    overflow: 'hidden',
  },
  opcoes: {
    fontSize: (props) => props.fontSize || '14px',
    color: BLACK,
    fontFamily: FONT_FAMILY_REGULAR,
  },
  loading: {
    position: 'absolute',
    top: '10%',
    left: 0,
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: WHITE,
    opacity: 0.6,
  },
  removalButton: {
    marginRight: '2%',
    cursor: 'pointer',
  },
});

const SelectSimples = (props) => {
  const {
    carregando,
    titulo,
    listaOpcoes,
    opcao,
    setOpcao,
    disabled,
    listHeight,
    removalButton = false,
  } = props;

  const classes = useStyles(props);

  const handleChange = (event) => {
    setOpcao(event.target.value);
  };

  return (
    <Grid
      container
      wrap="nowrap"
      className={classes.containerSelect}
      data-testid="select-simples"
    >
      {!opcao.Title && (
        <Typography
          variant="body2"
          className={`${classes.titleCard} ${
            disabled ? classes.disabledTitle : ''
          }`}
          noWrap
          data-testid="select-title"
        >
          {titulo}
        </Typography>
      )}
      {carregando && (
        <Grid item container className={classes.loading}>
          <ReactLoading type="spin" color={DARK_CYAN} height={25} width={25} />
        </Grid>
      )}
      <Select
        labelId="simple-select-label"
        id="simple-select-standard"
        data-testid="simple-select-standard-component"
        className={classes.selectSimples}
        value={opcao}
        onChange={handleChange}
        disabled={disabled || carregando || !listaOpcoes}
        label="Opcao"
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          MenuListProps: {
            style: {
              overflowY: 'auto',
              maxHeight: listHeight || 'auto',
            },
          },
        }}
        IconComponent={(MuiProps) =>
          removalButton ? (
            <Trash2
              data-testid="trash-icon-select"
              size="1.5em"
              color={disabled ? GRAY : DARK_CYAN}
              className={classes.removalButton}
              onClick={() => setOpcao('')}
            />
          ) : (
            <ChevronDown
              {...MuiProps}
              size="1.5em"
              color={disabled ? GRAY : DARK_CYAN}
              style={{ marginRight: '2%' }}
              data-testid="chevron-icon-select"
            />
          )
        }
      >
        {listaOpcoes &&
          listaOpcoes.length > 0 &&
          listaOpcoes.map((item) => (
            <MenuItem
              key={item.Title}
              value={item}
              data-testid="select-menu-item"
              style={{ color: disabled ? GRAY : DARK_GRAY }}
            >
              {item.Title}
            </MenuItem>
          ))}
      </Select>
    </Grid>
  );
};

export default SelectSimples;
