import React from 'react';
import { Grid, Box } from '@material-ui/core';
import '../css-components/CardProdutividade.css';

const CardProdutividade = (props) => {
  const { children } = props;

  return (
    <Box className="containerBox">
      <Grid style={{ borderRadius: '8px' }} container>
        {children}
      </Grid>
    </Box>
  );
};

export default CardProdutividade;
