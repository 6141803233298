import PermissaoUsuarioService from '../../services/PermissaoUsuarioService';

class ConfiguracoesUsuarioManager {
  async obterListaUsuarios() {
    const users = await PermissaoUsuarioService.getListaUsuarios();

    if (!users) return { PermissionGroups: [], Operators: [] };

    users.PermissionGroups.sort((a, b) =>
      a.HierarchyLevel > b.HierarchyLevel ? 1 : -1
    );

    return users;
  }

  async excluirUsuario(operatorId) {
    const resp = await PermissaoUsuarioService.deleteUser(operatorId);

    return resp && resp.status === 204;
  }

  async alterarPermissaoUsuario(operatorId, permissionGroupId) {
    const resp = await PermissaoUsuarioService.changeUser(operatorId, {
      PermissionGroupId: permissionGroupId,
    });

    return resp && resp.status === 204;
  }

  async removerGrupoVisualizacaoUsuario(operatorId, Group) {
    const resp = await PermissaoUsuarioService.changeUser(operatorId, {
      VisualizationGroup: Group,
    });

    return resp && resp.status === 204;
  }

  async adicionarGrupoVisualizacao(listaUsuarioFinal) {
    const resp = await PermissaoUsuarioService.changeVisualizationGroup({
      Operators: listaUsuarioFinal,
    });

    return resp && resp.status === 204;
  }

  async adicionarUsuario(email, permissao, grupoDeVisualizacao) {
    const resp = await PermissaoUsuarioService.adicionarUsuario(
      email,
      permissao,
      grupoDeVisualizacao
    );

    return resp && resp.status === 201;
  }
}

export default new ConfiguracoesUsuarioManager();
