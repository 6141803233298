import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  MEDIUM_GRAY,
  DARK_CYAN,
  FONT_FAMILY_SEMIBOLD,
  FONT_FAMILY_EXTRABOLD,
  ESPACAMENTO_VERTICAL,
} from '../../../../theme';
import { tempoMaximoDesempenho } from '../../../../shared/util/Constantes';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    height: '80px',
    justifyContent: 'space-between',
    lineHeight: ESPACAMENTO_VERTICAL,
    padding: '16px',
    width: '203px',
  },
  quantidade: {
    color: MEDIUM_GRAY,
    fontFamily: FONT_FAMILY_SEMIBOLD,
  },
  tempoAtendimento: {
    color: DARK_CYAN,
    fontFamily: FONT_FAMILY_EXTRABOLD,
  },
});

const TooltipGrafico = ({ tempoAtendimento, quantidade }) => {
  const qty = quantidade === 1 ? 'atendimento' : 'atendimentos';
  const time = tempoAtendimento === 1 ? 'minuto' : 'minutos';
  const indic = tempoAtendimento >= tempoMaximoDesempenho ? 'mais de ' : '';

  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.quantidade}>
          {quantidade} {qty}
        </div>
        <div className={classes.tempoAtendimento}>
          Tempo: {indic} {tempoAtendimento} {time}
        </div>
      </div>
    </>
  );
};

export default TooltipGrafico;
