import axios from '../apis/axiosInstanceAuth';

export const getSubdomain = () => {
  const hostnamePiece = window.location.host.split('.');
  let firstPart;
  let secondPart;
  if (hostnamePiece.length === 1) {
    [firstPart, secondPart] = [
      hostnamePiece[0].split(':')[0],
      hostnamePiece[0].replaceAll(':', '-'),
    ];
  } else {
    [firstPart, secondPart] = hostnamePiece;
  }
  return `${firstPart}.${secondPart}`;
};

class AutenticacaoService {
  async getInfoSubdomain() {
    let OidcConfig = JSON.parse(localStorage.getItem('OidcConfig'));
    if (OidcConfig) return OidcConfig;

    const OidcConfigResponse = await axios.get(
      `/subdomains/${getSubdomain()}`,
      {}
    );
    OidcConfig = OidcConfigResponse.data.oidcConfig;
    OidcConfig.chaveArmazenamentoUsuario = `oidc.user:${OidcConfig.authority}:${OidcConfig.client_id}`;
    localStorage.setItem('OidcConfig', JSON.stringify(OidcConfig));

    return OidcConfig;
  }
}

export default new AutenticacaoService();
