import { React } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { DARK_CYAN } from '../../../theme';

const useStyles = makeStyles(() => ({
  labelSubTituloLadoDireito: {
    fontSize: '20px',
    color: DARK_CYAN,
    fontWeight: '800',
    marginBottom: '20px',
  },
}));

const GruposVisualizacaoPerfil = (props) => {
  const { gruposDeVisualizacao } = props;

  const classes = useStyles();

  return (
    <>
      <Typography className={classes.labelSubTituloLadoDireito}>
        Meus Grupos de Visualização
      </Typography>
      <Grid container>
        {gruposDeVisualizacao &&
          gruposDeVisualizacao.map((el) => (
            <Grid key={el} item sm={4}>
              <Typography className="mb-2">{el}</Typography>
            </Grid>
          ))}
        {gruposDeVisualizacao && gruposDeVisualizacao.length <= 0 && (
          <Typography className="mb-2">
            Você ainda não está vinculado a nenhum grupo de visualização
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default GruposVisualizacaoPerfil;
