import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { BarChart2, Calendar, Edit3, Inbox, ChevronsUp } from 'react-feather';
import WhatsappIcon from '@material-ui/icons/WhatsApp';
import CardTitle from '../../../shared/componentes/CardTitle';
import FiltrosPreVisualizar from '../../../shared/componentes/FiltrosPreVisualizar';
import ItemFiltrosPreVisualizar from '../../../shared/componentes/ItemFiltrosPreVisualizar';
import VidroInclicavel from '../../../shared/componentes/VidroInclicavel';
import { formatarDataParaString } from '../../../shared/util/Utils';
import {
  DARK_GRAY,
  FONT_FAMILY_REGULAR,
  GRAY,
  LIGHT_GRAY,
  ORANGE,
} from '../../../theme';

const PreVisualizarEdicao = (props) => {
  const {
    titulo,
    orientacoesOriginal,
    orientacoesEditada,
    categoria,
    situacao,
    formaDeIngresso,
    perfilAluno,
    marca,
    campus,
    curso,
    modalidade,
    turno,
    dataInicioEditada,
    dataTerminoEditada,
    dataInicioOriginal,
    dataTerminoOriginal,
    dataInicioMatricula,
    dataTerminoMatricula,
    modeloDeRisco,
    impactoDaCampanhaOriginal,
    impactoDaCampanhaEditado,
    grupoDeVisualizacaoOriginal,
    grupoDeVisualizacaoEditado,
    edicaoCampanha,
    marcaWhatsapp,
    primeiroContato,
    segundoContato,
    primeiraMensagem,
    segundaMensagem,
    terceiraMensagem,
    marcaWhatsappOriginal,
    primeiroContatoOriginal,
    segundoContatoOriginal,
    primeiraMensagemOriginal,
    segundaMensagemOriginal,
    terceiraMensagemOriginal,
  } = props;

  const obtemCorEdicaoWhatsapp = () => {
    return marcaWhatsapp !== marcaWhatsappOriginal ||
      (primeiroContato !== primeiroContatoOriginal && !!primeiraMensagem) ||
      (segundoContato !== segundoContatoOriginal && !!segundaMensagem) ||
      primeiraMensagem !== primeiraMensagemOriginal ||
      segundaMensagem !== segundaMensagemOriginal ||
      terceiraMensagem !== terceiraMensagemOriginal
      ? ORANGE
      : GRAY;
  };

  const useStyles = makeStyles({
    subtitulo: {
      fontSize: '14px',
      color: DARK_GRAY,
      fontFamily: FONT_FAMILY_REGULAR,
      padding: '8px 7px 0 7px',
    },
    container: {
      flex: '1',
      maxHeight: '100%',
    },
    containerTexto: {
      position: 'relative',
      overflowWrap: 'break-word',
      marginLeft: '16px',
      marginTop: '4px',
    },
    preVisualizar: {
      width: '100%',
      height: '100%',
      border: `1px solid ${LIGHT_GRAY}`,
      borderRadius: '6px',
      overflowY: 'auto',
      padding: '12px',
    },
    preVisualizarVazio: {
      width: '100%',
      height: '100%',
      border: `1px solid ${LIGHT_GRAY}`,
      borderRadius: '6px',
      padding: '2.3rem',
    },
    labelTitulo: {
      fontSize: '13px',
      color: GRAY,
    },
    labelDataHora: {
      fontSize: '13px',
      color:
        formatarDataParaString(dataInicioEditada, true) !==
          dataInicioOriginal ||
        formatarDataParaString(dataTerminoEditada, true) !== dataTerminoOriginal
          ? ORANGE
          : GRAY,
    },
    labelOrientacao: {
      fontSize: '13px',
      color: orientacoesEditada !== orientacoesOriginal ? ORANGE : GRAY,
    },
    labelOriginal: {
      fontSize: '13px',
      paddingLeft: '8px',
      color: GRAY,
    },
    labelEditada: {
      fontSize: '13px',
      paddingLeft: '8px',
      color: ORANGE,
    },
    alinharItensCentro: {
      marginTop: '16px',
      alignItems: 'center',
    },
    tituloPrevia: {
      fontSize: '18px',
      color: GRAY,
      fontFamily: FONT_FAMILY_REGULAR,
      fontWeight: '800',
      textAlign: 'center',
      marginBottom: '32px',
      marginTop: '16px',
    },
    iconWhatsapp: {
      color: obtemCorEdicaoWhatsapp(),
    },
    labelWhatsapp: {
      fontSize: '13px',
      color: obtemCorEdicaoWhatsapp(),
    },
  });

  const classes = useStyles();

  const vizualizarSituacaoFinanceira = () => {
    if (situacao === true) {
      return ['Possui debito financeiro'];
    }
    if (situacao === false) {
      return ['Nao possui debito financeiro'];
    }
    return [];
  };

  return (
    <>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        className={`p-3 ${classes.preVisualizar}`}
      >
        <Grid>
          <Grid>
            <Typography className={classes.tituloPrevia} variant="h3">
              Prévia da Campanha
            </Typography>
          </Grid>

          <Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <CardTitle text={titulo} icon={<Inbox />} />
              </Grid>
            </Grid>

            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  className="mt-4"
                >
                  <BarChart2 size={20} color={GRAY} />
                  <Typography
                    className={`${classes.labelTitulo} pl-2 pt-1`}
                    variant="h6"
                  >
                    Modelo de Risco
                  </Typography>
                </Grid>
                <Grid>
                  <ItemFiltrosPreVisualizar
                    descricao="Modelo de Risco"
                    valores={modeloDeRisco}
                    naoExpandir
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  className={classes.alinharItensCentro}
                >
                  <ChevronsUp
                    size={20}
                    color={
                      impactoDaCampanhaOriginal !== impactoDaCampanhaEditado
                        ? ORANGE
                        : GRAY
                    }
                  />
                  <Typography
                    className={
                      impactoDaCampanhaOriginal !== impactoDaCampanhaEditado
                        ? classes.labelEditada
                        : classes.labelOriginal
                    }
                    variant="h6"
                  >
                    Impacto da Campanha
                  </Typography>
                </Grid>
                <Grid>
                  <ItemFiltrosPreVisualizar
                    valores={
                      impactoDaCampanhaEditado || impactoDaCampanhaOriginal
                    }
                    naoExpandir
                  />
                </Grid>
              </Grid>
            </Grid>

            <FiltrosPreVisualizar
              grupoDeVisualizacaoOriginal={grupoDeVisualizacaoOriginal}
              grupoDeVisualizacao={grupoDeVisualizacaoEditado}
              categoria={categoria ? [categoria] : []}
              situacao={vizualizarSituacaoFinanceira()}
              perfilAluno={perfilAluno ? [perfilAluno] : []}
              formaDeIngresso={formaDeIngresso || []}
              dataInicioMatricula={dataInicioMatricula}
              dataTerminoMatricula={dataTerminoMatricula}
              modalidade={modalidade || []}
              marca={marca || []}
              campus={campus || []}
              curso={curso || []}
              turno={turno || []}
              coloracaoFiltros={GRAY}
              realceFiltroEditado={ORANGE}
              edicaoCampanha={edicaoCampanha}
            />

            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  className="mt-4"
                  alignItems="center"
                >
                  <Calendar
                    data-testid="icone calendario"
                    size={20}
                    color={
                      formatarDataParaString(dataInicioEditada, true) !==
                        dataInicioOriginal ||
                      formatarDataParaString(dataTerminoEditada, true) !==
                        dataTerminoOriginal
                        ? ORANGE
                        : GRAY
                    }
                  />
                  <Typography
                    className={`${classes.labelDataHora} pl-2 pt-1`}
                    variant="h6"
                  >
                    Data e Hora
                  </Typography>
                </Grid>
              </Grid>
              <ItemFiltrosPreVisualizar
                descricao={dataInicioOriginal}
                valores={formatarDataParaString(dataInicioEditada, true)}
                naoExpandir
              />
              <ItemFiltrosPreVisualizar
                descricao={dataTerminoOriginal}
                valores={formatarDataParaString(dataTerminoEditada, true)}
                naoExpandir
              />
            </Grid>

            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  className="mt-4"
                >
                  <WhatsappIcon size={20} className={classes.iconWhatsapp} />
                  <Typography
                    className={`${classes.labelWhatsapp} pl-1 pt-1`}
                    variant="h6"
                  >
                    Automação de Mensagens por WhatsApp
                  </Typography>
                </Grid>
                <ItemFiltrosPreVisualizar
                  descricao="Marca"
                  valores={marcaWhatsapp ? [marcaWhatsapp] : []}
                />
                <ItemFiltrosPreVisualizar
                  descricao={`Após ${primeiroContato} contatos`}
                  valores={primeiraMensagem ? [primeiraMensagem] : []}
                />
                <ItemFiltrosPreVisualizar
                  descricao={`Após ${segundoContato} contatos`}
                  valores={segundaMensagem ? [segundaMensagem] : []}
                />
                <ItemFiltrosPreVisualizar
                  descricao="Ao término da Campanha"
                  valores={terceiraMensagem ? [terceiraMensagem] : []}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  className="mt-4"
                  alignItems="center"
                >
                  <Edit3
                    size={20}
                    color={
                      orientacoesEditada !== orientacoesOriginal ? ORANGE : GRAY
                    }
                  />
                  <Typography
                    className={`${classes.labelOrientacao} pl-2 pt-1`}
                    variant="h6"
                  >
                    Orientações ao Atendimento
                  </Typography>
                </Grid>
                <Grid className={classes.containerTexto}>
                  <VidroInclicavel />
                  <Typography
                    style={{
                      WebkitUserModify: 'read-write-plaintext-only',
                      color:
                        orientacoesEditada !== orientacoesOriginal
                          ? DARK_GRAY
                          : GRAY,
                    }}
                    variant="body1"
                  >
                    {orientacoesEditada || orientacoesOriginal}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PreVisualizarEdicao;
