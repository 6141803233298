import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TableRow,
  TableCell,
  Grid,
  Button,
} from '@material-ui/core';
import { Download, File, Loader } from 'react-feather';
import { GRAY, LIGHT_GRAY, DARK_CYAN, ORANGE } from '../../../theme';
import ModalConfirmacao from '../../../shared/componentes/ModalConfirmacao';
import { informacoesModais } from '../../../shared/util/Constantes';

const useStyles = makeStyles(() => ({
  row: {
    height: '40px',
  },
  containerFileIcon: {
    backgroundColor: LIGHT_GRAY,
    borderRadius: '4px',
    padding: '8px 6px',
  },
  botaoDownloadFile: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  tableBody: {
    padding: '6px 8px 4px 8px',
  },
  tableBodyIconDownload: {
    padding: '6px 4px 6px 4px',
  },
  startICon: {
    margin: 0,
  },
  textButton: {
    padding: '6px 3px',
  },
  validadeArquivo: {
    color: (props) => !props.urlArquivo && ORANGE,
  },
  disabledButton: {
    backgroundColor: `transparent !important`,
  },
}));

const RepositorioArquivoTabelaLinha = (props) => {
  const { validade, titulo, dataExtracao, urlArquivo } = props;

  const classes = useStyles(props);

  const [abrirModalConfirmacaoDownload, setAbrirModalConfirmacaoDownload] =
    useState(false);

  return (
    <>
      <TableRow
        hover
        className={classes.row}
        data-testid="table-row-repository-linha"
      >
        <TableCell classes={{ body: classes.tableBody }}>
          <Grid
            container
            className={classes.containerFileIcon}
            justifyContent="center"
            alignItems="center"
          >
            {urlArquivo ? (
              <File color={DARK_CYAN} size={22} />
            ) : (
              <Loader color={GRAY} size={22} />
            )}
          </Grid>
        </TableCell>

        <TableCell classes={{ body: classes.tableBody }}>
          <Grid direction="row" alignItems="center" container>
            <Typography variant="body1" align="left">
              {titulo}
            </Typography>
          </Grid>
        </TableCell>

        <TableCell classes={{ body: classes.tableBody }}>
          <Grid
            direction="row"
            alignItems="center"
            justifyContent="center"
            container
          >
            <Typography variant="body1" align="center">
              {dataExtracao}
            </Typography>
          </Grid>
        </TableCell>

        <TableCell classes={{ body: classes.tableBody }}>
          <Grid
            direction="row"
            alignItems="center"
            justifyContent="center"
            container
          >
            <Typography
              variant="body1"
              align="center"
              data-testid="typography-validade-item-repositorio-arquivo"
              className={classes.validadeArquivo}
            >
              {validade}
            </Typography>
          </Grid>
        </TableCell>

        <TableCell classes={{ body: classes.tableBodyIconDownload }}>
          <Button
            onClick={() => setAbrirModalConfirmacaoDownload(true)}
            startIcon={
              <Download size={22} color={urlArquivo ? DARK_CYAN : GRAY} />
            }
            fullWidth
            classes={{
              startIcon: classes.startICon,
              text: classes.textButton,
              disabled: classes.disabledButton,
            }}
            className={classes.botaoDownloadFile}
            disabled={urlArquivo === ''}
          />
        </TableCell>
      </TableRow>
      <ModalConfirmacao
        modalAberto={abrirModalConfirmacaoDownload}
        setModalAberto={setAbrirModalConfirmacaoDownload}
        tituloModal={informacoesModais.ModalConfirmacaoDownload.Titulo}
        textoBotaoDireito={
          informacoesModais.ModalConfirmacaoDownload.TextoBotaoDireito
        }
        descricao={`${informacoesModais.ModalConfirmacaoDownload.Descricao}${titulo}`}
        textoBotaoEsquerdo={informacoesModais.TextoBotaoCancelar}
        handleBotaoEsquerdo={() => setAbrirModalConfirmacaoDownload(false)}
        handleBotaoDireito={() => setAbrirModalConfirmacaoDownload(false)}
        href={urlArquivo}
      />
    </>
  );
};

export default RepositorioArquivoTabelaLinha;
