import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    height: '100%',
    top: '0',
  },
  fundo: {
    width: '100%',
    height: '100%',
    zIndex: '2',
  },
});

const VidroInclicavel = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} container item justifyContent="center">
      <Grid container item className={classes.fundo} />
    </Grid>
  );
};

export default VidroInclicavel;
