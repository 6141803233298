import React from 'react';
import { Grid, Typography, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';

import { DARK_CYAN } from '../../../theme';
import SelectCampanha from '../../../shared/componentes/SelectCampanha';
import { labelSelectCriarCampanha } from '../../../shared/util/Constantes';

const useStyles = makeStyles({
  subtitulo: {
    fontSize: '14px',
    color: DARK_CYAN,
  },

  iconeSubtitulo: {
    marginTop: '4px',
  },
  containerWrapper: {
    marginTop: '10px',
  },
  containerFiltro: {
    marginTop: '8px',
    marginBottom: '8px',
  },
});

const ParametrosIES = (props) => {
  const {
    filtroParametrosIES,
    carregando,
    marca,
    setMarca,
    campus,
    setCampus,
    curso,
    setCurso,
    modalidade,
    setModalidade,
    turno,
    setTurno,
  } = props;
  const classes = useStyles();

  return (
    <Grid
      item
      container
      direction="row"
      alignItems="flex-end"
      className={classes.containerWrapper}
    >
      <Icon>
        <BusinessRoundedIcon
          style={{
            color: DARK_CYAN,
            height: '19px',
            width: '19px',
          }}
          className={classes.iconeSubtitulo}
        />
      </Icon>
      <Typography noWrap variant="h5" className={classes.subtitulo}>
        Parâmetros da IES
      </Typography>
      <Grid item container direction="row" className={classes.containerFiltro}>
        <SelectCampanha
          titulo={labelSelectCriarCampanha.Modalidade}
          listaOpcoes={filtroParametrosIES.Modalidade}
          handleAbrirSimpleDropdown={false}
          carregando={carregando}
          model={modalidade}
          setModel={setModalidade}
        />
        <SelectCampanha
          titulo={labelSelectCriarCampanha.Marca}
          listaOpcoes={filtroParametrosIES.Marca}
          handleAbrirSimpleDropdown={false}
          carregando={carregando}
          model={marca}
          setModel={setMarca}
        />
        <SelectCampanha
          titulo={labelSelectCriarCampanha.Campus}
          listaOpcoes={filtroParametrosIES.Campus}
          handleAbrirSimpleDropdown={false}
          carregando={carregando}
          model={campus}
          setModel={setCampus}
        />
        <SelectCampanha
          titulo={labelSelectCriarCampanha.Curso}
          listaOpcoes={filtroParametrosIES.Curso}
          handleAbrirSimpleDropdown={false}
          carregando={carregando}
          model={curso}
          setModel={setCurso}
        />
        <SelectCampanha
          titulo={labelSelectCriarCampanha.Turno}
          listaOpcoes={filtroParametrosIES.Turno}
          handleAbrirSimpleDropdown={false}
          carregando={carregando}
          model={turno}
          setModel={setTurno}
        />
      </Grid>
    </Grid>
  );
};

export default ParametrosIES;
