import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import { Activity, Clock, User, Phone } from 'react-feather';
import ReactLoading from 'react-loading';
import SvgPillIcon01 from '../../../shared/img/SvgPillIcon01';
import {
  DARK_CYAN,
  FONT_FAMILY_EXTRABOLD,
  BORDA_FILTRO,
  FONT_FAMILY_SEMIBOLD,
} from '../../../theme';
import Dropdown from '../../../shared/componentes/Dropdown';
import DefaultTooltip from '../../../shared/componentes/DefaultTooltip';
import { formatarTituloPorItensSelecionados } from '../../../shared/util/Utils';
import {
  opcoesFiltroDesempenho,
  pluraisFiltroDesempenho,
  singularesFiltroDesempenho,
} from '../../../shared/util/Constantes';
import FiltroManager from '../FiltroDesempenhoManager';

const useStyles = makeStyles({
  containerInterno: {
    borderRight: `1px solid ${BORDA_FILTRO}`,
  },
  containerExterno: {
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(158,171,181,0.05)',
    },
  },
  tooltipIcon: {
    width: '20px',
    height: '20px',
  },
  selectedTypography: {
    color: DARK_CYAN,
    fontFamily: FONT_FAMILY_EXTRABOLD,
  },
  openDropdown: {
    fontFamily: FONT_FAMILY_SEMIBOLD,
    color: DARK_CYAN,
  },
});

const FiltroItem = (props) => {
  const { atualizarFiltro, titulo, carregando, listaItens, setlistaItens } =
    props;
  const icones = {
    [opcoesFiltroDesempenho.Periodo]: Clock,
    [opcoesFiltroDesempenho.Atendente]: User,
    [opcoesFiltroDesempenho.Campanha]: BusinessRoundedIcon,
    [opcoesFiltroDesempenho.Situacao]: Phone,
    [opcoesFiltroDesempenho.CategoriaProblema]: Activity,
  };

  const classes = useStyles();
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [abrirDropdown, setDropdown] = useState(false);
  const [descricaoItem, setDescricaoItem] = useState(titulo);

  const getTooltipIcon = (key) => {
    const Ico = icones[key];
    if (key !== opcoesFiltroDesempenho.Remedio) {
      return <> {Ico && <Ico className={classes.tooltipIcon} />} </>;
    }
    return <SvgPillIcon01 fill={DARK_CYAN} width="22px" height="22px" />;
  };

  function obtainTitle() {
    return `${itensSelecionados.length > 1 ? itensSelecionados.length : ''} ${
      itensSelecionados.length > 1
        ? pluraisFiltroDesempenho[titulo]
        : singularesFiltroDesempenho[titulo]
    }`;
  }

  function toFilter(array) {
    return array
      .filter((item) => item.checked)
      .map((item) => (item.id ? item.id : item.title));
  }

  useEffect(() => {
    const inicializarDropdownFiltros = async () => {
      setItensSelecionados([]);
      atualizarFiltro([], titulo);
      setDescricaoItem(titulo);
    };
    inicializarDropdownFiltros();
  }, [listaItens, titulo, atualizarFiltro]);

  async function SalvarNoReduxEBuscarItensDependentes(
    listaDeItensAtualizados,
    identificador
  ) {
    const listaAtualizados = toFilter(listaDeItensAtualizados);
    if (identificador === opcoesFiltroDesempenho.Campanha) {
      const listaCampanha = listaAtualizados;
      atualizarFiltro(listaCampanha, identificador);
    } else if (identificador === opcoesFiltroDesempenho.Atendente) {
      const listaAtendente = listaAtualizados;
      atualizarFiltro(listaAtendente, identificador);
    } else if (identificador === opcoesFiltroDesempenho.Situacao) {
      const listaSituacao = listaAtualizados;
      atualizarFiltro(listaSituacao, identificador);
    } else if (identificador === opcoesFiltroDesempenho.CategoriaProblema) {
      const listaProblema = listaAtualizados;
      atualizarFiltro(listaProblema, identificador);
    } else {
      atualizarFiltro(listaAtualizados, identificador);
    }
  }

  function setarListaItensSelecionados(lista) {
    setItensSelecionados(lista);
    setDescricaoItem(
      formatarTituloPorItensSelecionados(titulo, listaItens.length, lista)
    );
    SalvarNoReduxEBuscarItensDependentes(lista, titulo);
  }

  function getTooltipContent() {
    const maxItems = 7;
    const remainingItems = itensSelecionados.length - maxItems;
    return (
      <>
        {itensSelecionados.slice(0, maxItems).map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Typography noWrap variant="body1" className="mt-5" key={index}>
            {item.title}
          </Typography>
        ))}

        {remainingItems > 0 && (
          <Typography className="h4-dropdown mt-5 dark-green">
            {`+ ${remainingItems} ${
              remainingItems === 1 ? titulo : pluraisFiltroDesempenho[titulo]
            }`}
          </Typography>
        )}
      </>
    );
  }

  function getItem() {
    let classeTypography = '';
    if (
      (itensSelecionados.length >= 1 && abrirDropdown) ||
      (itensSelecionados.length >= 1 && !abrirDropdown)
    ) {
      classeTypography = classes.selectedTypography;
    } else if (itensSelecionados.length < 1 && abrirDropdown) {
      classeTypography = classes.openDropdown;
    }

    return (
      <Typography
        variant="h5"
        align="center"
        onClick={() => setDropdown(!abrirDropdown)}
        className={classeTypography}
      >
        {descricaoItem}
      </Typography>
    );
  }

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.containerExterno}
        onClick={() => setDropdown(!abrirDropdown)}
        id="filtro-desempenho-field"
      >
        {carregando && (
          <ReactLoading type="spin" color={DARK_CYAN} height={25} width={25} />
        )}
        {!carregando && (
          <Grid
            container
            className={classes.containerInterno}
            alignItems="center"
            justifyContent="center"
          >
            <DefaultTooltip
              color="dark-green"
              title={obtainTitle()}
              place="bottom"
              content={getTooltipContent()}
              icon={getTooltipIcon(titulo)}
              disable={
                abrirDropdown ||
                !itensSelecionados.length ||
                itensSelecionados.length < 1
              }
            >
              {getItem()}
            </DefaultTooltip>
          </Grid>
        )}
      </Grid>

      {!carregando && (
        <Dropdown
          mostrarDropdown={abrirDropdown}
          setMostrarDropdown={setDropdown}
          listaItensInseridos={itensSelecionados}
          setListaItensInseridos={setarListaItensSelecionados}
          listaItens={listaItens}
          setListaItens={setlistaItens}
          descricaoItem={titulo}
          top="23px"
          left="-185px"
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  campanha: state.filtroDesempenho.campanha,
  atendente: state.filtroDesempenho.atendente,
  situacao: state.filtroDesempenho.situacao,
  problema: state.filtroDesempenho.problema,
  remedio: state.filtroDesempenho.remedio,
});

const mapDispatchToProps = {
  atualizarFiltro: (lista, descricao) =>
    FiltroManager.atualizarFiltro(lista, descricao),
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltroItem);
