import React, { useState, useEffect } from 'react';
import {
  Grid,
  makeStyles,
  TextField,
  ClickAwayListener,
} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import MoodIcon from '@material-ui/icons/Mood';
import { Picker } from 'emoji-mart';
import { DARK_CYAN, ORANGE, LIGHT_GRAY, DARK_GRAY } from '../../../theme';

const theme = createTheme({
  palette: {
    primary: {
      main: DARK_CYAN,
    },
    error: {
      main: ORANGE,
    },
  },
  overrides: {
    MuiInput: {
      root: {
        '&.Mui-disabled': {
          backgroundColor: 'rgba(192,192,222,0.15)',
        },
        '&.Mui-focused': {
          backgroundColor: 'rgba(14,167,137,0.05)',
        },
        backgroundColor: 'rgba(232,238,242,0.2)',
        color: DARK_GRAY,
        fontWeight: '600',
        fontSize: '12px',
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid ${LIGHT_GRAY}`,
        },
        '&:hover': {
          borderBottom: 'none',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid #d9d9d9',
        },
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  iconeEmoji: {
    cursor: 'pointer',
    color: DARK_CYAN,
  },
}));

const ParagrafoMenssagemWhatsapp = (props) => {
  const classes = useStyles();

  const { mensagem, placeholderMensagem, setMensagem, mensagemOriginal } =
    props;

  const [boxEmoji, setBoxEmoji] = useState(false);

  useEffect(() => {
    if (mensagemOriginal) setMensagem(mensagemOriginal);
  }, [mensagemOriginal, setMensagem]);

  return (
    <Grid item container alignItems="center" justifyContent="flex-start">
      <Grid
        item
        container
        style={{
          position: 'relative',
        }}
      >
        <ThemeProvider theme={theme}>
          <TextField
            fullWidth
            multiline
            variant="standard"
            size="small"
            placeholder={placeholderMensagem}
            value={mensagem}
            className="mb-4"
            onChange={(e) => {
              setMensagem(e.target.value);
            }}
            error={mensagemOriginal && mensagem !== mensagemOriginal}
            InputProps={{
              endAdornment: (
                <MoodIcon
                  size={16}
                  className={classes.iconeEmoji}
                  onClick={() => setBoxEmoji(!boxEmoji)}
                />
              ),
              style: {
                fontWeight: 'normal',
                fontSize: '14px',
                padding: '12px 16px 12px 16px',
              },
            }}
          />
        </ThemeProvider>
        {boxEmoji && (
          <ClickAwayListener onClickAway={() => setBoxEmoji(!boxEmoji)}>
            <Picker
              emojiTooltip={false}
              showPreview={false}
              perLine={6}
              showSkinTones={false}
              color={DARK_CYAN}
              set="google"
              sheetSize={32}
              title=""
              onSelect={(emoji) => setMensagem(mensagem + emoji.native)}
              i18n={{
                search: 'Pesquisar',
                categories: {
                  search: 'Resultado da busca',
                  recent: 'Recentes',
                  smileys: 'Emoções',
                  people: 'Pessoas',
                  nature: 'Animais e Natureza',
                  foods: 'Comidas e Bebidas',
                  activity: 'Esporte',
                  places: 'Locais',
                  objects: 'Objetos',
                  symbols: 'Símbolos',
                  flags: 'Nacionalidades',
                  custom: 'Customizado',
                },
              }}
              style={{
                position: 'absolute',
                width: '34%',
                top: '85%',
                right: '0',
                zIndex: 2,
              }}
            />
          </ClickAwayListener>
        )}
      </Grid>
    </Grid>
  );
};

export default ParagrafoMenssagemWhatsapp;
