import React from 'react';
import { Typography, Grid, Box, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FONT_FAMILY_EXTRABOLD } from '../../theme';

const useStyles = makeStyles({
  descricao: {
    marginLeft: '8px',
  },
  indicadores: {
    marginTop: '20px',
  },
  secundarioHorizontal: {
    paddingLeft: '8px',
  },
  textoSecundarioHorizontal: {
    fontFamily: `${FONT_FAMILY_EXTRABOLD} !important`,
  },
  secundarioVertical: {
    paddingTop: '4px',
  },
  titulo: {
    fontSize: '14px',
  },
});

const IndicadorIlustradoCockpit = (props) => {
  const classes = useStyles();
  const { horizontal, titulo, valorPrimario, valorSecundario, icon, color } =
    props;

  const classIconContainer = `indicatorIconContainer ${color}Background`;
  const classeH2 = `h2 ${color}`;
  const classeH7 = `h7 ${color}`;

  const typographySecundarioHorizontal = () => {
    return valorSecundario ? (
      <Typography
        className={`${classeH7} ${classes.textoSecundarioHorizontal}`}
        align="left"
      >
        {' '}
        {valorSecundario}{' '}
      </Typography>
    ) : null;
  };
  const typographySecundarioVertical = () => {
    return valorSecundario ? (
      <Typography className={classeH7} align="center">
        {' '}
        {valorSecundario}{' '}
      </Typography>
    ) : null;
  };
  return horizontal ? (
    <Grid
      item
      container
      direction="row"
      alignItems="flex-start"
      justifyContent="flex-start"
      wrap="nowrap"
    >
      <Grid container item xs={5} style={{ flexBasis: 0 }}>
        <Box p={1.5} mr={1} borderRadius="50%" className={classIconContainer}>
          <Icon className={color}>{icon}</Icon>
        </Box>
      </Grid>

      <Grid item className={classes.descricao}>
        <Grid container alignItems="baseline">
          <Typography className={classeH2} align="left">
            {' '}
            {valorPrimario}{' '}
          </Typography>
          <Grid item className={classes.secundarioHorizontal}>
            {typographySecundarioHorizontal}
          </Grid>
        </Grid>
        <Typography variant="h5" className={classes.titulo} align="left">
          {' '}
          {titulo}{' '}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.indicadores}
    >
      <Box p={1.5} borderRadius="50%" className={classIconContainer}>
        <Icon className={color}>{icon}</Icon>
      </Box>
      <Typography className={classeH2} align="center">
        {' '}
        {valorPrimario}{' '}
      </Typography>
      <Typography variant="h5" className={classes.titulo} align="center">
        {' '}
        {titulo}{' '}
      </Typography>
      <Grid item className={classes.secundarioVertical}>
        {typographySecundarioVertical}
      </Grid>
    </Grid>
  );
};

export default IndicadorIlustradoCockpit;
