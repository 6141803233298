import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import { Briefcase, Sunrise, BookOpen, Calendar } from 'react-feather';
import ReactLoading from 'react-loading';
import { DARK_CYAN, BORDA_FILTRO } from '../../../../theme';
import Dropdown from '../../../../shared/componentes/Dropdown';
import DefaultTooltip from '../../../../shared/componentes/DefaultTooltip';
import { formatarTituloPorItensSelecionados } from '../../../../shared/util/Utils';
import {
  filtroItens,
  pluralItens,
  singularesItems,
} from '../../../../shared/util/Constantes';
import FiltroManager from '../FiltroManager';

const useStyles = makeStyles({
  itemOpcao: {
    position: 'relative',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    'text-overflow': 'ellipsis',
    overflow: 'hidden',
  },
  containerInterno: {
    borderRight: `1px solid ${BORDA_FILTRO}`,
  },
  containerExterno: {
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(158,171,181,0.05)',
    },
  },
  destaqueTitulo: {
    color: DARK_CYAN,
  },
  tooltipArea: {
    width: '100%',
  },
  tooltipIcon: {
    width: '20px',
    height: '20px',
  },
});

const FiltroItem = (props) => {
  const {
    atualizarFiltro,
    atualizarAuxiliar,
    marca,
    campi,
    modalidade,
    auxiliar,
    titulo,
    codigoIes,
    limparFiltro,
    lastItem,
    tooltip,
    operatorId,
  } = props;

  const icones = {};
  icones[filtroItens.Aluno] = SchoolOutlinedIcon;
  icones[filtroItens.Marca] = BusinessRoundedIcon;
  icones[filtroItens.Campus] = BusinessRoundedIcon;
  icones[filtroItens.Curso] = SchoolOutlinedIcon;
  icones[filtroItens.Modalidade] = Briefcase;
  icones[filtroItens.Turno] = Sunrise;
  icones[filtroItens.PeriodoLetivo] = BookOpen;
  icones[filtroItens.Ingresso] = Calendar;

  const classes = useStyles();
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [abrirDropdown, setDropdown] = useState(false);
  const [descricaoItem, setDescricaoItem] = useState(titulo);
  const [listaItems, setlistaItems] = useState([]);
  const [carregando, setCarregando] = useState(false);

  const getTooltipIcon = (tituloItem) => {
    const Ico = icones[tituloItem];
    return <>{Ico && <Ico className={classes.tooltipIcon} />} </>;
  };

  function toDropdown(array) {
    return array.map((item) => {
      return { title: item, checked: false };
    });
  }

  function toFilter(array) {
    return array.filter((item) => item.checked).map((item) => item.title);
  }

  function obtainTitle() {
    return `${itensSelecionados.length > 1 ? itensSelecionados.length : ''} ${
      itensSelecionados.length > 1
        ? pluralItens[titulo]
        : singularesItems[titulo]
    }`;
  }

  useEffect(() => {
    const inicializaFiltros = async () => {
      if (titulo !== filtroItens.Aluno) {
        setCarregando(true);
      }
      const initialValues = await FiltroManager.buscarValoresIniciais(
        codigoIes,
        titulo,
        operatorId
      );
      setCarregando(false);
      setlistaItems(toDropdown(initialValues));
      setItensSelecionados([]);
      atualizarFiltro([], titulo);
      setDescricaoItem(titulo);
    };
    inicializaFiltros();
  }, [
    titulo,
    codigoIes,
    limparFiltro,
    setItensSelecionados,
    atualizarFiltro,
    operatorId,
  ]);

  useEffect(() => {
    if (auxiliar && titulo === auxiliar.titulo && auxiliar.loading) {
      setCarregando(true);
      atualizarFiltro([], titulo);
      setItensSelecionados([]);
    } else if (auxiliar && titulo === auxiliar.titulo) {
      setCarregando(false);
      setDescricaoItem(titulo);
      setlistaItems(toDropdown(auxiliar.lista));
    }
  }, [titulo, auxiliar, atualizarFiltro]);

  function obterFiltro(
    listaMarca,
    listaCampus,
    listaModalidade,
    listaCurso,
    listaTurno,
    listaIngresso
  ) {
    const filter = {};
    if (listaMarca && listaMarca.length > 0) filter.institution = listaMarca;
    if (listaCampus && listaCampus.length > 0) filter.campus = listaCampus;
    if (listaModalidade && listaModalidade.length > 0)
      filter.modality = listaModalidade;
    if (listaCurso && listaCurso.length > 0) filter.course = listaCurso;
    if (listaTurno && listaTurno.length > 0) filter.shift = listaTurno;
    if (listaIngresso && listaIngresso.length > 0)
      filter.intake_cycle = listaIngresso;

    return filter;
  }

  async function buscarCampus(listaMarca) {
    atualizarAuxiliar({ titulo: filtroItens.Campus, lista: [], loading: true });
    const opcoes = await FiltroManager.buscarCampus(
      codigoIes,
      operatorId,
      obterFiltro(listaMarca)
    );
    atualizarAuxiliar({
      titulo: filtroItens.Campus,
      lista: opcoes,
      loading: false,
    });
    return opcoes;
  }

  async function buscarModalidade(listaMarca, listaCampus) {
    atualizarAuxiliar({
      titulo: filtroItens.Modalidade,
      lista: [],
      loading: true,
    });
    const opcoes = await FiltroManager.buscarModalidade(
      codigoIes,
      operatorId,
      obterFiltro(listaMarca, listaCampus)
    );
    atualizarAuxiliar({
      titulo: filtroItens.Modalidade,
      lista: opcoes,
      loading: false,
    });
    return opcoes;
  }

  async function buscarCurso(listaMarca, listaCampus, listaModalidade) {
    atualizarAuxiliar({ titulo: filtroItens.Curso, lista: [], loading: true });
    const opcoes = await FiltroManager.buscarCurso(
      codigoIes,
      operatorId,
      obterFiltro(listaMarca, listaCampus, listaModalidade)
    );
    atualizarAuxiliar({
      titulo: filtroItens.Curso,
      lista: opcoes,
      loading: false,
    });
    return opcoes;
  }

  async function buscarTurno(
    listaMarca,
    listaCampus,
    listaModalidade,
    listaCurso
  ) {
    atualizarAuxiliar({ titulo: filtroItens.Turno, lista: [], loading: true });
    const opcoes = await FiltroManager.buscarTurno(
      codigoIes,
      operatorId,
      obterFiltro(listaMarca, listaCampus, listaModalidade, listaCurso)
    );
    atualizarAuxiliar({
      titulo: filtroItens.Turno,
      lista: opcoes,
      loading: false,
    });
    return opcoes;
  }

  function getTooltipContent() {
    const maxItems = 10;
    const remainingItems = itensSelecionados.length - maxItems;
    return (
      <>
        {itensSelecionados.slice(0, maxItems).map((item) => (
          <Typography noWrap variant="body1" className="mt-5" key={item.title}>
            {item.title}
          </Typography>
        ))}

        {remainingItems > 0 && (
          <Typography className="h4-dropdown mt-5 dark-green">
            {`+ ${remainingItems} ${
              remainingItems === 1 ? titulo : pluralItens[titulo]
            }`}
          </Typography>
        )}
      </>
    );
  }

  function getItem() {
    return (
      <Typography
        variant="h5"
        align="center"
        className={
          abrirDropdown
            ? `${classes.itemOpcao} ${classes.destaqueTitulo}`
            : `${classes.itemOpcao}`
        }
        onClick={() => setDropdown(!abrirDropdown)}
      >
        {descricaoItem}
      </Typography>
    );
  }

  async function SalvarNoReduxEBuscarItensDependentes(
    listaDeItensAtualizados,
    tituloItem
  ) {
    const listaAtualizados = toFilter(listaDeItensAtualizados);
    if (tituloItem === filtroItens.Marca) {
      const listaMarca = listaAtualizados;
      atualizarFiltro(listaMarca, tituloItem);
      await buscarCampus(listaMarca);
      await buscarModalidade(listaMarca);
      await buscarCurso(listaMarca);
      await buscarTurno(listaMarca);
    } else if (tituloItem === filtroItens.Campus) {
      const listaCampus = listaAtualizados;
      atualizarFiltro(listaCampus, tituloItem);
      await buscarModalidade(marca, listaCampus);
      await buscarCurso(marca, listaCampus);
      await buscarTurno(marca, listaCampus);
    } else if (tituloItem === filtroItens.Modalidade) {
      const listaModalidade = listaAtualizados;
      atualizarFiltro(listaModalidade, tituloItem);
      await buscarCurso(marca, campi, listaModalidade);
      await buscarTurno(marca, campi, listaModalidade);
    } else if (tituloItem === filtroItens.Curso) {
      const listaCurso = listaAtualizados;
      atualizarFiltro(listaCurso, tituloItem);
      await buscarTurno(marca, campi, modalidade, listaCurso);
    } else {
      atualizarFiltro(listaAtualizados, tituloItem);
    }
  }

  function setarListaItensSelecionados(lista) {
    setItensSelecionados(lista);
    setDescricaoItem(
      formatarTituloPorItensSelecionados(titulo, listaItems.length, lista)
    );
    SalvarNoReduxEBuscarItensDependentes(lista, titulo);
  }

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.containerExterno}
        onClick={() => setDropdown(!abrirDropdown)}
      >
        {carregando && (
          <ReactLoading type="spin" color={DARK_CYAN} height={25} width={25} />
        )}
        {!carregando && (
          <Grid
            container
            className={
              !lastItem ? classes.containerInterno : classes.tooltipArea
            }
            alignItems="center"
            justifyContent="center"
          >
            {tooltip && itensSelecionados.length ? (
              <DefaultTooltip
                color="dark-green"
                title={obtainTitle()}
                place="bottom"
                content={getTooltipContent()}
                icon={getTooltipIcon(titulo)}
                disable={abrirDropdown}
              >
                {getItem()}
              </DefaultTooltip>
            ) : (
              getItem()
            )}
          </Grid>
        )}
      </Grid>

      {!carregando && (
        <Dropdown
          mostrarDropdown={abrirDropdown}
          setMostrarDropdown={setDropdown}
          listaItensInseridos={itensSelecionados}
          setListaItensInseridos={setarListaItensSelecionados}
          listaItens={listaItems}
          setListaItens={setlistaItems}
          descricaoItem={titulo}
          top="23px"
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    codigoIes: parseInt(state.login.usuario.iesCode, 10),
    marca: state.filtro.marca,
    campi: state.filtro.campi,
    modalidade: state.filtro.modalidade,
    curso: state.filtro.curso,
    turno: state.filtro.turno,
    auxiliar: state.filtro.auxiliar,
    operatorId: state.login.usuario.principalId,
  };
};

const mapDispatchToProps = {
  atualizarAuxiliar: (valor) => FiltroManager.atualizarAuxiliar(valor),
  atualizarFiltro: (lista, descricao) =>
    FiltroManager.atualizarFiltro(lista, descricao),
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltroItem);
