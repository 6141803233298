import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UpdateIcon from '@material-ui/icons/Update';
import { Activity, Calendar } from 'react-feather';
import { connect } from 'react-redux';
import CardTitle from '../../../../shared/componentes/CardTitle';
import IndicadorIlustradoCockpit from '../../../../shared/componentes/IndicadorIlustradoCockpit';
import RaioXEvasaoManager from '../RaioXEvasaoManager';
import { LIGHT_GRAY } from '../../../../theme';
import GraficoLinha from './GraficoLinha';
import LoadingDashboard from '../../../../shared/componentes/LoadingDashboard';
import DadosNaoEncontrados from '../../../../shared/componentes/DadosNaoEncontrados';

const useStyles = makeStyles({
  containerGrafico: {
    alignContent: 'center',
    position: 'relative',
    height: '350px',
  },
  linhaSeparadora: {
    borderRight: `1px solid ${LIGHT_GRAY}`,
    height: '350px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginTop: '20px',
    paddingBottom: '20px',
    paddingRight: '20px',
    '@media (max-width:600px)': {
      flexDirection: 'row',
      alignItems: 'flex-start',
      height: '160px',
      borderRight: `0px solid ${LIGHT_GRAY}`,
      borderBottom: `1px solid ${LIGHT_GRAY}`,
    },
  },
});

const RaioXEvasao = (props) => {
  const classes = useStyles();
  const { filtro, codigoIes } = props;
  const [comparativoEvasao, setComparativoEvasao] = React.useState(null);
  const [evolucaoEvasao, setEvolucaoEvasao] = React.useState(null);
  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setCarregando(true);

      const dadosComparativoEvasao =
        await RaioXEvasaoManager.buscarDadosComparativoEvasao(
          codigoIes,
          filtro
        );
      const dadosEvolucaoEvasao =
        await RaioXEvasaoManager.buscarDadosEvolucaoEvasao(codigoIes, filtro);

      setComparativoEvasao(dadosComparativoEvasao);
      setEvolucaoEvasao(dadosEvolucaoEvasao);

      setCarregando(false);
    };
    getData();
  }, [filtro, codigoIes]);

  const calcularMinimoEixoY = (dadosEvolucaoEvasao) => {
    let retorno = 0;
    if (dadosEvolucaoEvasao && dadosEvolucaoEvasao.length > 0) {
      let menorValor = 1;
      for (let i = 1; i < dadosEvolucaoEvasao.length; i++) {
        for (let j = 1; j < dadosEvolucaoEvasao[i].length; j++) {
          menorValor =
            dadosEvolucaoEvasao[i][j] < menorValor
              ? dadosEvolucaoEvasao[i][j]
              : menorValor;
        }
      }
      retorno = menorValor - 0.05 > 0 ? menorValor - 0.05 : 0;
    }
    return retorno;
  };

  const calcularMaximoEixoY = (dadosEvolucaoEvasao) => {
    let retorno = 1;
    if (dadosEvolucaoEvasao && dadosEvolucaoEvasao.length > 0) {
      let maiorValor = 0;
      for (let i = 1; i < dadosEvolucaoEvasao.length; i++) {
        for (let j = 1; j < dadosEvolucaoEvasao[i].length; j++) {
          maiorValor =
            dadosEvolucaoEvasao[i][j] > maiorValor
              ? dadosEvolucaoEvasao[i][j]
              : maiorValor;
        }
      }
      retorno = maiorValor + 0.05;
    }
    return retorno;
  };

  return (
    <>
      <CardTitle text="Raio X da Evasão e Abandono" icon={<Activity />} />
      <Grid item xs={12} sm={3} className={classes.linhaSeparadora}>
        {carregando && <LoadingDashboard />}
        {comparativoEvasao && comparativoEvasao.length > 1 && (
          <>
            <IndicadorIlustradoCockpit
              titulo="Desligamentos"
              icon={<Calendar />}
              color="green"
              valorPrimario={comparativoEvasao[0].valorPrimario}
              valorSecundario={comparativoEvasao[0].valorSecundario}
            />

            <IndicadorIlustradoCockpit
              titulo="Desligamentos Referência"
              icon={<UpdateIcon />}
              color="yellow"
              valorPrimario={comparativoEvasao[1].valorPrimario}
              valorSecundario={comparativoEvasao[1].valorSecundario}
            />
          </>
        )}

        {comparativoEvasao && comparativoEvasao.length === 0 && (
          <DadosNaoEncontrados chaveamentoEstadoVazio={2} />
        )}
      </Grid>
      <Grid
        className={classes.containerGrafico}
        container
        item
        xs={12}
        sm={9}
        justifyContent="center"
      >
        {carregando && <LoadingDashboard />}
        <GraficoLinha
          dadosGrafico={evolucaoEvasao}
          minimoEixoY={calcularMinimoEixoY(evolucaoEvasao)}
          maximoEixoY={calcularMaximoEixoY(evolucaoEvasao)}
        />
      </Grid>
      <Grid item />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    codigoIes: parseInt(state.login.usuario.iesCode, 10),
  };
};

export default connect(mapStateToProps)(RaioXEvasao);
