import React from 'react';
import { X } from 'react-feather';
import { Box, makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { DARK_CYAN, LIGHT_CYAN, MEDIUM_GRAY, WHITE } from '../../theme';

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: MEDIUM_GRAY,
    color: WHITE,
    fontSize: '14px',
    padding: '6px 10px',
  },
  closeIcon: {
    margin: '8px',
  },
});

const CloseIcon = (props) => {
  const { tooltipTitle, handleFechar } = props;
  const classes = useStyles();

  return (
    <Tooltip
      title={tooltipTitle}
      classes={{
        tooltip: classes.customTooltip,
      }}
    >
      <Box
        sx={{
          width: 38,
          height: 38,
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: LIGHT_CYAN,
          },
          cursor: 'pointer',
        }}
        borderRadius={50}
        data-testid="box-close-icon-component"
        onClick={() => handleFechar()}
      >
        <X
          size={22}
          color={DARK_CYAN}
          className={classes.closeIcon}
          data-testid="icon-close-icon-component"
        />
      </Box>
    </Tooltip>
  );
};

export default CloseIcon;
