import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import BarChartRoundedIcon from '@material-ui/icons/BarChartRounded';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import CardTitle from '../../../../shared/componentes/CardTitle';
import DestaquesManager from '../DestaquesManager';
import DestaqueItens from './DestaqueItens';
import LoadingDashboard from '../../../../shared/componentes/LoadingDashboard';
import DadosNaoEncontrados from '../../../../shared/componentes/DadosNaoEncontrados';

const useStyles = makeStyles({
  containerDados: {
    overflowY: 'scroll',
    scrollbarColor: '#bbbbbe white',
    scrollbarWidth: 'thin',
    overflowX: 'hidden',
    maxHeight: '21rem',
    marginTop: '20px',
    display: 'flex',
    alignContent: 'start',
  },
  container: {
    position: 'relative',
    minHeight: '300px',
    '@media (max-width:960px)': {
      minHeight: '70px',
    },
  },
  dadosNaoEncontradosDestaque: {
    marginTop: '60px',
  },
});

const Destaques = (props) => {
  const { filtro, codigoIes } = props;
  const classes = useStyles();

  const [listaItensDestaque, setListaItensDestaque] = React.useState(null);
  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setCarregando(true);
      const listaItens = await DestaquesManager.buscarDadosGraficos(
        codigoIes,
        filtro
      );
      setListaItensDestaque(listaItens);
      setCarregando(false);
    };
    getData();
  }, [filtro, codigoIes]);

  return (
    <>
      <CardTitle text="Destaques" icon={<BarChartRoundedIcon />} />
      <Grid item container className={classes.container}>
        {carregando && <LoadingDashboard />}

        {listaItensDestaque && listaItensDestaque.length > 0 && (
          <Grid className={classes.containerDados} item container>
            {listaItensDestaque.map((item) => (
              <DestaqueItens key={item.nomeCurso} item={item} />
            ))}
          </Grid>
        )}
        {listaItensDestaque && listaItensDestaque.length === 0 && (
          <Grid
            container
            justifyContent="center"
            className={classes.dadosNaoEncontradosDestaque}
          >
            <DadosNaoEncontrados chaveamentoEstadoVazio={2} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    codigoIes: parseInt(state.login.usuario.iesCode, 10),
  };
};

export default connect(mapStateToProps)(Destaques);
