import FiltroDesempenhoService from '../../services/FiltroDesempenhoService';
import { formatarDataParaString } from '../../shared/util/Utils';

class PerformanceManager {
  buscarListaArquivosPerformance = async () => {
    const resp = await FiltroDesempenhoService.buscarListaArquivosPerformance();

    if (!resp || resp.length <= 0) return [];

    resp.sort((a, b) => (a.CreationTimestamp < b.CreationTimestamp ? 1 : -1));

    resp.forEach((element) => {
      element.CreationTimestamp = formatarDataParaString(
        new Date(element.CreationTimestamp),
        true
      );
      element.TimeToLive = formatarDataParaString(
        new Date(element.TimeToLive * 1000),
        true
      );
    });

    return resp;
  };
}

export default new PerformanceManager();
