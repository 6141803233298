import store from '../../setup/reducer';
import { exibirNotificacao } from '../../features/notificacao/Store';
import { mensagensNotificacaoErro, iconesNotificacao } from './Constantes';

export function handleRequestError(error) {
  if (Object(error).toString() === 'Cancel') return;

  const mensagem =
    error.response &&
    error.response.data &&
    (error.response.data.mensagem || error.response.data.Message);
  const mensagemNotificacao = mensagem || mensagensNotificacaoErro.ErroPadrao;
  store.dispatch(
    exibirNotificacao(mensagemNotificacao, iconesNotificacao.TentarNovamente)
  );
}
