import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TableRow, TableCell, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { Edit2, Trash2, AlignLeft } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import TooltipMensagem from '../../../shared/componentes/TooltipMensagem';
import ModalExclusaoWhatsappMensagem from './ModalExclusaoWhatsappMensagem';

import { limiteCaracterWhatsapp } from '../../../shared/util/Constantes';
import { DARK_GRAY_OP_1, DARK_CYAN } from '../../../theme';
import ModalFormMensagemWhatsapp from '../../formMensagemWhatsapp/views/ModalFormMensagemWhatsapp';

const useStyles = makeStyles({
  row: {
    height: '50px',
  },
  reactTooltip: {
    padding: '0px !important',
    opacity: '100% !important',
  },
});

const LinhaMensagens = (props) => {
  const { lineNum, mensagem, handleExclusao } = props;
  const [
    abrirModalExclusaoWhatsappMensagem,
    setAbrirModalExclusaoWhatsappMensagem,
  ] = useState(false);

  const classes = useStyles(props);

  const handleAtualizarMensagem = (
    mensagem1,
    mensagem2,
    mensagem3,
    tituloMensagem
  ) => {
    mensagem.Message1 = mensagem1;
    mensagem.Message2 = mensagem2;
    mensagem.Message3 = mensagem3;
    mensagem.Title = tituloMensagem;
  };

  const [abrirModalEditarMensagem, setAbrirModalEditarMensagem] =
    useState(false);

  const definirQuebraDeMensagens = () => {
    if (mensagem) {
      return `${mensagem.Message1}\n\n${mensagem.Message2}\n\n${mensagem.Message3}`;
    }
  };

  return (
    <>
      <TableRow
        hover
        className={classes.row}
        data-testid="table-row-linha-mensagem-gestao"
      >
        <TableCell>
          <Typography
            data-tip
            data-for={`${lineNum}tit`}
            noWrap
            variant="body1"
            data-testid="tipografia-titulo-mensagens"
          >
            {mensagem && mensagem.Title}
          </Typography>
        </TableCell>

        <TableCell>
          <ReactTooltip
            id={`${lineNum}msg`}
            backgroundColor="white"
            type="light"
            className={classes.reactTooltip}
            arrowColor="rgba(0, 0, 0, 0)"
            data-testid="mensagem-tooltip-icon"
          >
            <TooltipMensagem
              titulo="Mensagem da Campanha"
              mensagem={definirQuebraDeMensagens()}
              icone={<AlignLeft size={20} color={DARK_CYAN} />}
              data-testid="mensagem-tooltip"
            />
          </ReactTooltip>
          <Typography
            data-tip
            data-for={`${lineNum}msg`}
            variant="body1"
            align="left"
            data-testid="tipografia-mensagem"
          >
            {definirQuebraDeMensagens() <
            limiteCaracterWhatsapp.mensagensWhatsapp
              ? definirQuebraDeMensagens()
              : `${definirQuebraDeMensagens().substring(
                  0,
                  limiteCaracterWhatsapp.mensagensWhatsapp
                )}...`}
          </Typography>
        </TableCell>

        <TableCell align="right">
          <IconButton
            onClick={() => setAbrirModalEditarMensagem(true)}
            data-testid="icon-edit"
          >
            <Edit2 size={20} color={DARK_GRAY_OP_1} />
          </IconButton>
          <IconButton
            data-testid="icon-delete"
            className="ml-2"
            onClick={() => {
              setAbrirModalExclusaoWhatsappMensagem(true);
            }}
          >
            <Trash2 size={20} color={DARK_GRAY_OP_1} />
          </IconButton>
        </TableCell>
      </TableRow>
      {abrirModalEditarMensagem && (
        <ModalFormMensagemWhatsapp
          modalAberto={abrirModalEditarMensagem}
          setModalAberto={setAbrirModalEditarMensagem}
          mensagemOriginal={mensagem}
          handleAtualizarMensagem={handleAtualizarMensagem}
        />
      )}
      <ModalExclusaoWhatsappMensagem
        modalAberto={abrirModalExclusaoWhatsappMensagem}
        setModalAberto={setAbrirModalExclusaoWhatsappMensagem}
        idMensagem={mensagem.SK}
        handleExclusao={handleExclusao}
      />
    </>
  );
};

export const mapStateToProps = () => ({});

export default connect(mapStateToProps)(LinhaMensagens);
