import moment from 'moment';
import {
  opcoesFiltroDesempenho,
  filtroItens,
  headerAtendimentoSelecionado,
  opcaoAvaliacao,
  todosComGeneroIdentificado,
  mensagemBoasVindas,
} from './Constantes';

function identificarGeneroPalavra(termo) {
  switch (termo) {
    case opcoesFiltroDesempenho.Campanha:
      return todosComGeneroIdentificado.Feminino;
    case opcoesFiltroDesempenho.Atendente:
      return todosComGeneroIdentificado.Masculino;
    case opcoesFiltroDesempenho.Situacao:
      return todosComGeneroIdentificado.Feminino;
    case opcoesFiltroDesempenho.CategoriaProblema:
      return todosComGeneroIdentificado.Feminino;
    case opcoesFiltroDesempenho.Remedio:
      return todosComGeneroIdentificado.Masculino;
    default:
      return todosComGeneroIdentificado.Masculino;
  }
}

export function formataCPF(cpf) {
  const cpfMascara = cpf.replace(/[^\d]/g, '');
  return cpfMascara.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export function formatarTituloPorItensSelecionados(
  titulo,
  tamanhoListaBase,
  listaItensSelecionados
) {
  let textoFormatado = '';
  const labelTodos = identificarGeneroPalavra(titulo);
  const descricao = titulo.split(' ');

  if (listaItensSelecionados.length === 0) {
    descricao.forEach((element) => {
      textoFormatado += `${element} `;
    });
  } else if (listaItensSelecionados.length === 1) {
    textoFormatado = listaItensSelecionados[0].title;
  } else if (
    listaItensSelecionados.length > 1 &&
    listaItensSelecionados.length < tamanhoListaBase
  ) {
    switch (titulo) {
      case filtroItens.Situacao:
        textoFormatado = `${listaItensSelecionados.length} ${filtroItens.PluralSituacao}`;
        break;
      case filtroItens.Campus:
        textoFormatado = `${listaItensSelecionados.length} ${filtroItens.PluralCampus}`;
        break;
      case filtroItens.Chatbot:
        textoFormatado = `${listaItensSelecionados.length} ${filtroItens.PluralChatbot}`;
        break;
      case opcoesFiltroDesempenho.CategoriaProblema:
        textoFormatado = `${listaItensSelecionados.length} ${opcoesFiltroDesempenho.PluralCategoriaProblema}`;
        break;
      default:
        if (descricao.length > 1) {
          textoFormatado = `${listaItensSelecionados.length} ${descricao[0]}s ${descricao[1]}s`;
        } else {
          textoFormatado = `${listaItensSelecionados.length} ${descricao[0]}s`;
        }
        break;
    }
  } else {
    switch (titulo) {
      case filtroItens.Situacao:
        textoFormatado = labelTodos + filtroItens.PluralSituacao;
        break;
      case filtroItens.PerfilAluno:
        textoFormatado = labelTodos + filtroItens.PluralPerfilAluno;
        break;
      case filtroItens.Campus:
        textoFormatado = labelTodos + filtroItens.PluralCampus;
        break;
      case filtroItens.Chatbot:
        textoFormatado = labelTodos + filtroItens.PluralChatbot;
        break;
      case opcoesFiltroDesempenho.CategoriaProblema:
        textoFormatado =
          labelTodos + opcoesFiltroDesempenho.PluralCategoriaProblema;
        break;
      default:
        if (descricao.length > 1) {
          textoFormatado = `${labelTodos + descricao[0]}s ${descricao[1]}s`;
        } else {
          textoFormatado = `${labelTodos + descricao[0]}s`;
        }
    }
  }
  return textoFormatado;
}

export function compareArrays(arr1, arr2) {
  if (!arr1 || !arr2 || arr1.length !== arr2.length) return false;

  arr1.sort();
  arr2.sort();

  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}

function compareDates(date1, date2) {
  const date1Time = date1 && date1.getTime();
  const date2Time = date2 && date2.getTime();
  return date1Time === date2Time;
}

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    const areArrays = Array.isArray(val1) && Array.isArray(val2);
    const areDates = val1 instanceof Date && val2 instanceof Date;
    if (
      (areArrays && !compareArrays(val1, val2)) ||
      (areObjects && !areDates && !deepEqual(val1, val2)) ||
      (areDates && !compareDates(val1, val2)) ||
      (!areObjects && !areDates && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

function isString(value) {
  return {}.toString.call(value) === '[object String]';
}

function isSerializedDate(value) {
  const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  return isString(value) && datePattern.test(value);
}

export function getCurrentDate() {
  const current = new Date();
  current.setHours(0, 0, 0, 0);
  return current;
}

function dateReviver(key, value) {
  if (isSerializedDate(value)) {
    return new Date(value);
  }
  return value;
}

export function deepCopy(object) {
  return JSON.parse(JSON.stringify(object), dateReviver);
}

export function formatarTituloFluxoAtendimento(titulo) {
  const textoFormatado = {};
  textoFormatado[
    headerAtendimentoSelecionado.Aluno.Atendeu
  ] = `Aluno ${titulo}`;
  textoFormatado[headerAtendimentoSelecionado.ConversaComAluno.EmAtend] =
    'Em Atendimento';
  textoFormatado[
    headerAtendimentoSelecionado.Problema.Financeiro
  ] = `Problema ${titulo}`;
  textoFormatado[
    headerAtendimentoSelecionado.Problema.Academico
  ] = `Problema ${titulo}`;
  textoFormatado[
    headerAtendimentoSelecionado.Problema.Institucional
  ] = `Problema ${titulo}`;
  textoFormatado[
    headerAtendimentoSelecionado.Problema.PessoalFamiliar
  ] = `Problema ${titulo}`;

  let texto = textoFormatado[titulo];
  if (texto === null || texto === undefined) {
    texto = titulo;
  }

  return texto;
}

export function formatarFeedbackAtendimento(titulo) {
  const feedback = {};
  feedback[opcaoAvaliacao.MuitoSatisfeito] = '5';
  feedback[opcaoAvaliacao.Satisfeito] = '4';
  feedback[opcaoAvaliacao.Neutro] = '3';
  feedback[opcaoAvaliacao.Insatisfeito] = '2';
  feedback[opcaoAvaliacao.MuitoInsatisfeito] = '1';
  feedback[opcaoAvaliacao.Na] = '0';

  return feedback[titulo];
}

export const normalizeText = (text) =>
  String(text)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

export const zeroPad = (num, places) => String(num).padStart(places, '0');

export const safeHtml = (text) =>
  String(text)
    .replaceAll(/&/g, '&amp;')
    .replaceAll(/</g, '&lt;')
    .replaceAll(/>/g, '&gt;')
    .replaceAll(/"/g, '&quot;')
    .replaceAll('&lt;br/&gt;', '<br/>')
    .replaceAll('&lt;a ', '<a ')
    .replaceAll('&lt;/a&gt;', '</a>')
    .replaceAll('&gt;http', '>http')
    .replaceAll('&lt;/b&gt;', '</b>')
    .replaceAll('&lt;b&gt;', '<b>');

export const verificarSelecionado = (opcaoItem, parametroEscolhido) => {
  return opcaoItem === parametroEscolhido;
};

export const bucarObjetoEmListaComChaveValor = (param, lista, key) => {
  return lista.find((object) => object[key] === param);
};

export const verificarSeObjetosSaoIguais = (obj1, obj2) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

export function checkResponse(response) {
  if (response && response.status >= 200 && response.status < 300) {
    return response.status === 204 ? null : response.data;
  }
  return undefined;
}

export function convertMinutesToHoursAndMinutes(time) {
  const hours = Math.round(time / 60);
  const minutes = Math.round(time % 60);
  return hours === 0 ? `${minutes}min` : `${hours}h ${minutes}min`;
}

export function obterFiltroGrafico(filter) {
  const outFilter = {};
  if (filter.marca && filter.marca.length > 0)
    outFilter.institution = filter.marca;
  if (filter.campi && filter.campi.length > 0) outFilter.campus = filter.campi;
  if (filter.modalidade && filter.modalidade.length > 0)
    outFilter.modality = filter.modalidade;
  if (filter.curso && filter.curso.length > 0) outFilter.course = filter.curso;
  if (filter.turno && filter.turno.length > 0) {
    const listaFinalTurno = [];
    let turno = '';
    filter.turno.forEach((element) => {
      if (element === 'Noite') {
        turno = 'Noturno';
      } else if (element === 'Manhã') {
        turno = 'Matutino';
      }
      listaFinalTurno.push(turno);
    });
    outFilter.shift = listaFinalTurno;
  }
  if (filter.ingresso && filter.ingresso.length > 0)
    outFilter.intake_cycle = filter.ingresso;
  if (filter.periodoLetivo && filter.periodoLetivo.length > 0)
    outFilter.period = filter.periodoLetivo;

  return outFilter;
}

export function mensagemBoasVindasHorario() {
  const dataAtual = new Date();
  const horaAtual = dataAtual.getHours();
  let retorno;

  if (horaAtual < 12) {
    retorno = mensagemBoasVindas.Dia;
  } else if (horaAtual < 18) {
    retorno = mensagemBoasVindas.Tarde;
  } else {
    retorno = mensagemBoasVindas.Noite;
  }
  return retorno;
}

export function placeholderDateTimer() {
  const dataPlaceholder = moment()
    .second(0)
    .millisecond(0)
    .add(10, 'D')
    .format('DD/MM/YYYY HH:mm');
  return `Ex: ${dataPlaceholder}`;
}

export function validarCaractereDropdownDatePicker(char) {
  const texto = char;
  const regex =
    /^[0-9]{0,2}\/?[0-9]{0,2}\/?[0-9]{0,4}\s?[0-9]{0,2}:?[0-9]{0,2}$/gm;

  if (texto && texto.length && texto.length > 0 && !regex.test(texto)) {
    return false;
  }
  return true;
}

export function formatarDataParaString(data, possuiHora) {
  if (!data) return '';
  if (possuiHora) return moment(data).format('DD/MM/YYYY HH:mm');
  return moment(data).format('DD/MM/YYYY');
}

export function formatarStringParaData(dataString) {
  // expected format: DD/MM/YYYY HH:MM
  const dataHoraSplit = dataString.split(' ');
  const dataSplit = dataHoraSplit[0].split('/');
  const horaSplit = dataHoraSplit[1].split(':');
  return new Date(
    dataSplit[2],
    dataSplit[1] - 1,
    dataSplit[0],
    horaSplit[0],
    horaSplit[1]
  );
}

export function formatarDataParaTimestamp(data) {
  if (data) return moment(data).unix();
  return null;
}

export function formatarTimestampParaData(timestamp) {
  const date = new Date(timestamp);
  return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`;
}

export function validarEmail(email) {
  const regex = /^[a-z0-9.-]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;
  return email && email.length && email.length > 0 && !regex.test(email);
}

export function serializeArrayForPart(arr, arrSize) {
  const arrParted = [[]];
  let lastIndex = arrParted[arrParted.length - 1];
  arr.forEach((value) => {
    if (lastIndex.length >= arrSize) {
      arrParted.push([]);
      lastIndex = arrParted[arrParted.length - 1];
    }
    lastIndex = arrParted[arrParted.length - 1];
    arrParted[arrParted.length - 1].push(value);
  });
  return arrParted;
}

export function ordenarPorParticaoDeHifen(array) {
  return array.sort((a, b) => {
    const partitionA = a.Title.split('–')[1];
    const partitionB = b.Title.split('–')[1];
    if (
      partitionA === partitionB ||
      partitionA !== undefined ||
      partitionB !== undefined
    ) {
      return a.Title.localeCompare(b.Title);
    }
    return partitionA.localeCompare(partitionB);
  });
}

export function dividirArrayPorDirecao(direcao, array, posicao) {
  if (direcao === 'antes') {
    return array.slice(0, parseInt(posicao, 10) - 1);
  }

  if (direcao === 'depois') {
    return array.slice(parseInt(posicao, 10));
  }
}

export function inicializarDataInicialDesempenho() {
  const dataInicial = new Date();
  dataInicial.setMonth(dataInicial.getMonth() - 6);
  return dataInicial;
}
