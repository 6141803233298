import axios from '../apis/axiosInstanceScape0Crm';
import { checkResponse } from '../shared/util/Utils';

class GestaoCampanhaService {
  async buscarListaCampanhas() {
    return axios.get('/campaign').then(checkResponse);
  }

  async buscarListaCampanhasWhatsapp() {
    return axios.get('/campaign/whatsapp').then(checkResponse);
  }

  async buscarListaRepositorioArquivos() {
    return axios.get('/campaign_recipient_report').then(checkResponse);
  }

  async excluirCampanha(campaignId) {
    return axios.delete(`/campaign/${campaignId}`).then(checkResponse);
  }

  async excluirCampanhaWhatsapp(campaignId) {
    return axios.delete(`/campaign/whatsapp/${campaignId}`).then(checkResponse);
  }

  async editarDadosCampanha(campaignId, payload) {
    return axios.put(`/campaign/${campaignId}`, payload).then(checkResponse);
  }

  async downloadDadosCampanha(campaignId) {
    return axios
      .post(`/campaign_recipient_report?CampaignId=${campaignId}`)
      .then(checkResponse);
  }

  async alterarStatusCampanha(idCampanha, option, payload) {
    return axios
      .put(`/campaign/${idCampanha}`, payload, {
        params: { option },
      })
      .then(checkResponse);
  }

  async buscarCampanha(campaignId, queryStringParam) {
    return axios
      .get(`/campaign/${campaignId}`, { params: queryStringParam })
      .then(checkResponse);
  }
}

export default new GestaoCampanhaService();
