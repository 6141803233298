import React, { useState, useEffect } from 'react';
import { Grid, Typography, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronDown } from 'react-feather';
import ReactLoading from 'react-loading';
import {
  GRAY,
  DARK_CYAN,
  LIGHT_GRAY,
  FONT_FAMILY_SEMIBOLD,
  FONT_FAMILY_REGULAR,
} from '../../theme';
import Dropdown from './Dropdown';
import { formatarTituloPorItensSelecionados } from '../util/Utils';
import SimpleDropdown from './SimpleDropdown';

const SelectCampanha = (props) => {
  const {
    titulo,
    listaOpcoes,
    handleAbrirSimpleDropdown,
    carregando,
    model,
    setModel,
    valorInicial,
    top,
    left,
    width,
  } = props;

  const useStyles = makeStyles({
    container: {
      minWidth: '5rem',
      width: width || '16.6rem',
      height: '32px',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
      borderBottom: 'solid 1px',
      margin: '4px 8px 4px 0px',
      cursor: 'pointer',
    },
    containerIcone: {
      float: 'right',
      margin: '2px 4px 0 0',
    },
    icone: {
      height: '18px',
      width: '18px',
    },
    containerDescricao: {
      marginLeft: '16px',
      width: '13.5rem',
      textAlignLast: 'left',
    },
  });

  const classes = useStyles();

  const [abrirDropdown, setDropdown] = useState(false);
  const [descricaoItem, setDescricaoItem] = useState(titulo);
  const [listaItems, setlistaItems] = useState([]);
  const [abrirSimpleDropdown, setAbrirSimpleDropdown] = useState(false);

  function mudarEstiloSelect() {
    if (model && model.length > 0) {
      return {
        backgroundColor: 'rgba(14,167,137,0.05)',
        borderBottomColor: DARK_CYAN,
      };
    }
    return {
      backgroundColor: 'rgba(158,171,181,0.05)',
      borderBottomColor: LIGHT_GRAY,
    };
  }

  function mudarEstiloTextoIcone() {
    if (model && model.length > 0) {
      return { color: DARK_CYAN, fontFamily: FONT_FAMILY_SEMIBOLD };
    }
    return { color: GRAY };
  }

  const setarListaItensSelecionados = (lista) => {
    setModel(lista);
    setDescricaoItem(
      formatarTituloPorItensSelecionados(titulo, listaItems.length, lista)
    );
  };

  const setarItemSelecionadoSimpleDropdown = (itemDescricao) => {
    setModel(itemDescricao);
    setDescricaoItem(itemDescricao);
  };

  function handleSimpleDropdown() {
    if (carregando) return;
    if (handleAbrirSimpleDropdown) {
      setAbrirSimpleDropdown(!abrirSimpleDropdown);
    } else {
      setDropdown(!abrirDropdown);
    }
  }

  useEffect(() => {
    const verificarGrupoDaCampanha = (grupo) => {
      if (valorInicial.find((el) => el === grupo.Id)) {
        return true;
      }
      return false;
    };

    if (listaOpcoes && listaOpcoes.length > 0) {
      const listaItemsTemp = [];
      listaOpcoes.forEach((el) => {
        listaItemsTemp.push({
          checked:
            valorInicial && Array.isArray(valorInicial)
              ? verificarGrupoDaCampanha(el)
              : false,
          title: el.Description,
          id: el.Id,
        });
      });

      if (valorInicial && Array.isArray(valorInicial)) {
        setModel(listaItemsTemp.filter((item) => item.checked === true));
        setDescricaoItem(
          formatarTituloPorItensSelecionados(
            titulo,
            listaItemsTemp.length,
            listaItemsTemp.filter((item) => item.checked === true)
          )
        );
      }

      setlistaItems(listaItemsTemp);

      if (valorInicial && !Array.isArray(valorInicial)) {
        const itemInicial = listaItemsTemp.find(
          (item) => item.title === valorInicial
        );
        if (itemInicial) {
          setModel(itemInicial.title);
          setDescricaoItem(itemInicial.title);
        }
      }
    }
  }, [listaOpcoes, setModel, titulo, valorInicial]);

  return (
    <>
      <Grid
        container
        wrap="nowrap"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.container}
        style={mudarEstiloSelect()}
        onClick={() => handleSimpleDropdown()}
        data-testid="container-select-campanha"
      >
        <Grid item className={classes.containerDescricao}>
          <Typography
            variant="body1"
            align="center"
            noWrap
            style={mudarEstiloTextoIcone()}
          >
            {descricaoItem}
          </Typography>
        </Grid>
        <Grid item className={classes.containerIcone}>
          {!carregando && (
            <Icon>
              <ChevronDown
                className={classes.icone}
                style={mudarEstiloTextoIcone()}
              />
            </Icon>
          )}

          {carregando && (
            <ReactLoading
              type="spin"
              color={DARK_CYAN}
              height={25}
              width={25}
            />
          )}
        </Grid>
      </Grid>
      <Dropdown
        mostrarDropdown={abrirDropdown}
        setMostrarDropdown={setDropdown}
        listaItensInseridos={model}
        setListaItensInseridos={setarListaItensSelecionados}
        listaItens={listaItems}
        setListaItens={setlistaItems}
        descricaoItem={titulo}
        top={top || '36px'}
        left={left || '-278px'}
        width="270px"
      />
      <SimpleDropdown
        mostrarDropdown={abrirSimpleDropdown}
        setMostrarDropdown={setAbrirSimpleDropdown}
        positionTop={top || '36px'}
        positionLeft={left || '-278px'}
        listaNavegacao={listaOpcoes}
        handleClickSimpleDropdown={null}
        fontSizeDescription="body2"
        larguraSimpleDropdown="270px"
        setarItemSelecionadoSimpleDropdown={setarItemSelecionadoSimpleDropdown}
        fontFamily={FONT_FAMILY_REGULAR}
      />
    </>
  );
};

export default SelectCampanha;
