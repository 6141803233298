import DashboardService from '../../../services/cockpit/DashboardService';

class DestaquesManager {
  async buscarDadosGraficos(codigoIes, filters) {
    const payload = {
      institution_id: codigoIes,
      chart_name: '03_mpe_ranking',
      filters,
    };
    const lista = await DashboardService.buscarDadosGraficos(payload).then(
      this.checkResponse
    );
    const listaFinal = [];

    if (lista !== undefined) {
      lista.forEach((element) => {
        const dto = {};
        dto.nomeCurso = element.course;
        dto.evasaoAtual = (element.dropout_rate * 100).toFixed(2);
        dto.evasaoYtd = (element.dropout_rate_ytd * 100).toFixed(2);
        dto.numeroTotalEstudantes = element.students;
        dto.numeroTotalEstudantesYtd = element.students_ytd;

        listaFinal.push(dto);
      });
    }
    return listaFinal.sort((a, b) => b.evasaoAtual - a.evasaoAtual);
  }
}

export default new DestaquesManager();
