import axios from '../apis/axiosInstanceScape0Crm';
import { checkResponse } from '../shared/util/Utils';

class WhatsappService {
  async listaMensagensWhatsapp() {
    return axios.get('/whatsapp_message').then(checkResponse);
  }

  async obterZenviaTemplate(ies) {
    return axios
      .get('/whatsapp_message', { params: { ies } })
      .then(checkResponse);
  }

  async enviarAtualizarMensagemWhatsapp(id, params) {
    return axios.put(`/whatsapp_message/${id}`, params).then(checkResponse);
  }

  async enviarCriarMensagemWhatsapp(params) {
    return axios.post('/whatsapp_message', params).then(checkResponse);
  }

  async listaMarcasWhatsapp() {
    return axios.get('/whatsapp_organization').then(checkResponse);
  }

  async deletarMensagemWhatsapp(idMensagem) {
    return axios.delete(`/whatsapp_message/${idMensagem}`).then(checkResponse);
  }
}
export default new WhatsappService();
