import { DARK_CYAN, GRAY, MEDIUM_GRAY } from '../../theme';
import { pluralItens, filtroItens } from './Constantes';

export const formatarListaSemFiltro = (icone, id, filtro) => {
  const descricao = filtro;
  const corIcone = DARK_CYAN;
  const corTitulo = DARK_CYAN;
  const fonteTitulo = 'bold';

  return {
    title: descricao,
    icon: icone,
    iconColor: corIcone,
    titleColor: corTitulo,
    titleFont: fonteTitulo,
    id,
    itemFiltro: filtro,
    semFiltro: true,
  };
};

export const formatarLista = (itensFiltro, icone, id, filtro) => {
  let descricao = '';
  let corIcone = '';
  let corTitulo = '';
  let fonteTitulo = '';

  if (itensFiltro.length > 1) {
    descricao = `${itensFiltro.length} ${pluralItens[icone]}`;
    corIcone = DARK_CYAN;
    corTitulo = DARK_CYAN;
    fonteTitulo = 'bold';
  } else {
    descricao =
      itensFiltro[0] && typeof itensFiltro[0] === 'string'
        ? itensFiltro[0]
        : '';
    corIcone = GRAY;
    corTitulo = MEDIUM_GRAY;
    fonteTitulo = 'normal';
  }

  return {
    title: descricao,
    icon: icone,
    iconColor: corIcone,
    titleColor: corTitulo,
    titleFont: fonteTitulo,
    itemFiltro: filtro,
    id,
    semFiltro: false,
  };
};

export const montarListaIES = (filtro) => {
  const lista = [];

  if (
    filtro &&
    filtro.marca &&
    filtro.campi &&
    filtro.curso &&
    filtro.ingresso &&
    filtro.HasFinancialDebt &&
    filtro.StartRegistrationDate &&
    filtro.EndRegistrationDate
  ) {
    if (filtro.marca.length > 0) {
      lista.push(
        formatarLista(filtro.marca, 'Marca', 'marca', filtroItens.Marca)
      );
    }

    if (filtro.campi.length > 0) {
      lista.push(
        formatarLista(filtro.campi, 'Campus', 'campi', filtroItens.Campus)
      );
    }

    if (filtro.HasFinancialDebt.length > 0) {
      lista.push(
        formatarLista(
          filtro.HasFinancialDebt,
          'DebitoFinanceiro',
          'debitoFinanceiro',
          filtroItens.DebitoFinanceiro
        )
      );
    }

    if (filtro.Modality.length > 0) {
      lista.push(
        formatarLista(
          filtro.Modality,
          'Modality',
          'modality',
          filtroItens.Modality
        )
      );
    }

    if (filtro.curso.length > 0) {
      lista.push(
        formatarLista(filtro.curso, 'Curso', 'curso', filtroItens.Curso)
      );
    }

    if (filtro.turno.length > 0) {
      lista.push(
        formatarLista(filtro.turno, 'Turno', 'turno', filtroItens.Turno)
      );
    }

    if (filtro.EnrollmentType.length > 0) {
      lista.push(
        formatarLista(
          filtro.EnrollmentType,
          'Ingresso',
          'ingresso',
          filtroItens.Ingresso
        )
      );
    }

    if (filtro.StartRegistrationDate.length > 0) {
      lista.push(
        formatarLista(
          filtro.StartRegistrationDate,
          'DataInicioMatricula',
          'dataInicioMatricula',
          filtroItens.DataInicioMatricula
        )
      );
    }

    if (filtro.EndRegistrationDate.length > 0) {
      lista.push(
        formatarLista(
          filtro.StartRegistrationDate,
          'DataFinalMatricula',
          'dataFinalMatricula',
          filtroItens.DataFinalMatricula
        )
      );
    }
  }
  return lista;
};

export const montarListaAluno = (filtro) => {
  const lista = [];

  if (filtro && filtro.problema && filtro.situacao && filtro.aluno) {
    if (filtro.aluno.length > 0) {
      lista.push(
        formatarLista(filtro.aluno, 'Aluno', 'aluno', filtroItens.Aluno)
      );
    }
  }
  return lista;
};
