import React from 'react';
import {
  ChevronUp,
  ChevronDown,
  ChevronsDown,
  ChevronsUp,
} from 'react-feather';

import DragHandleIcon from '@material-ui/icons/DragHandle';
import { makeStyles } from '@material-ui/core/styles';
import { TOMATO, ORANGE, LIGHT_SEA_GREEN } from '../../theme';

const useStyles = makeStyles(() => ({
  IconePrioridadeAlta: {
    height: '22px',
    width: '22px',
    color: TOMATO,
    marginRight: '5px',
  },
  IconePrioridadeBaixa: {
    height: '22px',
    width: '22px',
    color: LIGHT_SEA_GREEN,
    marginRight: '5px',
  },
  IconePrioridadeMedia: {
    height: '22px',
    width: '22px',
    color: ORANGE,
    marginRight: '5px',
  },
}));

const IconePrioridade = (props) => {
  const classes = useStyles();
  const { prioridade } = props;
  const Icone = {
    1: ChevronsDown,
    2: ChevronDown,
    3: DragHandleIcon,
    4: ChevronUp,
    5: ChevronsUp,
  }[prioridade];
  const ClasseDaProridade = {
    1: classes.IconePrioridadeBaixa,
    2: classes.IconePrioridadeBaixa,
    3: classes.IconePrioridadeMedia,
    4: classes.IconePrioridadeAlta,
    5: classes.IconePrioridadeAlta,
  };
  return <Icone size={20} className={ClasseDaProridade[prioridade]} />;
};

export default IconePrioridade;
