import React, { useEffect, useRef } from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { X } from 'react-feather';
import { BLACK } from '../../../theme';

const useStyles = makeStyles(() => ({
  containerTooltip: {
    width: '660px',
    height: '226px',
    backgroundColor: 'rgba(246, 203, 92, 1)',
    padding: '15px',
    borderRadius: '8px',
    position: 'absolute',
    top: '45px',
    left: '115px',
    zIndex: 11,
  },
  textoModal: {
    marginBottom: '10px',
    color: BLACK,
    fontSize: '14px',
    maxWidth: '540px',
  },
  informacaoPermissao: {
    fontSize: '14px',
    maxWidth: '620px',
    marginBottom: '2px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  closeIcon: {
    cursor: 'pointer',
  },
}));

const InformacoesPermissaoUsuario = (props) => {
  const { setAbrirInformacoesModal } = props;
  const classes = useStyles();
  const refGridInformacoes = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        refGridInformacoes.current &&
        !refGridInformacoes.current.contains(event.target)
      ) {
        setAbrirInformacoesModal(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setAbrirInformacoesModal]);

  return (
    <Grid
      container
      className={classes.containerTooltip}
      direction="column"
      data-testid="component-informacoes-permissao-usuario"
      ref={refGridInformacoes}
      onClick={() => setAbrirInformacoesModal(false)}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4" className={classes.textoModal}>
          Permissões de Usuário
        </Typography>

        <X
          data-testid="close-button-informacoes-permissao-usuario"
          size={20}
          color={BLACK}
          onClick={() => setAbrirInformacoesModal(false)}
          className={classes.closeIcon}
        />
      </Grid>

      <Typography variant="body1" className={classes.textoModal}>
        Utilize os Círculos de seleção em frente a cada uma das linhas da tabela
        para determinar o perfil de seu respectivo Usuário
      </Typography>
      <Typography variant="body1" className={classes.textoModal}>
        As permissões de cada Perfil estão descritas abaixo:
      </Typography>

      <Typography variant="body1" className={classes.informacaoPermissao}>
        <b>OPERADOR</b> - Fazer Ativo
      </Typography>
      <Typography variant="body1" className={classes.informacaoPermissao}>
        <b>LÍDER</b> - Fazer Ativo - Extrair Relatórios - Atribuir Permissão -
        Acesso Cockpit
      </Typography>
      <Typography variant="body1" className={classes.informacaoPermissao}>
        <b>GESTOR</b> - Fazer Ativo - Extrair Relatórios - Atribuir Permissão -
        Acesso Cockpit
      </Typography>
      <Typography variant="body1" className={classes.informacaoPermissao}>
        <b>ADM</b> - Fazer Ativo - Extrair Relatórios - Atribuir Permissão -
        Acesso Cockpit - Criar Campanhas
      </Typography>
    </Grid>
  );
};

export default InformacoesPermissaoUsuario;
