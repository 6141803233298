import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { Plus, Search } from 'react-feather';
import { connect } from 'react-redux';
import WhatsappIcon from '@material-ui/icons/WhatsApp';
import DadosNaoEncontrados from '../../../shared/componentes/DadosNaoEncontrados';
import LoadingDashboard from '../../../shared/componentes/LoadingDashboard';
import { hierarquiaPermissoesUsuarios } from '../../../shared/util/Constantes';
import { deepCopy, normalizeText } from '../../../shared/util/Utils';
import CardTitle from '../../../shared/componentes/CardTitle';
import { DARK_CYAN, GRAY, LIGHT_SEA_GREEN_HOVER, WHITE } from '../../../theme';
import GestaoMensagensManager from '../GestaoMensagensManager';
import TabelaMensagens from './TabelaMensagens';
import ModalFormMensagemWhatsapp from '../../formMensagemWhatsapp/views/ModalFormMensagemWhatsapp';

const useStyles = makeStyles({
  containerGestaoMensagem: {
    minHeight: '620px',
    position: 'relative',
  },
  botaoAplicar: {
    backgroundColor: DARK_CYAN,
    height: '45px',
    width: '210px',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: LIGHT_SEA_GREEN_HOVER,
    },
  },
  textoBotaoAplicar: {
    fontSize: '14px',
    color: WHITE,
  },
  searchField: {
    width: 'calc(450% - 450px)',
    marginLeft: '10px',
  },
  containerTitulo: {
    marginBottom: '-24px',
  },
  dadosNaoEncontrados: {
    height: '79%',
  },
});

const GestaoMensagensWhatsapp = (props) => {
  const { login } = props;

  const classes = useStyles();
  const [carregando, setCarregando] = useState(false);
  const [busca, setBusca] = useState('');
  const [listaMensagens, setListaMensagens] = useState([]);
  const [listaMensagensFiltradas, setListaMensagensFiltradas] = useState([]);
  const [possuiPermissaoCriarMensagem, setPossuiPermissaoCriarMensagem] =
    useState(false);
  const [abrirModalCriarMensagem, setAbrirModalCriarMensagem] = useState(false);

  const handleExclusaoMensagem = (idDaMensagem) => {
    for (const mensagem of listaMensagens) {
      if (mensagem.SK === idDaMensagem) {
        listaMensagens.splice(listaMensagens.indexOf(mensagem), 1);
      }
    }
    setListaMensagens(deepCopy(listaMensagens));
  };

  const handleCriarMensagem = async () => {
    setCarregando(true);
    const resultMensagens =
      await GestaoMensagensManager.buscarMensagensWhatsapp();
    if (resultMensagens) {
      setListaMensagens(resultMensagens);
      setListaMensagensFiltradas(resultMensagens);
    }
    const possuiPermissao =
      login &&
      login.usuarioScape0 &&
      login.usuarioScape0.HierarchyLevel <=
        hierarquiaPermissoesUsuarios.Administrador;
    setPossuiPermissaoCriarMensagem(!!possuiPermissao);
    setCarregando(false);
  };

  useEffect(() => {
    const BuscarDadosGestaoMensagens = async () => {
      setCarregando(true);
      const resultMensagens =
        await GestaoMensagensManager.buscarMensagensWhatsapp();
      if (resultMensagens) {
        setListaMensagens(resultMensagens);
        setListaMensagensFiltradas(resultMensagens);
      }
      const possuiPermissao =
        login &&
        login.usuarioScape0 &&
        login.usuarioScape0.HierarchyLevel <=
          hierarquiaPermissoesUsuarios.Administrador;
      setPossuiPermissaoCriarMensagem(!!possuiPermissao);
      setCarregando(false);
    };
    BuscarDadosGestaoMensagens();
  }, [login]);

  useEffect(() => {
    if (busca && busca.length && listaMensagens && listaMensagens.length) {
      const listaFiltrada = listaMensagens.filter(
        (mensagem) =>
          normalizeText(mensagem.Title).indexOf(normalizeText(busca)) !== -1
      );
      setListaMensagensFiltradas(listaFiltrada);
    } else {
      setListaMensagensFiltradas(listaMensagens);
    }
  }, [busca, listaMensagens]);

  return (
    <>
      <Grid
        item
        container
        direction="row"
        alignContent="center"
        justifyContent="space-between"
        className={classes.containerTitulo}
      >
        <CardTitle text="Gestão de Mensagens" icon={<WhatsappIcon />} />
      </Grid>
      <Grid
        container
        item
        alignContent="flex-start"
        justifyContent="space-evenly"
        className={classes.containerGestaoMensagem}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
          spacing={2}
        >
          <TextField
            variant="filled"
            value={busca}
            onInput={(e) => {
              setBusca(e.target.value);
            }}
            size="small"
            placeholder="Pesquise por uma mensagem..."
            InputProps={{
              style: {
                fontWeight: 'normal',
                backgroundColor: 'rgba(158, 171, 181, 0.05)',
                fontSize: '14px',
              },
              endAdornment: <Search size={16} color={GRAY} />,
            }}
            className={classes.searchField}
            data-testid="campo-pesquisa-titulo_messagem"
          />
          <Grid
            item
            container
            direction="row"
            alignContent="center"
            justifyContent="flex-end"
          >
            {possuiPermissaoCriarMensagem && (
              <Button
                type="submit"
                disableElevation
                startIcon={<Plus size={20} color={WHITE} />}
                className={`${classes.botaoAplicar} "Login-Btn"`}
                data-testid="buttom-nova-mensagem"
                onClick={() => setAbrirModalCriarMensagem(true)}
              >
                <Typography
                  className={classes.textoBotaoAplicar}
                  variant="body1"
                >
                  Nova Mensagem
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
        <TabelaMensagens
          handleExclusaoMensagem={handleExclusaoMensagem}
          mensagens={listaMensagensFiltradas}
          busca={busca}
        />
        {(!listaMensagensFiltradas || listaMensagensFiltradas.length === 0) && (
          <Grid container className={classes.dadosNaoEncontrados}>
            <DadosNaoEncontrados chaveamentoEstadoVazio={1} />
          </Grid>
        )}
        {carregando && <LoadingDashboard />}
      </Grid>
      {abrirModalCriarMensagem && (
        <ModalFormMensagemWhatsapp
          modalAberto={abrirModalCriarMensagem}
          setModalAberto={setAbrirModalCriarMensagem}
          handleCriarMensagem={handleCriarMensagem}
          carregando={carregando}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

export default connect(mapStateToProps)(GestaoMensagensWhatsapp);
