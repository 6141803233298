import axios from 'axios';
import { checkResponse } from '../shared/util/Utils';
import axiosInstanceScape0Crm from '../apis/axiosInstanceScape0Crm';

const { CancelToken } = axios;
let cancel;

class CriarCampanhaService {
  checkResponseCriarCampanha(response) {
    if (response && response.status >= 200 && response.status < 300) {
      return true;
    }
    return undefined;
  }

  async criarCampanha(payload) {
    return axiosInstanceScape0Crm
      .post('/campaign', payload)
      .then(this.checkResponseCriarCampanha);
  }

  async criarCampanhaUpload(payload, queryStringParam) {
    return axiosInstanceScape0Crm
      .post('/campaign', payload, { params: queryStringParam })
      .then(this.checkResponseCriarCampanha);
  }

  async criarCampanhaViaWhatsapp(payload, queryStringParam) {
    return axiosInstanceScape0Crm
      .post('/campaign', payload, { params: queryStringParam })
      .then(this.checkResponseCriarCampanha);
  }

  async buscarQuantidadeAlunos(payload) {
    if (cancel !== undefined) {
      cancel();
    }
    return axiosInstanceScape0Crm
      .post('preview_campaign_range', payload, {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      })
      .then(checkResponse);
  }
}
export default new CriarCampanhaService();
