import {
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Tab,
  Tabs,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Info, Settings } from 'react-feather';
import CloseIcon from '../../../shared/componentes/CloseIcon';
import Loading from '../../../shared/componentes/Loading';
import CardTitle from '../../../shared/util/components/CardTitle';
import {
  WHITE,
  DARK_CYAN,
  FONT_FAMILY_EXTRABOLD,
  SHADOW_MODAL,
  STAR_COMMAND_BLUE,
} from '../../../theme';
import ConfiguracoesUsuarioManager from '../ConfiguracoesUsuarioManager';
import AbaGruposVisualizacao from './AbaGruposVisualizacao';
import AbaPermissoesUsuario from './AbaPermissoesUsuario';
import GerenciarMembros from '../../gerenciarMembros/views/GerenciarMembros';
import InformacoesPermissaoUsuario from './InformacoesPermissaoUsuario';

const useStyles = makeStyles(() => ({
  modal: {
    maxWidth: '948px',
    height: '570px',
    width: '948px',
    backgroundColor: WHITE,
    boxShadow: `0 2px 10px 0 ${SHADOW_MODAL}`,
    borderRadius: '8px',
  },
  backgroundProps: {
    background: 'rgba(192,198,214,0.4)',
  },
  dialogContentSpace: {
    paddingTop: '16px',
  },

  containerTitle: {
    display: 'grid',
    gridTemplateColumns: 'max-content min-content',
    justifyContent: 'space-between',
    marginBottom: '-6px',
  },

  containerAbas: {
    height: '58px',
  },
  abaNavegacao: {
    color: DARK_CYAN,
    fontSize: '18px',
    fontFamily: FONT_FAMILY_EXTRABOLD,
  },
  containerCardTitle: {
    display: 'grid',
    gridTemplateColumns: 'max-content min-content',
  },
  infoIcon: {
    cursor: 'pointer',
    marginLeft: '10px',
    marginTop: '6px',
  },
  iconLabelWrapper: {
    flexDirection: 'row-reverse',
  },
}));

const ModalConfiguracoes = (props) => {
  const classes = useStyles();

  const { modalAberto, handleFecharModal, login } = props;

  const [busca, setBusca] = useState('');
  const [buscaGrupos, setBuscaGrupos] = useState('');
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [listaPermissoes, setListaPermissoes] = useState([]);
  const [listaGruposVisualizacao, setListaGruposVisualizacao] = useState([]);
  const [usuarioLogado, setUsuarioLogado] = useState();
  const [carregando, setCarregando] = useState(false);
  const [grupoGerenciado, setGrupoGerenciado] = useState('nenhum');
  const [abaSelecionada, setAbaSelecionada] = useState(1);

  const [abrirInformacoesModal, setAbrirInformacoesModal] = useState(false);

  const [
    filtroGruposVisualizacaoDropdown,
    setfiltroGruposVisualizacaoDropdown,
  ] = useState([]);
  const [itensSelecionadosDropdown, setItensSelecionadosDropdown] = useState(
    []
  );

  useEffect(() => {
    const buscarListaUsuarios = async () => {
      setCarregando(true);
      const resp = await ConfiguracoesUsuarioManager.obterListaUsuarios();

      const index = resp.Operators.findIndex(
        (element) => element.Sub === login.usuario.sub
      );
      resp.Operators.unshift(resp.Operators.splice(index, 1)[0]);

      setUsuarioLogado({
        Usuario: resp.Operators[0],
        Permissao: resp.PermissionGroups.find(
          (element) =>
            element.PermissionGroupId === resp.Operators[0].PermissionGroupId
        ),
        GruposVisualizacao: resp.Operators[0].VisualizationGroup,
      });

      setListaUsuarios(resp.Operators);
      setListaPermissoes(resp.PermissionGroups);
      setListaGruposVisualizacao(resp.VisualizationGroup);
      setCarregando(false);
    };
    if (modalAberto) {
      setGrupoGerenciado('nenhum');
      setAbaSelecionada(1);
      buscarListaUsuarios();
    }
  }, [modalAberto, login]);

  useEffect(() => {
    const tratarGruposParaDropdown = (listaParaTratar) => {
      const listaTratada = [];
      for (const grupoVisualizacao of listaParaTratar) {
        listaTratada.push({
          title: grupoVisualizacao,
          id: grupoVisualizacao,
          checked:
            usuarioLogado &&
            usuarioLogado.GruposVisualizacao &&
            usuarioLogado.GruposVisualizacao.includes(grupoVisualizacao),
        });
      }
      return listaTratada;
    };
    if (modalAberto) {
      const newListaGruposDropdown = tratarGruposParaDropdown(
        listaGruposVisualizacao
      );
      setfiltroGruposVisualizacaoDropdown(newListaGruposDropdown);
      setItensSelecionadosDropdown(
        newListaGruposDropdown.filter((item) => item.checked === true)
      );
    }
  }, [modalAberto, listaGruposVisualizacao, usuarioLogado]);

  const handleTrocarAba = (_event, novoValor) => {
    setBuscaGrupos('');
    setBusca('');
    setAbaSelecionada(novoValor);
  };

  return (
    <Dialog
      open={modalAberto}
      onClose={handleFecharModal}
      aria-labelledby="modal-titulo"
      aria-describedby="modal-descricao"
      scroll="body"
      classes={{ paper: classes.modal }}
      BackdropProps={{
        classes: {
          root: classes.backgroundProps,
        },
      }}
    >
      {carregando && <Loading />}
      {!listaGruposVisualizacao.includes(grupoGerenciado) ? (
        <DialogContent className={classes.dialogContentSpace}>
          <Grid
            container
            className={classes.containerTitle}
            alignItems="center"
          >
            <Grid
              container
              alignItems="center"
              className={classes.containerCardTitle}
            >
              <CardTitle
                text="Configurações"
                icon={<Settings size={24} color={DARK_CYAN} />}
              />
            </Grid>

            <CloseIcon
              tooltipTitle="Fechar Permissões de Usuário"
              handleFechar={handleFecharModal}
            />
          </Grid>
          <Tabs
            value={abaSelecionada}
            onChange={handleTrocarAba}
            variant="fullWidth"
            className={classes.containerAbas}
          >
            <Tab
              value={1}
              className={classes.abaNavegacao}
              classes={{
                wrapper: classes.iconLabelWrapper,
              }}
              label="Permissões de Usuário"
              icon={
                <Info
                  data-testid="info-button-permissao-usuario"
                  size={22}
                  color={STAR_COMMAND_BLUE}
                  className={classes.infoIcon}
                  onClick={() => setAbrirInformacoesModal(true)}
                />
              }
            />

            <Tab
              value={2}
              className={classes.abaNavegacao}
              label="Grupos de Visualização"
            />
          </Tabs>
          {abaSelecionada && abaSelecionada === 1 && (
            <AbaPermissoesUsuario
              busca={busca}
              setBusca={setBusca}
              carregando={carregando}
              usuarioLogado={usuarioLogado}
              listaPermissoes={listaPermissoes}
              setListaUsuarios={setListaUsuarios}
              listaUsuarios={listaUsuarios}
              filtroGruposVisualizacaoDropdown={
                filtroGruposVisualizacaoDropdown
              }
              setfiltroGruposVisualizacaoDropdown={
                setfiltroGruposVisualizacaoDropdown
              }
              itensSelecionadosDropdown={itensSelecionadosDropdown}
              setItensSelecionadosDropdown={setItensSelecionadosDropdown}
            />
          )}
          {abaSelecionada && abaSelecionada === 2 && (
            <AbaGruposVisualizacao
              modalAberto={modalAberto}
              listaUsuarios={listaUsuarios}
              listaGruposVisualizacao={listaGruposVisualizacao}
              usuarioLogado={usuarioLogado}
              carregando={carregando}
              buscaGrupos={buscaGrupos}
              setBuscaGrupos={setBuscaGrupos}
              setGrupoGerenciado={setGrupoGerenciado}
              filtroGruposVisualizacaoDropdown={
                filtroGruposVisualizacaoDropdown
              }
              setfiltroGruposVisualizacaoDropdown={
                setfiltroGruposVisualizacaoDropdown
              }
              itensSelecionadosDropdown={itensSelecionadosDropdown}
              setItensSelecionadosDropdown={setItensSelecionadosDropdown}
            />
          )}
          {abrirInformacoesModal && (
            <InformacoesPermissaoUsuario
              setAbrirInformacoesModal={setAbrirInformacoesModal}
            />
          )}
        </DialogContent>
      ) : (
        <DialogContent className={classes.dialogContentSpace}>
          <GerenciarMembros
            usuarioLogado={usuarioLogado}
            listaPermissoes={listaPermissoes}
            listaUsuarios={listaUsuarios}
            setListaUsuarios={setListaUsuarios}
            grupoGerenciado={grupoGerenciado}
            setGrupoGerenciado={setGrupoGerenciado}
            handleFecharModal={handleFecharModal}
            buscaGrupos={buscaGrupos}
            setBuscaGrupos={setBuscaGrupos}
            carregando={carregando}
          />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ModalConfiguracoes;
