import moment from 'moment';
import DashboardService from '../../../services/cockpit/DashboardService';
import 'moment/locale/pt-br';

class RaioXEvasaoManager {
  async buscarDadosEvolucaoEvasao(codigoIes, filtro) {
    const cabecalho = [
      { type: 'string', role: 'domain', label: 'Ano' },
      'Período Atual',
      'Período de Referência',
      'Tendência',
    ];

    const payload = {
      institution_id: codigoIes,
      chart_name: '02_mpe_evolution',
      filters: filtro,
    };

    const resposta = await DashboardService.buscarDadosGraficos(payload).then(
      this.checkResponse
    );
    if (!resposta) return [];

    const data = resposta.map((item) => {
      const [date, ...rest] = item;
      return [moment(new Date(date)).format('DD/MMM/YY'), ...rest];
    });

    for (let i = 1; i < data.length; i++) {
      for (let j = 1; j < data[i].length; j++) {
        if (data[i][j] !== 0) {
          return [cabecalho].concat(data);
        }
      }
    }
    return [];
  }

  async buscarDadosComparativoEvasao(codigoIes, filtro) {
    const payload = {
      institution_id: codigoIes,
      chart_name: '01_mpe_dropout',
      filters: filtro,
    };

    const resposta = await DashboardService.buscarDadosGraficos(payload).then(
      this.checkResponse
    );
    if (!resposta) return [];

    const dadosComparativoEvasao = resposta.map((item) => {
      return {
        valorPrimario: item.secondary,
        valorSecundario: item.primary,
      };
    });

    for (let i = 1; i < dadosComparativoEvasao.length; i++) {
      if (
        Number(dadosComparativoEvasao[i].valorPrimario) !== 0 &&
        dadosComparativoEvasao[i].valorSecundario !== '0%'
      ) {
        return dadosComparativoEvasao;
      }
    }
    return [];
  }
}
export default new RaioXEvasaoManager();
