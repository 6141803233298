import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableFooter,
  TablePagination,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronDown } from 'react-feather';
import ReactHoverObserver from 'react-hover-observer';
import LinhaVaziaTabelaPermissaoUsuario from './LinhaVaziaTabelaPermissaoUsuario';
import { DARK_CYAN, GRAY } from '../../../theme';
import DadosNaoEncontrados from '../../../shared/componentes/DadosNaoEncontrados';

import LinhaTabelaPermissaoUsuario from './LinhaTabelaPermissaoUsuario';
import { deepCopy } from '../../../shared/util/Utils';

const useStyles = makeStyles({
  caption: {
    color: DARK_CYAN,
  },
  toolbar: {
    paddingRight: '8px',
  },
  labelColuna: {
    marginRight: '5px',
  },
  labelColunaHover: {
    marginRight: '5px',
    color: DARK_CYAN,
  },
  iconHover: {
    color: DARK_CYAN,
  },
  containerReactHover: {
    alignSelf: 'center',
    cursor: 'pointer',
  },
  dadosNaoEncontrados: {
    marginTop: '10rem',
  },
  tableCellLogin: {
    width: '50%',
  },
});

const TabelaPermissaoUsuario = (props) => {
  const classes = useStyles();
  const {
    listaUsuarios,
    setListaUsuarios,
    listaUsuariosFiltrados,
    listaPermissoes,
    usuarioLogado,
    showFooter,
    carregando,
  } = props;
  const [page, setPage] = useState(0);

  const rowsPerPage = 6;

  useEffect(() => {
    setPage(0);
  }, [listaUsuariosFiltrados]);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const getNumPages = () =>
    Math.ceil(
      (listaUsuariosFiltrados && listaUsuariosFiltrados.length) / rowsPerPage
    ) - 1;

  const completarItens = (nItems) => {
    const list = [];
    for (let i = 0; i < nItems; i++) {
      const index = i + rowsPerPage - nItems;
      list.push(
        <LinhaVaziaTabelaPermissaoUsuario
          key={index}
          numeroColunas={listaPermissoes.length + 1}
        />
      );
    }
    return list;
  };

  const ordenarPorNome = () => {
    const filtroPorLogin = listaUsuarios.sort((a, b) =>
      a.Login.localeCompare(b.Login)
    );

    setListaUsuarios(deepCopy(filtroPorLogin));
  };

  const ordenarPorColunaPermissao = (permissionGroupId) => {
    const listaUsuariosComPermissaoSelecionada = listaUsuarios.filter(
      (element) => element.PermissionGroupId === permissionGroupId
    );
    const listaUsuariosComPermissaoNaoSelecionada = listaUsuarios.filter(
      (element) => element.PermissionGroupId !== permissionGroupId
    );

    const listaFinal = listaUsuariosComPermissaoSelecionada.concat(
      listaUsuariosComPermissaoNaoSelecionada
    );

    setListaUsuarios(deepCopy(listaFinal));
  };

  const renderizarColunas = () => {
    const header = [];
    let index = 0;

    listaPermissoes.forEach((element) => {
      header.push(
        <TableCell key={index++}>
          <ReactHoverObserver className={classes.containerReactHover}>
            {({ isHovering }) => (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                onClick={() =>
                  ordenarPorColunaPermissao(element.PermissionGroupId)
                }
              >
                <Typography
                  align="center"
                  variant="h5"
                  className={
                    isHovering ? classes.labelColunaHover : classes.labelColuna
                  }
                >
                  {element.GroupName}
                </Typography>
                <ChevronDown
                  size={14}
                  className={isHovering ? classes.iconHover : undefined}
                />
              </Grid>
            )}
          </ReactHoverObserver>
        </TableCell>
      );
    });

    return header;
  };

  return (
    <>
      <Table>
        <TableHead>
          {listaUsuariosFiltrados && listaUsuariosFiltrados.length > 0 && (
            <TableRow>
              <TableCell className={classes.tableCellLogin}>
                <ReactHoverObserver className={classes.containerReactHover}>
                  {({ isHovering }) => (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      onClick={() => ordenarPorNome()}
                    >
                      <Typography
                        align="left"
                        variant="h5"
                        className={`${classes.labelColuna} ${
                          isHovering && classes.labelColunaHover
                        }`}
                      >
                        Login
                      </Typography>
                      <ChevronDown
                        size={14}
                        className={isHovering ? classes.iconHover : undefined}
                      />
                    </Grid>
                  )}
                </ReactHoverObserver>
              </TableCell>
              {renderizarColunas()}
              <TableCell />
            </TableRow>
          )}
        </TableHead>
        {!carregando &&
          listaUsuariosFiltrados &&
          listaUsuariosFiltrados.length > 0 && (
            <TableBody>
              {listaUsuariosFiltrados
                .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                .map((usuario) => (
                  <LinhaTabelaPermissaoUsuario
                    usuario={usuario}
                    listaUsuarios={listaUsuarios}
                    setListaUsuarios={setListaUsuarios}
                    permissaoUsuarioDaLista={listaPermissoes.find(
                      (element) =>
                        element.PermissionGroupId === usuario.PermissionGroupId
                    )}
                    operadorLogado={{
                      EhOperadorLogado:
                        usuario.Sub &&
                        usuario.Sub === usuarioLogado.Usuario.Sub,
                      PermissionGroupId: usuarioLogado.Permissao,
                    }}
                    listaPermissoes={listaPermissoes}
                    key={usuario.Login}
                  />
                ))}
              {completarItens(
                Math.abs(
                  listaUsuariosFiltrados.slice(
                    page * rowsPerPage,
                    (page + 1) * rowsPerPage
                  ).length - rowsPerPage
                )
              )}
            </TableBody>
          )}
        {showFooter && (
          <TableFooter data-testid="table-footer-permissao-usuario">
            <TableRow>
              <TablePagination
                classes={{ caption: classes.caption, toolbar: classes.toolbar }}
                count={listaUsuariosFiltrados.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                page={page}
                onPageChange={handleChangePage}
                backIconButtonProps={{
                  cursor: 'pointer',
                  style: {
                    cursor: 'pointer',
                    marginRight: '16px',
                    color: page === 0 ? GRAY : DARK_CYAN,
                  },
                  'data-testid': 'previous-page-button-table-permissao-usuario',
                }}
                nextIconButtonProps={{
                  style: {
                    cursor: 'pointer',
                    marginLeft: '16px',
                    color: page === getNumPages() ? GRAY : DARK_CYAN,
                  },
                  'data-testid': 'next-page-button-table-permissao-usuario',
                }}
                labelDisplayedRows={(obj) =>
                  `${obj.from}-${obj.to} de ${obj.count}`
                }
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
      {!carregando &&
        listaUsuariosFiltrados &&
        listaUsuariosFiltrados.length === 0 && (
          <Grid container className={classes.dadosNaoEncontrados}>
            <DadosNaoEncontrados chaveamentoEstadoVazio={2} />
          </Grid>
        )}
    </>
  );
};
export default TabelaPermissaoUsuario;
