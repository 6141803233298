import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { BACKGROUND_COLOR } from '../../theme';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: BACKGROUND_COLOR,
    paddingBottom: '0',
    overflowX: 'auto',
    minHeight: 'calc(100vh - 48px)',
    marginTop: '48px',
  },
}));

const Conteudo = (props) => {
  const classes = useStyles();
  const { conteudo } = props;

  return (
    <Grid container className={classes.content}>
      {conteudo}
    </Grid>
  );
};

// eslint-disable-next-line react/jsx-props-no-spreading
export default withRouter((props) => <Conteudo {...props} />);
