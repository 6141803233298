import axios from 'axios';
import AxiosWrapper from '../shared/util/AxiosWrapper';
import { handleRequestError } from '../shared/util/RequestUtil';

const header = { Pragma: 'no-cache' };

const axiosInstance = axios.create({
  headers: header,
  baseURL: process.env.REACT_APP_AUTH_API,
});

export default new AxiosWrapper(axiosInstance, {
  defaultErrorHandler: handleRequestError,
});
