import React from 'react';
import { Typography, Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { WHITE, DARK_GRAY_OP_1 } from '../../theme';

const useStyles = makeStyles({
  box: {
    backgroundColor: (props) => props.corBackground,
    borderRadius: '4px',
    width: 'fit-content',
    padding: '5px 5px',
    display: 'inline-block',
    margin: '0px 4px',
  },
  sigla: {
    fontSize: '14px',
    color: (props) => props.corSigla,
  },
  customTooltip: {
    color: WHITE,
    backgroundColor: DARK_GRAY_OP_1,
  },
});

export default function TagStatus(props) {
  const { status, mostrarTooltip } = props;

  const classes = useStyles(props);

  return (
    <Tooltip
      title={mostrarTooltip ? status : ''}
      classes={{
        tooltip: classes.customTooltip,
      }}
    >
      <Box className={classes.box} data-testid="box-tag-status-component">
        <Typography variant="h6" align="left" className={classes.sigla}>
          {status[0]}
        </Typography>
      </Box>
    </Tooltip>
  );
}
