import React from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import DefaultTooltip from '../../../../shared/componentes/DefaultTooltip';

const NodeContainer = (props) => {
  const {
    titulo,
    valorPrimario,
    valorSecundario,
    icon,
    color,
    tooltip,
    className,
  } = props;

  const classContainer = `${className} treeNodeContainer ${color}Background`;
  const classIcon = `${color}`;
  const classprimary = `h3 ${color}`;
  const classNodeName = `subtitle1 ${color}`;
  const classsecondary = `subtitle2 ${color}`;

  const tooltipContent = (
    <Grid container direction="column" alignItems="flex-start">
      {tooltip &&
        tooltip.map((entry, index) => (
          <Typography variant="h5" className="mt-2" key={index}>
            {entry}
          </Typography>
        ))}
    </Grid>
  );

  return (
    <Grid container direction="column" alignItems="center">
      {(tooltip && (
        <DefaultTooltip
          color={color}
          title={titulo}
          place="right"
          content={tooltipContent}
          identifier={JSON.stringify(tooltip)}
        >
          <Box pt={1} pb={1} borderRadius="8px" className={classContainer}>
            <Grid container justifyContent="center" className={classIcon}>
              {icon}
            </Grid>
            <Typography className={classprimary} align="center">
              {' '}
              {valorPrimario}{' '}
            </Typography>
            <Typography className={classNodeName} align="center">
              {' '}
              {titulo}{' '}
            </Typography>
            <Typography className={classsecondary} align="center">
              {' '}
              {valorSecundario}{' '}
            </Typography>
          </Box>
        </DefaultTooltip>
      )) || (
        <Box pt={1} pb={1} borderRadius="8px" className={classContainer}>
          <Grid container justifyContent="center" className={classIcon}>
            {icon}
          </Grid>
          <Typography className={classprimary} align="center">
            {' '}
            {valorPrimario}{' '}
          </Typography>
          <Typography className={classNodeName} align="center">
            {' '}
            {titulo}{' '}
          </Typography>
          <Typography className={classsecondary} align="center">
            {' '}
            {valorSecundario}{' '}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default NodeContainer;
