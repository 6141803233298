import React from 'react';
import {
  PhoneCall,
  UserCheck,
  Repeat,
  XCircle,
  PhoneMissed,
  Copy,
  AlertCircle,
  Check,
  Trash2,
} from 'react-feather';
import { iconesNotificacao } from '../../../shared/util/Constantes';

const IconeNotificacao = (props) => {
  const { chave, color, defaultIcon } = props;

  const icones = {};
  icones[iconesNotificacao.LigacaoIniciada] = PhoneCall;
  icones[iconesNotificacao.AtendimentoFinalizado] = UserCheck;
  icones[iconesNotificacao.ErroPadrao] = XCircle;
  icones[iconesNotificacao.TentarNovamente] = Repeat;
  icones[iconesNotificacao.ErroLigacao] = PhoneMissed;
  icones[iconesNotificacao.CopiaFeita] = Copy;
  icones[iconesNotificacao.AlertaPadrao] = AlertCircle;
  icones[iconesNotificacao.Exclusao] = Trash2;
  icones[iconesNotificacao.MarcaVerificacao] = Check;

  let Icone = icones[chave];

  if (!Icone) {
    Icone = icones[defaultIcon];
  }

  return Icone ? <Icone color={color} /> : <></>;
};

export default IconeNotificacao;
