import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Inbox, Plus, Search } from 'react-feather';
import { connect } from 'react-redux';
import CardTitle from '../../../shared/componentes/CardTitle';
import DadosNaoEncontrados from '../../../shared/componentes/DadosNaoEncontrados';
import LoadingDashboard from '../../../shared/componentes/LoadingDashboard';
import { hierarquiaPermissoesUsuarios } from '../../../shared/util/Constantes';
import { deepCopy, normalizeText } from '../../../shared/util/Utils';
import {
  DARK_CYAN,
  DARK_CYAN_HOVER,
  GRAY,
  LIGHT_SEA_GREEN_HOVER,
  WHITE,
} from '../../../theme';
import CriarCampanhaWhatsApp from '../../criarCampanha/views/whatsapp/CriarCampanhaWhatsApp';
import ModalOpcaoCriarCampanha from '../../criarCampanha/views/ModalOpcaoCriarCampanha';
import GestaoCampanhasWhatsappManager from '../GestaoCampanhasWhatsappManager';
import TabelaCampanhasWhatsapp from './TabelaCampanhasWhatsapp';

const useStyles = makeStyles({
  containerGestaoCampanhas: {
    minHeight: '620px',
    position: 'relative',
  },
  botaoAplicar: {
    backgroundColor: DARK_CYAN,
    height: '45px',
    width: '210px',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: LIGHT_SEA_GREEN_HOVER,
    },
  },
  textoBotaoAplicar: {
    fontSize: '14px',
    color: WHITE,
  },
  botaoRepositorioArquivos: {
    height: '45px',
    width: '260px',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: DARK_CYAN_HOVER,
    },
    marginRight: '32px',
  },
  textoBotaoRepositorioArquivos: {
    color: DARK_CYAN,
    fontSize: '18px',
  },
  searchField: {
    width: 'calc(100% - 450px)',
    minWidth: '50px',
    maxWidth: '500px',
  },
  iconeScape: {
    marginRight: '15px',
    cursor: 'pointer',
  },
  containerTitulo: {
    marginBottom: '-24px',
  },
  dadosNaoEncontrados: {
    height: '79%',
  },
});

const GestaoCampanhas = (props) => {
  const { filtroTela, login } = props;

  const classes = useStyles();
  const [carregando, setCarregando] = useState(false);
  const [busca, setBusca] = useState('');
  const [listaCampanhas, setListaCampanhas] = useState([]);
  const [listaCampanhasFiltradas, setListaCampanhasFiltradas] = useState([]);
  const [modalCriarCampanha, setModalCriarCampanha] = useState(false);
  const [modalOpcaoCriarCampanha, setModalOpcaoCriarCampanha] = useState(false);
  const [criarCampanhaUpload, setCriarCampanhaUpload] = useState(false);
  const [possuiPermissaoCriarCampanha, setPossuiPermissaoCriarCampanha] =
    useState(false);

  const abrirModalCampanha = () => {
    setModalOpcaoCriarCampanha(true);
  };

  useEffect(() => {
    const getData = async () => {
      setCarregando(true);
      const data = await GestaoCampanhasWhatsappManager.listarCampanhas();
      const possuiPermissao =
        login &&
        login.usuarioScape0 &&
        login.usuarioScape0.HierarchyLevel <=
          hierarquiaPermissoesUsuarios.Administrador;
      setPossuiPermissaoCriarCampanha(!!possuiPermissao);
      setListaCampanhas(data);
      setListaCampanhasFiltradas(data);
      setCarregando(false);
    };
    getData();
  }, [login]);

  function attListaItens(bolean) {
    if (bolean) {
      const getData = async () => {
        setCarregando(true);
        const campaignData =
          await GestaoCampanhasWhatsappManager.listarCampanhas();
        setListaCampanhas(campaignData);
        setListaCampanhasFiltradas(campaignData);
        setCarregando(false);
      };
      getData();
    }
  }

  useEffect(() => {
    if (busca && busca.length && listaCampanhas && listaCampanhas.length) {
      const listaFiltrada = listaCampanhas.filter(
        (campanha) =>
          normalizeText(campanha.tituloCampanha).indexOf(
            normalizeText(busca)
          ) !== -1
      );

      setListaCampanhasFiltradas(listaFiltrada);
    } else {
      setListaCampanhasFiltradas(listaCampanhas);
    }
  }, [busca, listaCampanhas]);

  const handleExclusaoCampanha = (idDaCampanha) => {
    for (const campanha of listaCampanhas) {
      if (campanha.idCampanha === idDaCampanha) {
        listaCampanhas.splice(listaCampanhas.indexOf(campanha), 1);
      }
    }
    setListaCampanhas(deepCopy(listaCampanhas));
  };

  return (
    <>
      <Grid
        item
        container
        direction="row"
        alignContent="center"
        justifyContent="space-between"
        className={classes.containerTitulo}
      >
        <CardTitle text="Gestão de Campanhas via Whatsapp" icon={<Inbox />} />
      </Grid>
      <Grid
        container
        item
        direction="column"
        alignContent="flex-start"
        justifyContent="flex-start"
        className={classes.containerGestaoCampanhas}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          wrap="nowrap"
          spacing={4}
        >
          <Grid item container xs={8}>
            <TextField
              fullWidth
              variant="filled"
              value={busca}
              onInput={(e) => {
                setBusca(e.target.value);
              }}
              size="small"
              placeholder="Pesquise por uma campanha"
              InputProps={{
                style: {
                  fontWeight: 'normal',
                  backgroundColor: 'rgba(158, 171, 181, 0.05)',
                  fontSize: '14px',
                },
                endAdornment: <Search size={16} color={GRAY} />,
              }}
            />
          </Grid>

          <Grid item container justifyContent="flex-end" xs={4}>
            {possuiPermissaoCriarCampanha && (
              <Button
                type="submit"
                disableElevation
                startIcon={<Plus size={20} color={WHITE} />}
                className={`${classes.botaoAplicar} "Login-Btn"`}
                onClick={() => abrirModalCampanha()}
              >
                <Typography className={classes.textoBotaoAplicar} variant="h6">
                  Nova Campanha
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
        <TabelaCampanhasWhatsapp
          campanhas={listaCampanhasFiltradas}
          handleExclusaoCampanha={handleExclusaoCampanha}
          filtroTela={filtroTela}
          possuiPermissaoCriarCampanha={possuiPermissaoCriarCampanha}
          busca={busca}
        />
        {(!listaCampanhasFiltradas || listaCampanhasFiltradas.length === 0) && (
          <Grid container className={classes.dadosNaoEncontrados}>
            <DadosNaoEncontrados chaveamentoEstadoVazio={1} />
          </Grid>
        )}

        {modalOpcaoCriarCampanha && (
          <ModalOpcaoCriarCampanha
            modalAberto={modalOpcaoCriarCampanha}
            setModalAberto={setModalOpcaoCriarCampanha}
            setModalCriarCampanha={setModalCriarCampanha}
            setCriarCampanhaUpload={setCriarCampanhaUpload}
          />
        )}
        {modalCriarCampanha && (
          <CriarCampanhaWhatsApp
            attListaItens={attListaItens}
            handleFecharModal={() => {
              setModalCriarCampanha(false);
            }}
            criarCampanhaUpload={criarCampanhaUpload}
          />
        )}
        {carregando && <LoadingDashboard />}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    filtroTela: state.criarCampanha,
    login: state.login,
  };
};
export default connect(mapStateToProps)(GestaoCampanhas);
