import React from 'react';
import { Checkbox, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  itemOpcao: {
    position: 'relative',
    width: '75%',
  },
  container: {
    marginLeft: '0.5px',
    overflowX: 'hidden',
    cursor: 'pointer',
  },
});

export default function DropdownItem(props) {
  const { label, item, handleChange, index } = props;

  const classes = useStyles();

  return (
    <Grid
      spacing={2}
      container
      item
      direction="row"
      justifyContent="flex-start"
      zeroMinWidth
      alignItems="center"
      className={classes.container}
      onClick={() => handleChange(item, index)}
    >
      <Checkbox
        checked={item.checked}
        inputProps={{ 'aria-label': 'primary checkbox' }}
        label={label}
      />
      <Typography noWrap className={classes.itemOpcao} variant="h5">
        {label}
      </Typography>
    </Grid>
  );
}
