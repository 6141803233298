import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Plus, Search } from 'react-feather';
import { normalizeText } from '../../../shared/util/Utils';
import { DARK_CYAN, GRAY, LIGHT_SEA_GREEN_HOVER, WHITE } from '../../../theme';
import ModalAdicionarUsuario from './ModalAdicionarUsuario';
import TabelaPermissaoUsuario from './TabelaPermissaoUsuario';
import FiltroGrupos from './FiltroGrupos';

const useStyles = makeStyles(() => ({
  searchField: {
    width: 'calc(100% - 540px)',
    minWidth: '50px',
    maxWidth: '600px',

    '& .MuiFilledInput-inputMarginDense': {
      paddingTop: '12px',
      paddingBottom: '11px',
    },
  },
  botaoAplicar: {
    backgroundColor: DARK_CYAN,
    height: '42px',
    width: '210px',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: LIGHT_SEA_GREEN_HOVER,
    },
  },
  textoBotaoAplicar: {
    fontSize: '14px',
    color: WHITE,
  },
  containerTextFielButton: {
    maxHeight: '45px',
    overflowY: 'visible',
    marginTop: '20px',
    marginBottom: '10px',
    alignItems: 'flex-start',
  },
}));

const AbaPermissoesUsuario = (props) => {
  const classes = useStyles();

  const {
    busca,
    setBusca,
    carregando,
    usuarioLogado,
    listaPermissoes,
    listaUsuarios,
    setListaUsuarios,
    filtroGruposVisualizacaoDropdown,
    setfiltroGruposVisualizacaoDropdown,
    itensSelecionadosDropdown,
    setItensSelecionadosDropdown,
  } = props;

  const [modalAdicionarUsuarioAberto, setModalAdicionarUsuarioAberto] =
    useState(false);
  const [listaUsuariosFiltrados, setListaUsuariosFiltrados] =
    useState(listaUsuarios);

  useEffect(() => {
    const usuarioTemGrupoVisualizacao = (usuario) => {
      const newListaGruposDropdown = [];
      itensSelecionadosDropdown.forEach((item) =>
        newListaGruposDropdown.push(item.title)
      );
      if (
        itensSelecionadosDropdown.length ===
          filtroGruposVisualizacaoDropdown.length &&
        usuario.VisualizationGroup &&
        !usuario.VisualizationGroup.length
      ) {
        return true;
      }
      if (!usuario.VisualizationGroup || !usuario.VisualizationGroup.length)
        return false;

      return usuario.VisualizationGroup.some((gruposUsuario) =>
        newListaGruposDropdown.includes(gruposUsuario)
      );
    };

    if (busca && busca.length && busca.length > 0 && listaUsuarios) {
      const listaFiltrada = listaUsuarios.filter(
        (element) =>
          normalizeText(element.Login).indexOf(normalizeText(busca)) !== -1
      );

      if (itensSelecionadosDropdown && itensSelecionadosDropdown.length > 0) {
        setListaUsuariosFiltrados(
          listaFiltrada
            .filter((usuario) => usuarioTemGrupoVisualizacao(usuario))
            .sort()
        );
      } else {
        setListaUsuariosFiltrados(listaFiltrada);
      }
    } else if (
      itensSelecionadosDropdown &&
      itensSelecionadosDropdown.length &&
      itensSelecionadosDropdown.length > 0
    ) {
      setListaUsuariosFiltrados(
        listaUsuarios
          .filter((usuario) => usuarioTemGrupoVisualizacao(usuario))
          .sort()
      );
    } else {
      setListaUsuariosFiltrados(listaUsuarios);
    }
  }, [
    busca,
    listaUsuarios,
    filtroGruposVisualizacaoDropdown,
    itensSelecionadosDropdown,
  ]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        display="inline"
        className={classes.containerTextFielButton}
      >
        <TextField
          variant="filled"
          value={busca}
          onInput={(e) => {
            setBusca(e.target.value);
          }}
          size="small"
          placeholder="Pesquise um Nome de Usuário ou Login..."
          InputProps={{
            style: {
              fontWeight: 'normal',
              backgroundColor: 'rgba(158, 171, 181, 0.05)',
              fontSize: '16px',
            },
            endAdornment: <Search size={20} color={GRAY} />,
            'data-testid': 'textfield-modal-permissao-usuario',
          }}
          className={classes.searchField}
        />

        <FiltroGrupos
          carregando={carregando}
          listaItens={filtroGruposVisualizacaoDropdown}
          setlistaItens={setfiltroGruposVisualizacaoDropdown}
          itensSelecionados={itensSelecionadosDropdown}
          setItensSelecionados={setItensSelecionadosDropdown}
          width="270px"
        />

        <Button
          type="submit"
          data-testid="button-adicionar-usuario-permissao-usuario"
          disableElevation
          startIcon={<Plus size={20} color={WHITE} />}
          className={`${classes.botaoAplicar} "Login-Btn"`}
          onClick={() => setModalAdicionarUsuarioAberto(true)}
        >
          <Typography className={classes.textoBotaoAplicar} variant="h6">
            Adicionar Usuário
          </Typography>
        </Button>
      </Grid>
      <TabelaPermissaoUsuario
        listaUsuarios={listaUsuarios}
        setListaUsuarios={setListaUsuarios}
        listaUsuariosFiltrados={listaUsuariosFiltrados}
        listaPermissoes={listaPermissoes}
        usuarioLogado={usuarioLogado}
        showFooter={listaUsuariosFiltrados && listaUsuariosFiltrados.length > 0}
        carregando={carregando}
      />
      {modalAdicionarUsuarioAberto && (
        <ModalAdicionarUsuario
          listaUsuarios={listaUsuarios}
          setListaUsuarios={setListaUsuarios}
          modalAberto={modalAdicionarUsuarioAberto}
          setModalAberto={setModalAdicionarUsuarioAberto}
          listaPermissoes={listaPermissoes}
          usuarioLogado={usuarioLogado}
          opcoesGrupoDeVisualizacao={filtroGruposVisualizacaoDropdown.map(
            (item) => {
              return item.title;
            }
          )}
        />
      )}
    </>
  );
};

export default AbaPermissoesUsuario;
