// Types
export const INSERIR_ALUNO = 'app/campanha/INSERIR_ALUNO';
export const INSERIR_MARCA = 'app/campanha/INSERIR_MARCA';
export const INSERIR_CAMPI = 'app/campanha/INSERIR_CAMPI';
export const INSERIR_CURSO = 'app/campanha/INSERIR_CURSO';
export const INSERIR_MODALIDADE = 'app/campanha/INSERIR_MODALIDADE';
export const INSERIR_TURNO = 'app/campanha/INSERIR_TURNO';
export const INSERIR_PERIODO_LETIVO = 'app/campanha/INSERIR_PERIODO_LETIVO';
export const INSERIR_INGRESSO = 'app/campanha/INSERIR_INGRESSO';
export const INSERIR_SITUACAO = 'app/campanha/INSERIR_SITUACAO';
export const INSERIR_PROBLEMA = 'app/campanha/INSERIR_PROBLEMA';
export const INSERIR_AUXILIAR = 'app/campanha/INSERIR_AUXILIAR';
export const INSERIR_ESTADO_FILTRO_CAMPANHA =
  'app/campanha/INSERIR_ESTADO_FILTRO_CAMPANHA';

// Reducer
export const INITIAL_STATE = {
  aluno: [],
  marca: [],
  campi: [],
  curso: [],
  turno: [],
  ingresso: [],
  debitoFinanceiro: [],
  dataInicioMatricula: [],
  dataFinalMatricula: [],
  modality: [],

  estadoFiltroCampanha: false,
  auxiliar: null,
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case INSERIR_ALUNO:
      return {
        ...state,
        aluno: action.payload,
      };
    case INSERIR_AUXILIAR:
      return {
        ...state,
        auxiliar: action.payload,
      };
    case INSERIR_CURSO:
      return {
        ...state,
        curso: action.payload,
      };
    case INSERIR_CAMPI:
      return {
        ...state,
        campi: action.payload,
      };
    case INSERIR_ESTADO_FILTRO_CAMPANHA:
      return {
        ...state,
        estadoFiltroCampanha: action.payload,
      };
    case INSERIR_INGRESSO:
      return {
        ...state,
        ingresso: action.payload,
      };
    case INSERIR_MARCA:
      return {
        ...state,
        marca: action.payload,
      };
    case INSERIR_MODALIDADE:
      return {
        ...state,
        modalidade: action.payload,
      };
    case INSERIR_PERIODO_LETIVO:
      return {
        ...state,
        periodoLetivo: action.payload,
      };
    case INSERIR_PROBLEMA:
      return {
        ...state,
        problema: action.payload,
      };
    case INSERIR_SITUACAO:
      return {
        ...state,
        situacao: action.payload,
      };
    case INSERIR_TURNO:
      return {
        ...state,
        turno: action.payload,
      };
    default:
      return state;
  }
}

// Action creators
export const atualizarAluno = (aluno) => {
  return {
    type: INSERIR_ALUNO,
    payload: aluno,
  };
};

export const atualizarAuxiliar = (auxiliar) => {
  return {
    type: INSERIR_AUXILIAR,
    payload: auxiliar,
  };
};

export const atualizarCurso = (curso) => {
  return {
    type: INSERIR_CURSO,
    payload: curso,
  };
};

export const atualizarCampus = (campi) => {
  return {
    type: INSERIR_CAMPI,
    payload: campi,
  };
};

export const atualizarEstadoFiltroCampanha = (estado) => {
  return {
    type: INSERIR_ESTADO_FILTRO_CAMPANHA,
    payload: estado,
  };
};

export const atualizarIngresso = (ingresso) => {
  return {
    type: INSERIR_INGRESSO,
    payload: ingresso,
  };
};

export const atualizarMarca = (marca) => {
  return {
    type: INSERIR_MARCA,
    payload: marca,
  };
};

export const atualizarModalidade = (modalidade) => {
  return {
    type: INSERIR_MODALIDADE,
    payload: modalidade,
  };
};

export const atualizarTurno = (turno) => {
  return {
    type: INSERIR_TURNO,
    payload: turno,
  };
};

export const atualizarSituacao = (situacao) => {
  return {
    type: INSERIR_SITUACAO,
    payload: situacao,
  };
};

export const atualizarPeriodo = (periodoLetivo) => {
  return {
    type: INSERIR_PERIODO_LETIVO,
    payload: periodoLetivo,
  };
};

export const atualizarProblema = (problema) => {
  return {
    type: INSERIR_PROBLEMA,
    payload: problema,
  };
};
