import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableFooter,
  TablePagination,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinhaVazia from './LinhaVaziaRepositorioArquivos';
import { DARK_CYAN, GRAY } from '../../../theme';
import RepositorioArquivoTabelaLinha from './RepositorioArquivoTabelaLinha';
import DadosNaoEncontrados from '../../../shared/componentes/DadosNaoEncontrados';

const useStyles = makeStyles({
  caption: {
    color: DARK_CYAN,
  },
  toolbar: {
    paddingRight: '8px',
  },
  iconeFile: {
    width: '5% !important',
  },
  arquivo: {
    width: '56% !important',
  },
  dataExtracao: {
    width: '18% !important',
  },
  dataValidade: {
    width: '18% !important',
  },
  iconeDownload: {
    width: '3% !important',
  },
  dadosNaoEncontrados: {
    marginTop: '5rem',
    marginBottom: '2px',
  },
});

const RepositorioArquivosTabela = (props) => {
  const classes = useStyles();
  const { listaArquivos, showFooter, carregando } = props;
  const [page, setPage] = useState(0);

  const rowsPerPage = 5;

  useEffect(() => {
    setPage(0);
  }, [listaArquivos]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getNumPages = () =>
    Math.ceil((listaArquivos && listaArquivos.length) / rowsPerPage) - 1;

  const completarItens = (nItems) => {
    const list = [];
    for (let i = 0; i < nItems; i++) {
      const index = i + rowsPerPage - nItems;
      list.push(<LinhaVazia key={index} />);
    }
    return list;
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.iconeFile}>
              <Typography align="left" />
            </TableCell>
            <TableCell className={classes.arquivo}>
              <Typography align="left">Arquivo</Typography>
            </TableCell>
            <TableCell className={classes.dataExtracao}>
              <Typography align="center">Data de Extração</Typography>
            </TableCell>
            <TableCell className={classes.dataValidade}>
              <Typography align="center">Validade</Typography>
            </TableCell>
            <TableCell className={classes.iconeDownload}>
              <Typography align="left" />
            </TableCell>
          </TableRow>
        </TableHead>

        {!carregando && listaArquivos && listaArquivos.length > 0 && (
          <TableBody>
            {listaArquivos
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((arquivo) => (
                <RepositorioArquivoTabelaLinha
                  validade={arquivo.TimeToLive}
                  titulo={arquivo.CampaignTitle}
                  dataExtracao={arquivo.DueTimestamp}
                  key={arquivo.Id}
                  urlArquivo={arquivo.FileURL}
                />
              ))}
            {completarItens(
              Math.abs(
                listaArquivos.slice(
                  page * rowsPerPage,
                  (page + 1) * rowsPerPage
                ).length - rowsPerPage
              )
            )}
          </TableBody>
        )}
        {showFooter && (
          <TableFooter data-testid="table-footer-repository">
            <TableRow data-testid="table-row-repository">
              <TablePagination
                data-testid="table-pagination-repository"
                classes={{ caption: classes.caption, toolbar: classes.toolbar }}
                count={listaArquivos ? listaArquivos.length : 0}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                page={page}
                onPageChange={handleChangePage}
                backIconButtonProps={{
                  cursor: 'pointer',
                  style: {
                    cursor: 'pointer',
                    marginRight: '16px',
                    color: page === 0 ? GRAY : DARK_CYAN,
                  },
                }}
                nextIconButtonProps={{
                  style: {
                    cursor: 'pointer',
                    marginLeft: '16px',
                    color: page === getNumPages() ? GRAY : DARK_CYAN,
                  },
                }}
                labelDisplayedRows={(obj) =>
                  `${obj.from}-${obj.to} de ${obj.count}`
                }
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
      {!carregando && listaArquivos && listaArquivos.length === 0 && (
        <Grid container className={classes.dadosNaoEncontrados}>
          <DadosNaoEncontrados chaveamentoEstadoVazio={2} />
        </Grid>
      )}
    </>
  );
};

export default RepositorioArquivosTabela;
