import qs from 'qs';

const fakeAxios = {
  get: async (path) => {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    let mock = [];
    if (path.includes('01_mpe_dropout')) {
      mock = [
        {
          name: 'dropout',
          description: 'Desligamentos',
          primary: '19,19%',
          secondary: '128.0',
          icon: 'react-feather/dist/icons/user-x',
          color: 'red',
          tooltip: ['Teste Tooltip'],
        },
        {
          name: 'dropout_ytd',
          description: 'Desligamentos Refer\u00eancia',
          primary: '22,83%',
          secondary: '141.0',
          icon: '@material-ui/icons/UpdateRounded',
          color: 'yellow',
          tooltip: ['Teste Tooltip 2'],
        },
      ];
    }
    if (path.includes('02_mpe_evolution')) {
      mock = [
        ['2020-08-03', 0.04, 0.05, 0.04],
        ['2020-08-04', 0.04, 0.06, 0.04],
        ['2020-08-05', 0.05, 0.08, 0.04],
        ['2020-08-06', 0.05, 0.09, 0.04],
        ['2020-08-07', 0.05, 0.09, 0.06],
        ['2020-08-08', 0.06, 0.09, 0.07],
        ['2020-08-09', 0.06, 0.1, 0.07],
        ['2020-08-10', 0.06, 0.11, 0.07],
        ['2020-08-11', 0.07, 0.11, 0.08],
        ['2020-08-12', 0.08, 0.12, 0.08],
        ['2020-08-13', 0.08, 0.13, 0.08],
        ['2020-08-14', 0.08, 0.14, 0.09],
        ['2020-08-15', 0.08, 0.14, 0.09],
        ['2020-08-16', 0.1, 0.15, 0.1],
        ['2020-08-17', 0.1, 0.15, 0.1],
        ['2020-08-18', 0.11, 0.17, 0.11],
        ['2020-08-19', 0.12, 0.17, 0.11],
        ['2020-08-20', 0.12, 0.19, 0.12],
        ['2020-08-21', 0.14, 0.19, 0.12],
        ['2020-08-22', 0.14, 0.19, 0.13],
        ['2020-08-23', 0.14, 0.2, 0.13],
        ['2020-08-24', 0.14, 0.2, 0.15],
        ['2020-08-25', 0.14, 0.2, 0.15],
        ['2020-08-26', 0.15, 0.2, 0.16],
        ['2020-08-27', 0.15, 0.21, 0.16],
        ['2020-08-28', 0.16, 0.22, 0.16],
        ['2020-08-29', 0.16, 0.22, 0.18],
        ['2020-08-30', 0.17, 0.22, 0.18],
        ['2020-08-31', 0.18, 0.22, 0.18],
      ];
    }
    if (path.includes('03_mpe_ranking')) {
      mock = [
        {
          course: 'Ci\u00eancias Cont\u00e1beis',
          students: 23.0,
          dropout_rate: 0.30434782608695654,
          students_ytd: 21.0,
          dropout_rate_ytd: 0.42857142857142855,
        },
        {
          course: 'An\u00e1lise e Desenvolvimento de Sistemas',
          students: 14.0,
          dropout_rate: 0.2857142857142857,
          students_ytd: 13.0,
          dropout_rate_ytd: 0.3076923076923077,
        },
        {
          course: 'Nutri\u00e7\u00e3o',
          students: 15.0,
          dropout_rate: 0.26666666666666666,
          students_ytd: 13.0,
          dropout_rate_ytd: 0.38461538461538464,
        },
        {
          course: 'Engenharia Civil',
          students: 23.0,
          dropout_rate: 0.2608695652173913,
          students_ytd: 20.0,
          dropout_rate_ytd: 0.3,
        },
        {
          course: 'Administra\u00e7\u00e3o',
          students: 89.0,
          dropout_rate: 0.25842696629213485,
          students_ytd: 76.0,
          dropout_rate_ytd: 0.35526315789473684,
        },
        {
          course: 'Psicologia',
          students: 56.0,
          dropout_rate: 0.25,
          students_ytd: 55.0,
          dropout_rate_ytd: 0.34545454545454546,
        },
        {
          course: 'Farm\u00e1cia',
          students: 29.0,
          dropout_rate: 0.2413793103448276,
          students_ytd: 28.0,
          dropout_rate_ytd: 0.2857142857142857,
        },
        {
          course: 'Engenharia de Produ\u00e7\u00e3o',
          students: 17.0,
          dropout_rate: 0.23529411764705882,
          students_ytd: 17.0,
          dropout_rate_ytd: 0.23529411764705882,
        },
        {
          course: 'Engenharia Agron\u00f4mica',
          students: 45.0,
          dropout_rate: 0.2222222222222222,
          students_ytd: 41.0,
          dropout_rate_ytd: 0.24390243902439024,
        },
        {
          course: 'Fisioterapia',
          students: 44.0,
          dropout_rate: 0.20454545454545456,
          students_ytd: 41.0,
          dropout_rate_ytd: 0.2682926829268293,
        },
      ];
    }
    if (path.includes('04_mpe_tree')) {
      mock = [
        {
          name: 'student_base',
          description: 'Base de Alunos',
          primary: '100%',
          secondary: '667.0',
          icon: 'react-feather/dist/icons/users',
          color: 'teal',
        },
        {
          name: 'high_risk',
          description: 'Alto Risco',
          primary: '47,5%',
          secondary: '317.0',
          icon: '@material-ui/icons/InfoOutlined',
          color: 'yellow',
        },
        {
          name: 'low_risk',
          description: 'Baixo Risco',
          primary: '52,5%',
          secondary: '350.0',
          icon: '@material-ui/icons/CheckCircleOutlineRounded',
          color: 'blue',
        },
        {
          name: 'high_risk_called',
          description: 'Contatados',
          primary: '28,3%',
          secondary: '189.0',
          icon: 'react-feather/dist/icons/phone-outgoing',
          color: 'blue',
        },
        {
          name: 'high_risk_not_called',
          description: 'Sem Contato',
          primary: '19,2%',
          secondary: '128.0',
          icon: 'react-feather/dist/icons/phone-missed',
          tooltip: ['6.3% N\u00e3o Atenderam', '8.0% Telefone Inv\u00e1lido'],
          color: 'yellow',
        },
        {
          name: 'low_risk_called',
          description: 'Contatados',
          primary: '8,2%',
          secondary: '55.0',
          icon: 'react-feather/dist/icons/phone-outgoing',
          color: 'blue',
        },
        {
          name: 'low_risk_not_called',
          description: 'Sem Contato',
          primary: '44,2%',
          secondary: '295.0',
          icon: 'react-feather/dist/icons/phone-missed',
          tooltip: ['3.5% N\u00e3o Atenderam', '4.0% Telefone Inv\u00e1lido'],
          color: 'yellow',
        },
        {
          name: 'high_risk_called_non_dropout',
          description: 'Ativo',
          primary: '24,1%',
          secondary: '161.0',
          icon: 'react-feather/dist/icons/user-check',
          color: 'teal',
        },
        {
          name: 'high_risk_called_dropout',
          description: 'Desistente',
          primary: '4,2%',
          secondary: '28.0',
          icon: 'react-feather/dist/icons/user-x',
          color: 'red',
        },
        {
          name: 'high_risk_not_called_non_dropout',
          description: 'Ativo',
          primary: '9,3%',
          secondary: '62.0',
          icon: 'react-feather/dist/icons/user-check',
          color: 'teal',
        },
        {
          name: 'high_risk_not_called_dropout',
          description: 'Desistente',
          primary: '9,9%',
          secondary: '66.0',
          icon: 'react-feather/dist/icons/user-x',
          color: 'red',
        },
        {
          name: 'low_risk_called_non_dropout',
          description: 'Ativo',
          primary: '6,4%',
          secondary: '43.0',
          icon: 'react-feather/dist/icons/user-check',
          color: 'teal',
        },
        {
          name: 'low_risk_called_dropout',
          description: 'Desistente',
          primary: '1,8%',
          secondary: '12.0',
          icon: 'react-feather/dist/icons/user-x',
          color: 'red',
        },
        {
          name: 'low_risk_not_called_non_dropout',
          description: 'Ativo',
          primary: '40,9%',
          secondary: '273.0',
          icon: 'react-feather/dist/icons/user-check',
          color: 'teal',
        },
        {
          name: 'low_risk_not_called_dropout',
          description: 'Desistente',
          primary: '3,3%',
          secondary: '22.0',
          icon: 'react-feather/dist/icons/user-x',
          color: 'red',
        },
      ];
    }
    if (path.includes('05_mpe_tree_indexes')) {
      mock = [
        {
          name: 'operation_success',
          description: 'Alunos em alto risco contatados',
          primary: '458.0',
          secondary: '68,7%',
          icon: 'react-feather/dist/icons/phone-outgoing',
          color: 'teal',
        },
        {
          name: 'model_success',
          description: 'Desligamentos antecipados pelo modelo',
          primary: '505.0',
          secondary: '75,8%',
          icon: 'react-feather/dist/icons/eye',
          color: 'teal',
        },
        {
          name: 'joint_dropout_rate_effect',
          description: 'Desligamentos Evitados',
          primary: '8.0',
          secondary: '0%',
          icon: 'react-feather/dist/icons/user-check',
          color: 'blue',
        },
      ];
    }
    if (path.includes('06_clusters')) {
      mock = [
        {
          name: 'freshmen',
          description: 'Cancelamento de Matr\u00edcula',
          primary: '6,6%',
          secondary: '43.0',
          icon: 'react-feather/dist/icons/users',
          color: 'red',
        },
        {
          name: 'finance',
          description: 'Financeiro',
          primary: '4,4%',
          secondary: '29.0',
          icon: '@material-ui/icons/AccountBalanceWalletOutlined',
          color: 'red',
        },
        {
          name: 'struggle',
          description: 'Acad\u00eamico',
          primary: '9,1%',
          secondary: '60.0',
          icon: 'react-feather/dist/icons/book-open',
          color: 'yellow',
        },
        {
          name: 'engagement',
          description: 'Engajamento',
          primary: '0,6%',
          secondary: '4.0',
          icon: 'react-feather/dist/icons/user-x',
          color: 'yellow',
        },
        {
          name: 'commercial_policy',
          description: 'Pol\u00edtica de Desconto',
          primary: '4,4%',
          secondary: '28.0',
          icon: '@material-ui/icons/AccountBalanceWalletOutlined',
          color: 'red',
        },
      ];
    }
    await delay(300);
    return { data: { data: mock } };
  },
};

class DashboardService {
  checkResponse(response) {
    if (response && response.data && response.data.data)
      return response.data.data;
    return undefined;
  }

  async buscarDadosGraficos(payload) {
    const queryStringParams = {
      params: {
        ...payload,
      },
      paramsSerializer: (p) => {
        return qs.stringify(p, { arrayFormat: 'brackets' });
      },
    };
    return fakeAxios
      .get(`/get_chart_data/${payload.chart_name}`, queryStringParams)
      .then(this.checkResponse);
  }
}
export default new DashboardService();
