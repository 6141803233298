import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ClickAwayListener, Grid } from '@material-ui/core';
import { WHITE, SHADOW_DROPDOWN } from '../../theme';
import SimpleDropdownItem from './SimpleDropdownItem';

const useStyles = makeStyles(() => ({
  containerInterno: {
    position: 'absolute',
    zIndex: '1',
    backgroundColor: WHITE,
    width: (props) => props.larguraSimpleDropdown,
    boxShadow: `0 0 18px -1px ${SHADOW_DROPDOWN}`,
    borderRadius: '8px',
    left: (props) => props.positionLeft,
    top: (props) => props.positionTop,
  },
  containerExterno: {
    position: 'relative',
    height: '0px',
    width: '0px',
  },
}));

export default function SimpleDropdown(props) {
  const classes = useStyles(props);
  const {
    mostrarDropdown,
    setMostrarDropdown,
    listaNavegacao,
    setarItemSelecionadoSimpleDropdown,
    handleClickSimpleDropdown,
    fontSizeDescription,
    marginSimpleDropdown,
    fontFamily,
  } = props;
  const basicHandleClickSimpleDropdown = (itemSelecionado) => {
    setarItemSelecionadoSimpleDropdown(itemSelecionado);
    setMostrarDropdown(false);
  };

  return (
    <>
      {mostrarDropdown && (
        <ClickAwayListener onClickAway={() => setMostrarDropdown(false)}>
          <Grid container className={classes.containerExterno}>
            <Grid className={classes.containerInterno}>
              {listaNavegacao.map((item) => (
                <SimpleDropdownItem
                  disable={item.Disable}
                  description={item.Description || item}
                  itemColor={item.Color}
                  key={item.Description || item}
                  handleClickSimpleDropdown={
                    handleClickSimpleDropdown || basicHandleClickSimpleDropdown
                  }
                  fontSizeDescription={fontSizeDescription}
                  marginSimpleDropdown={marginSimpleDropdown || '8px 10px'}
                  fontFamily={fontFamily}
                />
              ))}
            </Grid>
          </Grid>
        </ClickAwayListener>
      )}
    </>
  );
}
