import React, { useEffect, useState } from 'react';
import {
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableFooter,
  TablePagination,
  Grid,
} from '@material-ui/core';
import { ChevronDown } from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';
import { MEDIUM_GRAY, GRAY, DARK_CYAN } from '../../../theme';
import LinhaVazia from './LinhaVazia';
import LinhaMensagens from './LinhaMensagens';

const useStyles = makeStyles({
  table: {
    marginTop: '30px',
  },
  headerFont: {
    fontSize: '12px',
    color: MEDIUM_GRAY,
  },
  iconChevronDown: {
    color: MEDIUM_GRAY,
  },
  colunaTituloMensagem: {
    width: '35%',
  },
  colunaInicioMensagem: {
    width: '50%',
  },
  colunaAcoes: {
    width: '15%',
  },
  caption: {
    color: DARK_CYAN,
  },
});

const TabelaMensagens = (props) => {
  const { mensagens, busca, handleExclusaoMensagem } = props;
  const [page, setPage] = useState(0);

  const rowsPerPage = 8;
  const classes = useStyles();

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const getNumPages = () =>
    Math.ceil((mensagens && mensagens.length) / rowsPerPage) - 1;

  const completarItens = (nItems) => {
    const list = [];
    for (let i = 0; i < nItems; i++) {
      const index = i + rowsPerPage - nItems;
      list.push(<LinhaVazia key={index} />);
    }
    return list;
  };

  useEffect(() => {
    setPage(0);
  }, [busca]);

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.colunaTituloMensagem}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Typography align="left" className={classes.headerFont}>
                Título da Mensagem
              </Typography>
              <ChevronDown size={14} className={classes.iconChevronDown} />
            </Grid>
          </TableCell>
          <TableCell className={classes.colunaInicioMensagem}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Typography align="left" className={classes.headerFont}>
                Início da Mensagem
              </Typography>
              <ChevronDown size={14} className={classes.iconChevronDown} />
            </Grid>
          </TableCell>
          <TableCell className={classes.colunaTituloMensagem}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Typography className={classes.headerFont}>Ações</Typography>
              <ChevronDown size={14} className={classes.iconChevronDown} />
            </Grid>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {mensagens &&
          mensagens
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((item, index) => (
              <LinhaMensagens
                handleExclusao={handleExclusaoMensagem}
                mensagem={item}
                lineNum={index}
                key={index}
              />
            ))}
        {mensagens && mensagens.length
          ? completarItens(
              Math.abs(
                mensagens.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .length - rowsPerPage
              )
            )
          : null}
      </TableBody>

      {mensagens && mensagens.length > 0 && (
        <TableFooter>
          <TableRow>
            <TablePagination
              classes={{ caption: classes.caption }}
              count={mensagens.length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              page={page}
              onPageChange={handleChangePage}
              backIconButtonProps={{
                cursor: 'pointer',
                style: {
                  cursor: 'pointer',
                  marginRight: '16px',
                  color: page === 0 ? GRAY : DARK_CYAN,
                },
                'data-testid': 'previous-page-button',
              }}
              nextIconButtonProps={{
                style: {
                  cursor: 'pointer',
                  marginLeft: '16px',
                  color: page === getNumPages() ? GRAY : DARK_CYAN,
                },
                'data-testid': 'next-page-button',
              }}
              labelDisplayedRows={(obj) =>
                `${obj.from}-${obj.to} de ${obj.count}`
              }
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

export default TabelaMensagens;
