import FiltroDesempenhoService from '../../services/FiltroDesempenhoService';
import { opcoesFiltroDesempenho } from '../../shared/util/Constantes';
import * as FiltroStore from './Store';

class FiltroManager {
  async buscarFiltros() {
    return FiltroDesempenhoService.buscarFiltros();
  }

  async getCsvURL(filtro) {
    const payload = {
      EndTimestamp: filtro.dataFinal
        ? filtro.dataFinal.setHours(23, 59, 59)
        : null,
      StartTimestamp: filtro.dataInicial
        ? filtro.dataInicial.setHours(0, 0, 0)
        : null,
      OperatorId: filtro.atendente,
      CampaignId: filtro.campanha,
      Status: filtro.situacao,
      ProblemCategoriesId: filtro.problema,
      RemediesId: filtro.remedio,
      OperatorName: filtro.atendenteNome,
      CampaignName: filtro.campanhaNome,
      ProblemCategoriesName: filtro.problemaNome,
      RemediesName: filtro.remedioNome,
    };
    return FiltroDesempenhoService.getCsvURL(payload);
  }

  atualizarFiltro(lista, descricao) {
    return async (dispatch) => {
      if (descricao === opcoesFiltroDesempenho.Atendente) {
        await dispatch(FiltroStore.atualizarAtendente(lista));
      } else if (descricao === opcoesFiltroDesempenho.Campanha) {
        await dispatch(FiltroStore.atualizarCampanha(lista));
      } else if (descricao === opcoesFiltroDesempenho.Situacao) {
        await dispatch(FiltroStore.atualizarSituacao(lista));
      } else if (descricao === opcoesFiltroDesempenho.CategoriaProblema) {
        await dispatch(FiltroStore.atualizarProblema(lista));
      } else if (descricao === opcoesFiltroDesempenho.Remedio) {
        await dispatch(FiltroStore.atualizarRemedio(lista));
      }
    };
  }
}

export default new FiltroManager();
