import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
} from '@material-ui/core';
import { XCircle } from 'react-feather';
import Loading from '../../../shared/componentes/Loading';
import {
  WHITE,
  BLACK,
  DARK_CYAN,
  DARK_GRAY,
  LIGHT_TOMATO,
  TOMATO,
  FONT_FAMILY_REGULAR,
} from '../../../theme';
import { validarEmail } from '../../../shared/util/Utils';
import ConfiguracoesUsuarioManager from '../ConfiguracoesUsuarioManager';
import FiltroGrupos from './FiltroGrupos';

const useStyles = makeStyles(() => ({
  botaoEsquerdo: {
    color: DARK_GRAY,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px',
  },
  titulo: {
    color: DARK_CYAN,
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '25px',
  },
  botaoDireito: {
    color: DARK_CYAN,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px',
  },
  backgroundProps: {
    background: 'rgba(192,198,214,0.4)',
  },
  modal: {
    maxWidth: '400px',
    backgroundColor: WHITE,
    boxShadow: '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
    borderRadius: '8px',
    minWidth: '350px',
  },
  containerConteudoDialog: {
    padding: '20px 15px 20px 15px',
  },
  descricao: {
    fontSize: '16px',
    color: BLACK,
    fontFamily: FONT_FAMILY_REGULAR,
  },
  inputEmail: {
    minWidth: '100%',
    marginTop: '10px',
    marginBottom: '35px',
  },
  inputEmailComErro: {
    minWidth: '100%',
    marginTop: '10px',
    marginBottom: '0',
    backgroundColor: LIGHT_TOMATO,
  },
  avisoFormatoEmailCorreto: {
    display: 'none',
  },
  avisoFormatoEmailIncorreto: {
    display: 'block',
    color: TOMATO,
    marginTop: '5px',
    marginBottom: '10px',
  },
  radioButtons: {
    padding: '2px 2px 2px 2px',
    marginLeft: '48px',
  },
  radioGroup: {
    margin: '10px 0',
  },
  containerGroup: {
    minWidth: '100%',
    marginTop: '9px',
    marginBottom: '22px',
  },
}));

const ModalAdicionarUsuario = (props) => {
  const classes = useStyles();

  const {
    modalAberto,
    setModalAberto,
    listaPermissoes,
    usuarioLogado,
    listaUsuarios,
    setListaUsuarios,
    opcoesGrupoDeVisualizacao,
  } = props;

  const getDefaultValue = () => {
    const arrayDePermissoes = [];
    listaPermissoes.map((permissao) =>
      arrayDePermissoes.push(permissao.HierarchyLevel)
    );
    const menorNivelHierarquico = Math.max(...arrayDePermissoes);
    const idPermissao = listaPermissoes.find(
      (element) => element.HierarchyLevel === menorNivelHierarquico
    );
    return idPermissao.PermissionGroupId;
  };

  const [carregando, setCarregando] = useState(false);
  const [permissionGroupIdNovoUsuario, setpermissionGroupIdNovoUsuario] =
    useState(getDefaultValue());
  const [emailUsuario, setEmailUsuario] = useState('');
  const [
    listaGruposVisualizacaoSelecionado,
    setListaGruposVisualizacaoSelecionado,
  ] = useState([]);
  const [listaGruposVisualizacao, setListaGruposVisualizacao] = useState([]);

  const handlepermissionGroupIdNovoUsuario = (event) => {
    setpermissionGroupIdNovoUsuario(event.target.value);
  };

  const handleBotaoAdicionar = async () => {
    setCarregando(true);

    const listaGrupos = listaGruposVisualizacaoSelecionado.map((item) => {
      return item.title;
    });
    const resp = await ConfiguracoesUsuarioManager.adicionarUsuario(
      emailUsuario,
      permissionGroupIdNovoUsuario,
      listaGrupos
    );

    if (resp) {
      const novaLista = [
        {
          Login: emailUsuario,
          OperatorId: emailUsuario.replaceAll('@', '-').replaceAll('.', '-'),
          PermissionGroupId: permissionGroupIdNovoUsuario,
          VisualizationGroup: listaGrupos,
          Sub: null,
        },
        ...listaUsuarios,
      ];

      setListaUsuarios(novaLista);
    }
    setCarregando(false);
    setModalAberto(!resp);
  };

  useEffect(() => {
    if (modalAberto) {
      const listaItens = [];
      for (const grupoVisualizacao of opcoesGrupoDeVisualizacao) {
        listaItens.push({
          title: grupoVisualizacao,
          id: grupoVisualizacao,
          checked: false,
        });
      }
      setListaGruposVisualizacao(listaItens);
    }
  }, [modalAberto, opcoesGrupoDeVisualizacao]);

  return (
    <Dialog
      open={modalAberto}
      aria-labelledby="Adicionar Novo Usuário"
      aria-describedby="Modal de adicição de novos usuários"
      scroll="body"
      classes={{ paper: classes.modal }}
      BackdropProps={{
        classes: {
          root: classes.backgroundProps,
        },
      }}
    >
      {carregando && <Loading />}
      <DialogContent>
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.containerConteudoDialog}
        >
          <Typography className={classes.titulo}>
            Adicionar Novo Usuário
          </Typography>
          <Grid>
            <Typography className={classes.descricao}>
              1 - Preencha o campo de e-mail para cadastrar um novo usuário.
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <TextField
              error={!!validarEmail(emailUsuario)}
              autoFocus
              variant="filled"
              size="small"
              placeholder=" usuario@email.com.br"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {validarEmail(emailUsuario) && <XCircle color={TOMATO} />}
                  </InputAdornment>
                ),
                style: {
                  fontWeight: 'normal',
                  fontSize: '16px',
                  height: '48px',
                  '&:focus': {
                    backgroundColor: 'rgba(14,167,137,0.05)',
                  },
                },
                'data-testid': 'input-adicionar-usuario',
              }}
              className={
                validarEmail(emailUsuario)
                  ? classes.inputEmailComErro
                  : classes.inputEmail
              }
              value={emailUsuario}
              onChange={(e) => setEmailUsuario(e.target.value)}
            />
          </Grid>
          <Grid>
            <Typography
              className={
                validarEmail(emailUsuario)
                  ? classes.avisoFormatoEmailIncorreto
                  : classes.avisoFormatoEmailCorreto
              }
            >
              Formato de e-mail Inválido
            </Typography>
          </Grid>
          <Grid>
            <Typography className={classes.descricao}>
              2 - Selecione um tipo de Perfil para o Usuário.
            </Typography>
          </Grid>
          <Grid
            container
            className={classes.radiopermissionGroupIdNovoUsuarios}
          >
            <RadioGroup
              aria-labelledby="tipo-usuario"
              name="tipo-usuario"
              defaultValue={permissionGroupIdNovoUsuario}
              onChange={handlepermissionGroupIdNovoUsuario}
              className={classes.radioGroup}
            >
              {listaPermissoes.map(
                (permissao) =>
                  usuarioLogado.Permissao.HierarchyLevel <=
                    permissao.HierarchyLevel && (
                    <FormControlLabel
                      key={permissao.PermissionGroupId}
                      value={permissao.PermissionGroupId}
                      control={
                        <Radio
                          className={classes.radioButtons}
                          inputProps={{
                            'data-testid': `${permissao.GroupName}-radio-button-form-control`,
                          }}
                        />
                      }
                      label={permissao.GroupName}
                    />
                  )
              )}
            </RadioGroup>
            <Grid>
              <Typography className={classes.descricao}>
                3 - Caso necessário, selecione os Grupos de Vizualização que
                este Usuário possui acesso.
              </Typography>
            </Grid>
            <Grid className={classes.containerGroup}>
              <FiltroGrupos
                carregando={false}
                listaItens={listaGruposVisualizacao}
                setlistaItens={setListaGruposVisualizacao}
                itensSelecionados={listaGruposVisualizacaoSelecionado}
                setItensSelecionados={setListaGruposVisualizacaoSelecionado}
                width="336px"
                showOnTop
                top="-42px"
                placeholder="Selecione o(s) Grupo(s) de Visualização"
                iconeChevronDown
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Button
              className={classes.botaoEsquerdo}
              onClick={() => {
                setModalAberto(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={
                emailUsuario === '' ? true : !!validarEmail(emailUsuario)
              }
              className={classes.botaoDireito}
              onClick={() => handleBotaoAdicionar()}
            >
              Adicionar
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAdicionarUsuario;
