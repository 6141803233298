import GestaoCampanhaService from '../../services/GestaoCampanhaService';
import { formatarLista } from '../../shared/util/ListasFiltro';
import {
  formatarDataParaString,
  formatarTimestampParaData,
} from '../../shared/util/Utils';
import {
  DARK_CYAN,
  LIGHT_CYAN,
  LIGHT_ORANGE,
  LIGHT_TOMATO,
  ORANGE,
  TOMATO,
} from '../../theme';

const timestampHoursAndMinutes = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
};

const setTagColor = (tagStatus) => {
  if (tagStatus === 'Ativa') return [LIGHT_CYAN, DARK_CYAN];
  if (tagStatus === 'Expirada') return [LIGHT_TOMATO, TOMATO];
  return [LIGHT_ORANGE, ORANGE];
};
class GestaoCampanhasManager {
  montarListaIES(filtro) {
    const lista = [];
    if (filtro.BrandNames) {
      lista.push(formatarLista(filtro.BrandNames, 'Marca', 'marca'));
    }

    if (filtro.CampusNames) {
      lista.push(formatarLista(filtro.CampusNames, 'Campus', 'campi'));
    }

    if (filtro.CourseNames) {
      lista.push(formatarLista(filtro.CourseNames, 'Curso', 'curso'));
    }

    if (filtro.Shift) {
      lista.push(formatarLista(filtro.Shift, 'Turno', 'turno'));
    }

    if (filtro.Modality) {
      lista.push(formatarLista(filtro.Modality, 'Modality', 'modality'));
    }

    return lista;
  }

  montarListaAluno(filtro) {
    const lista = [];

    if (filtro.StudentType) {
      lista.push(formatarLista([filtro.StudentType], 'Perfil Aluno', 'aluno'));
    }

    if (filtro.HasFinancialDebt !== null && filtro.HasFinancialDebt === true) {
      lista.push(
        formatarLista(
          ['Possui debito financeiro'],
          'DebitoFinanceiro',
          'debitoFinanceiro'
        )
      );
    } else if (
      filtro.HasFinancialDebt !== null &&
      filtro.HasFinancialDebt === false
    ) {
      lista.push(
        formatarLista(
          ['Nao possui debito financeiro'],
          'DebitoFinanceiro',
          'debitoFinanceiro'
        )
      );
    }

    if (filtro.EnrollmentType) {
      lista.push(formatarLista(filtro.EnrollmentType, 'Ingresso', 'ingresso'));
    }

    if (filtro.StartRegistrationDate) {
      lista.push(
        formatarLista(
          [formatarTimestampParaData(filtro.StartRegistrationDate)],
          'DataInicioMatricula',
          'dataInicioMatricula'
        )
      );
    }

    if (filtro.EndRegistrationDate) {
      lista.push(
        formatarLista(
          [formatarTimestampParaData(filtro.EndRegistrationDate)],
          'DataFinalMatricula',
          'dataFinalMatricula'
        )
      );
    }
    return lista;
  }

  async listarCampanhas() {
    const response = await GestaoCampanhaService.buscarListaCampanhas();
    if (response && response.length && response.length > 0) {
      return response.map((campanha) => {
        let listaIES = [];
        let listaAluno = [];

        const filtroFinal = campanha.FilterData;

        if (filtroFinal) {
          listaIES = this.montarListaIES(filtroFinal);
          listaAluno = this.montarListaAluno(filtroFinal);
        }
        const [tagStatusColor, tagCaractereColor] = setTagColor(
          campanha.Status
        );

        const mensagensWhatsapp = [];

        if (
          campanha.WhatsappTriggerData &&
          campanha.WhatsappTriggerData.length &&
          campanha.WhatsappTriggerData.length > 0
        ) {
          campanha.WhatsappTriggerData.forEach((mensagem) => {
            mensagensWhatsapp.push({
              gatilho: mensagem.TriggerMoment,
              idMensagem: mensagem.WhatsappMessageId,
              organization: mensagem.WhatsappOrganization,
            });
          });
        }

        return {
          tagStatus: campanha.Status,
          tituloCampanha: campanha.Title,
          idCampanha: campanha.CampaignId,
          categoria: campanha.Category,
          totalAlunos: campanha.TotalRecipients,
          totalAtendidos: campanha.FinalizedCalls,
          totalEmAtend: campanha.RecipientsAwaiting,
          totalAguardando: campanha.RecipientsAwaiting,
          totalEvasao: parseFloat((campanha.EvasionPercents * 100).toFixed(2)),
          dataInicio: formatarTimestampParaData(campanha.StartTimestamp * 1000),
          dataTermino: formatarTimestampParaData(campanha.EndTimestamp * 1000),
          horaInicio: timestampHoursAndMinutes(campanha.StartTimestamp * 1000),
          horaTermino: timestampHoursAndMinutes(campanha.EndTimestamp * 1000),
          descricaoCampanha: campanha.Description,
          tagStatusColor,
          tagCaractereColor,
          listaIES,
          listaAluno,
          filtro: filtroFinal,
          modeloRisco: campanha.RiskType,
          grupoVisualizacao: campanha.VisualizationGroup,
          prioridade: campanha.PriorityLevel,
          mensagensWhatsapp,
        };
      });
    }
  }

  async downloadDadosCampanha(idCampanha) {
    return GestaoCampanhaService.downloadDadosCampanha(idCampanha);
  }

  async excluirCampanha(idCampanha) {
    return GestaoCampanhaService.excluirCampanha(idCampanha);
  }

  async alterarStatusCampanha(idCampanha, option, EndTimestamp) {
    return GestaoCampanhaService.alterarStatusCampanha(idCampanha, option, {
      EndTimestamp,
    });
  }
}

export const buscarListaRepositorioArquivos = async () => {
  const resp = await GestaoCampanhaService.buscarListaRepositorioArquivos();

  if (!resp) return [];

  resp.sort((a, b) => (a.DueTimestamp < b.DueTimestamp ? 1 : -1));

  resp.forEach((element) => {
    element.Id = element.DueTimestamp;

    element.DueTimestamp = formatarDataParaString(
      element.DueTimestamp,
      true
    ).replace(':', 'h');

    if (!element.FileURL) {
      element.TimeToLive = 'Extraindo dados...';
      element.FileURL = '';
      return;
    }

    element.TimeToLive = formatarDataParaString(
      element.TimeToLive,
      true
    ).replace(':', 'h');
  });

  return resp;
};

export const buscarInformacaoModalCampanha = async (idCampanha) => {
  const resposta = await GestaoCampanhaService.buscarCampanha(idCampanha, {
    StudentWillReceiveEndCampaignMessage: true,
    StudentReceivedEndCampaignMessage: true,
  });

  if (resposta) {
    return {
      mensagemFimDeCampanhaQueReceberao:
        resposta.StudentWillReceiveEndCampaignMessage ?? 0,
      mensagemFimDeCampanhaRecebida:
        resposta.StudentReceivedEndCampaignMessage ?? 0,
    };
  }
};

export default new GestaoCampanhasManager();
