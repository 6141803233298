export default class AxiosCache {
  constructor() {
    this.data = {};
    window.cacheTeste = this;
  }

  put(key, value) {
    this.data[key] = value;
  }

  get(key) {
    return this.data[key];
  }

  getKey(url, method, params) {
    // eslint-disable-next-line no-use-before-define
    return `${method} ${url}${stringifyQueryString(params)}`;
  }
}

function stringifyQueryString(params) {
  if (!params) return '';
  const querystringParts = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const p in params) {
    if (params[p] !== null && params[p] !== undefined) {
      querystringParts.push(`${p}=${params[p]}`);
    }
  }

  if (querystringParts.length === 0) return '';

  querystringParts.sort();
  return `?${querystringParts.join('&')}`;
}
