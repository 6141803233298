import GestaoCampanhaService from '../../services/GestaoCampanhaService';
import { formatarLista } from '../../shared/util/ListasFiltro';
import { formatarTimestampParaData } from '../../shared/util/Utils';
import {
  DARK_CYAN,
  LIGHT_CYAN,
  LIGHT_ORANGE,
  LIGHT_TOMATO,
  ORANGE,
  TOMATO,
} from '../../theme';

const definirStatusCampanha = (campanha) => {
  if (campanha && !campanha.TimeToLive && campanha.RecipientsAwaiting > 0)
    return 'Enviando';
  if (campanha && !campanha.TimeToLive && campanha.RecipientsAwaiting === 0)
    return 'Encerrada';
  return 'Programada';
};

const setTagColor = (campanha) => {
  if (definirStatusCampanha(campanha) === 'Enviando')
    return [LIGHT_CYAN, DARK_CYAN];
  if (definirStatusCampanha(campanha) === 'Encerrada')
    return [LIGHT_TOMATO, TOMATO];
  return [LIGHT_ORANGE, ORANGE];
};

class GestaoCampanhasWhatsappManager {
  montarListaIES(filtro) {
    const lista = [];
    if (filtro.BrandNames) {
      lista.push(formatarLista(filtro.BrandNames, 'Marca', 'marca'));
    }

    if (filtro.CampusNames) {
      lista.push(formatarLista(filtro.CampusNames, 'Campus', 'campi'));
    }

    if (filtro.CourseNames) {
      lista.push(formatarLista(filtro.CourseNames, 'Curso', 'curso'));
    }

    if (filtro.Shift) {
      lista.push(formatarLista(filtro.Shift, 'Turno', 'turno'));
    }

    if (filtro.Modality) {
      lista.push(formatarLista(filtro.Modality, 'Modality', 'modality'));
    }

    return lista;
  }

  montarListaAluno(filtro) {
    const lista = [];

    if (filtro.StudentType) {
      lista.push(formatarLista([filtro.StudentType], 'Perfil Aluno', 'aluno'));
    }

    if (filtro.HasFinancialDebt !== null && filtro.HasFinancialDebt === true) {
      lista.push(
        formatarLista(
          ['Possui debito financeiro'],
          'DebitoFinanceiro',
          'debitoFinanceiro'
        )
      );
    } else if (
      filtro.HasFinancialDebt !== null &&
      filtro.HasFinancialDebt === false
    ) {
      lista.push(
        formatarLista(
          ['Nao possui debito financeiro'],
          'DebitoFinanceiro',
          'debitoFinanceiro'
        )
      );
    }

    if (filtro.EnrollmentType) {
      lista.push(formatarLista(filtro.EnrollmentType, 'Ingresso', 'ingresso'));
    }

    if (filtro.StartRegistrationDate) {
      lista.push(
        formatarLista(
          [formatarTimestampParaData(filtro.StartRegistrationDate)],
          'DataInicioMatricula',
          'dataInicioMatricula'
        )
      );
    }

    if (filtro.EndRegistrationDate) {
      lista.push(
        formatarLista(
          [formatarTimestampParaData(filtro.EndRegistrationDate)],
          'DataFinalMatricula',
          'dataFinalMatricula'
        )
      );
    }
    return lista;
  }

  async listarCampanhas() {
    const response = await GestaoCampanhaService.buscarListaCampanhasWhatsapp();
    if (response && response.length && response.length > 0) {
      return response.map((campanha) => {
        let listaIES = [];
        let listaAluno = [];

        const filtroFinal = campanha.FilterData;

        if (filtroFinal) {
          listaIES = this.montarListaIES(filtroFinal);
          listaAluno = this.montarListaAluno(filtroFinal);
        }
        const [tagStatusColor, tagCaractereColor] = setTagColor(campanha);

        return {
          tagStatus: definirStatusCampanha(campanha),
          tituloCampanha: campanha.Title,
          idCampanha: campanha.SK,
          dataInicio: campanha.TimeToLive
            ? formatarTimestampParaData(campanha.TimeToLive * 1000)
            : formatarTimestampParaData(campanha.ExpiredTimestamp * 1000),
          categoria: campanha.Category,
          totalAlunos: campanha.TotalRecipients,
          totalRecebidos: campanha.RecievedRecipients || 0,
          totalAguardando: campanha.RecipientsAwaiting,
          totalEvasao:
            parseFloat(
              (
                (campanha.EvadedRecipients / campanha.TotalRecipients) *
                100
              ).toFixed(2)
            ) || 0,
          listaIES,
          listaAluno,
          filtro: filtroFinal,
          marcaWhatsapp: campanha.WhatsappOrganization,
          idMensagemWhatsapp: campanha.WhatsappMessageId,
          tagStatusColor,
          tagCaractereColor,
        };
      });
    }
  }

  async excluirCampanha(idCampanha) {
    return GestaoCampanhaService.excluirCampanhaWhatsapp(idCampanha);
  }
}

export default new GestaoCampanhasWhatsappManager();
