/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TableRow,
  TableCell,
  Grid,
  Box,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { connect } from 'react-redux';
import {
  Filter,
  AlignLeft,
  Edit3,
  Users,
  Phone,
  PhoneCall,
  MoreVertical,
} from 'react-feather';
import ReactTooltip from 'react-tooltip';
import { exibirNotificacaoSucesso } from '../../notificacao/Store';
import IconePrioridade from '../../../shared/componentes/IconePrioridade';
import TooltipFiltro from '../../../shared/componentes/TooltipFiltro';
import TooltipMensagem from '../../../shared/componentes/TooltipMensagem';
import TooltipMensagemMultiple from '../../../shared/componentes/TooltipMensagemMultiple';
import ModalConfirmacao from '../../../shared/componentes/ModalConfirmacao';

import {
  DARK_CYAN,
  GRAY,
  DARK_GRAY_OP_1,
  WHITE,
  LIGHT_CYAN,
  TOMATO,
  LIGHT_TOMATO,
  BLACK,
  FONT_FAMILY_BOLD,
} from '../../../theme';
import {
  informacoesModais,
  iconesNotificacao,
  mappingPrioridades,
} from '../../../shared/util/Constantes';
import SimpleDropdown from '../../../shared/componentes/SimpleDropdown';
import GestaoCampanhasManager from '../GestaoCampanhasManager';
import ModalExclusaoCampanha from '../../../shared/componentes/ModalExclusaoCampanha';
import EditarCampanhas from '../../edicaoCampanhas/views/EditarCampanha';
import ModalAlterarStatusCampanha from './ModalAlterarStatusCampanha';
import { deepCopy } from '../../../shared/util/Utils';

const useStyles = makeStyles(() => ({
  row: {
    height: '50px',
  },
  firstColumn: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '300px',
    maxWidth: '300px',
  },
  reactTooltip: {
    padding: '0px !important',
    opacity: '100% !important',
  },
  boxTagStatus: {
    backgroundColor: (props) => props.tagStatusColor,
    borderRadius: '4px',
    width: 'fit-content',
    padding: '6px 8px',
  },
  iconFields: {
    marginRight: '7px',
  },
  siglaTagStatus: {
    fontSize: '14px',
    color: (props) => props.tagCaractereColor,
  },
  customTooltip: {
    color: WHITE,
    backgroundColor: DARK_GRAY_OP_1,
  },
  extractionDropdown: {
    fontSize: '12px',
    width: 'fit-content',
  },
  statusPrioridade: {
    display: 'flex',
  },
  iconeGrupoVisualizacao: {
    color: DARK_GRAY_OP_1,
  },
}));

const LinhaCampanha = (props) => {
  const {
    idCampanha,
    lineNum,
    filtroCampanha,
    filtroTela,
    tituloCampanha,
    totalAlunos,
    totalAtendidos,
    totalAguardando,
    totalEvasao,
    dataInicio,
    dataTermino,
    horaInicio,
    horaTermino,
    descricaoCampanha,
    listaIES,
    listaAluno,
    tagStatus,
    categoria,
    notificarSucesso,
    handleExclusao,
    modeloRisco,
    handleAlterarStatusCampanha,
    handleEdicaoCampanha,
    tagStatusColor,
    tagCaractereColor,
    possuiPermissaoCriarCampanha,
    prioridade,
    grupoVisualizacao,
    mensagensWhatsapp,
  } = props;
  const classes = useStyles(props);

  const listaNavegacao = [
    {
      Description: 'Extrair Dados para o Repositório',
      Type: ['Programada', 'Ativa', 'Expirada'],
      Permission: false,
      Color: BLACK,
    },
    {
      Description: 'Ativar Campanha',
      Type: ['Programada'],
      Permission: true,
      Color: DARK_CYAN,
    },
    {
      Description: 'Expirar Campanha',
      Type: ['Ativa'],
      Permission: true,
      Color: TOMATO,
    },
    {
      Description: 'Reativar Campanha',
      Type: ['Expirada'],
      Permission: true,
      Color: DARK_CYAN,
    },
    {
      Description: 'Editar Campanha',
      Type: ['Programada', 'Ativa', 'Expirada'],
      Permission: true,
      Color: BLACK,
    },
    {
      Description: 'Excluir Campanha',
      Type: ['Programada'],
      Permission: true,
      Color: TOMATO,
    },
  ];

  const [mostrarDropdown, setMostrarDropdown] = useState(false);
  const [abrirModalConfirmacaoExtracao, setAbrirModalConfirmacaoExtracao] =
    useState(false);
  const [abrirModalExclusaoCampanha, setAbrirModalExclusaoCampanha] =
    useState(false);
  const [abrirEdicaoCampanha, setAbrirEdicaoCampanha] = useState(false);
  const [abrirModalOpcoesCampanha, setAbrirModalOpcoesCampanha] =
    useState(false);

  const downloadCampanha = async () => {
    const success = await GestaoCampanhasManager.downloadDadosCampanha(
      idCampanha
    );
    if (success === null) {
      setAbrirModalConfirmacaoExtracao(false);
      notificarSucesso(
        'Dados extraídos para o Repositório de Arquivos com sucesso',
        iconesNotificacao.AtendimentoFinalizado
      );
    }
  };

  const obterListaNavegacao = () => {
    let listaFinal = deepCopy(listaNavegacao);

    if (!possuiPermissaoCriarCampanha) {
      listaFinal = listaNavegacao.filter(
        (element) => !element.Permission && element
      );
      return listaFinal;
    }

    listaFinal.forEach((element) => {
      if (element.Type.includes(tagStatus) && element) {
        element.Disable = false;
      } else {
        element.Disable = true;
      }
    });

    return listaFinal;
  };

  const handleClickSimpleDropdown = (itemSelecionado) => {
    if (itemSelecionado === 'Extrair Dados para o Repositório') {
      setMostrarDropdown(false);
      setAbrirModalConfirmacaoExtracao(true);
    }
    if (itemSelecionado === 'Excluir Campanha') {
      setMostrarDropdown(false);
      setAbrirModalExclusaoCampanha(true);
    }
    if (itemSelecionado === 'Editar Campanha') {
      setMostrarDropdown(false);
      setAbrirEdicaoCampanha(true);
    }
    if (
      itemSelecionado === 'Ativar Campanha' ||
      itemSelecionado === 'Reativar Campanha' ||
      itemSelecionado === 'Expirar Campanha'
    ) {
      setMostrarDropdown(false);
      setAbrirModalOpcoesCampanha(true);
    }
  };

  const converterStatus = () => {
    if (tagStatus === 'Expirada')
      return {
        status: 'Reativar',
        caractereColor: DARK_CYAN,
        backgroundColor: LIGHT_CYAN,
      };
    if (tagStatus === 'Programada')
      return {
        status: 'Ativar',
        caractereColor: DARK_CYAN,
        backgroundColor: LIGHT_CYAN,
      };
    return {
      status: 'Expirar',
      caractereColor: TOMATO,
      backgroundColor: LIGHT_TOMATO,
    };
  };

  return (
    <>
      <TableRow
        hover
        className={classes.row}
        data-testid="table-row-linha-campanha-gestao"
      >
        <TableCell>
          <Grid container className={classes.statusPrioridade}>
            <Tooltip
              title={mappingPrioridades[prioridade]}
              classes={{
                tooltip: classes.customTooltip,
              }}
            >
              <Box>
                <IconePrioridade prioridade={prioridade} />
              </Box>
            </Tooltip>
            <Tooltip
              title={tagStatus}
              classes={{
                tooltip: classes.customTooltip,
              }}
            >
              <Box className={classes.boxTagStatus}>
                <Typography
                  variant="h6"
                  align="left"
                  className={classes.siglaTagStatus}
                  data-testid="tipografia-status-tooltip"
                >
                  {tagStatus[0]}
                </Typography>
              </Box>
            </Tooltip>
          </Grid>
        </TableCell>

        <TableCell className={classes.firstColumn}>
          {tituloCampanha && tituloCampanha.length > 40 && (
            <ReactTooltip
              id={`${lineNum}tit`}
              backgroundColor="white"
              type="light"
              className={classes.reactTooltip}
              arrowColor="rgba(0, 0, 0, 0)"
            >
              <TooltipMensagem
                titulo="Título da Campanha"
                mensagem={tituloCampanha}
                icone={<Edit3 size={20} color={DARK_CYAN} />}
              />
            </ReactTooltip>
          )}
          <Typography
            data-tip
            data-for={`${lineNum}tit`}
            noWrap
            variant="body1"
            data-testid="tipografia-titulo-campanha"
          >
            {tituloCampanha}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="body1"
            align="left"
            data-testid="tipografia-categoria"
          >
            {categoria}
          </Typography>
        </TableCell>

        <TableCell>
          <Grid direction="row" alignItems="center" container>
            <Users color={GRAY} size={20} className={classes.iconFields} />
            <Typography
              variant="body1"
              align="left"
              data-testid="tipografia-total-alunos"
            >
              {totalAlunos}
            </Typography>
          </Grid>
        </TableCell>

        <TableCell>
          <Grid direction="row" alignItems="center" container>
            <Phone color={GRAY} size={20} className={classes.iconFields} />
            <Typography
              variant="body1"
              align="left"
              data-testid="tipografia-total-atendidos"
            >
              {totalAtendidos}
            </Typography>
          </Grid>
        </TableCell>

        <TableCell>
          <Grid direction="row" alignItems="center" container>
            <PhoneCall color={GRAY} size={20} className={classes.iconFields} />
            <Typography
              variant="body1"
              align="left"
              data-testid="tipografia-total-aguardando"
            >
              {totalAguardando}
            </Typography>
          </Grid>
        </TableCell>

        <TableCell>
          <Typography
            variant="body1"
            align="left"
            data-testid="tipografia-total-evasao"
          >
            {totalEvasao}%
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="body1"
            align="left"
            data-testid="tipografia-data-inicio"
          >
            {dataInicio}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="body1"
            align="left"
            data-testid="tipografia-data-termino"
          >
            {dataTermino}
          </Typography>
        </TableCell>

        <TableCell align="center">
          <ReactTooltip
            id={`${lineNum}filtro`}
            backgroundColor="white"
            type="light"
            className={classes.reactTooltip}
            arrowColor="rgba(0, 0, 0, 0)"
            data-testid="filtro-tooltip-icon"
          >
            <TooltipFiltro
              listaAluno={listaAluno}
              listaIES={listaIES}
              filtroTela={filtroTela}
              data-testid="filtro-tooltip"
            />
          </ReactTooltip>
          <Filter
            data-tip
            data-for={`${lineNum}filtro`}
            size={20}
            color={DARK_GRAY_OP_1}
          />
        </TableCell>
        <TableCell align="center" data-testid="mensagem-tooltip-icon">
          <ReactTooltip
            id={idCampanha}
            backgroundColor="white"
            type="light"
            className={classes.reactTooltip}
            arrowColor="rgba(0, 0, 0, 0)"
            data-testid="mensagem-tooltip-icon"
          >
            <TooltipMensagemMultiple
              titulo="Grupos de Visualização da Campanha"
              mensagem={grupoVisualizacao ?? []}
              data-testid="mensagem-tooltip"
            />
          </ReactTooltip>
          <ErrorOutlineIcon
            data-tip
            data-for={idCampanha}
            size={20}
            className={classes.iconeGrupoVisualizacao}
          />
        </TableCell>
        <TableCell align="center">
          <ReactTooltip
            id={`${lineNum}msg`}
            backgroundColor="white"
            type="light"
            className={classes.reactTooltip}
            arrowColor="rgba(0, 0, 0, 0)"
            data-testid="mensagem-tooltip-icon"
          >
            <TooltipMensagem
              titulo="Mensagem da Campanha"
              mensagem={descricaoCampanha}
              icone={<AlignLeft size={20} color={DARK_CYAN} />}
              data-testid="mensagem-tooltip"
            />
          </ReactTooltip>
          <AlignLeft
            data-tip
            data-for={`${lineNum}msg`}
            size={20}
            color={DARK_GRAY_OP_1}
          />
        </TableCell>

        <TableCell align="center" style={{ paddingBottom: '18px' }}>
          <IconButton
            onClick={() => {
              setMostrarDropdown(true);
            }}
            data-testid="more-actions-button"
          >
            <MoreVertical size={20} />
          </IconButton>
          <SimpleDropdown
            mostrarDropdown={mostrarDropdown}
            setMostrarDropdown={setMostrarDropdown}
            fontFamily={FONT_FAMILY_BOLD}
            positionLeft="-14rem"
            positionTop={
              lineNum >= 4
                ? `-${obterListaNavegacao().length * 50 + 20}px`
                : null
            }
            listaNavegacao={obterListaNavegacao()}
            handleClickSimpleDropdown={handleClickSimpleDropdown}
            larguraSimpleDropdown="16rem"
            fontSizeDescription="body1"
            marginSimpleDropdown="15px"
            data-testid="simple-dropdown-linha"
          />
        </TableCell>
      </TableRow>

      <EditarCampanhas
        idCampanha={idCampanha}
        tituloCampanha={tituloCampanha}
        quantidadeAlunos={totalAlunos}
        statusCampanha={tagStatus}
        descricaoCampanhaOriginal={descricaoCampanha}
        dataInicioOriginal={`${dataInicio} ${horaInicio}`}
        dataTerminoOriginal={`${dataTermino} ${horaTermino}`}
        modalAberto={abrirEdicaoCampanha}
        setModalAberto={setAbrirEdicaoCampanha}
        categoria={categoria}
        situacao={filtroCampanha.HasFinancialDebt}
        perfilAluno={filtroCampanha.StudentType}
        formaDeIngresso={filtroCampanha.EnrollmentType}
        modalidade={filtroCampanha.Modality}
        marca={filtroCampanha.BrandNames}
        campus={filtroCampanha.CampusNames}
        curso={filtroCampanha.CourseNames}
        turno={filtroCampanha.Shift}
        dataInicioMatricula={
          filtroCampanha.StartRegistrationDate
            ? new Date(filtroCampanha.StartRegistrationDate)
            : null
        }
        dataTerminoMatricula={
          filtroCampanha.EndRegistrationDate
            ? new Date(filtroCampanha.EndRegistrationDate)
            : null
        }
        modeloDeRisco={modeloRisco}
        impactoDaCampanhaOriginal={prioridade}
        grupoDeVisualizacaoOriginal={grupoVisualizacao}
        mensagensWhatsapp={mensagensWhatsapp.sort(
          (mensasgemA, mensagemB) => mensasgemA.gatilho - mensagemB.gatilho
        )}
        handleEdicaoCampanha={handleEdicaoCampanha}
      />

      <ModalConfirmacao
        modalAberto={abrirModalConfirmacaoExtracao}
        setModalAberto={setAbrirModalConfirmacaoExtracao}
        tituloModal={informacoesModais.ModalExtracaoDeDados.Titulo}
        textoBotaoDireito={
          informacoesModais.ModalExtracaoDeDados.TextoBotaoDireito
        }
        descricao={`${informacoesModais.ModalExtracaoDeDados.Descricao}${tituloCampanha}${informacoesModais.ModalExtracaoDeDados.SegundaDescricao}`}
        textoBotaoEsquerdo={informacoesModais.TextoBotaoCancelar}
        handleBotaoEsquerdo={() => setAbrirModalConfirmacaoExtracao(false)}
        handleBotaoDireito={() => downloadCampanha()}
      />
      <ModalExclusaoCampanha
        modalAberto={abrirModalExclusaoCampanha}
        setModalAberto={setAbrirModalExclusaoCampanha}
        idCampanha={idCampanha}
        handleExclusao={handleExclusao}
      />
      {abrirModalOpcoesCampanha && (
        <ModalAlterarStatusCampanha
          idCampanha={idCampanha}
          modalAberto={abrirModalOpcoesCampanha}
          setModalAberto={setAbrirModalOpcoesCampanha}
          handleAlterarStatusCampanha={handleAlterarStatusCampanha}
          statusConvertido={converterStatus()}
          statusAtualCampanha={{
            status: tagStatus,
            backgroundColor: tagStatusColor,
            caractereColor: tagCaractereColor,
          }}
          mensagensWhatsapp={mensagensWhatsapp}
        />
      )}
    </>
  );
};

export const mapStateToProps = () => ({});

const mapDispatchToProps = {
  notificarSucesso: exibirNotificacaoSucesso,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinhaCampanha);
