import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from './history';
import loginReducer, {
  INITIAL_STATE as loginInitialState,
} from '../../features/login/Store';
import campanhaReducer, {
  INITIAL_STATE as campanhaInitialState,
} from '../../features/listaDeCampanhas/Store';
import notificacaoReducer, {
  INITIAL_STATE as notificacaoInitialState,
} from '../../features/notificacao/Store';
import atendimentoReducer, {
  INITIAL_STATE as atendimentoInitialState,
} from '../../features/atendimento/Store';
import filtroDesempenhoReducer, {
  INITIAL_STATE as filtroDesempenhoInitialState,
} from '../../features/filtroDesempenho/Store';
import loadingReducer, {
  INITIAL_STATE as loadingInitialState,
} from '../../features/loading/Store';
import homeReducer, {
  INITIAL_STATE as homeInitialState,
} from '../../features/home/Store';
import filtroReducerCockpit, {
  INITIAL_STATE as filtroInitialState,
} from '../../features/cockpit/filtro/Store';
import criarCampanha from '../../features/criarCampanha/Store';
/*
importar reducers a serem utilizados
*/

export const INITIAL_STATE = {
  login: loginInitialState,
  campanha: campanhaInitialState,
  notificacao: notificacaoInitialState,
  filtroDesempenho: filtroDesempenhoInitialState,
  atendimento: atendimentoInitialState,
  loading: loadingInitialState,
  home: homeInitialState,
  filtro: filtroInitialState,
};

export default combineReducers({
  router: connectRouter(history),
  login: loginReducer,
  campanha: campanhaReducer,
  notificacao: notificacaoReducer,
  filtroDesempenho: filtroDesempenhoReducer,
  atendimento: atendimentoReducer,
  loading: loadingReducer,
  home: homeReducer,
  filtro: filtroReducerCockpit,
  criarCampanha,
  /*
  exportar reducers
  */
});
