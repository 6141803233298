import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Search, Trash2 } from 'react-feather';
import Loading from '../../../shared/componentes/Loading';
import { normalizeText, deepCopy } from '../../../shared/util/Utils';
import {
  WHITE,
  BLACK,
  DARK_CYAN,
  DARK_GRAY,
  FONT_FAMILY_BOLD,
  FONT_FAMILY_EXTRABOLD,
  FONT_FAMILY_REGULAR,
  LIGHT_GRAY,
  LIGHT_GREEN,
  GRAY,
  SHADOW_DROPDOWN,
} from '../../../theme';
import ConfiguracoesUsuarioManager from '../../configuracoes/ConfiguracoesUsuarioManager';

const useStyles = makeStyles(() => ({
  containerInternoOpcoes: {
    position: 'absolute',
    zIndex: '1',
    overflowY: 'scroll',
    maxHeight: '252px',
    backgroundColor: WHITE,
    width: '100%',
    boxShadow: `0 0 18px -1px ${SHADOW_DROPDOWN}`,
    borderRadius: '8px',
  },
  containerExternoOpcoes: {
    position: 'relative',
    width: '100%',
    marginBottom: '8px',
    marginTop: '-55px',
  },
  containerExternoUsuariosAdicionados: {
    padding: '15px 12px 12px 12px',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: LIGHT_GRAY,
    borderRadius: '8px',
    marginBottom: '8px',
  },
  containerInternoUsuariosAdicionados: {
    maxHeight: '170px',
    overflowY: 'scroll',
    minHeight: '40px',
  },
  botaoEsquerdo: {
    color: DARK_GRAY,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px',
  },
  titulo: {
    color: DARK_CYAN,
    fontFamily: FONT_FAMILY_EXTRABOLD,
    fontSize: '18px',
    marginBottom: '25px',
  },
  botaoDireito: {
    color: DARK_CYAN,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px',
  },
  backgroundProps: {
    background: 'rgba(192,198,214,0.4)',
  },
  modal: {
    maxWidth: '570px',
    backgroundColor: WHITE,
    boxShadow: '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
    borderRadius: '8px',
    minWidth: '500px',
    minHeight: '300px',
    maxHeight: '530px',
    overflowY: 'visible',
  },
  containerConteudoDialog: {
    padding: '20px 15px 10px 15px',
  },
  descricao: {
    fontSize: '16px',
    color: BLACK,
    fontFamily: FONT_FAMILY_REGULAR,
  },
  descricaoRealcada: {
    fontSize: '16px',
    color: DARK_CYAN,
    fontFamily: FONT_FAMILY_BOLD,
  },
  inputLogin: {
    minWidth: '100%',
    marginTop: '10px',
    marginBottom: '32px',
  },
  opcoesItens: {
    color: DARK_GRAY,
    fontSize: '16px',
    fontFamily: FONT_FAMILY_REGULAR,
    lineHeight: '24px',
  },
}));

const ModalAdicionarMembrosGrupo = (props) => {
  const {
    modalAdicionarMembrosGrupoAberto,
    setModalAdicionarMembrosGrupo,
    listaUsuarios,
    listaUsuariosHierarquiaMenor,
    setListaUsuarios,
    nomeGrupoVisualizacao,
  } = props;

  const [carregando, setCarregando] = useState(false);
  const [busca, setBusca] = useState('');
  const [listaUsuariosEnvio, setListaUsuariosEnvio] = useState([]);
  const [listaOpcoes, setListaOpcoes] = useState(listaUsuariosHierarquiaMenor);
  const [menuOpcoesAberto, setMenuOpcoesAberto] = useState(true);
  const [focoMenuOpcoes, setFocoMenuOpcoes] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (listaUsuariosEnvio) {
      if (busca && busca.length && busca.length > 0) {
        setListaOpcoes(
          listaUsuariosHierarquiaMenor.filter(
            (element) =>
              normalizeText(element.Login).indexOf(normalizeText(busca)) !==
                -1 &&
              !listaUsuariosEnvio.find(
                (user) => user.OperatorId === element.OperatorId
              ) &&
              !element.VisualizationGroup.includes(nomeGrupoVisualizacao)
          )
        );
      } else {
        setListaOpcoes(
          listaUsuariosHierarquiaMenor.filter(
            (element) =>
              !listaUsuariosEnvio.find(
                (user) => user.OperatorId === element.OperatorId
              ) && !element.VisualizationGroup.includes(nomeGrupoVisualizacao)
          )
        );
      }
    } else {
      setListaOpcoes(listaUsuariosHierarquiaMenor);
    }
  }, [
    busca,
    listaUsuariosEnvio,
    listaUsuariosHierarquiaMenor,
    nomeGrupoVisualizacao,
  ]);

  const handleBotaoAdicionar = async () => {
    setCarregando(true);
    const listaEnvioFinal = deepCopy(listaUsuariosEnvio);
    listaEnvioFinal.forEach((usuario) => {
      usuario.VisualizationGroup.push(nomeGrupoVisualizacao);
    });
    const succes = await ConfiguracoesUsuarioManager.adicionarGrupoVisualizacao(
      listaEnvioFinal
    );
    if (succes) {
      const novaListaAposEnvio = [...listaUsuarios];
      listaUsuariosEnvio.forEach((usuario) => {
        novaListaAposEnvio
          .find((element) => element.OperatorId === usuario.OperatorId)
          .VisualizationGroup.push(nomeGrupoVisualizacao);
      });
      setListaUsuarios(novaListaAposEnvio);
      setListaUsuariosEnvio([]);
      setModalAdicionarMembrosGrupo(false);
    }
    setCarregando(false);
  };

  const handleBotaoCancelar = () => {
    setListaUsuariosEnvio([]);
    setModalAdicionarMembrosGrupo(false);
  };

  const handleClickOpcao = (opcao) => {
    const newlistaUsuariosEnvio = [...listaUsuariosEnvio];
    newlistaUsuariosEnvio.push(opcao);
    setListaUsuariosEnvio(newlistaUsuariosEnvio);
  };

  const removerListaUsuariosEnvio = (usuario) => {
    const newlistaUsuariosEnvio = [...listaUsuariosEnvio];
    newlistaUsuariosEnvio.splice(newlistaUsuariosEnvio.indexOf(usuario), 1);
    setListaUsuariosEnvio(newlistaUsuariosEnvio);
  };

  return (
    <Dialog
      open={modalAdicionarMembrosGrupoAberto}
      aria-labelledby="Adicionar Membros"
      aria-describedby="Modal de adição de membros ao grupo de visualização"
      scroll="body"
      classes={{ paper: classes.modal }}
      BackdropProps={{
        classes: {
          root: classes.backgroundProps,
        },
      }}
    >
      {carregando && <Loading />}
      <DialogContent style={{ overflowY: 'visible' }}>
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.containerConteudoDialog}
        >
          <Typography
            className={classes.titulo}
            data-testid="tipografia-titulo"
          >
            Adicionar Membros
          </Typography>
          <Grid>
            <Typography className={classes.descricao} display="inline">
              Utilize o campo abaxio para buscar o login dos usuários que deseja
              adicionar ao grupo{' '}
              <Typography
                className={classes.descricaoRealcada}
                display="inline"
              >
                {nomeGrupoVisualizacao}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <TextField
              autoFocus
              variant="filled"
              size="small"
              placeholder=" usuario-email-com-br"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search color={DARK_CYAN} />
                  </InputAdornment>
                ),
                style: {
                  fontWeight: 'normal',
                  fontSize: '16px',
                  height: '48px',
                  backgroundColor: LIGHT_GREEN,
                },
                'data-testid': 'input-adicionar-membro',
              }}
              className={classes.inputLogin}
              value={busca}
              onChange={(e) => setBusca(e.target.value)}
              onClick={() => setMenuOpcoesAberto(true)}
              onFocus={() => setFocoMenuOpcoes(true)}
              onBlur={() => setFocoMenuOpcoes(false)}
            />
            {menuOpcoesAberto && (
              <ClickAwayListener
                onClickAway={() => {
                  if (!focoMenuOpcoes) {
                    setMenuOpcoesAberto(false);
                  }
                }}
              >
                <Grid container className={classes.containerExternoOpcoes}>
                  <Grid className={classes.containerInternoOpcoes}>
                    {listaOpcoes &&
                      listaOpcoes.length > 0 &&
                      listaOpcoes.map((item) => (
                        <MenuItem
                          key={item.OperatorId}
                          value={item}
                          data-testid="menu-item-usuario"
                          onClick={() => handleClickOpcao(item)}
                          className={classes.opcoesItens}
                        >
                          {item.OperatorId}
                        </MenuItem>
                      ))}
                  </Grid>
                </Grid>
              </ClickAwayListener>
            )}
          </Grid>
          {listaUsuariosEnvio && listaUsuariosEnvio.length > 0 && (
            <Grid
              container
              className={classes.containerExternoUsuariosAdicionados}
            >
              <Typography
                variant="body2"
                style={{ fontFamily: FONT_FAMILY_BOLD }}
              >
                Usuários adicionados
              </Typography>
              <Grid
                container
                className={classes.containerInternoUsuariosAdicionados}
              >
                {listaUsuariosEnvio.map((usuario) => (
                  <Grid
                    container
                    display="inline"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ paddingTop: '10px' }}
                  >
                    <Typography variant="body1">
                      {usuario.OperatorId}
                    </Typography>
                    <Trash2
                      size={20}
                      color={GRAY}
                      cursor="pointer"
                      onClick={() => removerListaUsuariosEnvio(usuario)}
                      data-testid="remover-usuario-da-lista"
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Button
              className={classes.botaoEsquerdo}
              onClick={() => {
                handleBotaoCancelar();
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={listaUsuariosEnvio.length < 1 || focoMenuOpcoes}
              className={classes.botaoDireito}
              onClick={() => handleBotaoAdicionar()}
              data-testid="botao-adicionar-membros"
            >
              Adicionar
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAdicionarMembrosGrupo;
