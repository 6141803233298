import React, { useEffect, useState } from 'react';
import ReactHoverObserver from 'react-hover-observer';
import { connect } from 'react-redux';
import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { ChevronDown, LogOut, Settings, User } from 'react-feather';
import {
  GRAY,
  WHITE,
  LIGHT_GRAY,
  DARK_CYAN,
  DARK_GRAY_OP_1,
} from '../../../theme';
import Logo from '../../../shared/layout/Logo';
import {
  informacoesModais,
  menuNavegacaoDeTelas,
} from '../../../shared/util/Constantes';
import { selecionarCampanha } from '../../listaDeCampanhas/Store';
import { atualizarUserScape0 } from '../../login/Store';
import ModalConfirmacao from '../../../shared/componentes/ModalConfirmacao';
import ModalConfiguracoes from '../../configuracoes/views/ModalConfiguracoes';
import ModalLogout from '../../../shared/componentes/ModalLogout';
import { iniciarSessao } from '../../atendimento/Store';
import { encerrarSessao } from '../../aluno/AlunoManager';
import { atualizarIsLoading } from '../../loading/Store';
import { atualizarAbaSelecionada } from '../../home/Store';
import { LogoutComponent } from '../../login/LoginManager';
import ModalMeuPerfil from '../../perfil/views/ModalMeuPerfil';
import SimpleDropdown from '../../../shared/componentes/SimpleDropdown';
import CabecalhoManager from '../CabecalhoManager';

const useStyles = makeStyles(() => ({
  gridContainer: {
    padding: '0px 32px',
  },
  appBar: {
    position: 'fixed',
    width: '100%',
    justifyContent: 'center',
    boxShadow:
      '0px 1px 1px -2px rgba(0,0,0,0.2), 0px -1px 1px 1px rgba(0,0,0,0.05), 0px 3px 1px 3px rgba(0,0,0,0.01)',
    height: '48px',
    backgroundColor: WHITE,
    zIndex: '10',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    minHeight: '48px',
    padding: 0,
  },
  iconesDeAcoes: {
    float: 'right',
    marginRight: '24px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  containerLogoAnima: {
    width: 'fit-content',
    cursor: 'pointer',
    borderRight: `2px solid ${LIGHT_GRAY}`,
    marginRight: '15px',
  },
  icon: {
    color: (mostrarDropdown) => (mostrarDropdown ? DARK_CYAN : GRAY),
  },
  iconHover: {
    color: DARK_CYAN,
  },
  dropdownTitle: {
    color: (mostrarDropdown) => (mostrarDropdown ? DARK_CYAN : GRAY),
    marginRight: '8px',
  },
  dropdownTitleHover: {
    color: DARK_CYAN,
    marginRight: '8px',
  },
  containerReactHover: {
    alignSelf: 'center',
    cursor: 'pointer',
    width: 'fit-content',
  },
  customTooltip: {
    color: WHITE,
    backgroundColor: DARK_GRAY_OP_1,
  },
  userNameContainer: {
    width: '-webkit-fill-available',
  },
  userNameLabel: {
    marginRight: '15px',
    fontSize: '15px',
    fontWeight: 'bold',
    color: GRAY,
  },
}));

export const CabecalhoUnwrapped = (props) => {
  const [mostrarDropdown, setMostrarDropdown] = useState(false);
  const [abrirModalConfiguracoes, setAbrirModalConfiguracoes] = useState(false);

  const classes = useStyles(mostrarDropdown);
  const location = useLocation();
  const history = useHistory();

  const {
    selecionaCampanha,
    iniciaSessao,
    session,
    atualizaIsLoading,
    login,
    atualizaAbaSelecionada,
    atualizaUserScape0,
  } = props;

  const [modalConfirmarHome, setModalConfirmarHome] = useState(false);
  const [modalLogout, setModalLogout] = useState(false);
  const [listaNavegacao, setListaNavegacao] = useState([
    { Description: menuNavegacaoDeTelas.atendimento },
  ]);
  const [usuarioTemPermissaoConfiguracao, setUsuarioTemPermissaoConfiguracao] =
    useState(false);
  const [abrirModalMeuPerfil, setAbrirModalMeuPefil] = useState(false);

  const email = login && login.usuario && login.usuario.email;

  useEffect(() => {
    const getUsuarioLogado = async () => {
      if (email) {
        const response = await CabecalhoManager.getUsuario(email);
        if (response) {
          atualizaUserScape0(response.data);
        }
      }
    };

    if (login.usuario && Object.keys(login.usuario).length !== 0) {
      getUsuarioLogado();
    }
  }, [atualizaUserScape0, email, login.usuario]);

  useEffect(() => {
    const getData = () => {
      const response = CabecalhoManager.getListaNavegacao(
        login && login.usuarioScape0 && login.usuarioScape0.HierarchyLevel
      );
      setListaNavegacao(response);
    };

    const getUsuarioTemPermissaoConfiguracao = () => {
      const response = CabecalhoManager.possuiPermissaoConfiguracaoUsuarios(
        login && login.usuarioScape0 && login.usuarioScape0.HierarchyLevel
      );
      setUsuarioTemPermissaoConfiguracao(response);
    };

    if (login.usuarioScape0 && Object.keys(login.usuarioScape0).length !== 0) {
      getData();
      getUsuarioTemPermissaoConfiguracao();
    }
  }, [atualizaUserScape0, email, login, login.usuario]);

  const sair = async () => {
    if (session && !session.CallTimestamp) {
      atualizaIsLoading(true);
      await encerrarSessao(session.CampaignId, session.StudentId);
      atualizaIsLoading(false);
    }
    iniciaSessao(null);
    selecionaCampanha(null);
    atualizaAbaSelecionada(0);
    LogoutComponent(login.userManager);
  };

  const goHome = async () => {
    if (!session) {
      iniciaSessao(null);
      selecionaCampanha(null);
      atualizaAbaSelecionada(0);
    } else if (session && session.CallTimestamp) {
      setModalConfirmarHome(true);
    } else if (session && !session.CallTimestamp) {
      atualizaIsLoading(true);
      await encerrarSessao(session.CampaignId, session.StudentId);
      atualizaIsLoading(false);
      iniciaSessao(null);
      selecionaCampanha(null);
      atualizaAbaSelecionada(0);
    }

    if (
      location.pathname.includes('errorPage') ||
      location.pathname.includes('cockpit')
    ) {
      history.push('/');
    }
  };

  const goHomeModal = async () => {
    if (session && !session.CallTimestamp) {
      atualizaIsLoading(true);
      await encerrarSessao(session.CampaignId, session.StudentId);
      atualizaIsLoading(false);
    }
    iniciaSessao(null);
    selecionaCampanha(null);
    atualizaAbaSelecionada(0);
    setModalConfirmarHome(false);
  };

  const doLogout = () => {
    if (location.pathname.includes('errorPage')) {
      history.push('/');
    }
    setModalLogout(true);
  };

  const handleClickSimpleDropdown = (itemSelecionado) => {
    switch (itemSelecionado) {
      case 'Desempenho':
        history.push('/performance');
        break;
      case 'Gestão de Campanhas':
        history.push('/cockpit');
        break;
      case 'Mensagens de WhatsApp':
        history.push('/whatsapp-message');
        break;
      case 'Campanha via WhatsApp':
        history.push('/whatsapp-campaign');
        break;
      default:
        history.push('/');
    }
    setMostrarDropdown(false);
  };

  const getRamal = () => {
    const ramal = login && login.usuarioScape0 && login.usuarioScape0.Ramal;
    return ramal ? `${ramal} | ` : '';
  };

  const getNomeUsuario = () => {
    return (
      login &&
      login.usuario &&
      login &&
      login.usuarioScape0 &&
      login.usuario.name
    );
  };
  return (
    <AppBar position="absolute" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container>
          <Grid
            id="go-home"
            container
            item
            onClick={goHome}
            className={classes.containerLogoAnima}
            data-testid="botao-home-cabecalho"
          >
            <Logo id="logo" />
          </Grid>
          <ReactHoverObserver className={classes.containerReactHover}>
            {({ isHovering }) => (
              <Grid container onClick={() => setMostrarDropdown(true)}>
                <Typography
                  variant="h3"
                  className={
                    isHovering
                      ? classes.dropdownTitleHover
                      : classes.dropdownTitle
                  }
                >
                  {(location.pathname.includes('performance') &&
                    menuNavegacaoDeTelas.desempenho) ||
                    (location.pathname.includes('cockpit') &&
                      menuNavegacaoDeTelas.gestaoDeCampanhas) ||
                    (location.pathname.includes('whatsapp-message') &&
                      menuNavegacaoDeTelas.gestaoMensagensWhatsapp) ||
                    (location.pathname.includes('whatsapp-campaign') &&
                      menuNavegacaoDeTelas.gestaoCampanhasWhatsapp) ||
                    (location.pathname.includes('/') &&
                      menuNavegacaoDeTelas.atendimento)}
                </Typography>
                <ChevronDown
                  className={isHovering ? classes.iconHover : classes.icon}
                />
              </Grid>
            )}
          </ReactHoverObserver>
        </Grid>
        <Grid item className={classes.userNameContainer}>
          <ReactHoverObserver>
            {({ isHovering }) => (
              <Tooltip
                title="Meu Perfil"
                classes={{ tooltip: classes.customTooltip }}
              >
                <IconButton
                  className={classes.iconesDeAcoes}
                  data-testid="botao-meu-perfil-cabecalho"
                  onClick={() => setAbrirModalMeuPefil(true)}
                >
                  <Grid container>
                    <Typography className={classes.userNameLabel}>
                      {getRamal()}
                      {getNomeUsuario()}
                    </Typography>
                    {getNomeUsuario() && (
                      <User color={isHovering ? DARK_CYAN : GRAY} size={20} />
                    )}
                  </Grid>
                </IconButton>
              </Tooltip>
            )}
          </ReactHoverObserver>
        </Grid>
        {usuarioTemPermissaoConfiguracao && (
          <ReactHoverObserver>
            {({ isHovering }) => (
              <Grid item>
                <Tooltip
                  title="Configurações"
                  classes={{ tooltip: classes.customTooltip }}
                >
                  <IconButton
                    className={classes.iconesDeAcoes}
                    data-testid="botao-configuracoes-cabecalho"
                    onClick={() => setAbrirModalConfiguracoes(true)}
                  >
                    <Settings color={isHovering ? DARK_CYAN : GRAY} size={20} />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </ReactHoverObserver>
        )}
        <ReactHoverObserver>
          {({ isHovering }) => (
            <Grid item>
              <Tooltip
                title="Sair"
                classes={{ tooltip: classes.customTooltip }}
              >
                <IconButton
                  className={classes.iconesDeAcoes}
                  onClick={doLogout}
                  id="logout-button"
                  data-testid="botao-logout-cabecalho"
                >
                  <LogOut color={isHovering ? DARK_CYAN : GRAY} size={20} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </ReactHoverObserver>
        <ModalLogout
          modalAberto={modalLogout}
          tituloModal={informacoesModais.ModalSair.Titulo}
          textoBotaoContinuar={informacoesModais.ModalSair.TextoBotaoContinuar}
          handleBotaoContinuar={() => {
            sair();
          }}
          textoBotaoCancelar={informacoesModais.TextoBotaoCancelar}
          handleBotaoCancelar={() => {
            setModalLogout(false);
          }}
          tipo="Logout"
        />
        <ModalConfirmacao
          modalAberto={modalConfirmarHome}
          setModalAberto={setModalConfirmarHome}
          tituloModal={informacoesModais.ModalAtendimentoEmAndamento.Titulo}
          textoBotaoDireito={
            informacoesModais.ModalAtendimentoEmAndamento.TextoBotaoContinuar
          }
          descricao={informacoesModais.ModalAtendimentoEmAndamento.Descricao}
          segundaDescricao={informacoesModais.SegundaDescricao}
          handleBotaoDireito={goHomeModal}
          textoBotaoEsquerdo={informacoesModais.TextoBotaoCancelar}
          handleBotaoEsquerdo={() => {
            setModalConfirmarHome(false);
          }}
        />
        <ModalConfiguracoes
          modalAberto={abrirModalConfiguracoes}
          handleFecharModal={() => setAbrirModalConfiguracoes(false)}
          login={login}
        />
        <ModalMeuPerfil
          modalAberto={abrirModalMeuPerfil}
          handleFecharModal={() => setAbrirModalMeuPefil(false)}
          login={login}
          atualizaUserScape0={atualizaUserScape0}
        />
      </Toolbar>
      <SimpleDropdown
        mostrarDropdown={mostrarDropdown}
        setMostrarDropdown={setMostrarDropdown}
        positionLeft="3.6rem"
        listaNavegacao={listaNavegacao}
        handleClickSimpleDropdown={handleClickSimpleDropdown}
        larguraSimpleDropdown="18rem"
        fontSizeDescription="body2"
        marginSimpleDropdown="15px"
      />
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  session: state.atendimento.session,
  login: state.login,
});

const mapDispatchToProps = {
  selecionaCampanha: selecionarCampanha,
  iniciaSessao: iniciarSessao,
  atualizaIsLoading: atualizarIsLoading,
  atualizaAbaSelecionada: atualizarAbaSelecionada,
  atualizaUserScape0: atualizarUserScape0,
};

export default connect(mapStateToProps, mapDispatchToProps)(CabecalhoUnwrapped);
