import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ReactDOM from 'react-dom/server';
import GraficosDesempenhoManager from '../../GraficosDesempenhoManager';
import Loading from '../../../../shared/componentes/Loading';
import GraficoLinha from './GraficoLinha';
import TooltipGrafico from './TooltipGrafico';
import { deepCopy } from '../../../../shared/util/Utils';

const useStyles = makeStyles({
  container: {
    height: '410px',
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
  },
});

const criarTooltip = (tempoAtendimento, quantidade) => {
  const tooltip = (
    <TooltipGrafico
      tempoAtendimento={tempoAtendimento}
      quantidade={quantidade}
    />
  );
  return ReactDOM.renderToStaticMarkup(tooltip);
};

const GraficoTempoAtendimento = (props) => {
  const { filtro } = props;

  const [mediaAtendimento, setMediaAtendimento] = useState(null);
  const [carregando, setCarregando] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    async function getGraficoTempoAtendimento() {
      setCarregando(true);
      const response =
        await GraficosDesempenhoManager.listarDesempenhoAtendimento(
          deepCopy(filtro),
          criarTooltip
        );
      setMediaAtendimento(response);
      setCarregando(false);
    }
    getGraficoTempoAtendimento();
  }, [filtro]);

  const calcularMinimoEixoY = (dados) => {
    let retorno = 0;
    if (dados && dados.length > 0) {
      let menorValor = Infinity;
      for (let i = 1; i < dados.length; i++) {
        menorValor = dados[i][2] < menorValor ? dados[i][2] : menorValor;
      }
      retorno = menorValor - 1 > 0 ? menorValor - 1 : 0;
    }
    return retorno;
  };

  const calcularMaximoEixoY = (dados) => {
    let retorno = 1;
    if (dados && dados.length > 0) {
      let maiorValor = 0;
      for (let i = 1; i < dados.length; i++) {
        maiorValor = dados[i][2] > maiorValor ? dados[i][2] : maiorValor;
      }
      retorno = maiorValor + 1;
    }
    return retorno;
  };

  const calcularMaximoEixoX = (dados) => {
    let retorno = 1;
    if (dados && dados.length > 0) {
      let maiorValor = 0;
      for (let i = 1; i < dados.length; i++) {
        maiorValor = dados[i][0] > maiorValor ? dados[i][0] : maiorValor;
      }
      retorno = maiorValor + 1;
    }
    return retorno;
  };

  const calcularMinimoEixoX = (dados) => {
    let retorno = 0;
    if (dados && dados.length > 0) {
      let menorValor = Infinity;
      for (let i = 1; i < dados.length; i++) {
        menorValor = dados[i][0] < menorValor ? dados[i][0] : menorValor;
      }
      retorno = menorValor - 1 > 0 ? menorValor - 1 : 0;
    }
    return retorno;
  };

  return (
    <Grid
      container
      direction="row"
      alignContent="flex-start"
      className={classes.container}
    >
      {carregando && <Loading iconSize="40px" />}
      <GraficoLinha
        dadosGrafico={mediaAtendimento}
        minimoEixoY={calcularMinimoEixoY(mediaAtendimento)}
        maximoEixoY={calcularMaximoEixoY(mediaAtendimento)}
        maximoEixoX={calcularMaximoEixoX(mediaAtendimento)}
        minimoEixoX={calcularMinimoEixoX(mediaAtendimento)}
      />
    </Grid>
  );
};

export default GraficoTempoAtendimento;
