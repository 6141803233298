import { Grid, TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Settings, Users } from 'react-feather';
import {
  DARK_CYAN,
  FONT_FAMILY_EXTRABOLD,
  GRAY,
  LIGHT_SEA_GREEN_HOVER,
} from '../../../theme';

const useStyles = makeStyles(() => ({
  row: {
    height: '50px',
  },
  tableBody: {
    padding: '6px -5px 4px -5px',
  },
  textoGrupo: {
    color: DARK_CYAN,
    fontFamily: FONT_FAMILY_EXTRABOLD,
    padding: '2px 0px',
  },
  textoGerenciar: {
    color: DARK_CYAN,
    fontFamily: FONT_FAMILY_EXTRABOLD,
    '&:hover': {
      color: `${LIGHT_SEA_GREEN_HOVER} !important`,
    },
  },
  textoMembros: {
    paddingLeft: '8px',
  },
  disabledButton: {
    color: `${GRAY} !important`,
  },
  engrenagem: {
    color: DARK_CYAN,
    marginRight: '5px',
  },
  iconeLixeira: {
    cursor: 'pointer',
  },
  iconButton: {
    color: DARK_CYAN,
    cursor: 'pointer',
    '&:disabled': {
      color: GRAY,
    },
  },
  containerClicavel: {
    cursor: 'pointer',
  },
}));

const LinhaTabelaGruposVisualizacao = (props) => {
  const { nomeGrupoVisualizacao, listaUsuarios, setGrupoGerenciado } = props;

  const classes = useStyles();
  const [numberoMembros, setNumeroMembros] = useState();

  useEffect(() => {
    if (nomeGrupoVisualizacao && listaUsuarios && listaUsuarios.length) {
      let membros = 0;

      for (const usuario of listaUsuarios) {
        if (usuario.VisualizationGroup.includes(nomeGrupoVisualizacao)) {
          membros++;
        }
      }
      setNumeroMembros(membros);
    } else {
      setNumeroMembros(0);
    }
  }, [listaUsuarios, nomeGrupoVisualizacao]);

  return (
    <>
      <TableRow
        hover
        className={classes.row}
        data-testid="linha-tabela-grupo-visualizacao"
      >
        <TableCell classes={{ body: classes.tableBody }}>
          <Typography
            variant="body1"
            align="left"
            className={classes.textoGrupo}
          >
            {nomeGrupoVisualizacao.toUpperCase()}
          </Typography>
        </TableCell>

        <TableCell classes={{ body: classes.tableBody }}>
          <Grid
            direction="row"
            alignItems="center"
            container
            justifyContent="center"
          >
            <Users size={20} />
            <Typography
              variant="body1"
              align="left"
              className={classes.textoMembros}
            >
              {numberoMembros}
            </Typography>
          </Grid>
        </TableCell>

        <TableCell classes={{ body: classes.tableBody }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              onClick={() => setGrupoGerenciado(nomeGrupoVisualizacao)}
              className={classes.containerClicavel}
            >
              <Settings size={20} className={classes.engrenagem} />
              <Typography className={classes.textoGerenciar}>
                Gerenciar Membros
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </>
  );
};

export default LinhaTabelaGruposVisualizacao;
