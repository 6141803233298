import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  DialogContent,
  Dialog,
  Typography,
  Grid,
} from '@material-ui/core';
import { File } from 'react-feather';
import { buscarListaRepositorioArquivos } from '../GestaoCampanhasManager';
import { WHITE, DARK_CYAN, SHADOW_MODAL } from '../../../theme';
import CardTitle from '../../../shared/componentes/CardTitle';
import RepositorioArquivosTabela from './RepositorioArquivosTabela';
import Loading from '../../../shared/componentes/Loading';
import CloseIcon from '../../../shared/componentes/CloseIcon';

const useStyles = makeStyles(() => ({
  modal: {
    maxWidth: '1000px',
    height: '450px',
    width: '950px',
    backgroundColor: WHITE,
    boxShadow: `0 2px 10px 0 ${SHADOW_MODAL}`,
    borderRadius: '8px',
  },
  backgroundProps: {
    background: 'rgba(192,198,214,0.4)',
  },
  subTituloRepositorio: {
    color: DARK_CYAN,
    padding: '8px 0',
    marginTop: '8px',
  },
  containerCardCloseIcon: {
    display: 'grid',
    gridTemplateColumns: 'max-content min-content',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: '8px 30px',
  },
  rootDialogContent: {
    '&:first-child': {
      paddingTop: '30px',
    },
  },
}));

const ModalRepositorioArquivos = (props) => {
  const classes = useStyles();

  const { modalAberto, handleFecharModal } = props;

  const [carregando, setCarregando] = useState(false);
  const [listaRepositorioArquivos, setListaRepositorioArquivos] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setCarregando(true);
      const data = await buscarListaRepositorioArquivos();
      setListaRepositorioArquivos(data);
      setCarregando(false);
    };
    if (modalAberto) {
      getData();
    }
  }, [modalAberto]);

  return (
    <Dialog
      open={modalAberto}
      onClose={handleFecharModal}
      aria-labelledby="modal-titulo"
      aria-describedby="modal-descricao"
      scroll="body"
      classes={{ paper: classes.modal }}
      data-testid="modal-repository-dialog"
      BackdropProps={{
        classes: {
          root: classes.backgroundProps,
        },
      }}
    >
      {carregando && <Loading />}
      <DialogContent
        data-testid="dialog-content-repository"
        className={classes.dialogContent}
        classes={{ root: classes.rootDialogContent }}
      >
        <Grid container direction="column" spacing={2}>
          <Grid
            container
            alignItems="center"
            className={classes.containerCardCloseIcon}
          >
            <CardTitle
              text="Repositório de Arquivos - Base de Dados de Campanhas de Atendimento"
              icon={<File />}
            />
            <CloseIcon
              tooltipTitle="Fechar Repositório de Arquivos"
              handleFechar={handleFecharModal}
            />
          </Grid>

          <Typography variant="body1" className={classes.subTituloRepositorio}>
            Últimos Arquivos
          </Typography>

          <RepositorioArquivosTabela
            listaArquivos={listaRepositorioArquivos}
            showFooter={
              listaRepositorioArquivos && listaRepositorioArquivos.length > 0
            }
            carregando={carregando}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalRepositorioArquivos;
