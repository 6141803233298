import {
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { PhoneOutgoing, User } from 'react-feather';
import AbaWhatsappScapeZero from '../../../shared/componentes/AbaWhatsappScapeZero';
import CardTitle from '../../../shared/componentes/CardTitle';
import Contador from '../../../shared/componentes/Contador';
import {
  arrayOpcoesContato,
  listaOpcoesImpactoCampanha,
} from '../../../shared/util/Constantes';
import {
  dividirArrayPorDirecao,
  formatarStringParaData,
  ordenarPorParticaoDeHifen,
} from '../../../shared/util/Utils';
import {
  BLACK,
  DARK_CYAN,
  FONT_FAMILY_EXTRABOLD,
  LIGHT_GRAY,
  LIGHT_SEA_GREEN,
  LIGHT_SEA_GREEN_HOVER,
  ORANGE,
  TOMATO,
  TOMATO_HOVER,
  WHITE,
} from '../../../theme';
import EditarCampanhaManager from '../EditarCampanhaManager';
import AbaEdicaoCampanha from './AbaEdicaoCampanha';
import BotoesEdicaoCampanha from './BotoesEdicaoCampanha';
import PreVisualizarEdicao from './PreVisualizarEdicao';

const useStyles = makeStyles(() => ({
  containerInputs: {
    overflowY: 'scroll',
    height: 'calc(90vh - 260px)',
    scrollbarColor: '#bbbbbe white',
    scrollbarWidth: 'thin',
  },
  modal: {
    height: '90vh',
    margin: '5vh',
  },
  scrollModal: {
    overflowY: 'hidden',
  },
  conteudo: {
    height: 'calc(100% - 50px)',
  },
  container: {
    height: '100%',
    width: '100%',
    overflowY: 'hidden',
  },
  containerHeader: {
    marginBottom: '12px',
    paddingRight: '16px',
  },
  titulo: {
    width: '60%',
  },
  containerContador: {
    width: '40%',
  },
  filtro: {
    borderRight: `1px solid ${LIGHT_GRAY}`,
    margin: '4px 18px 12px 0',
    width: '75%',
  },
  containerPreVisualizar: {
    width: '30%',
    marginBottom: '12px',
  },
  backgroundProps: {
    background: 'rgba(0,0,0,0.3)',
  },
  botaoCancelar: {
    backgroundColor: TOMATO,
    color: WHITE,
    height: '100%',
    float: 'right',
    marginRight: '32px',
    '&:hover': {
      backgroundColor: TOMATO_HOVER,
    },
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  botaoAplicar: {
    backgroundColor: LIGHT_SEA_GREEN,
    color: WHITE,
    height: '100%',
    whiteSpace: 'nowrap',
    marginRight: '1.5rem',
    '&:hover': {
      backgroundColor: LIGHT_SEA_GREEN_HOVER,
    },
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  textoBotao: {
    fontSize: '14px',
    color: WHITE,
  },
  textoBotaoCancelar: {
    fontSize: '14px',
    color: WHITE,
    marginTop: '2px',
  },
  textoCabecalho: {
    color: BLACK,
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '20px',
    marginTop: '10px',
  },
  containerAbas: {
    width: '100%',
    marginRight: '10px',
  },
  abaNavegacao: {
    color: DARK_CYAN,
    fontSize: '18px',
    fontFamily: FONT_FAMILY_EXTRABOLD,
  },
  hidden: {
    display: 'none',
  },
}));

const EditarCampanha = (props) => {
  const classes = useStyles();

  const {
    idCampanha,
    tituloCampanha,
    quantidadeAlunos,
    statusCampanha,
    modalAberto,
    setModalAberto,
    descricaoCampanhaOriginal,
    dataInicioOriginal,
    dataTerminoOriginal,
    categoria,
    situacao,
    perfilAluno,
    formaDeIngresso,
    modalidade,
    marca,
    campus,
    curso,
    turno,
    dataInicioMatricula,
    dataTerminoMatricula,
    modeloDeRisco,
    impactoDaCampanhaOriginal,
    grupoDeVisualizacaoOriginal,
    handleEdicaoCampanha,
    mensagensWhatsapp,
  } = props;

  const [orientacoesAtendimento, setOrientacoesAtendimento] = useState(
    descricaoCampanhaOriginal
  );

  const [dataInicioCampanha, setDataInicioCampanha] = useState(
    formatarStringParaData(dataInicioOriginal)
  );
  const [dataTerminoCampanha, setDataTerminoCampanha] = useState(
    formatarStringParaData(dataTerminoOriginal)
  );

  const [dateTimePickerInicio, setDateTimePickerInicio] = useState(false);
  const [dateTimePickerTermino, setDateTimePickerTermino] = useState(false);

  const [impactoDaCampanhaEditado, setImpactoDaCampanhaEditado] = useState('');

  const impactoDaCampanhaInicial =
    listaOpcoesImpactoCampanha.find(
      (item) => item.Id === impactoDaCampanhaOriginal
    )?.Description || '';

  const [listaGruposDeVisualizacao, setListaGruposDeVisualizacao] = useState(
    []
  );
  const [grupoDeVisualizacaoEditado, setGrupoDeVisualizacaoEditado] = useState(
    []
  );

  const [abaSelecionada, setAbaSelecionada] = useState(1);

  const [switchMarca, setSwitchMarca] = useState(false);

  const [primeiraMensagemOriginal, setPrimeiraMensagemOriginal] = useState('');
  const [primeiroContatoOriginal, setPrimeiroContatoOriginal] = useState('');
  const [segundaMensagemOriginal, setSegundaMensagemOriginal] = useState('');
  const [segundoContatoOriginal, setSegundoContatoOriginal] = useState('');
  const [terceiraMensagemOriginal, setTerceiraMensagemOriginal] = useState('');

  const [marcaWhatsapp, setMarcaWhatsapp] = useState('');
  const [marcaWhatsappOriginal, setMarcaWhatsappOriginal] = useState('');
  const [listaMarcasWhatsapp, setListaMarcasWhatsapp] = useState([]);
  const [primeiroContato, setPrimeiroContato] = useState('');
  const [segundoContato, setSegundoContato] = useState('');
  const [listaMensagens, setListaMensagens] = useState([]);
  const [primeiraMensagem, setPrimeiraMensagem] = useState('');
  const [segundaMensagem, setSegundaMensagem] = useState('');
  const [terceiraMensagem, setTerceiraMensagem] = useState('');

  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    function obtemSegundaMensagem(mensagemDoResult) {
      return (
        mensagemDoResult.gatilho !== 0 &&
        mensagemDoResult.gatilho > parseInt(primeiroContatoOriginal.Title, 10)
      );
    }
    function obtemPrimeiraMensagem(mensagemDoResult) {
      return (
        mensagensWhatsapp.find(
          (mensagemDaCampanha) => mensagemDaCampanha.gatilho > 0
        )?.idMensagem === mensagemDoResult.SK
      );
    }
    function obtemTerceiraMensagem(mensagemDoResult) {
      return (
        mensagensWhatsapp.find(
          (mensagemDaCampanha) => mensagemDaCampanha.gatilho === 0
        )?.idMensagem === mensagemDoResult.SK
      );
    }
    const obtemSegundoContato = (contatoUm, contatoDois) => {
      if (contatoUm && !contatoDois) {
        const posicaoContatoUm = arrayOpcoesContato.indexOf(contatoUm);
        if (posicaoContatoUm < 4) {
          return arrayOpcoesContato[4];
        }
        if (posicaoContatoUm < arrayOpcoesContato.length - 1) {
          return arrayOpcoesContato[posicaoContatoUm + 1];
        }
        return '';
      }
      return contatoDois || arrayOpcoesContato[4];
    };
    const definirValoresPadroes = (
      contatoUm,
      contatoDois,
      mensagensDaCampanha,
      listaDeMarcas
    ) => {
      setPrimeiroContato(contatoUm || arrayOpcoesContato[2]);
      setSegundoContato(obtemSegundoContato(contatoUm, contatoDois));
      const marcaDaCampanha =
        mensagensDaCampanha &&
        mensagensDaCampanha.length &&
        listaDeMarcas &&
        listaDeMarcas.length
          ? listaDeMarcas.find(
              (ies) => ies.Title === mensagensDaCampanha[0].organization
            ) || ''
          : '';

      setMarcaWhatsappOriginal(marcaDaCampanha);
      setMarcaWhatsapp(marcaDaCampanha);
      setSwitchMarca(
        mensagensDaCampanha &&
          mensagensDaCampanha.length &&
          mensagensDaCampanha.length > 0 &&
          !marcaDaCampanha
      );
    };

    const buscarDadosIniciaisDaTela = async () => {
      setCarregando(true);
      const resultGruposVisualizacao =
        await EditarCampanhaManager.listarGruposDeVisualizacao();
      if (resultGruposVisualizacao) {
        setListaGruposDeVisualizacao(resultGruposVisualizacao);
      }
      const resultMensagens =
        await EditarCampanhaManager.buscarMensagensWhatsapp();
      let resultMarcas = await EditarCampanhaManager.buscarMarcasWhatsapp();
      let contatoUm = '';
      let contatoDois = '';
      if (resultMensagens) {
        contatoUm =
          arrayOpcoesContato[
            mensagensWhatsapp.find(
              (mensagemDaCampanha) => mensagemDaCampanha.gatilho > 0
            )?.gatilho - 1
          ] || '';

        contatoDois =
          arrayOpcoesContato[
            mensagensWhatsapp.find(obtemSegundaMensagem)?.gatilho - 1
          ] || '';

        setPrimeiroContatoOriginal(contatoUm);

        setSegundoContatoOriginal(contatoDois);

        setPrimeiraMensagemOriginal(
          resultMensagens.find(obtemPrimeiraMensagem) || ''
        );

        setPrimeiraMensagem(resultMensagens.find(obtemPrimeiraMensagem) || '');

        setSegundaMensagemOriginal(
          resultMensagens.find(
            (mensagemDoResult) =>
              mensagensWhatsapp.find(obtemSegundaMensagem)?.idMensagem ===
              mensagemDoResult.SK
          ) || ''
        );

        setSegundaMensagem(
          resultMensagens.find(
            (mensagemDoResult) =>
              mensagensWhatsapp.find(obtemSegundaMensagem)?.idMensagem ===
              mensagemDoResult.SK
          ) || ''
        );

        setTerceiraMensagemOriginal(
          resultMensagens.find(obtemTerceiraMensagem) || ''
        );

        setTerceiraMensagem(resultMensagens.find(obtemTerceiraMensagem) || '');

        setListaMensagens(ordenarPorParticaoDeHifen(resultMensagens));
      }
      if (resultMarcas) {
        resultMarcas.sort();
        resultMarcas = resultMarcas.map((ies) => {
          return { Title: ies };
        });
        setListaMarcasWhatsapp(resultMarcas);
      }
      definirValoresPadroes(
        contatoUm,
        contatoDois,
        mensagensWhatsapp,
        resultMarcas
      );
      setCarregando(false);
    };
    if (modalAberto) {
      buscarDadosIniciaisDaTela();
    }
  }, [
    mensagensWhatsapp,
    primeiroContatoOriginal,
    impactoDaCampanhaOriginal,
    modalAberto,
  ]);

  const obtemMarcaPrevisualizar = () => {
    if (switchMarca) {
      return 'IES do Destinatário';
    }
    if (marcaWhatsapp && marcaWhatsapp?.Title && !switchMarca) {
      return marcaWhatsapp.Title;
    }
    return '';
  };

  const obtemMarcaOriginalPrevisualizar = () => {
    if (
      mensagensWhatsapp &&
      mensagensWhatsapp.length &&
      !(marcaWhatsappOriginal && marcaWhatsappOriginal?.Title)
    ) {
      return 'IES do Destinatário';
    }
    if (marcaWhatsappOriginal && marcaWhatsappOriginal?.Title) {
      return marcaWhatsappOriginal?.Title;
    }
    return '';
  };

  return (
    <>
      <Dialog
        open={modalAberto}
        fullWidth
        maxWidth="lg"
        aria-labelledby="modal-titulo"
        aria-describedby="modal-descricao"
        scroll="body"
        classes={{ paper: classes.modal, scrollBody: classes.scrollModal }}
        BackdropProps={{
          classes: {
            root: classes.backgroundProps,
          },
        }}
      >
        <DialogContent className={classes.container}>
          <Grid container wrap="nowrap" className={classes.conteudo}>
            <Grid
              item
              container
              direction="row"
              alignItems="flex-start"
              alignContent="space-between"
              className={classes.filtro}
            >
              <Grid item container alignItems="flex-start">
                <Grid item container>
                  <Grid container className={classes.containerHeader}>
                    <Grid container className={classes.titulo}>
                      <CardTitle
                        text={tituloCampanha}
                        icon={<PhoneOutgoing />}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="flex-end"
                      className={classes.containerContador}
                    >
                      <Contador
                        icon={<User />}
                        total={quantidadeAlunos}
                        loading={false}
                        afterNumber="Alunos"
                        labelUpperNumber="Alcance"
                        minNumber={0}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Typography
                      component="span"
                      className={classes.textoCabecalho}
                      display="inline"
                    >
                      As edições realizadas são
                      <Typography
                        className={classes.textoCabecalho}
                        style={{ color: ORANGE }}
                        display="inline"
                      >
                        {' '}
                        realçadas{' '}
                      </Typography>{' '}
                      no campo de prévia de campanha ao lado.
                    </Typography>
                  </Grid>
                  <Tabs
                    value={abaSelecionada}
                    onChange={(_event, novoValor) => {
                      setAbaSelecionada(novoValor);
                    }}
                    variant="fullWidth"
                    className={classes.containerAbas}
                  >
                    <Tab
                      value={1}
                      className={classes.abaNavegacao}
                      classes={{
                        wrapper: classes.iconLabelWrapper,
                      }}
                      label="Dados da Campanha"
                    />

                    <Tab
                      value={2}
                      className={classes.abaNavegacao}
                      label="WhatsApp e Orientações"
                    />
                  </Tabs>
                  <Grid
                    item
                    container
                    className={
                      abaSelecionada && abaSelecionada === 1
                        ? classes.containerInputs
                        : classes.hidden
                    }
                  >
                    <AbaEdicaoCampanha
                      statusCampanha={statusCampanha}
                      dataInicioOriginal={dataInicioOriginal}
                      dataTerminoOriginal={dataTerminoOriginal}
                      dataInicioCampanha={dataInicioCampanha}
                      setDataInicioCampanha={setDataInicioCampanha}
                      dataTerminoCampanha={dataTerminoCampanha}
                      setDataTerminoCampanha={setDataTerminoCampanha}
                      dateTimePickerInicio={dateTimePickerInicio}
                      dateTimePickerTermino={dateTimePickerTermino}
                      setDateTimePickerInicio={setDateTimePickerInicio}
                      setDateTimePickerTermino={setDateTimePickerTermino}
                      impactoDaCampanhaOriginal={impactoDaCampanhaInicial}
                      impactoDaCampanhaEditado={impactoDaCampanhaEditado}
                      setImpactoDaCampanha={setImpactoDaCampanhaEditado}
                      listaImpactosCampanha={listaOpcoesImpactoCampanha}
                      grupoDeVisualizacaoOriginal={grupoDeVisualizacaoOriginal}
                      listaGruposDeVisualizacao={listaGruposDeVisualizacao}
                      grupoDeVisualizacaoEditado={grupoDeVisualizacaoEditado}
                      setGrupoDeVisualizacaoEditado={
                        setGrupoDeVisualizacaoEditado
                      }
                      carregando={carregando}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    className={
                      abaSelecionada && abaSelecionada === 2
                        ? classes.containerInputs
                        : classes.hidden
                    }
                  >
                    <AbaWhatsappScapeZero
                      carregando={carregando}
                      marcaWhatsapp={marcaWhatsapp}
                      setMarcaWhatsapp={setMarcaWhatsapp}
                      listaMarcasWhatsapp={listaMarcasWhatsapp}
                      primeiroContato={primeiroContato}
                      setPrimeiroContato={setPrimeiroContato}
                      opcoesPrimeiroContato={dividirArrayPorDirecao(
                        'antes',
                        arrayOpcoesContato,
                        segundoContato?.Title || 11
                      )}
                      opcoesSegundoContato={dividirArrayPorDirecao(
                        'depois',
                        arrayOpcoesContato,
                        primeiroContato.Title
                      )}
                      segundoContato={segundoContato}
                      setSegundoContato={setSegundoContato}
                      listaMensagens={listaMensagens}
                      primeiraMensagem={primeiraMensagem}
                      setPrimeiraMensagem={setPrimeiraMensagem}
                      segundaMensagem={segundaMensagem}
                      setSegundaMensagem={setSegundaMensagem}
                      terceiraMensagem={terceiraMensagem}
                      setTerceiraMensagem={setTerceiraMensagem}
                      orientacoes={orientacoesAtendimento}
                      setOrientacoes={setOrientacoesAtendimento}
                      switchHabilitado={switchMarca}
                      setSwitchHabilitado={setSwitchMarca}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              className={classes.containerPreVisualizar}
            >
              <PreVisualizarEdicao
                titulo={tituloCampanha}
                orientacoesOriginal={descricaoCampanhaOriginal}
                orientacoesEditada={orientacoesAtendimento}
                categoria={categoria}
                situacao={situacao}
                perfilAluno={perfilAluno}
                formaDeIngresso={formaDeIngresso}
                modalidade={modalidade}
                marca={marca}
                campus={campus}
                curso={curso}
                turno={turno}
                dataInicioEditada={dataInicioCampanha}
                dataTerminoEditada={dataTerminoCampanha}
                dataInicioOriginal={dataInicioOriginal}
                dataTerminoOriginal={dataTerminoOriginal}
                dataInicioMatricula={dataInicioMatricula}
                dataTerminoMatricula={dataTerminoMatricula}
                modeloDeRisco={modeloDeRisco}
                impactoDaCampanhaOriginal={impactoDaCampanhaInicial}
                impactoDaCampanhaEditado={impactoDaCampanhaEditado}
                grupoDeVisualizacaoOriginal={grupoDeVisualizacaoOriginal}
                grupoDeVisualizacaoEditado={grupoDeVisualizacaoEditado.map(
                  (item) => {
                    return item.title;
                  }
                )}
                marcaWhatsapp={obtemMarcaPrevisualizar()}
                primeiroContato={primeiroContato.Title}
                segundoContato={segundoContato?.Title || 'X'}
                primeiraMensagem={
                  primeiraMensagem
                    ? `${primeiraMensagem.Message1}\n${primeiraMensagem.Message2}\n${primeiraMensagem.Message3}`
                    : ''
                }
                segundaMensagem={
                  segundaMensagem
                    ? `${segundaMensagem.Message1}\n${segundaMensagem.Message2}\n${segundaMensagem.Message3}`
                    : ''
                }
                terceiraMensagem={
                  terceiraMensagem
                    ? `${terceiraMensagem.Message1}\n${terceiraMensagem.Message2}\n${terceiraMensagem.Message3}`
                    : ''
                }
                marcaWhatsappOriginal={obtemMarcaOriginalPrevisualizar()}
                primeiroContatoOriginal={primeiroContatoOriginal.Title}
                segundoContatoOriginal={segundoContatoOriginal.Title}
                primeiraMensagemOriginal={
                  primeiraMensagemOriginal
                    ? `${primeiraMensagemOriginal.Message1}\n${primeiraMensagemOriginal.Message2}\n${primeiraMensagemOriginal.Message3}`
                    : ''
                }
                segundaMensagemOriginal={
                  segundaMensagemOriginal
                    ? `${segundaMensagemOriginal.Message1}\n${segundaMensagemOriginal.Message2}\n${segundaMensagemOriginal.Message3}`
                    : ''
                }
                terceiraMensagemOriginal={
                  terceiraMensagemOriginal
                    ? `${terceiraMensagemOriginal.Message1}\n${terceiraMensagemOriginal.Message2}\n${terceiraMensagemOriginal.Message3}`
                    : ''
                }
                edicaoCampanha
              />
            </Grid>
          </Grid>
          <BotoesEdicaoCampanha
            idCampanha={idCampanha}
            setModalAberto={setModalAberto}
            descricaoCampanhaOriginal={descricaoCampanhaOriginal}
            orientacoesAtendimento={orientacoesAtendimento}
            setOrientacoesAtendimento={setOrientacoesAtendimento}
            dataInicioCampanha={dataInicioCampanha}
            setDataInicioCampanha={setDataInicioCampanha}
            dataInicioOriginal={dataInicioOriginal}
            dataTerminoOriginal={dataTerminoOriginal}
            setDataTerminoCampanha={setDataTerminoCampanha}
            dataTerminoCampanha={dataTerminoCampanha}
            handleEdicaoCampanha={handleEdicaoCampanha}
            datePickerAberto={dateTimePickerInicio || dateTimePickerTermino}
            impactoDaCampanhaOriginal={impactoDaCampanhaInicial}
            impactoDaCampanhaEditado={impactoDaCampanhaEditado}
            grupoDeVisualizacaoOriginal={grupoDeVisualizacaoOriginal}
            grupoDeVisualizacaoEditado={grupoDeVisualizacaoEditado.map(
              (item) => {
                return item.title;
              }
            )}
            marcaWhatsapp={marcaWhatsapp}
            switchMarca={switchMarca}
            primeiraMensagem={primeiraMensagem}
            primeiroContato={primeiroContato}
            segundaMensagem={segundaMensagem}
            segundoContato={segundoContato}
            terceiraMensagem={terceiraMensagem}
            marcaWhatsappOriginal={marcaWhatsappOriginal}
            primeiroContatoOriginal={primeiroContatoOriginal}
            segundoContatoOriginal={segundoContatoOriginal}
            primeiraMensagemOriginal={primeiraMensagemOriginal}
            segundaMensagemOriginal={segundaMensagemOriginal}
            terceiraMensagemOriginal={terceiraMensagemOriginal}
            setMarcaWhatsapp={setMarcaWhatsapp}
            setPrimeiroContato={setPrimeiroContato}
            setSegundoContato={setSegundoContato}
            setPrimeiraMensagem={setPrimeiraMensagem}
            setSegundaMensagem={setSegundaMensagem}
            setTerceiraMensagem={setTerceiraMensagem}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditarCampanha;
