import React from 'react';
import Chart from 'react-google-charts';
import '../../../../shared/css-componentes/GraficoLinha.css';
import {
  MEDIUM_GRAY,
  ORANGE,
  DARK_CYAN,
  GRAY,
  LIGHT_GRAY,
  FONT_FAMILY_REGULAR,
} from '../../../../theme';
import DadosNaoEncontrados from '../../../../shared/componentes/DadosNaoEncontrados';

const GraficoLinha = (props) => {
  const { dadosGrafico, maximoEixoY, minimoEixoY } = props;

  return (
    <>
      {dadosGrafico && dadosGrafico.length > 0 && (
        <Chart
          chartType="LineChart"
          height="100%"
          width="100%"
          data={dadosGrafico}
          formatters={[
            {
              type: 'NumberFormat',
              column: 1,
              options: {
                pattern: '#.##%',
              },
            },
            {
              type: 'NumberFormat',
              column: 2,
              options: {
                pattern: '#.##%',
              },
            },
            {
              type: 'NumberFormat',
              column: 3,
              options: {
                pattern: '#.##%',
              },
            },
          ]}
          options={{
            curveType: 'function',
            focusTarget: 'category',
            backgroundColor: {
              fill: 'transparent',
            },
            tooltip: {
              isHtml: true,
            },
            chartArea: { width: '84%', height: '70%', top: 20 },
            animation: {
              duration: 1000,
              easing: 'out',
              startup: true,
            },
            vAxis: {
              baselineColor: 'transparent',
              gridlines: {
                color: 'transparent',
              },
              textStyle: {
                color: 'transparent',
              },
              format: '#,##',
              viewWindow: {
                max: maximoEixoY,
                min: minimoEixoY,
              },
            },
            hAxis: {
              textStyle: {
                color: MEDIUM_GRAY,
                fontSize: 10,
                fontName: FONT_FAMILY_REGULAR,
              },
            },
            legend: {
              position: 'bottom',
              maxLines: 3,
              textStyle: {
                color: MEDIUM_GRAY,
                fontSize: 12,
                fontName: FONT_FAMILY_REGULAR,
              },
            },
            lineWidth: 4,
            series: {
              0: { color: DARK_CYAN, visibleInLegend: true },
              1: { color: ORANGE, visibleInLegend: true },
              2: { color: GRAY, visibleInLegend: true, lineDashStyle: [4, 8] },
            },
            crosshair: {
              trigger: 'both',
              orientation: 'vertical',
              color: LIGHT_GRAY,
              opacity: 0.3,
            },
          }}
        />
      )}
      {dadosGrafico && dadosGrafico.length === 0 && (
        <DadosNaoEncontrados chaveamentoEstadoVazio={1} />
      )}
    </>
  );
};

export default GraficoLinha;
