import React, { useState } from 'react';
import {
  Typography,
  TableRow,
  TableCell,
  Tooltip,
  Grid,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Download, File, Filter } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import TooltipFiltroDesempenho from '../../../shared/componentes/TooltipFiltroDesempenho';
import ModalConfirmacao from '../../../shared/componentes/ModalConfirmacao';
import { DARK_CYAN, GRAY } from '../../../theme';
import { informacoesModais } from '../../../shared/util/Constantes';

const useStyles = makeStyles({
  celulaDeIcones: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'right',
  },
  reactTooltip: {
    padding: '0px !important',
    opacity: '100% !important',
  },
});

const LinhaRepositorioPerformance = (props) => {
  const classes = useStyles();

  const {
    titulo,
    lineNum,
    dataDeExtracao,
    validade,
    urlArquivo,
    dataInicio,
    dataFim,
    campanhas,
    status,
    atendentes,
    problemas,
    remedios,
  } = props;

  const [abrirModalConfirmacaoDownload, setAbrirModalConfirmacaoDownload] =
    useState(false);
  return (
    <>
      <TableRow hover>
        <TableCell>
          <Grid container justifyContent="center" alignItems="center">
            <File color={DARK_CYAN} size={22} />
          </Grid>
        </TableCell>
        <TableCell>
          {titulo && titulo.length > 30 ? (
            <Tooltip title={titulo}>
              <Typography noWrap variant="body1" align="left">
                {`${titulo.substring(0, 30)}...`}
              </Typography>
            </Tooltip>
          ) : (
            <Typography noWrap variant="body1" align="left">
              {titulo}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Typography variant="body1" align="left">
            {dataDeExtracao}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" align="left">
            {validade}
          </Typography>
        </TableCell>
        <TableCell align="right" className={classes.celulaDeIcones}>
          <ReactTooltip
            id={`${lineNum}filtro`}
            backgroundColor="white"
            type="light"
            className={classes.reactTooltip}
            arrowColor="rgba(0, 0, 0, 0)"
            data-testid="mensagem-tooltip-icon"
          >
            <TooltipFiltroDesempenho
              dataInicio={dataInicio}
              dataFim={dataFim}
              campanhas={campanhas}
              status={status}
              atendentes={atendentes}
              problemas={problemas}
              remedios={remedios}
            />
          </ReactTooltip>
          <Filter
            color={DARK_CYAN}
            size={22}
            className="mr-2"
            data-tip
            data-for={`${lineNum}filtro`}
          />
          <Button
            onClick={() => setAbrirModalConfirmacaoDownload(true)}
            disabled={urlArquivo === null}
            data-testid="button-fazer-download"
          >
            <Download size={22} color={urlArquivo ? DARK_CYAN : GRAY} />
          </Button>
        </TableCell>
      </TableRow>
      {abrirModalConfirmacaoDownload && (
        <ModalConfirmacao
          modalAberto={abrirModalConfirmacaoDownload}
          setModalAberto={setAbrirModalConfirmacaoDownload}
          tituloModal={informacoesModais.ModalConfirmacaoDownload.Titulo}
          textoBotaoDireito={
            informacoesModais.ModalConfirmacaoDownload.TextoBotaoDireito
          }
          descricao={`${informacoesModais.ModalConfirmacaoDownload.Descricao}${titulo}`}
          textoBotaoEsquerdo={informacoesModais.TextoBotaoCancelar}
          handleBotaoEsquerdo={() => setAbrirModalConfirmacaoDownload(false)}
          handleBotaoDireito={() => setAbrirModalConfirmacaoDownload(false)}
          href={urlArquivo}
        />
      )}
    </>
  );
};

export default LinhaRepositorioPerformance;
