import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import DadosNaoEncontrados from './DadosNaoEncontrados';
import { LIGHT_GRAY, DARK_CYAN } from '../../theme';

const useStyles = makeStyles({
  containerTituloTooltip: {
    borderBottom: `1px solid ${LIGHT_GRAY}`,
  },
  tituloTooltip: {
    fontSize: '14px',
    marginLeft: '8px',
    color: DARK_CYAN,
  },
  containerTooltip: {
    borderRadius: '8px',
    boxShadow: ' 0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
    minWidth: '300px',
    maxWidth: '450px',
  },
  containerIconeTituloPrincipal: {
    margin: '15px',
  },
  containerDescricao: {
    padding: '15px 15px 0px 15px',
    textAlignLast: 'left',
    maxHeight: '210px',
    overflow: 'hidden',
  },
  descricao: {
    paddingBottom: '15px',
    fontSize: '14px',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    textAlign: 'left',
  },
});

export default function TooltipMensagem(props) {
  const { titulo, mensagem, icone } = props;

  const classes = useStyles();

  return (
    <Grid className={classes.containerTooltip}>
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        className={classes.containerTituloTooltip}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.containerIconeTituloPrincipal}
        >
          {icone}
          <Typography className={classes.tituloTooltip} variant="h6">
            {titulo}
          </Typography>
        </Grid>
      </Grid>
      {mensagem ? (
        <>
          <Grid>
            <Grid className={classes.containerDescricao}>
              <Typography className={classes.descricao} variant="h5">
                {mensagem}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <DadosNaoEncontrados chaveamentoEstadoVazio={4} />
      )}
    </Grid>
  );
}
