import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import {
  Sunrise,
  Calendar,
  Users,
  DollarSign,
  Clock,
  XCircle,
  Hexagon,
} from 'react-feather';
import { filtroItens, pluralItens, singularesItems } from '../util/Constantes';
import { DARK_CYAN, FONT_FAMILY_REGULAR } from '../../theme';
import DefaultTooltip from './DefaultTooltip';

const useStyles = makeStyles({
  infoIES: {
    maxWidth: '120px',
    fontFamily: FONT_FAMILY_REGULAR,
  },
  titulo: {
    fontSize: '13px',
    marginLeft: '4px',
    color: DARK_CYAN,
  },
  containerinfoAlunos: {
    marginLeft: '15px',
  },
  containerIconeTituloPrincipal: {
    margin: '15px',
  },
  icone: {
    color: DARK_CYAN,
    fontSize: '20px',
  },
  tooltipIcon: {
    size: '15px',
  },
  containerTituloTooltip: {
    marginBottom: '12px',
  },
});

const DescricaoFiltros = (props) => {
  const classes = useStyles();

  const { listaIES, listaAluno, filtroTela } = props;
  const icones = {};
  icones[filtroItens.PerfilAluno] = SchoolOutlinedIcon;
  icones[filtroItens.Marca] = BusinessRoundedIcon;
  icones[filtroItens.Campus] = BusinessRoundedIcon;
  icones[filtroItens.Curso] = SchoolOutlinedIcon;
  icones[filtroItens.Turno] = Sunrise;
  icones[filtroItens.Ingresso] = Calendar;
  icones[filtroItens.DebitoFinanceiro] = DollarSign;
  icones[filtroItens.DataInicioMatricula] = Clock;
  icones[filtroItens.DataFinalMatricula] = XCircle;
  icones[filtroItens.Modality] = Hexagon;

  const getTooltipIcon = (titulo, cor) => {
    const Ico = icones[titulo];
    return (
      <>
        {Ico && <Ico style={{ color: cor, height: '18px', width: '18px' }} />}
      </>
    );
  };
  const getTitleString = (item) => {
    const primeiraMetade =
      filtroTela[item.id].length > 1 ? filtroTela[item.id].length : '';
    const segundaMetade =
      filtroTela[item.id].length > 1
        ? ` ${pluralItens[item.itemFiltro]}`
        : singularesItems[item.itemFiltro];

    return `${primeiraMetade}${segundaMetade}`;
  };

  const getTooltipContent = (item) => {
    const numeroMaximoDeItens = 5;
    const itensFaltantes = filtroTela[item.id].length - numeroMaximoDeItens;
    return (
      <>
        {filtroTela &&
          filtroTela[item.id].slice(0, numeroMaximoDeItens).map((element) => {
            return (
              <Typography variant="body1" className="mt-5" key={element}>
                {element}
              </Typography>
            );
          })}
        {itensFaltantes > 0 && (
          <Typography variant="body1" className="mt-5 h4-dropdown dark-green">
            {`+ ${itensFaltantes} ${
              itensFaltantes === 1
                ? item.itemFiltro
                : pluralItens[item.itemFiltro]
            }`}
          </Typography>
        )}
      </>
    );
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={classes.containerInfo}
      >
        {listaIES && listaIES.length > 0 && (
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.containerTituloTooltip}
            >
              <BusinessRoundedIcon className={classes.icone} />
              <Typography className={classes.titulo} variant="h6" noWrap>
                Parâmetros da IES
              </Typography>
            </Grid>
            <Grid container direction="column" className="ml-2 p-1">
              {listaIES.map((item) => (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  className="mb-4"
                  key={item.title}
                >
                  {getTooltipIcon(item.icon, item.iconColor)}
                  {item.semFiltro && (
                    <DefaultTooltip
                      color="dark-green"
                      title={item.title}
                      icon={getTooltipIcon(item.icon)}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          color: item.titleColor,
                          fontWeight: item.titleFont,
                        }}
                        className={`ml-3 ${classes.infoIES}`}
                        noWrap
                      >
                        {item.title}
                      </Typography>
                    </DefaultTooltip>
                  )}
                  {!item.semFiltro && (
                    <DefaultTooltip
                      color="dark-green"
                      title={item.title}
                      icon={getTooltipIcon(item.icon)}
                      content={!item.semFiltro && getTooltipContent(item)}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          color: item.titleColor,
                          fontWeight: item.titleFont,
                        }}
                        className={`ml-3 ${classes.infoIES}`}
                        noWrap
                      >
                        {item.title}
                      </Typography>
                    </DefaultTooltip>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {listaAluno && listaAluno.length > 0 && (
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.containerTituloTooltip}
            >
              <Users size={20} className={classes.icone} />
              <Typography className={classes.titulo} variant="h6" noWrap>
                Objetivos da campanha
              </Typography>
            </Grid>
            <Grid container direction="column" className="ml-2 p-1">
              {listaAluno.map((item) => (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  className="mb-4"
                  key={item.title}
                >
                  {getTooltipIcon(item.icon, item.iconColor)}
                  {item.semFiltro && (
                    <DefaultTooltip
                      color="dark-green"
                      title={item.title}
                      icon={getTooltipIcon(item.icon)}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          color: item.titleColor,
                          fontWeight: item.titleFont,
                        }}
                        noWrap
                        className="ml-3"
                      >
                        {item.title}
                      </Typography>
                    </DefaultTooltip>
                  )}
                  {!item.semFiltro && (
                    <DefaultTooltip
                      color="dark-green"
                      title={getTitleString(item)}
                      icon={getTooltipIcon(item.icon)}
                      content={getTooltipContent(item)}
                    >
                      <Typography
                        variant="h5"
                        noWrap
                        className="ml-3"
                        style={{
                          color: item.titleColor,
                          fontWeight: item.titleFont,
                        }}
                      >
                        {item.title}
                      </Typography>
                    </DefaultTooltip>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DescricaoFiltros;
