import axios from '../../apis/axiosInstanceScape0Crm';
import { checkResponse } from '../../shared/util/Utils';

class FiltroService {
  async buscarFiltros(
    payload,
    filter = { CampaignFields: true, VisualizationGroup: true }
  ) {
    return axios
      .post(
        `/filter_student_field_options?CampaignFields=${
          filter.CampaignFields ?? true
        }&VisualizationGroup=${filter.VisualizationGroup ?? true}`,
        payload
      )
      .then(checkResponse);
  }
}

export default new FiltroService();
