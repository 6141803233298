import * as FiltroStore from './Store';
import FiltroService from '../../../services/cockpit/FiltroService';
import { filtroItens } from '../../../shared/util/Constantes';
import { deepCopy } from '../../../shared/util/Utils';

const alunoDefault = ['Calouro', 'Veterano'];
class FiltroManager {
  formatOptions(array) {
    return array;
  }

  async buscarFiltros(filter) {
    return FiltroService.buscarFiltros(filter);
  }

  buscarAluno() {
    return deepCopy(alunoDefault);
  }

  atualizarFiltro = (lista, descricao) => async (dispatch) => {
    if (descricao === filtroItens.Aluno) {
      dispatch(FiltroStore.atualizarAluno(lista));
    } else if (descricao === filtroItens.Marca) {
      dispatch(FiltroStore.atualizarMarca(lista));
    } else if (descricao === filtroItens.Campus) {
      dispatch(FiltroStore.atualizarCampus(lista));
    } else if (descricao === filtroItens.Curso) {
      dispatch(FiltroStore.atualizarCurso(lista));
    } else if (descricao === filtroItens.Modalidade) {
      dispatch(FiltroStore.atualizarModalidade(lista));
    } else if (descricao === filtroItens.Turno) {
      dispatch(FiltroStore.atualizarTurno(lista));
    } else if (descricao === filtroItens.PeriodoLetivo) {
      dispatch(FiltroStore.atualizarPeriodo(lista));
    } else if (descricao === filtroItens.Ingresso) {
      dispatch(FiltroStore.atualizarIngresso(lista));
    }
  };

  atualizarAuxiliar = (valor) => async (dispatch) => {
    dispatch(FiltroStore.atualizarAuxiliar(valor));
  };

  atualizarEstadoFiltro = (valor) => async (dispatch) => {
    dispatch(FiltroStore.atualizarEstadoFiltro(valor));
  };
}
export default new FiltroManager();
