import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableFooter,
  TablePagination,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronDown } from 'react-feather';
import ReactHoverObserver from 'react-hover-observer';
import { DARK_CYAN, GRAY } from '../../../theme';
import DadosNaoEncontrados from '../../../shared/componentes/DadosNaoEncontrados';
import LinhaVaziaTabelaGerenciarMembros from './LinhaVaziaTabelaGerenciarMembros';
import LinhaTabelaGerenciarMembros from './LinhaTabelaGerenciarMembros';

import { deepCopy } from '../../../shared/util/Utils';

const useStyles = makeStyles({
  caption: {
    color: DARK_CYAN,
  },
  toolbar: {
    paddingRight: '8px',
  },
  labelColuna: {
    marginRight: '5px',
  },
  labelColunaHover: {
    marginRight: '5px',
    color: DARK_CYAN,
  },
  labelColunaAcoes: {
    marginRight: '5px',
    marginLeft: '170px',
  },
  labelColunaHoverAcoes: {
    marginLeft: '170px',
    marginRight: '5px',
    color: DARK_CYAN,
  },
  iconHover: {
    color: DARK_CYAN,
  },
  containerReactHover: {
    alignSelf: 'center',
    cursor: 'pointer',
  },
  dadosNaoEncontrados: {
    marginTop: '10rem',
  },
  tableCellHeader: {
    width: '35%',
  },
});

const TabelaGerenciarMembros = (props) => {
  const classes = useStyles();
  const {
    usuarioLogado,
    listaPermissoes,
    listaUsuarios,
    setListaUsuarios,
    listaUsuariosFiltrados,
    setListaUsuariosFiltrados,
    nomeGrupoVisualizacao,
    showFooter,
    carregando,
  } = props;
  const [page, setPage] = useState(0);

  const rowsPerPage = 6;

  useEffect(() => {
    setPage(0);
  }, [listaUsuariosFiltrados]);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const getNumPages = () =>
    Math.ceil(
      (listaUsuariosFiltrados && listaUsuariosFiltrados.length) / rowsPerPage
    ) - 1;

  const completarItens = (nItems) => {
    const list = [];
    for (let i = 0; i < nItems; i++) {
      const index = i + rowsPerPage - nItems;
      list.push(<LinhaVaziaTabelaGerenciarMembros key={index} />);
    }
    return list;
  };

  const ordenarPorNome = () => {
    const filtroPorNome = listaUsuariosFiltrados.sort();

    setListaUsuariosFiltrados(deepCopy(filtroPorNome));
  };

  return (
    <>
      <Table>
        <TableHead>
          {listaUsuariosFiltrados && listaUsuariosFiltrados.length > 0 && (
            <TableRow>
              <TableCell className={classes.tableCellHeader}>
                <ReactHoverObserver className={classes.containerReactHover}>
                  {({ isHovering }) => (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      onClick={() => ordenarPorNome()}
                    >
                      <Typography
                        align="left"
                        variant="h5"
                        className={`${classes.labelColuna} ${
                          isHovering && classes.labelColunaHover
                        }`}
                      >
                        Membro
                      </Typography>
                      <ChevronDown
                        size={14}
                        className={isHovering ? classes.iconHover : undefined}
                      />
                    </Grid>
                  )}
                </ReactHoverObserver>
              </TableCell>
              <TableCell className={classes.tableCellHeader}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    align="left"
                    variant="h5"
                    className={classes.labelColunaAcoes}
                  >
                    Ações
                  </Typography>
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        {!carregando &&
          listaUsuariosFiltrados &&
          listaUsuariosFiltrados.length > 0 && (
            <TableBody>
              {listaUsuariosFiltrados
                .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                .map((usuario) => (
                  <LinhaTabelaGerenciarMembros
                    usuarioLogado={usuarioLogado}
                    listaPermissoes={listaPermissoes}
                    listaUsuarios={listaUsuarios}
                    setListaUsuarios={setListaUsuarios}
                    nomeGrupoVisualizacao={nomeGrupoVisualizacao}
                    usuarioGerenciado={usuario}
                    key={usuario.OperatorId}
                  />
                ))}
              {completarItens(
                Math.abs(
                  listaUsuariosFiltrados.slice(
                    page * rowsPerPage,
                    (page + 1) * rowsPerPage
                  ).length - rowsPerPage
                )
              )}
            </TableBody>
          )}
        {showFooter && (
          <TableFooter data-testid="table-footer-gerenciar-membros">
            <TableRow>
              <TablePagination
                classes={{ caption: classes.caption, toolbar: classes.toolbar }}
                count={listaUsuariosFiltrados.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                page={page}
                onPageChange={handleChangePage}
                backIconButtonProps={{
                  cursor: 'pointer',
                  style: {
                    cursor: 'pointer',
                    marginRight: '16px',
                    color: page === 0 ? GRAY : DARK_CYAN,
                  },
                  'data-testid': 'previous-page-button-table-gerenciar-membros',
                }}
                nextIconButtonProps={{
                  style: {
                    cursor: 'pointer',
                    marginLeft: '16px',
                    color: page === getNumPages() ? GRAY : DARK_CYAN,
                  },
                  'data-testid': 'next-page-button-table-gerenciar-membros',
                }}
                labelDisplayedRows={(obj) =>
                  `${obj.from}-${obj.to} de ${obj.count}`
                }
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
      {!carregando &&
        listaUsuariosFiltrados &&
        listaUsuariosFiltrados.length === 0 && (
          <Grid container className={classes.dadosNaoEncontrados}>
            <DadosNaoEncontrados chaveamentoEstadoVazio={2} />
          </Grid>
        )}
    </>
  );
};
export default TabelaGerenciarMembros;
