import React from 'react';
import ReactLoading from 'react-loading';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { DARK_CYAN, WHITE } from '../../theme';

const useStyles = makeStyles({
  containerLoading: {
    position: 'absolute',
    height: '100%',
    top: '0',
  },
  fundoLoading: {
    width: '100%',
    height: '100%',
    background: WHITE,
    opacity: '0.6',
    zIndex: '2',
  },
  loading: {
    position: 'absolute',
    zIndex: '2',
    alignItems: 'center',
    height: '100%',
  },
});

const LoadingDashboard = () => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.containerLoading}
      container
      item
      justifyContent="center"
    >
      <Grid container item className={classes.fundoLoading} />
      <Grid className={classes.loading} container item justifyContent="center">
        <ReactLoading type="spin" color={DARK_CYAN} height={50} width={50} />
      </Grid>
    </Grid>
  );
};

export default LoadingDashboard;
