// Constantes
export const FONT_FAMILY = 'NunitoSans Light';
export const FONT_FAMILY_REGULAR = 'NunitoSans Regular';
export const FONT_FAMILY_BOLD = 'NunitoSans Bold';
export const FONT_FAMILY_SEMIBOLD = 'NunitoSans SemiBold';
export const FONT_FAMILY_EXTRABOLD = 'NunitoSans ExtraBold';
export const ESPACAMENTO_VERTICAL = '16px';
export const TEA_GREEN = '#D4FAC1';
export const LIGHT_SEA_GREEN_HOVER = '#2FB59B';

export const DROPDOWN_HOVER_GRAY = 'rgba(0, 0, 0, 0.04)';

export const DARK_GRAY_OP_1 = '#50595F';

export const SHADOW_MODAL = '#EDEDED';
export const SHADOW_DROPDOWN = '#E8E8E8';

export const BACKGROUND_MSG_ALERTA = '#BF43AB';
export const BACKGROUND_COLOR = '#F8F8F8';

export const COLOR_ITEM_DISABLE = '#E0E0E0';

export const STAR_COMMAND_BLUE = 'rgba(0, 119, 182, 1)';

export const DARK_CYAN = '#0EA789';
export const DARK_CYAN_HOVER = 'rgba(14,167,137, 0.2)';
export const LIGHT_CYAN = 'rgba(14,167,137,0.2)';
export const OPAQUE_LIGHT_CYAN = '#cfede7';
export const LIGHT_SEA_GREEN = '#35CAAD';

export const DODGER_BLUE = '#0091FF';
export const LIGHT_DODGER_BLUE = 'rgba(0,145,255,0.2)';
export const EXPORT_CSV_TEXT_BUTTON = '#2F54D3';

export const GRAY = '#9EABB5';
export const MEDIUM_GRAY = '#50595F';
export const LIGHT_GRAY = '#E8EEF2';
export const DARK_GRAY = '#1D1E1F';

export const ORANGE = '#F09300';
export const LIGHT_ORANGE = 'rgba(255,184,0,0.2)';
export const OPAQUE_LIGHT_ORANGE = '#FFF1CC';
export const TOMATO = '#FF404B';
export const TOMATO_HOVER = '#F81A3B';

export const LIGHT_TOMATO = 'rgba(255,64,75,0.2)';
export const OPAQUE_LIGHT_TOMATO = '#fed8da';
export const LIGHT_GREEN = '#ecf4f2';

export const BLACK = '#000000';
export const WHITE = '#FFFFFF';

export const BORDA_FILTRO = '#E8EEF2';

const SPACING = 8;

// Mixins

const generateNClasses = (name, number, calc) => {
  const classes = {};

  for (let i = 0; i <= number; i++) {
    const className = name.replace('{i}', i);
    classes[className] = calc(i);
  }

  return classes;
};

const spacing = (num) => (num ? `${num * SPACING}px` : '0');

// Tema

export default {
  palette: {
    primary: {
      light: LIGHT_CYAN,
      main: DARK_CYAN,
      dark: DARK_CYAN,
    },
    secondary: {
      light: LIGHT_CYAN,
      main: DARK_CYAN,
    },
    action: {
      disabled: {},
    },
    background: {
      default: '#ffffff',
    },
  },
  spacing: SPACING,
  typography: {
    fontFamily: FONT_FAMILY,
    h1: {
      color: DARK_CYAN,
      fontSize: '38px',
      fontFamily: FONT_FAMILY_EXTRABOLD,
      lineHeight: '48px',
    },
    h2: {
      color: DARK_CYAN,
      fontSize: '28px',
      fontFamily: FONT_FAMILY_EXTRABOLD,
      lineHeight: '36px',
    },
    h3: {
      color: DARK_CYAN,
      fontSize: '18px',
      fontFamily: FONT_FAMILY_EXTRABOLD,
      lineHeight: '24px',
    },
    h4: {
      color: GRAY,
      fontSize: '14px',
      fontFamily: FONT_FAMILY_EXTRABOLD,
      lineHeight: '20px',
    },
    h5: {
      color: MEDIUM_GRAY,
      fontSize: '12px',
      fontFamily: FONT_FAMILY_SEMIBOLD,
      lineHeight: '16px',
    },
    h6: {
      color: DARK_CYAN,
      fontSize: '12px',
      fontFamily: FONT_FAMILY_BOLD,
      lineHeight: '16px',
    },
    h7: {
      color: DARK_CYAN,
      fontSize: '12px',
      fontFamily: FONT_FAMILY_BOLD,
      lineHeight: '16px',
    },
    body1: {
      color: DARK_GRAY,
      fontSize: '14px',
      fontFamily: FONT_FAMILY_REGULAR,
      lineHeight: '20px',
    },
    body2: {
      color: DARK_CYAN,
      fontSize: '16px',
      fontFamily: FONT_FAMILY_REGULAR,
      lineHeight: '24px',
    },
    subtitle1: {
      color: GRAY,
      fontFamily: FONT_FAMILY,
      fontSize: '14px',
      lineHeight: '17px',
    },
    subtitle2: {
      color: DARK_CYAN,
      fontSize: '12px',
      fontFamily: FONT_FAMILY_SEMIBOLD,
      lineHeight: '18px',
    },
    caption: {
      fontSize: '13px',
      color: BLACK,
    },
  },

  overrides: {
    MuiFilledInput: {
      root: {
        '&.Mui-disabled': {
          backgroundColor: 'rgba(192,192,222,0.15)',
        },
        '&.Mui-focused': {
          backgroundColor: 'rgba(14,167,137,0.05)',
        },
        backgroundColor: 'rgba(232,238,242,0.2)',
        color: DARK_GRAY,
        fontWeight: '600',
        fontSize: '12px',
      },
      inputMarginDense: {
        paddingTop: '12px',
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid ${LIGHT_GRAY}`,
        },
        '&:hover:not($disabled):not($focused)': {
          backgroundColor: 'rgba(232,238,242,0.2)',
        },
        '&:hover:before': {
          borderBottom: '1px solid #bfbfbf',
        },
      },
      multiline: {
        padding: '20px',
      },
    },
    MuiAccordionSummary: {
      root: {
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
          minHeight: 56,
        },
        padding: '10px 0px',
      },
      content: {
        '&$expanded': {
          margin: '12px 0',
        },
        maxWidth: '95%',
      },
      expanded: {},
    },
    MuiFormLabel: {
      root: {
        fontFamily: FONT_FAMILY,
        color: '#636D76',
        fontSize: '1rem',
        fontWeight: '500',
        '&.Mui-focused': {
          color: BLACK,
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'rgba(255,255,255, 0.3)',
        },
        '&.MuiSelect-select': {
          paddingLeft: '9px',
        },
      },
    },
    MuiAlert: {
      standardSuccess: {
        backgroundColor: OPAQUE_LIGHT_CYAN,
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `1px solid ${LIGHT_GRAY}`,
        },
        '&:hover': {
          borderBottom: 'none',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid #d9d9d9',
        },
      },
    },
    MuiInputLabel: {
      marginDense: {
        transform: 'translate(12px, 12px) scale(0.8) !important',
      },
      shrink: {
        transform: 'translate(2px, -14px) scale(0.65) !important',
      },
      formControl: {
        fontSize: '14px',
        fontFamily: FONT_FAMILY_REGULAR,
        color: GRAY,
        transform: 'translate(8px, 24px) scale(1)',
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: 'rgba(158,171,181,0.05)',
        '&.Mui-focused': {
          backgroundColor: 'rgba(14, 167, 137, 0.05)',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: '500',
        padding: '10px 16px',
        fontSize: '13px',
        fontFamily: FONT_FAMILY_REGULAR,
        '&.Mui-disabled': {
          backgroundColor: LIGHT_GRAY,
          color: GRAY,
        },
      },
      contained: {
        color: GRAY,
        '&.Mui-disabled': {
          backgroundColor: '#e0e0e0',
          color: WHITE,
        },
      },
    },
    MuiPopover: {
      paper: {
        marginTop: '48px',
      },
    },
    MuiTab: {
      root: {
        padding: '0px 0px 10px 0px',
        width: '15%',
        fontSize: '20px',
      },
      textColorPrimary: {
        color: GRAY,
      },
    },
    MuiTableCell: {
      root: {
        '&.spacing-1': {
          padding: '14px 8px 14px 8px',
        },
      },
      head: {
        fontSize: '14px',
        // color: LIGHT_GRAY,
        fontWeight: 'normal',
        padding: `10px ${spacing(1)} 2px ${spacing(1)}`,
      },
      footer: {
        borderBottom: '0px',
      },
      body: {
        fontSize: '16px',
        fontWeight: '500',
        color: DARK_CYAN,
        padding: `10px ${spacing(1)} 10px ${spacing(1)}`,
        borderBottom: '1px solid #F9F9F9',
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: '0px',
      },
    },
    MuiCheckbox: {
      root: {
        '&.Mui-disabled': {
          color: LIGHT_GRAY,
        },
      },
    },
    MuiTouchRipple: {
      root: {
        display: 'none !important',
      },
      rippleVisible: {
        opacity: '0',
        animation: 'none',
      },
      ripplePulsate: {
        animationDuration: '0',
      },
      child: {
        borderRadius: '0',
        backgroundColor: 'transparent',
      },
      childPulsate: {
        animation: 'none',
      },
    },
    MuiGrid: {
      root: {
        '&.cardFiltro': {
          flexWrap: 'nowrap',
        },
      },
    },
    MuiButtonBase: {
      root: {
        textTransform: 'none !important',
        minWidth: '0px !important',
      },
    },
    MuiIconButton: {
      root: {
        padding: '0px',
      },
      edgeEnd: {
        marginRight: '0px',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow:
          '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
      },
    },
    MuiTooltip: {
      tooltip: {
        color: MEDIUM_GRAY,
        fontFamily: FONT_FAMILY_REGULAR,
        fontSize: '16px',
        backgroundColor: WHITE,
        maxWidth: '390px',
        padding: '8px 12px',
        borderRadius: '8px',
        boxShadow:
          '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
      },
    },
    MuiDialog: {
      paper: {
        margin: 0,
      },
    },
  },
  global: {
    '.cardIcon': {
      color: DARK_CYAN,
    },
    '.tooltipIcon': {
      heigth: '20px !important',
      width: '20px !important',
      marginTop: '-10px',
    },
    '.cardIconContainer': {
      backgroundColor: LIGHT_CYAN,
      height: '40px',
      width: '40px',
      borderRadius: '8px',
    },
    '.cardIconContainerRounded': {
      backgroundColor: LIGHT_CYAN,
      height: '40px',
      width: '40px',
      borderRadius: '23px',
    },
    '.situacaoContainer': {
      borderRadius: '4px',
      width: '102px',
      height: '24px',
    },
    '.situacaoCampo': {
      fontFamily: FONT_FAMILY_EXTRABOLD,
      fontSize: '12px',
      textAlign: 'center',
      top: '10%',
      position: 'relative',
      fontWeight: 'bold',
    },
    '.retomadaContainer': {
      backgroundColor: LIGHT_TOMATO,
    },
    '.retomadaText': {
      color: TOMATO,
    },
    '.bloqueadoContainer': {
      backgroundColor: LIGHT_GRAY,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '.bloqueadoIcon': {
      color: GRAY,
      height: '16px',
    },
    '.atendidoContainer': {
      backgroundColor: LIGHT_CYAN,
    },
    '.atendidoText': {
      color: DARK_CYAN,
    },
    '.aguardandoContainer': {
      backgroundColor: '#FFE4C6',
    },
    '.aguardandoText': {
      color: ORANGE,
    },

    '.inelegivelContainer': {
      backgroundColor: LIGHT_GRAY,
    },
    '.inelegivelText': {
      color: GRAY,
    },
    '.atendimentoContainer': {
      backgroundColor: LIGHT_DODGER_BLUE,
    },
    '.atendimentoText': {
      color: DODGER_BLUE,
    },
    '.evadiuContainer': {
      backgroundColor: LIGHT_TOMATO,
    },
    '.evadiuText': {
      color: TOMATO,
    },
    '.rowBlocked': {
      borderBottom: '1px solid #E8EEF2',
      height: '56px',
      backgroundColor: 'rgba(158, 171, 181, 0.05)',
    },
    '.rowClickable': {
      borderBottom: '1px solid #E8EEF2',
      height: '56px',
      cursor: 'pointer',
    },
    '.cardIconContainerFiltro': {
      backgroundColor: LIGHT_CYAN,
      height: '30px',
      width: '30px',
    },
    '.indicatorIconContainer': {
      backgroundColor: LIGHT_CYAN,
      height: '48px',
      width: '48px',
    },
    '.treeNodeContainer': {
      height: '90px',
      width: '80px',
    },
    '.yellowBackground': {
      backgroundColor: LIGHT_ORANGE,
    },
    '.greenBackground': {
      backgroundColor: LIGHT_CYAN,
    },
    '.tealBackground': {
      backgroundColor: LIGHT_CYAN,
    },
    '.redBackground': {
      backgroundColor: LIGHT_TOMATO,
    },
    '.blueBackground': {
      backgroundColor: LIGHT_DODGER_BLUE,
    },
    '.grayBackground': {
      backgroundColor: LIGHT_GRAY,
    },
    '.yellow': {
      color: ORANGE,
    },
    '.red': {
      color: TOMATO,
    },
    '.blue': {
      color: DODGER_BLUE,
    },
    '.green': {
      color: LIGHT_SEA_GREEN,
    },
    '.gray': {
      color: GRAY,
    },
    '.teal': {
      color: LIGHT_SEA_GREEN,
    },
    '.dark-green': {
      color: DARK_CYAN,
    },
    '.h2': {
      fontSize: '28px',
      fontWeight: 'bold',
      lineHeight: '34px',
      fontFamily: FONT_FAMILY_REGULAR,
    },
    '.h3': {
      fontSize: '18px',
      fontWeight: 'bold',
      lineHeight: '22px',
    },
    '.h6': {
      fontSize: '12px',
      lineHeight: '15px',
      fontWeight: 'bold',
    },
    '.h6Gray': {
      fontSize: '14px',
      lineHeight: '15px',
      color: GRAY,
    },
    '.h4-dropdown': {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 'bold',
    },
    '.subtitle1': {
      fontSize: '12px',
      lineHeight: '14px',
      fontFamily: FONT_FAMILY_REGULAR,
    },
    '.subtitle2': {
      fontSize: '10px',
      lineHeight: '14px',
      fontFamily: FONT_FAMILY_REGULAR,
    },
    // Item pai da arvore
    '.css-15zydmg:first-child::before': {
      borderLeft: '0px !important',
    },
    // Container da Arvore
    '.css-104n4p1': {
      width: '100%',
    },
    ...generateNClasses('.mb-{i}', 8, (i) => ({
      marginBottom: spacing(i * 0.5),
    })),
    ...generateNClasses('.mt-{i}', 8, (i) => ({
      marginTop: spacing(i * 0.5),
    })),
    ...generateNClasses('.mr-{i}', 8, (i) => ({
      marginRight: spacing(i * 0.5),
    })),
    ...generateNClasses('.ml-{i}', 8, (i) => ({
      marginLeft: spacing(i * 0.5),
    })),
    ...generateNClasses('.pl-{i}', 8, (i) => ({
      paddingLeft: spacing(i * 0.5),
    })),
    ...generateNClasses('.pr-{i}', 8, (i) => ({
      paddingRight: spacing(i * 0.5),
    })),
    ...generateNClasses('.pt-{i}', 8, (i) => ({
      paddingTop: spacing(i * 0.5),
    })),
  },
};
