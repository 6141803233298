import FiltroService from '../../services/cockpit/FiltroService';
import GestaoCampanhaService from '../../services/GestaoCampanhaService';
import WhatsappService from '../../services/WhatsappService';
import { formatarDataParaTimestamp } from '../../shared/util/Utils';

class EditarCampanhaManager {
  async editarDadosCampanha(
    idCampanha,
    inicioCampanha,
    terminoCampanha,
    impactoDaCampanha,
    grupoDeVisualizacao,
    orientacoesCampanha,
    dadosWhatsapp
  ) {
    const payload = {
      StartTimestamp: formatarDataParaTimestamp(inicioCampanha),
      EndTimestamp: formatarDataParaTimestamp(terminoCampanha),
      PriorityLevel: impactoDaCampanha,
      VisualizationGroup: grupoDeVisualizacao,
      Description: orientacoesCampanha,
      WhatsappData: {
        WhatsappOrganization: dadosWhatsapp.marcaWhatsapp?.Title || null,
        ListWhatsappTrigger: this.mapearArrayMensagensWhatsapp(
          dadosWhatsapp.mensagens
        ),
      },
    };
    return GestaoCampanhaService.editarDadosCampanha(idCampanha, payload);
  }

  mapearArrayMensagensWhatsapp(dadosMensagem) {
    const whatsappMessages = [];
    dadosMensagem.forEach((el) => {
      if (el.Id) {
        whatsappMessages.push({ MessageId: el.Id, Trigger: el.Gatilho });
      }
    });
    return whatsappMessages;
  }

  async listarGruposDeVisualizacao() {
    const payload = {
      filters: {},
    };
    const resp = await FiltroService.buscarFiltros(payload, {
      CampaignFields: false,
    });
    if (resp) {
      return resp.VisualizationGroup;
    }
  }

  async buscarMensagensWhatsapp() {
    return WhatsappService.listaMensagensWhatsapp();
  }

  async buscarMarcasWhatsapp() {
    return WhatsappService.listaMarcasWhatsapp();
  }
}

export default new EditarCampanhaManager();
