import store from '../setup/reducer';
import { exibirNotificacao } from '../features/notificacao/Store';
import {
  iconesNotificacao,
  mensagensNotificacaoErro,
} from '../shared/util/Constantes';
import axiosInstanceScape0Crm from '../apis/axiosInstanceScape0Crm';

class DesempenhoService {
  checkResponseBuscarDadosAtendentes(response) {
    if (response && response.data && response.data.data) {
      return response.data.data;
    }
    store.dispatch(
      exibirNotificacao(
        mensagensNotificacaoErro.FalhaAoBuscarDadosAtendente,
        iconesNotificacao.ErroPadrao
      )
    );
    return undefined;
  }

  async buscarDadosAtendentes(body) {
    return axiosInstanceScape0Crm
      .post('analytics/operator_performance', body)
      .then(this.checkResponseBuscarDadosGrafico);
  }

  checkResponseBuscarDadosGrafico(response) {
    if (response && response.data) {
      return response.data;
    }
    return undefined;
  }

  async listData(bodyParam) {
    return axiosInstanceScape0Crm
      .post('/analytics/attempt_performance', bodyParam)
      .then(this.checkResponseBuscarDadosGrafico);
  }
}

export default new DesempenhoService();
