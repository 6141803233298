import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { GRAY, DARK_CYAN } from '../../../../theme';

const useStyles = makeStyles({
  icone: {
    width: '16px',
    height: '16px',
    color: GRAY,
    marginRight: '9px',
  },
  mediaPorAtendimentoTitulo: {
    fontSize: '14px',
    lineHeight: '18px',
  },
  tempoMedioAtendente: {
    color: DARK_CYAN,
    fontWeight: 'Bold',
    fontSize: '14px',
    textAlign: 'end',
  },
  tempoMedioTodosAtendimentos: {
    fontSize: '14px',
    textAlign: 'end',
  },
  gridTitulo: {
    marginTop: '2px',
    width: '65px',
  },
  containerPerfilInfo: {
    marginBottom: '14px',
    display: 'grid',
    gridTemplateColumns: 'min-content auto min-content 65px',
    width: '100%',
  },
});

const MediaAtendimentoPerfil = (props) => {
  const { tempoMedioAtendente, tempoMedioTodosAtendimentos, operatorName } =
    props;

  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      className={classes.containerPerfilInfo}
    >
      <HeadsetMicOutlinedIcon
        className={classes.icone}
        style={{ color: GRAY }}
      />
      <Typography className={classes.mediaPorAtendimentoTitulo}>
        Média por Atendimento
      </Typography>
      <Grid item>
        <Tooltip title={`Média por atendimento de ${operatorName}`}>
          <Typography
            noWrap
            className={classes.tempoMedioAtendente}
            variant="body1"
          >
            {tempoMedioAtendente}min
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Média por atendimento de todo o time">
          <Typography noWrap className={classes.tempoMedioTodosAtendimentos}>
            {tempoMedioTodosAtendimentos}min
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default MediaAtendimentoPerfil;
