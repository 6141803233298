import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import { Briefcase, Sunrise, BookOpen, Calendar, Filter } from 'react-feather';
import { filtroItens } from '../util/Constantes';
import { DARK_CYAN, GRAY, LIGHT_GRAY, FONT_FAMILY_REGULAR } from '../../theme';
import DescricaoFiltros from './DescricaoFiltros';
import DadosNaoEncontrados from './DadosNaoEncontrados';

const useStyles = makeStyles({
  tituloIES: {
    fontSize: '14px',
    marginLeft: '8px',
    color: DARK_CYAN,
  },
  iconeInfoIES: {
    marginBottom: '15px',
    '&:last-child': {
      marginBottom: '3px',
    },
  },
  iconeInfoAlunos: {
    marginTop: '1px',
    marginBottom: '15px',
    '&:last-child': {
      marginBottom: '3px',
    },
  },
  containerinfoIES: {
    marginLeft: '15px',
  },
  infoIES: {
    marginLeft: '8px',
    fontFamily: FONT_FAMILY_REGULAR,
  },
  tituloAluno: {
    fontSize: '14px',
    marginLeft: '8px',
    color: DARK_CYAN,
  },
  containerinfoAlunos: {
    marginLeft: '15px',
  },
  containerInfo: {
    padding: '13px 13px 8px 13px',
  },
  containerTituloTooltip: {
    borderBottom: `1px solid ${LIGHT_GRAY}`,
  },
  tituloTooltip: {
    fontSize: '14px',
    marginLeft: '8px',
    color: DARK_CYAN,
  },
  containerTooltip: {
    borderRadius: '8px',
    boxShadow: ' 0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
    width: '450px',
  },
  containerIconeTituloPrincipal: {
    margin: '15px',
  },
  icone: {
    color: DARK_CYAN,
  },
  containerIconeTituloIES: {
    marginBottom: '14px',
  },
  containerIconeTituloAlunos: {
    marginBottom: '15px',
    marginTop: '2px',
  },
  tooltipIcon: {
    size: '15px',
  },
  iconeAlunos: {
    color: GRAY,
  },
  infoAlunos: {
    marginLeft: '8px',
  },
  dadosNaoEncontrados: {
    alignContent: 'center',
    justifyContent: 'center',
  },
});

export default function TooltipFiltro(props) {
  const classes = useStyles();

  const { listaAluno, listaIES, filtroTela } = props;

  const icones = {};
  icones[filtroItens.PerfilAluno] = SchoolOutlinedIcon;
  icones[filtroItens.Marca] = BusinessRoundedIcon;
  icones[filtroItens.Campus] = BusinessRoundedIcon;
  icones[filtroItens.Curso] = SchoolOutlinedIcon;
  icones[filtroItens.Modalidade] = Briefcase;
  icones[filtroItens.Turno] = Sunrise;
  icones[filtroItens.PeriodoLetivo] = BookOpen;
  icones[filtroItens.Ingresso] = Calendar;
  icones[filtroItens.Situacao] = Calendar;

  return (
    <Grid className={classes.containerTooltip}>
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        className={`mb-2 ${classes.containerTituloTooltip}`}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.containerIconeTituloPrincipal}
        >
          <Filter size={20} className={classes.icone} />
          <Typography className={classes.tituloTooltip} variant="h6">
            Filtros Aplicados
          </Typography>
        </Grid>
      </Grid>

      {(listaAluno && listaAluno.length > 0) ||
      (listaIES && listaIES.length > 0) ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          className="pl-6"
        >
          <DescricaoFiltros
            listaAluno={listaAluno}
            listaIES={listaIES}
            filtroTela={filtroTela}
            className="m-4"
          />
        </Grid>
      ) : (
        <Grid container className={classes.dadosNaoEncontrados}>
          <DadosNaoEncontrados chaveamentoEstadoVazio={4} />
        </Grid>
      )}
    </Grid>
  );
}
