// Types
export const NOTIFICAR_ERRO = 'notificacao/NOTIFICAR_ERRO';
export const OCULTAR_ERRO = 'notificacao/OCULTAR_ERRO';
export const NOTIFICAR_SUCESSO = 'notificacao/NOTIFICAR_SUCESSO';
export const OCULTAR_SUCESSO = 'notificacao/OCULTAR_SUCESSO';
export const NOTIFICAR_ALERTA = 'notificacao/NOTIFICAR_ALERTA';
export const OCULTAR_ALERTA = 'notificacao/OCULTAR_ALERTA';

// Reducer

export const INITIAL_STATE = {
  listaErro: [],
  listaSucesso: [],
  listaAlerta: [],
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case NOTIFICAR_ERRO:
      return {
        ...state,
        listaErro: [
          ...state.listaErro,
          {
            mensagem: action.payload.mensagem,
            icone: action.payload.icone,
            timestamp: action.payload.timestamp
              ? action.payload.timestamp
              : new Date().getTime(),
          },
        ],
      };
    case OCULTAR_ERRO:
      return {
        ...state,
        listaErro: [
          ...state.listaErro.slice(0, action.payload),
          ...state.listaErro.slice(action.payload + 1),
        ],
      };
    case NOTIFICAR_SUCESSO:
      return {
        ...state,
        listaSucesso: [
          ...state.listaSucesso,
          {
            mensagem: action.payload.mensagem,
            icone: action.payload.icone,
            timestamp: action.payload.timestamp
              ? action.payload.timestamp
              : new Date().getTime(),
          },
        ],
      };
    case OCULTAR_SUCESSO:
      return {
        ...state,
        listaSucesso: [
          ...state.listaSucesso.slice(0, action.payload),
          ...state.listaSucesso.slice(action.payload + 1),
        ],
      };
    case NOTIFICAR_ALERTA:
      return {
        ...state,
        listaAlerta: [
          ...state.listaAlerta,
          {
            mensagem: action.payload.mensagem,
            icone: action.payload.icone,
            ocultaAutomaticamente: action.payload.ocultaAutomaticamente,
            handlerBotao: action.payload.handlerBotao,
            textoBotao: action.payload.textoBotao,
            timestamp: action.payload.timestamp
              ? action.payload.timestamp
              : new Date().getTime(),
          },
        ],
      };
    case OCULTAR_ALERTA:
      return {
        ...state,
        listaAlerta: [
          ...state.listaAlerta.slice(0, action.payload),
          ...state.listaAlerta.slice(action.payload + 1),
        ],
      };
    default:
      return state;
  }
};

// Action creators
export const exibirNotificacao = (mensagem, icone, timestamp) => ({
  type: NOTIFICAR_ERRO,
  payload: {
    mensagem,
    icone,
    timestamp,
  },
});

export const ocultarNotificacaoErro = (index) => ({
  type: OCULTAR_ERRO,
  payload: index,
});

export const exibirNotificacaoSucesso = (mensagem, icone, timestamp) => ({
  type: NOTIFICAR_SUCESSO,
  payload: {
    mensagem,
    icone,
    timestamp,
  },
});

export const ocultarNotificacaoSucesso = (index) => ({
  type: OCULTAR_SUCESSO,
  payload: index,
});

export const exibirNotificacaoAlerta = (
  mensagem,
  icone,
  ocultaAutomaticamente,
  textoBotao,
  handlerBotao,
  timestamp
) => ({
  type: NOTIFICAR_ALERTA,
  payload: {
    mensagem,
    icone,
    ocultaAutomaticamente,
    handlerBotao,
    textoBotao,
    timestamp,
  },
});

export const ocultarNotificacaoAlerta = (index) => ({
  type: OCULTAR_ALERTA,
  payload: index,
});
