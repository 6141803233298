import DesempenhoService from '../../services/DesempenhoService';
import { deepCopy } from '../../shared/util/Utils';
import {
  labelsGraficoTempoMedio,
  papelLinhaGrafico,
  tipoDadoGrafico,
} from '../../shared/util/Constantes';

function listaExistenteOuNula(lista) {
  return lista.length > 0 ? lista : null;
}

class GraficosDesempenhoManager {
  async buscarDadosAtendentes(filtro) {
    const body = {
      EndTimestamp: filtro.dataFinal
        ? filtro.dataFinal.setHours(23, 59, 59)
        : null,
      StartTimestamp: filtro.dataInicial
        ? filtro.dataInicial.setHours(0, 0, 0)
        : null,
      CampaignId: filtro.campanha,
      OperatorId: filtro.atendente,
      Status: filtro.situacao,
      ProblemCategoriesId: filtro.problema,
      RemediesId: filtro.remedio,
    };

    const resposta = await DesempenhoService.buscarDadosAtendentes(body);

    if (!resposta) return [];

    const listaAtendentes = [];
    resposta.forEach((atendente) => {
      const dto = {
        operatorName: atendente.OperatorName,
        notFinishedAttemptsCount: atendente.NotFinishedAttemptsCount,
        notFinishedAttemptsPercent:
          (atendente.NotFinishedAttemptsPercent * 100).toFixed(2) * 1,
        remedyCount: atendente.RemedyCount,
        remedyPercent: (atendente.RemedyPercent * 100).toFixed(2) * 1,
        finishedCount: atendente.FinishedCount,
        finishedPercent: (atendente.FinishedPercent * 100).toFixed(2) * 1,
        inelegibleCount: atendente.InelegibleCount,
        inelegiblePercent: (atendente.InelegiblePercent * 100).toFixed(2) * 1,
        generalServiceAverage: Math.round(atendente.GeneralServiceAverage),
        operatorServiceAverage: Math.round(atendente.OperatorServiceAverage),
        operatorTotalTime: atendente.OperatorTotalTime,
        operatorTotalTimePercent:
          (atendente.OperatorTotalTimePercent * 100).toFixed(2) * 1,
      };
      listaAtendentes.push(dto);
    });

    return listaAtendentes;
  }

  async listarDesempenhoAtendimento(filtroOriginal, criarTooltip) {
    const cabecalho = [
      {
        type: tipoDadoGrafico.Numerico,
        role: papelLinhaGrafico.Dominio,
        label: labelsGraficoTempoMedio.Quantidade,
      },
      {
        type: tipoDadoGrafico.Texto,
        role: papelLinhaGrafico.Tooltip,
        p: { html: true },
      },
      {
        type: tipoDadoGrafico.Numerico,
        label: labelsGraficoTempoMedio.TempoMedio,
      },
    ];

    const filtro = deepCopy(filtroOriginal);

    const bodyParam = {
      EndTimestamp: filtro.dataFinal
        ? filtro.dataFinal.setHours(23, 59, 59)
        : null,
      StartTimestamp: filtro.dataInicial
        ? filtro.dataInicial.setHours(0, 0, 0)
        : null,
      CampaignId: listaExistenteOuNula(filtro.campanha),
      OperatorId: listaExistenteOuNula(filtro.atendente),
      Status: listaExistenteOuNula(filtro.situacao),
      ProblemCategoriesId: listaExistenteOuNula(filtro.problema),
      RemediesId: listaExistenteOuNula(filtro.remedio),
    };

    const response = await DesempenhoService.listData(bodyParam);

    if (response) {
      this.sortAttendanceArray(response);
    } else {
      return [];
    }

    for (const iteratedResponse of response) {
      const tooltip = criarTooltip(iteratedResponse[0], iteratedResponse[1]);
      iteratedResponse.splice(1, 0, tooltip);
    }

    for (const respostaIterada of response) {
      for (const respostaIteradaAninhada of respostaIterada) {
        if (respostaIteradaAninhada !== 0) {
          return [cabecalho].concat(response);
        }
      }
    }

    return [];
  }

  sortAttendanceArray(Array) {
    Array.sort((a, b) => {
      return a[0] - b[0];
    });
  }
}

export default new GraficosDesempenhoManager();
