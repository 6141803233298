import { React, useState } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  IconButton,
  TextField,
} from '@material-ui/core';
import { Phone, Edit2, Check } from 'react-feather';
import { DARK_CYAN, GRAY, LIGHT_TOMATO, TOMATO } from '../../../theme';
import Loading from '../../../shared/componentes/Loading';

const useStyles = makeStyles(() => ({
  flexGrid: {
    display: 'flex',
  },
  iconeLabel: {
    marginRight: '10px',
  },
  labelSubTitulo: {
    fontSize: '16px',
    color: DARK_CYAN,
    fontWeight: '700',
  },
  labelSubTituloEdicao: {
    fontSize: '16px',
    color: TOMATO,
    fontWeight: '700',
  },
  salvar: {
    color: DARK_CYAN,
    fontWeight: '700',
    marginRight: '5px',
  },
  salvarEdicao: {
    color: TOMATO,
    fontWeight: '700',
    marginRight: '5px',
  },
  gridSalvar: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  container: {
    padding: '8px',
    marginBottom: '35px',
  },
  containerSalvar: {
    padding: '8px',
    borderRadius: '5px',
    marginBottom: '35px',
  },
  containerEmEdicao: {
    padding: '8px',
    borderRadius: '5px',
    marginBottom: '0',
  },
  avisoFormatoRamalIncorreto: {
    display: 'block',
    color: TOMATO,
    marginTop: '5px',
    marginBottom: '10px',
    paddingLeft: '8px',
  },
  avisoFormatoRamalCorreto: {
    display: 'none',
  },
  inputRamal: {
    width: '100%',
    marginTop: '10px',
    borderRadius: '4x',
  },
  inputRamalComErro: {
    width: '100%',
    marginTop: '10px',
    borderRadius: '4px',
    backgroundColor: LIGHT_TOMATO,
  },
}));

const EditarRamalPerfil = (props) => {
  const { ramal, ramalEmEdicao, setRamalEmEdicao, handleEditarRamal } = props;

  const [carregando, setCarregando] = useState(false);
  const [novoRamal, setNovoRamal] = useState(ramal || '');

  const classes = useStyles();

  const re = /^[0-9\b]+$/;

  const handleChange = (event) => {
    if (
      event.target.value === '' ||
      (event.target.value.length <= 4 && re.test(event.target.value))
    ) {
      setNovoRamal(event.target.value);
    }
  };

  const validarRamal = (input) => {
    if (input) {
      return input.length === 4;
    }
    return !!input;
  };

  const handleBotaoSalvar = () => {
    if (novoRamal.length === 4) {
      setCarregando(true);
      handleEditarRamal(novoRamal);
      setRamalEmEdicao(false);
      setCarregando(false);
    }
  };

  return (
    <>
      {!ramalEmEdicao && (
        <Grid className={classes.container}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid className={classes.flexGrid}>
              <Phone
                size={20}
                color={DARK_CYAN}
                className={classes.iconeLabel}
              />
              <Typography className={classes.labelSubTitulo}>Ramal</Typography>
            </Grid>
            <IconButton
              onClick={() => setRamalEmEdicao(true)}
              data-testid="icone-editar-ramal"
            >
              <Edit2 size={20} color={GRAY} />
            </IconButton>
          </Grid>
          <Typography className={`${classes.conteudoParagrafo} pt-5`}>
            {novoRamal}
          </Typography>
        </Grid>
      )}
      {ramalEmEdicao && (
        <>
          <Grid
            className={
              validarRamal(novoRamal)
                ? classes.containerSalvar
                : classes.containerEmEdicao
            }
          >
            {carregando && <Loading />}
            <Grid container direction="row" justifyContent="space-between">
              <Grid className={classes.flexGrid}>
                <Phone
                  size={20}
                  color={validarRamal(novoRamal) ? DARK_CYAN : TOMATO}
                  className={classes.iconeLabel}
                />
                <Typography
                  className={
                    validarRamal(novoRamal)
                      ? classes.labelSubTitulo
                      : classes.labelSubTituloEdicao
                  }
                >
                  Ramal
                </Typography>
              </Grid>
              <Grid
                className={`${classes.flexGrid} ${classes.gridSalvar}`}
                onClick={() => handleBotaoSalvar()}
              >
                {novoRamal.length === 4 && (
                  <>
                    <Typography
                      className={
                        novoRamal.length < 4
                          ? classes.salvarEdicao
                          : classes.salvar
                      }
                    >
                      Salvar
                    </Typography>
                    <Check size={20} color={DARK_CYAN} />
                  </>
                )}
              </Grid>
            </Grid>
            <TextField
              autoFocus
              className={
                validarRamal(novoRamal)
                  ? classes.inputRamal
                  : classes.inputRamalComErro
              }
              error={!validarRamal(novoRamal)}
              value={novoRamal || ''}
              onChange={handleChange}
              variant="standard"
              size="medium"
              InputProps={{
                style: {
                  paddingLeft: '10px',
                  height: '48px',
                },
              }}
            />
          </Grid>
          <Typography
            className={
              validarRamal(novoRamal)
                ? classes.avisoFormatoRamalCorreto
                : classes.avisoFormatoRamalIncorreto
            }
          >
            Fomato inválido! Utilize apenas 4 números.
          </Typography>
        </>
      )}
    </>
  );
};

export default EditarRamalPerfil;
