import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TableRow,
  TableCell,
  Grid,
  Box,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Edit3, Users, Phone, PhoneCall, Trash2 } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import { exibirNotificacaoSucesso } from '../../notificacao/Store';
import TooltipMensagem from '../../../shared/componentes/TooltipMensagem';

import { DARK_CYAN, GRAY, DARK_GRAY_OP_1, WHITE } from '../../../theme';
import ModalExclusaoCampanha from '../../../shared/componentes/ModalExclusaoCampanha';

const LinhaCampanha = (props) => {
  const {
    idCampanha,
    lineNum,
    tagStatus,
    tituloCampanha,
    dataInicio,
    categoria,
    totalAlunos,
    totalAguardando,
    totalRecebidos,
    totalEvasao,
    tagStatusColor,
    tagCaractereColor,
    handleExclusao,
  } = props;

  const useStyles = makeStyles(() => ({
    row: {
      height: '50px',
    },
    firstColumn: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '300px',
      maxWidth: '300px',
    },
    reactTooltip: {
      padding: '0px !important',
      opacity: '100% !important',
    },
    boxTagStatus: {
      backgroundColor: tagStatusColor,
      borderRadius: '4px',
      width: 'fit-content',
      padding: '6px 8px',
    },
    iconFields: {
      marginRight: '7px',
    },
    siglaTagStatus: {
      fontSize: '14px',
      color: tagCaractereColor,
    },
    customTooltip: {
      color: WHITE,
      backgroundColor: DARK_GRAY_OP_1,
    },
    extractionDropdown: {
      fontSize: '12px',
      width: 'fit-content',
    },
    iconeGrupoVisualizacao: {
      color: DARK_GRAY_OP_1,
    },
  }));

  const classes = useStyles(props);

  const [abrirModalExclusaoCampanha, setAbrirModalExclusaoCampanha] =
    useState(false);

  return (
    <>
      <TableRow
        hover
        className={classes.row}
        data-testid="table-row-linha-campanha-gestao"
      >
        <TableCell size="small" align="center">
          <Grid item>
            <Tooltip
              title={tagStatus}
              classes={{
                tooltip: classes.customTooltip,
              }}
            >
              <Box className={classes.boxTagStatus}>
                <Typography
                  variant="h6"
                  align="center"
                  className={classes.siglaTagStatus}
                  data-testid="tipografia-status-tooltip"
                >
                  {tagStatus[0]}
                </Typography>
              </Box>
            </Tooltip>
          </Grid>
        </TableCell>

        <TableCell className={classes.firstColumn}>
          {tituloCampanha && tituloCampanha.length > 40 && (
            <ReactTooltip
              id={`${lineNum}tit`}
              backgroundColor="white"
              type="light"
              className={classes.reactTooltip}
              arrowColor="rgba(0, 0, 0, 0)"
            >
              <TooltipMensagem
                titulo="Título da Campanha"
                mensagem={tituloCampanha}
                icone={<Edit3 size={20} color={DARK_CYAN} />}
              />
            </ReactTooltip>
          )}
          <Typography
            data-tip
            data-for={`${lineNum}tit`}
            noWrap
            variant="body1"
            data-testid="tipografia-titulo-campanha"
          >
            {tituloCampanha}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="body1"
            align="left"
            data-testid="tipografia-categoria"
          >
            {categoria}
          </Typography>
        </TableCell>

        <TableCell>
          <Grid direction="row" alignItems="center" container>
            <Users color={GRAY} size={20} className={classes.iconFields} />
            <Typography
              variant="body1"
              align="left"
              data-testid="tipografia-total-alunos"
            >
              {totalAlunos}
            </Typography>
          </Grid>
        </TableCell>

        <TableCell>
          <Grid direction="row" alignItems="center" container>
            <Phone color={GRAY} size={20} className={classes.iconFields} />
            <Typography
              variant="body1"
              align="left"
              data-testid="tipografia-total-atendidos"
            >
              {totalRecebidos}
            </Typography>
          </Grid>
        </TableCell>

        <TableCell>
          <Grid direction="row" alignItems="center" container>
            <PhoneCall color={GRAY} size={20} className={classes.iconFields} />
            <Typography
              variant="body1"
              align="left"
              data-testid="tipografia-total-aguardando"
            >
              {totalAguardando}
            </Typography>
          </Grid>
        </TableCell>

        <TableCell>
          <Typography
            variant="body1"
            align="left"
            data-testid="tipografia-total-evasao"
          >
            {totalEvasao}%
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="body1"
            align="left"
            data-testid="tipografia-data-inicio"
          >
            {dataInicio}
          </Typography>
        </TableCell>

        <TableCell align="right">
          <IconButton
            data-testid="icon-delete"
            className="ml-2"
            onClick={() => setAbrirModalExclusaoCampanha(true)}
          >
            <Trash2 size={20} color={DARK_GRAY_OP_1} />
          </IconButton>
        </TableCell>
      </TableRow>

      <ModalExclusaoCampanha
        modalAberto={abrirModalExclusaoCampanha}
        setModalAberto={setAbrirModalExclusaoCampanha}
        idCampanha={idCampanha}
        handleExclusao={handleExclusao}
        exclusaoCampanhaWhatsapp
      />
    </>
  );
};

export const mapStateToProps = () => ({});

const mapDispatchToProps = {
  notificarSucesso: exibirNotificacaoSucesso,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinhaCampanha);
