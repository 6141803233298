import WhatsappService from '../../services/WhatsappService';

class GestaoMensagensManager {
  async buscarMensagensWhatsapp() {
    return WhatsappService.listaMensagensWhatsapp();
  }

  async deletarMensagemWhatsapp(idMensagem) {
    return WhatsappService.deletarMensagemWhatsapp(idMensagem);
  }
}

export default new GestaoMensagensManager();
