import axios from '../apis/axiosInstanceScape0Crm';
import { checkResponse } from '../shared/util/Utils';

class SessionService {
  async getSession() {
    return axios.get('/session').then(checkResponse);
  }

  async fecharSessao(queryStringParam) {
    return axios
      .delete('/session', { params: queryStringParam })
      .then(checkResponse);
  }
}

export default new SessionService();
