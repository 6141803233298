import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { Clock } from 'react-feather';
import Tooltip from '@material-ui/core/Tooltip';
import { GRAY, DARK_CYAN } from '../../../../theme';
import { convertMinutesToHoursAndMinutes } from '../../../../shared/util/Utils';

const useStyles = makeStyles({
  icone: {
    width: '25px',
    height: '29px',
    color: GRAY,
    marginRight: '6px',
  },
  totalDeAtendimentoTitulo: {
    marginBottom: '10px',
    lineHeight: '18px',
    width: '100px',
  },
  tempoTotalAtendimento: {
    marginBottom: '10px',
    color: DARK_CYAN,
    fontWeight: 'Bold',
    fontSize: '14px',
    textAlign: 'center',
  },
  tempoMedioPorcentagem: {
    marginBottom: '10px',
    fontSize: '14px',
    textAlign: 'center',
  },
  gridTitulo: {
    marginTop: '2px',
    width: '105px',
  },
});

const TempoTotalAtendimento = (props) => {
  const {
    tempoTotalAtendimentoAtendente,
    tempoMedioPorcentagem,
    operatorName,
  } = props;

  const classes = useStyles();

  return (
    <Grid container item direction="row" justifyContent="center">
      <Grid container direction="row" justifyContent="center">
        <Clock className={classes.icone} />
        <Typography className={classes.totalDeAtendimentoTitulo}>
          Tempo Total de Atendimento
        </Typography>
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <Tooltip title={`Tempo total de atendimento de ${operatorName}.`}>
          <Typography
            noWrap
            className={classes.tempoTotalAtendimento}
            variant="body1"
          >
            {convertMinutesToHoursAndMinutes(tempoTotalAtendimentoAtendente)}
          </Typography>
        </Tooltip>
        <Grid item style={{ marginTop: '-10px' }}>
          <Tooltip
            title={`Percentual de tempo de atendimento de ${operatorName} em relação ao total de tempo de todos os atendimentos do time.`}
          >
            <Typography noWrap className={classes.tempoMedioPorcentagem}>
              {tempoMedioPorcentagem}%
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TempoTotalAtendimento;
