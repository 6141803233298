import {
  Grid,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import React, { useState } from 'react';
import { Edit3 } from 'react-feather';
import { DARK_CYAN, FONT_FAMILY_REGULAR, GRAY } from '../../theme';
import SelectSimples from './SelectSimples';

const useStyles = makeStyles({
  inputLabel: {
    fontSize: '14px',
    color: GRAY,
    fontWeight: '300',
    fontFamily: FONT_FAMILY_REGULAR,
    marginRight: '20px',
    textAlign: 'end',
  },
  iconeLabelWhatsappSelect: {
    color: DARK_CYAN,
    marginRight: '4px',
  },
  labelWhatsappSelect: {
    fontSize: '14px',
    color: DARK_CYAN,
  },
  searchField: {
    width: '99%',
    padding: '8px 8px 8px 0px',
  },
  gridOrientacoes: {
    marginTop: '12px',
  },
  orientacoes: {
    width: '100%',
  },
  container: {
    marginTop: '15px',
  },
  switchMarcas: {
    width: '48px',
    height: '22px',
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(25px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#067A74',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#048a83',
        border: '6px solid #fff',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 19,
      height: 19,
    },
    '& .MuiSwitch-track': {
      borderRadius: 28 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
    },
  },
  linhaSelecao: {
    margin: '4px 20px 4px 0px',
    '& .MuiPopover-paper': {
      marginTop: '38px',
    },
    display: 'inline-flex',
  },
  linhaMarcas: {
    justifyContent: 'right',
    '& .MuiTypography-root': {
      marginRight: '8px',
    },
    '& .MuiGrid-root': {
      marginRight: '8px',
    },
    '& .MuiSwitch-root': {
      marginRight: '8px',
    },
  },
});

const AbaWhatsappScapeZero = (props) => {
  const {
    carregando,
    marcaWhatsapp,
    setMarcaWhatsapp,
    listaMarcasWhatsapp,
    primeiroContato,
    setPrimeiroContato,
    opcoesPrimeiroContato,
    segundoContato,
    setSegundoContato,
    opcoesSegundoContato,
    listaMensagens,
    primeiraMensagem,
    setPrimeiraMensagem,
    segundaMensagem,
    setSegundaMensagem,
    terceiraMensagem,
    setTerceiraMensagem,
    orientacoes,
    setOrientacoes,
    switchHabilitado,
    setSwitchHabilitado,
  } = props;

  const classes = useStyles();

  const [tituloMarca, setTituloMarca] = useState('Selecione uma marca');

  const handleSwitchChange = () => {
    if (switchHabilitado === false) {
      setTituloMarca('IES do Destinatário');
      setMarcaWhatsapp('');
    } else {
      setTituloMarca('Selecione uma marca');
    }
    setSwitchHabilitado(!switchHabilitado);
  };
  return (
    <Grid
      item
      container
      className={classes.container}
      data-testid="aba-whatsapp-scape-zero"
    >
      <Grid item container>
        <Grid item container xs={10} alignItems="center">
          <WhatsAppIcon
            size={20}
            className={classes.iconeLabelWhatsappSelect}
          />
          <Typography variant="h5" className={classes.labelWhatsappSelect}>
            Automação de Mensagens por Whatsapp
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        display="inline"
        alignItems="center"
        className={`${classes.linhaSelecao} ${classes.linhaMarcas}`}
      >
        <Typography
          display="inline"
          variant="h5"
          className={classes.labelWhatsappSelect}
        >
          Marca do Remetente
        </Typography>
        <SelectSimples
          carregando={carregando}
          titulo={tituloMarca}
          listaOpcoes={listaMarcasWhatsapp}
          opcao={marcaWhatsapp || ''}
          setOpcao={setMarcaWhatsapp}
          disabled={switchHabilitado}
          width="49%"
          height="35px"
          listHeight="25vh"
        />
        <Switch
          className={classes.switchMarcas}
          onChange={handleSwitchChange}
          checked={switchHabilitado}
          data-testid="switch-ies-destinatario"
        />
        <Typography
          display="inline"
          variant="h5"
          className={classes.labelWhatsappSelect}
        >
          Utilizar IES do Destinatário
        </Typography>
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.linhaSelecao}
      >
        <Typography
          display="inline"
          variant="h5"
          className={classes.labelWhatsappSelect}
        >
          Após
        </Typography>

        <SelectSimples
          carregando={carregando}
          titulo="X"
          listaOpcoes={opcoesPrimeiroContato}
          opcao={primeiroContato}
          setOpcao={setPrimeiroContato}
          width="8%"
          height="35px"
          listHeight="25vh"
          disabled={!(switchHabilitado || marcaWhatsapp)}
        />
        <Typography
          display="inline"
          variant="h5"
          className={classes.labelWhatsappSelect}
        >
          contatos
        </Typography>
        <SelectSimples
          carregando={carregando}
          titulo="Título da mensagem de automação"
          listaOpcoes={listaMensagens}
          opcao={primeiraMensagem}
          setOpcao={setPrimeiraMensagem}
          width="78%"
          height="35px"
          listHeight="25vh"
          disabled={!(switchHabilitado || marcaWhatsapp)}
          removalButton={!!primeiraMensagem}
        />
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.linhaSelecao}
      >
        <Typography
          display="inline"
          variant="h5"
          className={classes.labelWhatsappSelect}
        >
          Após
        </Typography>
        <SelectSimples
          carregando={carregando}
          titulo="X"
          listaOpcoes={opcoesSegundoContato}
          opcao={segundoContato}
          setOpcao={setSegundoContato}
          width="8%"
          height="35px"
          listHeight="25vh"
          disabled={!(switchHabilitado || marcaWhatsapp)}
        />
        <Typography
          display="inline"
          variant="h5"
          className={classes.labelWhatsappSelect}
        >
          contatos
        </Typography>
        <SelectSimples
          carregando={carregando}
          titulo="Título da mensagem de automação"
          listaOpcoes={listaMensagens}
          opcao={segundaMensagem}
          setOpcao={setSegundaMensagem}
          width="78%"
          height="35px"
          listHeight="25vh"
          disabled={!(switchHabilitado || marcaWhatsapp)}
          removalButton={!!segundaMensagem}
        />
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.linhaSelecao}
      >
        <Typography
          display="inline"
          variant="h5"
          className={classes.labelWhatsappSelect}
        >
          Ao término da Campanha
        </Typography>
        <SelectSimples
          carregando={carregando}
          titulo="Título da mensagem de automação"
          listaOpcoes={listaMensagens}
          opcao={terceiraMensagem}
          setOpcao={setTerceiraMensagem}
          width="78%"
          height="35px"
          listHeight="25vh"
          disabled={!(switchHabilitado || marcaWhatsapp)}
          removalButton={!!terceiraMensagem}
        />
      </Grid>

      <Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        wrap="nowrap"
      >
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          className={classes.gridOrientacoes}
        >
          <Grid item container xs={10}>
            <Edit3 size={20} className={classes.iconeLabelWhatsappSelect} />
            <Typography variant="h5" className={classes.labelWhatsappSelect}>
              Orientações ao Atendimento
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" className={classes.inputLabel}>
              {orientacoes.length}/1000
            </Typography>
          </Grid>
        </Grid>
        <Grid item container className={classes.searchField}>
          <TextField
            placeholder="Insira as orientações para sua campanha"
            variant="filled"
            minRows={10}
            multiline
            inputProps={{
              style: {
                fontWeight: 'normal',
                fontSize: '15px',
                minHeight: '100px',
              },
              'data-testid': 'textfield-orientacoes-criar-campanha',
              maxLength: 1000,
            }}
            className={classes.orientacoes}
            onChange={(e) => setOrientacoes(e.target.value)}
            value={orientacoes}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AbaWhatsappScapeZero;
