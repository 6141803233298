/* eslint-disable react/jsx-props-no-spreading */
import { Calendar, ChevronDown } from 'react-feather';
import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  StaticDateTimePicker,
  LocalizationProvider,
} from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';

import {
  Grid,
  ClickAwayListener,
  TextField,
  InputAdornment,
} from '@material-ui/core';

import { DARK_CYAN, GRAY, SHADOW_DROPDOWN, WHITE } from '../../theme';
import '../css-componentes/DropdownDateTimePicker.css';
import { validarCaractereDropdownDatePicker } from '../util/Utils';

const useStyles = makeStyles({
  containerInterno: {
    position: 'absolute',
    zIndex: '1',
    backgroundColor: WHITE,
    maxHeight: '20rem',
    boxShadow: `0 0 18px -1px ${SHADOW_DROPDOWN}`,
    borderRadius: '8px',
    left: '-20rem',
  },
  containerExterno: {
    position: 'relative',
    height: '0px',
    width: '0px',
  },
  icon: {
    cursor: 'pointer',
    color: (props) => (props.mostrarDropdown ? DARK_CYAN : GRAY),
    marginRight: '10px',
    marginBottom: '3px',
  },
  iconCalendar: {
    color: GRAY,
    marginRight: '10px',
    marginBottom: '3px',
  },
});

const DropdownDateTimePicker = (props) => {
  const {
    dataHora,
    setDataHora,
    mostrarDropdown,
    setMostrarDropdown,
    top,
    left,
    inputValueOnInit,
    placeholder,
    disabled,
    bloquearDataNoPassado,
  } = props;

  const [textoDataHora, setTextoDataHora] = useState(
    inputValueOnInit ? moment(dataHora).format('DD/MM/YYYY HH:mm') : ''
  );

  const classes = useStyles(props);

  const definirDataHora = (novaData) => {
    if (novaData && novaData.isValid()) {
      const dataHoraFormatada = moment(novaData).format('DD/MM/YYYY HH:mm');
      setDataHora(novaData.toDate());
      setTextoDataHora(dataHoraFormatada);
    }
  };

  const handleChange = (event) => {
    if (event.target.value.length > 16) return;
    if (!validarCaractereDropdownDatePicker(event.target.value)) return;
    const dataAlterada = moment(event.target.value, 'DD/MM/YYYY HH:mm', true);
    if (dataAlterada && dataAlterada.isValid()) {
      definirDataHora(dataAlterada);
      setMostrarDropdown(false);
    } else {
      setTextoDataHora();
      setTextoDataHora(setDataHora);
    }
  };

  const handlerAbrirModal = () => {
    if (!textoDataHora && !disabled) {
      setMostrarDropdown(true);
    }
  };

  return (
    <>
      <TextField
        style={{ width: '98%' }}
        size="medium"
        disabled={disabled}
        placeholder={placeholder}
        onChange={(event) => handleChange(event)}
        onClick={() => handlerAbrirModal()}
        InputProps={{
          style: {
            paddingLeft: 16,
            paddingTop: 2,
            paddingBottom: 1,
          },
          endAdornment: (
            <InputAdornment position="end" disablePointerEvents={disabled}>
              {disabled ? (
                <Calendar size={18} className={classes.icon} />
              ) : (
                <ChevronDown
                  size={18}
                  className={classes.icon}
                  onClick={() => setMostrarDropdown(true)}
                />
              )}
            </InputAdornment>
          ),
        }}
        value={textoDataHora}
      />
      {mostrarDropdown && (
        <ClickAwayListener onClickAway={() => setMostrarDropdown(false)}>
          <Grid container className={classes.containerExterno}>
            <Grid className={classes.containerInterno} style={{ top, left }}>
              <LocalizationProvider
                dateLibInstance={moment}
                dateAdapter={MomentAdapter}
              >
                <StaticDateTimePicker
                  displayStaticWrapperAs="desktop"
                  disablePast={bloquearDataNoPassado}
                  value={dataHora}
                  onClose={() => setMostrarDropdown(false)}
                  onChange={(novaData) => definirDataHora(novaData)}
                  renderInput={(startProps) => (
                    <>
                      <TextField {...startProps} />
                    </>
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </ClickAwayListener>
      )}
    </>
  );
};

export default DropdownDateTimePicker;
