import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../util/css-components/Dropdown.css';
import {
  Checkbox,
  Grid,
  Typography,
  ClickAwayListener,
  IconButton,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import CloseIcon from '@material-ui/icons/Close';
import DropdownItem from './DropdownItem';
import { normalizeText } from '../util/Utils';
import { GRAY, BLACK, SHADOW_DROPDOWN, WHITE, DARK_CYAN } from '../../theme';

const useStyles = makeStyles({
  pesquisa: {
    width: '91%',
    margin: '20px 0px 10px 0px',
    fontSize: '17px',
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid',
      borderColor: DARK_CYAN,
    },
    '& label.Mui-focused': {
      color: DARK_CYAN,
      fontWeight: '500',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(20px, -5px) scale(0.75) !important',
      transformOrigin: 'top left !important',
      backgroundColor: WHITE,
      paddingRight: '11px',
    },
  },
  emptyState: {
    marginTop: '6px',
    position: 'relative',
    left: '10%',
  },
  icon: {
    color: GRAY,
  },
  input: {
    fontSize: '12px',
    color: BLACK,
  },
  containerInterno: {
    position: 'absolute',
    zIndex: '1',
    backgroundColor: WHITE,
    maxHeight: '20rem',
    boxShadow: `0 0 18px -1px ${SHADOW_DROPDOWN}`,
    borderRadius: '4px',
  },
  containerExterno: {
    position: 'relative',
    height: '0px',
    width: '0px',
  },
  opcaoTodos: {
    cursor: 'pointer',
    marginLeft: '0.5px',
  },
  itens: {
    overflowY: 'scroll',
    scrollbarColor: '#bbbbbe white',
    scrollbarWidth: 'thin',
    overflowX: 'hidden',
    maxHeight: '11rem',
    paddingBottom: '10px',
  },
  scroll: {
    overflow: 'hidden !important',
  },
});

export default function Dropdown(props) {
  const {
    mostrarDropdown,
    setMostrarDropdown,
    listaItensInseridos,
    setListaItensInseridos,
    listaItens,
    setListaItens,
    descricaoItem,
    top,
    left,
    showOnTop,
    width = '16rem',
  } = props;

  const classes = useStyles();
  const [input, setInput] = useState('');
  const [scrollItems, setScrollItems] = useState([]);
  const [checkedTodos, setCheckedTodos] = useState(false);
  const [distanciaDropdownTop, setDistanciaDropdownTop] = useState('');

  useEffect(() => {
    setScrollItems(listaItens);
    setCheckedTodos(false);
    setInput('');
  }, [listaItens]);

  useEffect(() => {
    if (showOnTop && mostrarDropdown) {
      const elementoDropdown = document.getElementById('container-dropdown');
      setDistanciaDropdownTop(
        `${-elementoDropdown.offsetHeight + parseInt(top, 10)}px`
      );
    } else {
      setDistanciaDropdownTop(top);
    }
  }, [showOnTop, top, mostrarDropdown, input]);

  function adicionarOuRemoverItem(lista, item) {
    if (item.checked) {
      if (!lista.includes(item)) lista.push(item);
    } else {
      const index = lista.indexOf(item);
      if (index > -1) {
        lista.splice(index, 1);
      }
    }

    setListaItensInseridos([...lista]);
  }

  function handleChangeTodos() {
    for (const item of listaItens) {
      if (normalizeText(item.title).indexOf(normalizeText(input)) !== -1) {
        item.checked = !checkedTodos;
        adicionarOuRemoverItem(listaItensInseridos, item);
      }
    }
    setCheckedTodos(!checkedTodos);
    setListaItens(listaItens);
  }

  function handleChangeCheckbox(item, index) {
    if (item && index >= 0 && scrollItems[index]) {
      scrollItems[index].checked = !scrollItems[index].checked;
      setScrollItems(scrollItems);

      adicionarOuRemoverItem(listaItensInseridos, item);

      setCheckedTodos(listaItens.length === listaItensInseridos.length);
    }
  }

  function handleSearch(termo) {
    setInput(termo);
    const novosItens =
      termo.length > 0
        ? listaItens.filter(
            (item) =>
              normalizeText(item.title).indexOf(normalizeText(termo)) !== -1
          )
        : listaItens;

    setScrollItems(novosItens);

    for (const item of novosItens) {
      if (!item.checked) {
        setCheckedTodos(false);
        return;
      }
    }
    setCheckedTodos(true);
  }

  return (
    <>
      {mostrarDropdown && (
        <ClickAwayListener onClickAway={() => setMostrarDropdown(false)}>
          <Grid container className={classes.containerExterno}>
            <Grid
              className={classes.containerInterno}
              style={{ top: distanciaDropdownTop, left, width }}
              id="container-dropdown"
            >
              {listaItens.length > 5 && (
                <Grid justifyContent="center" container>
                  <TextField
                    value={input}
                    autoFocus
                    onChange={(event) => handleSearch(event.target.value)}
                    className={classes.pesquisa}
                    label={`Pesquise por ${descricaoItem}`}
                    size="small"
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {!input && !input.length ? (
                            <SearchOutlinedIcon className={classes.icon} />
                          ) : (
                            <IconButton
                              aria-label="Limpar texto"
                              onClick={() => handleSearch('')}
                            >
                              <CloseIcon className={classes.icon} />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                      className: classes.input,
                    }}
                  />
                </Grid>
              )}
              {scrollItems.length > 0 && (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  className={classes.opcaoTodos}
                  onClick={() => handleChangeTodos()}
                >
                  <Checkbox
                    checked={checkedTodos}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <Typography variant="h6">{`TODAS AS OPÇÕES ${
                    input && 'EM EXIBIÇÃO'
                  }`}</Typography>
                </Grid>
              )}

              {!scrollItems.length && (
                <Typography noWrap variant="h5" className={classes.emptyState}>
                  Nenhum resultado encontrado
                </Typography>
              )}

              <Grid className={classes.itens} id="scrollable">
                {scrollItems.map((item, index) => (
                  <DropdownItem
                    handleChange={() => handleChangeCheckbox(item, index)}
                    label={item.title}
                    item={item}
                    key={index}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </ClickAwayListener>
      )}
    </>
  );
}
