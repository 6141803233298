import React, { useEffect } from 'react';
import { Snackbar, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

import { DARK_CYAN } from '../../../theme';

const NotificacaoSucesso = (props) => {
  const {
    mensagemSucesso,
    ocultarMensagemSucesso,
    classeSnackBar,
    classeSnackBarText,
    iconeSucesso,
    posicaoSucesso,
    timestamp,
  } = props;

  const useStyles = makeStyles(() => ({
    sucessoSnackBar: {
      color: `${DARK_CYAN} !important`,
    },
    sucessoSnackBarText: {
      color: `${DARK_CYAN} !important`,
    },
    posicao: {
      bottom: `${24 + posicaoSucesso * 56}px !important`,
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const getExpireTime = (timestampParam) => {
      const nowDate = new Date().getTime();
      if (nowDate - timestampParam < 5000)
        return 5000 - (nowDate - timestampParam);
      return 1;
    };
    const interval = setTimeout(() => {
      if (mensagemSucesso) {
        ocultarMensagemSucesso(timestamp);
      }
    }, getExpireTime(timestamp));
    return () => {
      clearTimeout(interval);
    };
  }, [mensagemSucesso, ocultarMensagemSucesso, timestamp]);

  const mensagemAberta = (mensagemSucesso && mensagemSucesso.trim().length) > 0;

  return (
    <Snackbar
      className={classes.posicao}
      open={mensagemAberta}
      onClose={() => ocultarMensagemSucesso(timestamp)}
      ClickAwayListenerProps={{
        mouseEvent: false,
      }}
    >
      <MuiAlert
        elevation={0}
        icon={iconeSucesso}
        severity="success"
        className={`${classes.snackBar} ${classeSnackBar}`}
      >
        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid item>
            <Typography
              className={`${classes.sucessoSnackBarText} ${classeSnackBarText}`}
            >
              {mensagemSucesso}
            </Typography>
          </Grid>
        </Grid>
      </MuiAlert>
    </Snackbar>
  );
};

export default NotificacaoSucesso;
