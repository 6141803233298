/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Typography, Grid, Box, Icon } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import { makeStyles } from '@material-ui/core/styles';
import SvgPillIcon01 from '../img/SvgPillIcon01';
import { DARK_CYAN } from '../../theme';
import {
  pluraisFiltroDesempenho,
  singularesFiltroDesempenho,
  opcoesFiltroDesempenho,
} from '../util/Constantes';

const useStyles = makeStyles({
  tooltip: {
    padding: '0px !important',
    opacity: '1 !important',
    minWidth: '225px',
    maxWidth: '320px',
    overflowX: 'hidden',
    overflowY: 'hidden',
    borderRadius: '8px !important',
    boxShadow: '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
  },
  containerIconeRemedio: {
    height: '22px',
    width: '22px',
    marginRight: '7px',
    marginTop: '-5px',
  },
  iconeRemedio: {
    fill: DARK_CYAN,
    height: '22px',
    width: '22px',
  },
});

const ConteudoTooltip = (props) => {
  const { title, icon, children, color } = props;
  const classes = useStyles();

  const renderizarIcone = () => {
    if (
      !title.includes(
        pluraisFiltroDesempenho[opcoesFiltroDesempenho.Remedio]
      ) ||
      !title.includes(
        singularesFiltroDesempenho[opcoesFiltroDesempenho.Remedio]
      )
    ) {
      return <Icon className={`tooltipIcon mr-2 ${color}`}>{icon}</Icon>;
    }
    return (
      <Icon className={classes.containerIconeRemedio}>
        <SvgPillIcon01 className={classes.iconeRemedio} />
      </Icon>
    );
  };

  return (
    <Grid>
      <Grid container direction="row" alignItems="center" className="mb-2">
        {icon && renderizarIcone()}
        <Typography className={`h4-dropdown ${color}`}> {title} </Typography>
      </Grid>
      {children}
    </Grid>
  );
};

const DefaultTooltip = (props) => {
  const { className, children, content, place, identifier, title } = props;

  const classes = useStyles();
  const tooltipId = `${identifier || ''}${title || ''}${place || ''}`;

  return (
    <>
      <ReactTooltip
        id={tooltipId}
        type="light"
        effect="solid"
        className={classes.tooltip}
        arrowColor="rgba(0, 0, 0, 0)"
        clickable
        data-event-off="click"
        offset={{ top: place === 'bottom' ? -15 : 0 }}
        {...props}
      >
        <Box p={2}>
          <ConteudoTooltip {...props} title={title}>
            {content}
          </ConteudoTooltip>
        </Box>
      </ReactTooltip>

      <span
        data-tip
        data-for={tooltipId}
        className={className}
        style={{ opacity: '1 !important' }}
      >
        {children}
      </span>
    </>
  );
};

export default DefaultTooltip;
