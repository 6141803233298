export const ATUALIZAR_IS_LOADING = 'app/loading/CHANGE_IS_LOADING';

export const INITIAL_STATE = {
  isLoading: false,
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  if (action.type === ATUALIZAR_IS_LOADING) {
    return {
      ...state,
      isLoading: action.payload,
    };
  }
  return state;
}

export const atualizarIsLoading = (isLoading) => {
  return {
    type: ATUALIZAR_IS_LOADING,
    payload: isLoading,
  };
};
