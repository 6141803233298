import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import { User, Users } from 'react-feather';
import { DARK_CYAN, GRAY, ORANGE } from '../../theme';
import ItemFiltrosPreVisualizar from './ItemFiltrosPreVisualizar';
import { formatarDataParaString, deepEqual } from '../util/Utils';

const useStyles = makeStyles({
  titulo: {
    fontSize: '13px',
    marginLeft: '4px',
    color: (props) => props.coloracaoFiltros || DARK_CYAN,
  },
  icone: {
    color: (props) => props.coloracaoFiltros || DARK_CYAN,
    fontSize: '20px',
  },
  containerTituloTooltip: {
    marginTop: '16px',
  },
  labelOriginal: {
    fontSize: '13px',
    marginLeft: '4px',
    color: GRAY,
  },
  labelEditada: {
    fontSize: '13px',
    marginLeft: '4px',
    color: ORANGE,
  },
  labelCriarCampanha: {
    fontSize: '13px',
    marginLeft: '4px',
    color: DARK_CYAN,
  },
});

const FiltrosPreVisualizar = (props) => {
  const {
    categoria,
    situacao,
    formaDeIngresso,
    perfilAluno,
    marca,
    campus,
    curso,
    modalidade,
    turno,
    dataInicioMatricula,
    dataTerminoMatricula,
    coloracaoFiltros,
    realceFiltroEditado,
    grupoDeVisualizacaoOriginal,
    grupoDeVisualizacao,
    criarCampanhaUpload,
    edicaoCampanha,
  } = props;
  const classes = useStyles(props);

  const compararGruposDeVisualizacao = () => {
    if (!grupoDeVisualizacaoOriginal) {
      return false;
    }
    return deepEqual(
      grupoDeVisualizacaoOriginal.sort(),
      grupoDeVisualizacao.sort()
    );
  };

  const verificarEdicaoCampanhaParaAlterarCor = () => {
    if (edicaoCampanha) {
      return compararGruposDeVisualizacao()
        ? coloracaoFiltros
        : realceFiltroEditado;
    }
    return DARK_CYAN;
  };

  const verificarEdicaoCampanhaParaAlterarClasse = () => {
    if (edicaoCampanha) {
      return compararGruposDeVisualizacao()
        ? classes.labelOriginal
        : classes.labelEditada;
    }
    return classes.labelCriarCampanha;
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={classes.containerInfo}
      >
        {(grupoDeVisualizacao || grupoDeVisualizacaoOriginal) && (
          <>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.containerTituloTooltip}
              >
                <Users
                  size={18}
                  className={classes.icone}
                  color={verificarEdicaoCampanhaParaAlterarCor()}
                />
                <Typography
                  className={`${verificarEdicaoCampanhaParaAlterarClasse()} pl-1`}
                  variant="h6"
                  noWrap
                >
                  Grupos de Visualização
                </Typography>
              </Grid>
            </Grid>
            <ItemFiltrosPreVisualizar
              descricao="Grupos"
              valores={
                !compararGruposDeVisualizacao()
                  ? grupoDeVisualizacao
                  : grupoDeVisualizacaoOriginal
              }
            />
          </>
        )}
        {!criarCampanhaUpload && (
          <>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.containerTituloTooltip}
              >
                <User size={18} color={coloracaoFiltros || DARK_CYAN} />
                <Typography
                  className={`${classes.titulo} pl-1`}
                  variant="h6"
                  noWrap
                >
                  Objetivos da campanha
                </Typography>
              </Grid>
            </Grid>
            <ItemFiltrosPreVisualizar
              descricao="Categoria"
              valores={categoria}
            />
            <ItemFiltrosPreVisualizar descricao="Situação" valores={situacao} />
            <ItemFiltrosPreVisualizar
              descricao="Perfil Aluno"
              valores={perfilAluno}
            />
            <ItemFiltrosPreVisualizar
              descricao="Forma de Ingresso"
              valores={formaDeIngresso}
            />
            <ItemFiltrosPreVisualizar
              descricao="Data de Matrícula Inicial"
              valores={formatarDataParaString(dataInicioMatricula)}
              naoExpandir
            />
            <ItemFiltrosPreVisualizar
              descricao="Data de Matrícula Final"
              valores={formatarDataParaString(dataTerminoMatricula)}
              naoExpandir
            />
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.containerTituloTooltip}
              >
                <BusinessRoundedIcon className={classes.icone} />
                <Typography
                  className={`${classes.titulo} pl-1`}
                  variant="h6"
                  noWrap
                >
                  Parâmetros da IES
                </Typography>
              </Grid>
            </Grid>
            <ItemFiltrosPreVisualizar
              descricao="Modalidade"
              valores={modalidade}
            />
            <ItemFiltrosPreVisualizar descricao="Marca" valores={marca} />
            <ItemFiltrosPreVisualizar descricao="Campus" valores={campus} />
            <ItemFiltrosPreVisualizar descricao="Curso" valores={curso} />
            <ItemFiltrosPreVisualizar descricao="Turno" valores={turno} />
          </>
        )}
      </Grid>
    </>
  );
};

export default FiltrosPreVisualizar;
