import axios from '../apis/axiosInstanceScape0Crm';

class FiltroDesempenhoService {
  async buscarFiltros() {
    return axios.get('/analytics/conversation_filter').then(this.checkResponse);
  }

  async getCsvURL(payload) {
    return axios
      .post('analytics/conversation', payload)
      .then(this.checkResponse);
  }

  checkResponse(response) {
    if (response && response.data && response.data) {
      return response.data;
    }
    return undefined;
  }

  async buscarListaArquivosPerformance() {
    return axios.get('/analytics/conversation').then(this.checkResponse);
  }
}

export default new FiltroDesempenhoService();
