import { inicializarDataInicialDesempenho } from '../../shared/util/Utils';

// Types
export const INSERIR_DATA_INICAL = 'app/filtro/INSERIR_DATA_INICAL';
export const INSERIR_DATA_FINAL = 'app/filtro/INSERIR_DATA_FINAL';
export const INSERIR_ATENDENTE = 'app/filtro/INSERIR_ATENDENTE';
export const INSERIR_CAMPANHA = 'app/filtro/INSERIR_CAMPANHA';
export const INSERIR_SITUACAO = 'app/filtro/INSERIR_SITUACAO';
export const INSERIR_PROBLEMA = 'app/filtro/INSERIR_PROBLEMA';
export const INSERIR_REMEDIO = 'app/filtro/INSERIR_REMEDIO';

// Reducer
export const INITIAL_STATE = {
  dataInicial: inicializarDataInicialDesempenho(),
  dataFinal: new Date(),
  atendente: [],
  campanha: [],
  situacao: [],
  problema: [],
  remedio: [],
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case INSERIR_DATA_INICAL:
      return {
        ...state,
        dataInicial: action.payload,
      };
    case INSERIR_DATA_FINAL:
      return {
        ...state,
        dataFinal: action.payload,
      };
    case INSERIR_ATENDENTE:
      return {
        ...state,
        atendente: action.payload,
      };
    case INSERIR_CAMPANHA:
      return {
        ...state,
        campanha: action.payload,
      };
    case INSERIR_SITUACAO:
      return {
        ...state,
        situacao: action.payload,
      };
    case INSERIR_PROBLEMA:
      return {
        ...state,
        problema: action.payload,
      };
    case INSERIR_REMEDIO:
      return {
        ...state,
        remedio: action.payload,
      };
    default:
      return state;
  }
}

// Action creators
export const atualizarDataInicial = (novaDataInicial) => {
  return {
    type: INSERIR_DATA_INICAL,
    payload: novaDataInicial,
  };
};

export const atualizarDataFinal = (novaDataFinal) => {
  return {
    type: INSERIR_DATA_FINAL,
    payload: novaDataFinal,
  };
};

export const atualizarAtendente = (atendente) => {
  return {
    type: INSERIR_ATENDENTE,
    payload: atendente,
  };
};

export const atualizarCampanha = (campanha) => {
  return {
    type: INSERIR_CAMPANHA,
    payload: campanha,
  };
};

export const atualizarSituacao = (situacao) => {
  return {
    type: INSERIR_SITUACAO,
    payload: situacao,
  };
};

export const atualizarProblema = (problema) => {
  return {
    type: INSERIR_PROBLEMA,
    payload: problema,
  };
};

export const atualizarRemedio = (remedio) => {
  return {
    type: INSERIR_REMEDIO,
    payload: remedio,
  };
};
