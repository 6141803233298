import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  createTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography, Grid } from '@material-ui/core';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import { Repeat, PhoneCall, Clock, UserX } from 'react-feather';
import { LIGHT_GRAY, GRAY } from '../../../../theme';
import { labelsGraficoProdutividade } from '../../../../shared/util/Constantes';
import SvgPillIcon01 from '../../../../shared/img/SvgPillIcon01';

const useStyles = makeStyles({
  barraProgresso: {
    width: '100%',
    height: 7,
    borderRadius: 5,
    backgroundColor: LIGHT_GRAY,
    justifySelf: 'flex-start',
  },
  primeiroValor: {
    fontSize: '14px',
    marginLeft: '4px',
    width: '45px',
  },
  icone: {
    width: '16px',
    height: '16px',
    color: GRAY,
    marginRight: '9px',
  },
  containerInfo: {
    marginBottom: '5px',
    display: 'grid',
    gridTemplateColumns: 'min-content 95px auto 45px 50px',
    width: '100%',
  },
  containerPerfilInfo: {
    marginBottom: '10px',
    display: 'grid',
    gridTemplateColumns: 'min-content 95px auto 45px 65px',
    width: '100%',
  },
});

const themeLinearProgress = createTheme({
  palette: {
    primary: {
      main: '#0EA789',
    },
    secondary: {
      main: '#F09300',
    },
  },
});

const InformacaoItemProdutividade = (props) => {
  const { tipo, porcentagem, valorReal, perfilProdutividade } = props;

  const [corBarraLinear, setCorBarraLinear] = useState();

  useEffect(() => {
    if (porcentagem >= 50) {
      setCorBarraLinear('primary');
    } else {
      setCorBarraLinear('secondary');
    }
  }, [porcentagem]);

  const icones = {};
  icones[labelsGraficoProdutividade.Tentativas] = Repeat;
  icones[labelsGraficoProdutividade.Atendimentos] = PhoneCall;
  icones[labelsGraficoProdutividade.TotalAtend] = Clock;
  icones[labelsGraficoProdutividade.Inelegiveis] = UserX;
  icones[labelsGraficoProdutividade.MediaDeAtendimento] =
    HeadsetMicOutlinedIcon;

  const classes = useStyles();

  const renderizarIcone = (type) => {
    const Ico = icones[type];
    return (
      <>
        {type !== labelsGraficoProdutividade.Remedios ? (
          <> {Ico && <Ico className={classes.icone} />} </>
        ) : (
          <SvgPillIcon01 className={classes.icone} style={{ fill: GRAY }} />
        )}
      </>
    );
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      className={
        perfilProdutividade
          ? classes.containerPerfilInfo
          : classes.containerInfo
      }
    >
      {renderizarIcone(tipo)}
      <Typography noWrap variant="body1">
        {tipo}
      </Typography>
      <MuiThemeProvider theme={themeLinearProgress}>
        <LinearProgress
          color={corBarraLinear}
          className={classes.barraProgresso}
          variant="determinate"
          value={Number(porcentagem)}
        />
      </MuiThemeProvider>
      <Typography
        align="right"
        noWrap
        variant="h3"
        className={classes.primeiroValor}
      >
        {valorReal}
      </Typography>
      <Typography align="right" noWrap variant="body1">
        {porcentagem}%
      </Typography>
    </Grid>
  );
};

export default InformacaoItemProdutividade;
