import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { GRAY, DARK_CYAN } from '../../../../theme';

const useStyles = makeStyles({
  icone: {
    width: '25px',
    height: '29px',
    color: GRAY,
    marginRight: '6px',
  },
  mediaPorAtendimentoTitulo: {
    marginBottom: '10px',
    fontSize: '14px',
    lineHeight: '18px',
  },
  tempoMedioAtendente: {
    color: DARK_CYAN,
    fontWeight: 'Bold',
    fontSize: '14px',
    marginBottom: '10px',
  },
  tempoMedioTodosAtendimentos: {
    fontSize: '14px',
    marginBottom: '10px',
  },
  gridTitulo: {
    marginTop: '2px',
    width: '100px',
  },
});

const MediaAtendimento = (props) => {
  const { tempoMedioAtendente, tempoMedioTodosAtendimentos, operatorName } =
    props;

  const classes = useStyles();

  return (
    <Grid container item direction="row">
      <Grid container item direction="column" alignItems="center">
        <Grid container item direction="row" justifyContent="center">
          <Grid item>
            <HeadsetMicOutlinedIcon
              className={classes.icone}
              style={{ color: GRAY }}
            />
          </Grid>
          <Grid item className={classes.gridTitulo}>
            <Typography
              align="left"
              className={classes.mediaPorAtendimentoTitulo}
            >
              Média por Atendimento
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Tooltip title={`Média por atendimento de ${operatorName}`}>
              <Typography
                noWrap
                className={classes.tempoMedioAtendente}
                variant="body1"
              >
                {tempoMedioAtendente}min
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item style={{ marginTop: '-10px' }}>
            <Tooltip title="Média por atendimento de todo o time">
              <Typography
                noWrap
                className={classes.tempoMedioTodosAtendimentos}
              >
                {tempoMedioTodosAtendimentos}min
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MediaAtendimento;
