import React from 'react';
import { Grid } from '@material-ui/core';
import { Clock } from 'react-feather';
import GraficoTempoAtendimento from './GraficoTempoAtendimento/GraficoTempoAtendimento';
import CardTitle from '../../../shared/util/components/CardTitle';

const Graficos = (props) => {
  const { filtro } = props;

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Grid
        item
        container
        justifyContent="space-around"
        alignItems="flex-start"
        id="grafico-tempo-atendimento"
      >
        <CardTitle
          text="Tempo de Atendimento"
          icon={<Clock />}
          style={{
            marginTop: '16.8px',
            marginLeft: '21px',
          }}
        />

        <GraficoTempoAtendimento filtro={filtro} />
      </Grid>
    </Grid>
  );
};

export default Graficos;
