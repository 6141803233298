import React, { useState } from 'react';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import { Grid, Typography, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Upload, Trash2 } from 'react-feather';
import { connect } from 'react-redux';
import { exibirNotificacaoAlerta } from '../../notificacao/Store';
import {
  mensagensNotificacaoAlerta,
  limiteArquivo4mb,
} from '../../../shared/util/Constantes';
import { DARK_CYAN, DARK_GRAY, TOMATO } from '../../../theme';
import Loading from '../../../shared/componentes/Loading';

const useStyles = makeStyles({
  subtitulo: {
    fontSize: '14px',
    color: DARK_CYAN,
  },
  containerUpload: {
    marginTop: '10px',
    marginBottom: '20px',
    marginLeft: '25px',
    position: 'relative',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  nomeDoArquivo: {
    color: DARK_CYAN,
    maxWidth: 'calc(100% - 300px)',
    paddingLeft: '5px',
  },
  textoAviso: {
    color: TOMATO,
    paddingRight: '5px',
  },
  iconeLixeira: {
    color: DARK_GRAY,
    paddingLeft: '5px',
  },
  iconeUpload: {
    color: DARK_GRAY,
    paddingRight: '5px',
  },
  iconeFiltragem: {
    color: DARK_CYAN,
    height: '20px',
    marginLeft: '-2px',
  },
});

export const UploadCampanhaUnrwapped = (props) => {
  const { arquivo, setArquivo, exibeNotificacaoAlerta } = props;
  const classes = useStyles();
  const [carregando, setCarregando] = useState(false);
  const inputArquivo = React.createRef();

  const removerArquivo = () => {
    inputArquivo.current.value = null;
    setArquivo(false);
  };

  const selecionarArquivo = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (
        file.type.indexOf('csv') < 0 &&
        file.type.indexOf('application/vnd.ms-excel') < 0
      ) {
        exibeNotificacaoAlerta(
          mensagensNotificacaoAlerta.ArquivoFormatoIncorreto,
          null,
          true
        );
        inputArquivo.current.value = null;
      } else if (file.size > limiteArquivo4mb) {
        exibeNotificacaoAlerta(
          mensagensNotificacaoAlerta.ArquivoExcedeLimite,
          null,
          true
        );
        inputArquivo.current.value = null;
      } else {
        setCarregando(true);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          file.codigoBase64 = reader.result.substr(
            reader.result.indexOf(',') + 1
          );
          setArquivo(file);
          setCarregando(false);
        };
      }
    }
  };

  return (
    <Grid item container alignItems="flex-end">
      <input
        style={{ display: 'none' }}
        type="file"
        ref={inputArquivo}
        onChange={selecionarArquivo}
        accept="text/csv"
        data-testid="input-upload-file"
      />
      <Icon>
        <BusinessOutlinedIcon className={classes.iconeFiltragem} />
      </Icon>
      <Typography noWrap variant="h5" className={classes.subtitulo}>
        Filtragem de Estudantes
      </Typography>
      {!arquivo && (
        <Grid
          item
          container
          alignItems="center"
          className={`${classes.containerUpload} ${classes.cursorPointer}`}
          onClick={() => inputArquivo.current.click()}
        >
          {carregando && <Loading iconSize={40} />}
          <Upload className={classes.iconeUpload} />
          <Grid>
            <Typography noWrap variant="body1">
              Realize o upload do arquivo para utilizar como base de estudantes
              para esta campanha
            </Typography>
            <Grid container direction="row">
              <Typography noWrap variant="body1" className={classes.textoAviso}>
                Tamanho Máximo do Arquivo:
              </Typography>
              <Typography noWrap variant="body1">
                4,5MB -&nbsp;
              </Typography>
              <Typography noWrap variant="body1" className={classes.textoAviso}>
                Formato do Arquivo:
              </Typography>
              <Typography noWrap variant="body1">
                CSV
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {arquivo && (
        <Grid
          item
          container
          alignItems="center"
          className={classes.containerUpload}
        >
          <Typography noWrap variant="body1">
            Campanha criada com base em arquivo:
          </Typography>
          <Typography noWrap variant="body1" className={classes.nomeDoArquivo}>
            {arquivo.name}
          </Typography>
          <Trash2
            className={`${classes.iconeLixeira} ${classes.cursorPointer}`}
            onClick={() => removerArquivo()}
            data-testid="icone-remover-arquivo"
          />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  exibeNotificacaoAlerta: exibirNotificacaoAlerta,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadCampanhaUnrwapped);
