import { Grid, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useRef } from 'react';
import { Calendar, ChevronsUp, Users } from 'react-feather';
import DropdownDateTimePicker from '../../../shared/componentes/DropdownDateTimePicker';
import { DARK_CYAN, FONT_FAMILY_REGULAR, GRAY } from '../../../theme';
import SelectCampanha from '../../../shared/componentes/SelectCampanha';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: '14px',
    color: GRAY,
    fontWeight: '300',
    fontFamily: FONT_FAMILY_REGULAR,
    marginRight: '20px',
    textAlign: 'end',
  },
  container: {
    height: 'calc(100% - 15px)',
    width: '100%',
    marginTop: '15px',
  },
  iconeLabelTituloSelect: {
    color: DARK_CYAN,
    marginRight: '4px',
  },
  labelTituloSelect: {
    fontSize: '14px',
    color: DARK_CYAN,
  },
  containerDropdownDateTimePickerInicio: {
    width: '17rem',
    marginTop: '10px',
    marginBottom: '12px',
  },
  containerDropdownDateTimePickerTermino: {
    width: '17rem',
    marginTop: '10px',
    marginBottom: '12px',
    marginLeft: '2px',
  },
  searchField: {
    width: '99%',
    padding: '8px 8px 8px 0px',
  },
  orientacoes: {
    width: '100%',
  },
  marginBottomGrid: {
    marginBottom: '15px',
  },
}));

const AbaEdicaoCampanha = (props) => {
  const classes = useStyles();

  const {
    statusCampanha,
    dataInicioOriginal,
    dataTerminoOriginal,
    dataInicioCampanha,
    setDataInicioCampanha,
    dataTerminoCampanha,
    setDataTerminoCampanha,
    dateTimePickerInicio,
    dateTimePickerTermino,
    setDateTimePickerInicio,
    setDateTimePickerTermino,
    impactoDaCampanhaOriginal,
    impactoDaCampanhaEditado,
    setImpactoDaCampanha,
    listaImpactosCampanha,
    grupoDeVisualizacaoOriginal,
    listaGruposDeVisualizacao,
    grupoDeVisualizacaoEditado,
    setGrupoDeVisualizacaoEditado,
    carregando,
  } = props;

  const retornaDataHoraMinima = () =>
    moment(new Date()).second(0).millisecond(0).add(30, 'm').toDate();
  const dataHoraMinima = useRef(retornaDataHoraMinima());

  return (
    <>
      <Grid item className={classes.container} data-testid="editar-campanha">
        <Grid item container direction="row" alignItems="center">
          <Calendar size={20} className={classes.iconeLabelTituloSelect} />
          <Typography variant="h5" className={classes.labelTituloSelect}>
            Data e Hora - Início e Término
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid
            item
            container
            className={classes.containerDropdownDateTimePickerInicio}
            data-testid="date-picker-inicio"
          >
            <DropdownDateTimePicker
              bloquearDataNoPassado
              mostrarDropdown={dateTimePickerInicio}
              setMostrarDropdown={setDateTimePickerInicio}
              setDataHora={setDataInicioCampanha}
              dataHora={dataInicioCampanha}
              dataHoraMinima={dataHoraMinima}
              inputValueOnInit
              tamanhoTexto="17rem"
              top="32px"
              left="-16.5rem"
              placeholder={dataInicioOriginal}
              disabled={statusCampanha !== 'Programada'}
            />
          </Grid>
          <Grid
            item
            container
            className={classes.containerDropdownDateTimePickerTermino}
            data-testid="date-picker-termino"
          >
            <DropdownDateTimePicker
              bloquearDataNoPassado
              mostrarDropdown={dateTimePickerTermino}
              setMostrarDropdown={setDateTimePickerTermino}
              dataHora={dataTerminoCampanha}
              setDataHora={setDataTerminoCampanha}
              tamanhoTexto="17rem"
              top="32px"
              left="-17rem"
              inputValueOnInit
              placeholder={dataTerminoOriginal}
              disabled={statusCampanha === 'Expirada'}
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginBottomGrid}>
          <Grid container alignItems="center">
            <ChevronsUp size={20} className={classes.iconeLabelTituloSelect} />
            <Typography variant="h5" className={classes.labelTituloSelect}>
              Impacto da Campanha
            </Typography>
          </Grid>
          <SelectCampanha
            titulo="Preenchimento Obrigatório"
            listaOpcoes={listaImpactosCampanha}
            handleAbrirSimpleDropdown
            model={impactoDaCampanhaEditado}
            setModel={setImpactoDaCampanha}
            valorInicial={impactoDaCampanhaOriginal}
            top="0"
            left="0"
          />
        </Grid>
        <Grid item container className={classes.marginBottomGrid}>
          <Grid container alignItems="center">
            <Users size={20} className={classes.iconeLabelTituloSelect} />
            <Typography variant="h5" className={classes.labelTituloSelect}>
              Grupo de Visualização
            </Typography>
          </Grid>
          <SelectCampanha
            titulo="Grupo"
            listaOpcoes={listaGruposDeVisualizacao}
            handleAbrirSimpleDropdown={false}
            model={grupoDeVisualizacaoEditado}
            setModel={setGrupoDeVisualizacaoEditado}
            valorInicial={grupoDeVisualizacaoOriginal}
            carregando={carregando}
            top="0"
            left="0"
            width="100%"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AbaEdicaoCampanha;
