import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Typography, Grid, Card } from '@material-ui/core';
import Filtro from '../../filtro/views/Filtro';
import {
  LIGHT_GRAY,
  MEDIUM_GRAY,
  ORANGE,
  OPAQUE_LIGHT_ORANGE,
  FONT_FAMILY_REGULAR,
  FONT_FAMILY,
} from '../../../../theme';
import {
  obterFiltroGrafico,
  deepCopy,
  deepEqual,
  mensagemBoasVindasHorario,
} from '../../../../shared/util/Utils';
import EvasaoPorMotivo from '../../evasaoPorMotivo/views/EvasaoPorMotivo';
import FluxoRetencao from '../../fluxoRetencao/views/FluxoRetencao';
import { BreakpointProvider } from '../../../../shared/util/BreakpointProvider';
import RaioXEvasao from '../../RaioXEvasao/views/RaioXEvasao';
import Destaques from '../../destaques/views/Destaques';
import GestaoCampanhas from '../../../gestaoCampanhas/views/GestaoCampanhas';

const queries = {
  lg: '(max-width: 1279px)',
};

const useStyles = makeStyles({
  containerPrincipal: {
    overflowY: 'scroll',
    maxHeight: 'calc(100vh - 48px)',
    scrollbarColor: '#bbbbbe white',
    scrollbarWidth: 'thin',
    width: '100%',
    margin: '0px',
  },
  snackBar: {
    backgroundColor: OPAQUE_LIGHT_ORANGE,
    color: `${ORANGE} !important`,
    fontSize: '14px',
    fontWeight: 'bold',
    width: '544px',
    height: '48px',
    fill: 'solid',
    boxShadow: '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
  },
  snackButton: {
    align: 'right',
    color: ORANGE,
    fontSize: '14px',
    fontWeight: 'bold',
    marginTop: '-7px',
  },
  snackbarText: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: FONT_FAMILY_REGULAR,
    color: ORANGE,
    marginRight: '70px',
  },
  linhaSeparadora: {
    borderRight: '1px solid',
    borderColor: `${LIGHT_GRAY} !important`,
    '@media (max-width:960px)': {
      borderRight: `0px solid ${LIGHT_GRAY}`,
      borderBottom: `1px solid ${LIGHT_GRAY}`,
    },
  },
  mensagemBemVindo: {
    marginTop: '5rem',
    width: '95%',
    margin: '0px',
  },
  mensagemBemVindoBlockedScreen: {
    marginTop: '20px',
    width: '95%',
    margin: '0px',
  },
  bomDia: {
    color: MEDIUM_GRAY,
    fontFamily: FONT_FAMILY,
    marginRight: '12px',
  },
  bordaDireita: {
    paddingRight: '0px !important',
  },
  containerConteudoCards: {
    width: '100%',
    margin: '0px',
  },
  containerCards: {
    width: '95%',
    height: 'fit-content',
  },
  card: {
    width: '100%',
  },
});

const CockpitHome = (props) => {
  const { nomeOperator, filter } = props;

  const classes = useStyles();
  const referenciaCampanhas = useRef(null);
  const referenciaFiltro = useRef(null);
  const referenciaDashboard = useRef(null);
  const [filtroAtual, setFiltroAtual] = useState(filter);
  const [filtroAplicado, setFiltroAplicado] = useState(
    obterFiltroGrafico(filter)
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [hideFilter, setHideFilter] = useState(false);

  useEffect(() => {
    if (filtroAtual.estadoFiltroAplicado !== filter.estadoFiltroAplicado) {
      const filterCopy = deepCopy(filter);
      setFiltroAtual(filterCopy);
      setFiltroAplicado(obterFiltroGrafico(filterCopy));
      setOpenSnackbar(false);
    } else {
      setOpenSnackbar(!deepEqual(filter, filtroAtual));
    }
  }, [filter, filtroAtual]);

  function updateHide() {
    if (!(referenciaCampanhas.current && referenciaCampanhas.current.offsetTop))
      return;

    if (!(referenciaFiltro.current && referenciaFiltro.current.offsetTop))
      return;

    if (!(referenciaDashboard.current && referenciaDashboard.current.scrollTop))
      return;

    const dashboadScroll = referenciaDashboard.current.scrollTop;
    const campanhasOffset = referenciaCampanhas.current.offsetTop;
    const filterOffset = referenciaFiltro.current.offsetTop;
    const filterCampanhadistance =
      campanhasOffset - filterOffset - dashboadScroll;

    const hideF = filterCampanhadistance < 75;

    setHideFilter(hideF);
  }

  const BLOCKED_SCREEN = process.env.REACT_APP_BLOCKED_SCREEN !== 'true';

  return (
    <Grid
      container
      spacing={5}
      justifyContent="center"
      direction="row"
      className={classes.containerPrincipal}
      ref={referenciaDashboard}
      onScroll={updateHide}
    >
      <Grid
        item
        container
        wrap="nowrap"
        alignItems="flex-start"
        className={
          BLOCKED_SCREEN
            ? classes.mensagemBemVindo
            : classes.mensagemBemVindoBlockedScreen
        }
      >
        <Typography variant="h1" noWrap className={classes.bomDia}>
          {mensagemBoasVindasHorario()}
        </Typography>
        <Typography variant="h1" noWrap>
          {nomeOperator}
        </Typography>
      </Grid>

      {BLOCKED_SCREEN && (
        <Filtro
          filtroEstaAtualizado={!openSnackbar}
          filtroAplicado={filtroAplicado}
          internalRef={referenciaFiltro}
          open={!hideFilter}
        />
      )}

      {BLOCKED_SCREEN && (
        <Grid container item className={classes.containerCards}>
          <Card className={classes.card}>
            <Grid
              container
              item
              xs={12}
              className={classes.containerConteudoCards}
              spacing={5}
              alignItems="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                md={8}
                className={`${classes.linhaSeparadora} ${classes.bordaDireita}`}
              >
                <RaioXEvasao filtro={filtroAplicado} />
              </Grid>
              <Grid container item xs={12} md={4} justifyContent="flex-start">
                <Destaques filtro={filtroAplicado} />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}

      {BLOCKED_SCREEN && (
        <Grid container item className={classes.containerCards}>
          <Card className={classes.card}>
            <Grid
              container
              item
              xs={12}
              className={classes.containerConteudoCards}
              spacing={6}
              justifyContent="flex-start"
            >
              <BreakpointProvider queries={queries}>
                <FluxoRetencao filtro={filtroAplicado} />
              </BreakpointProvider>
            </Grid>
          </Card>
        </Grid>
      )}

      {BLOCKED_SCREEN && (
        <Grid container item className={classes.containerCards}>
          <Card className={classes.card}>
            <Grid
              container
              item
              xs={12}
              className={classes.containerConteudoCards}
              spacing={6}
              justifyContent="space-evenly"
            >
              <EvasaoPorMotivo filtro={filtroAplicado} />
            </Grid>
          </Card>
        </Grid>
      )}

      <Grid
        container
        item
        className={classes.containerCards}
        ref={referenciaCampanhas}
      >
        <Card className={classes.card}>
          <Grid
            container
            item
            xs={12}
            className={classes.containerConteudoCards}
            spacing={6}
            justifyContent="space-evenly"
          >
            <GestaoCampanhas filtroTela={filtroAplicado} />
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    nomeOperator: state.login.usuario.name,
    filter: state.filtro,
  };
};

export default connect(mapStateToProps)(CockpitHome);
