import React from 'react';
import { Typography, Grid, Box, Icon, makeStyles } from '@material-ui/core';
import ReactLoading from 'react-loading';
import {
  DARK_GRAY_OP_1,
  DARK_CYAN,
  TOMATO,
  LIGHT_CYAN,
  LIGHT_TOMATO,
} from '../../theme';

const useStyles = makeStyles(() => ({
  contadorTitle: {
    color: DARK_GRAY_OP_1,
    fontSize: '12px',
  },
  contadorContainer: {
    width: 'fit-content',
  },
  cardPositive: {
    color: DARK_CYAN,
  },
  cardNegative: {
    color: TOMATO,
  },
  cardIconContainerPositive: {
    backgroundColor: LIGHT_CYAN,
    height: '40px',
    width: '40px',
  },
  cardIconContainerNegative: {
    backgroundColor: LIGHT_TOMATO,
    height: '40px',
    width: '40px',
  },
}));

const Contador = (props) => {
  const classes = useStyles();

  const { icon, total, loading, afterNumber, labelUpperNumber, minNumber } =
    props;

  return (
    <Grid
      item
      container
      direction="row"
      alignItems="center"
      wrap="nowrap"
      className={classes.contadorContainer}
    >
      <Box
        mr={2}
        p={1}
        borderRadius="50%"
        className={
          total > 0
            ? classes.cardIconContainerPositive
            : classes.cardIconContainerNegative
        }
      >
        <Icon
          className={
            total > minNumber ? classes.cardPositive : classes.cardNegative
          }
        >
          {icon}
        </Icon>
      </Box>
      <Grid container direction="column">
        {loading && (
          <ReactLoading type="spin" color={DARK_CYAN} height={25} width={25} />
        )}
        {!loading && (
          <>
            <Typography
              noWrap
              variant="subtitle1"
              className={classes.contadorTitle}
            >
              {`${labelUpperNumber}`}
            </Typography>
            <Typography
              noWrap
              variant="h3"
              className={
                total > minNumber ? classes.cardPositive : classes.cardNegative
              }
            >
              {`${total} ${afterNumber}`}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Contador;
