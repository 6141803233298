import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import {
  DROPDOWN_HOVER_GRAY,
  BLACK,
  COLOR_ITEM_DISABLE,
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from '../../theme';

const useStyles = makeStyles(() => ({
  containerDropdown: {
    padding: (props) => props.marginSimpleDropdown,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: DROPDOWN_HOVER_GRAY,
    },
  },
  containerDropdownDisable: {
    padding: (props) => props.marginSimpleDropdown,
    cursor: 'default',
    '&:hover': {
      backgroundColor: DROPDOWN_HOVER_GRAY,
    },
  },
  itemTitleDropdown: {
    color: `${BLACK} !important`,
  },
  itemTitleDropdownDisable: {
    color: `${COLOR_ITEM_DISABLE} !important`,
  },
  itemPrimeiraDesc: {
    fontFamily: (props) =>
      props.fontFamily ? props.fontFamily : FONT_FAMILY_REGULAR,
    color: (props) => (props.itemColor ? props.itemColor : BLACK),
  },
  itemPrimeiraDescDisable: {
    fontFamily: FONT_FAMILY_BOLD,
  },
}));
export default function SimpleDropdownItem(props) {
  const {
    handleClickSimpleDropdown,
    fontSizeDescription,
    disable,
    description,
  } = props;

  const classes = useStyles(props);

  const handleClick = () => {
    if (disable) return;
    handleClickSimpleDropdown(description);
  };

  return (
    <Grid
      container
      className={
        disable ? classes.containerDropdownDisable : classes.containerDropdown
      }
      data-testid={`${description}-dropdown`}
      onClick={handleClick}
    >
      <Typography
        variant={fontSizeDescription}
        display="inline"
        className={
          disable
            ? `${classes.itemPrimeiraDescDisable} ${classes.itemTitleDropdownDisable}`
            : classes.itemPrimeiraDesc
        }
      >
        {description.substring(0, description.indexOf(' '))}
      </Typography>

      {description.split(' ').length - 1 > 0 && (
        <Typography display="inline">&nbsp;</Typography>
      )}

      <Typography
        variant={fontSizeDescription}
        display="inline"
        className={
          disable ? classes.itemTitleDropdownDisable : classes.itemTitleDropdown
        }
      >
        {description.substring(description.indexOf(' ') + 1)}
      </Typography>
    </Grid>
  );
}
