import React from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  DialogContent,
  Dialog,
  Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  DARK_GRAY,
  DARK_CYAN,
  WHITE,
  BLACK,
  FONT_FAMILY_REGULAR,
} from '../../theme';

const useStyles = makeStyles(() => ({
  botaoVoltar: {
    color: DARK_GRAY,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px',
  },
  botaoContinuar: {
    color: DARK_CYAN,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px',
  },
  titulo: {
    color: DARK_CYAN,
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '25px',
  },
  modal: {
    maxWidth: '400px',
    backgroundColor: WHITE,
    boxShadow: '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
    borderRadius: '8px',
    minWidth: '350px',
  },
  backgroundProps: {
    background: 'rgba(192,198,214,0.4)',
  },
  descricao: {
    fontSize: '16px',
    color: BLACK,
    fontFamily: FONT_FAMILY_REGULAR,
    marginBottom: '20px',
  },
  containerConteudoDialog: {
    padding: '20px 15px 20px 15px',
  },
}));

const ModalLogout = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const {
    modalAberto,
    tituloModal,
    textoBotaoContinuar,
    handleBotaoContinuar,
    textoBotaoCancelar,
    handleBotaoCancelar,
  } = props;

  const handleClick = () => {
    history.push('/');
    handleBotaoContinuar();
  };

  const handleOnClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }
    if (reason === 'escapeKeyDown') {
      return false;
    }
    if (typeof handleBotaoCancelar === 'function') {
      handleBotaoCancelar();
    }
  };

  return (
    <Dialog
      open={modalAberto}
      onClose={handleOnClose}
      aria-labelledby="modal-titulo"
      aria-describedby="modal-descricao"
      scroll="body"
      classes={{ paper: classes.modal }}
      BackdropProps={{
        classes: {
          root: classes.backgroundProps,
        },
      }}
    >
      <DialogContent>
        <Grid
          id="modal-logout"
          container
          direction="row"
          spacing={2}
          className={classes.containerConteudoDialog}
        >
          <Typography className={classes.titulo}>{tituloModal}</Typography>

          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Button
              className={classes.botaoVoltar}
              onClick={handleBotaoCancelar}
            >
              {textoBotaoCancelar}
            </Button>
            <Button
              className={classes.botaoContinuar}
              onClick={() => {
                handleClick();
              }}
            >
              {textoBotaoContinuar}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalLogout;
