import React, { useEffect, useState } from 'react';
import { CssBaseline } from '@material-ui/core';
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import { makeUserManager } from 'react-oidc';
import Conteudo from './shared/layout/Conteudo';
import Notificacao from './features/notificacao';
import FullScreenLoading from './features/loading';
import { tituloPagina } from './shared/util/Constantes';
import Router from './router';
import { atualizarUserManager, atualizarUsuario } from './features/login/Store';
import store from './setup/reducer/index';

import { GetUser, GetUserData } from './features/login/LoginManager';

import './App.css';
import styles from './theme';
import AutenticacaoService from './services/AutenticacaoService';
import Loading from './shared/componentes/Loading';
import Cabecalho from './features/cabecalho/views/Cabecalho';

const temaProjeto = createTheme(styles);

const useStyles = makeStyles(() => ({
  '@global': styles.global,
}));

function App() {
  useStyles();
  const [userData, setuserData] = useState(null);

  useEffect(() => {
    const siglaAmbiente = process.env.REACT_APP_ENVIRONMENT_NAME;

    if (siglaAmbiente !== '[PROD]') {
      document.title = `${siglaAmbiente} ${tituloPagina}`;
    }
  }, []);

  useEffect(() => {
    if (!userData) {
      AutenticacaoService.getInfoSubdomain().then((oidcConf) => {
        if (oidcConf) {
          const userDataResult = GetUserData(GetUser());
          if (userDataResult) {
            store.dispatch(atualizarUsuario(userDataResult));
            store.dispatch(atualizarUserManager(makeUserManager(oidcConf)));
            setuserData(userDataResult);
          }
        }
      });
    }
  }, [userData]);

  const conteudo = (
    <>
      <Router />
      <FullScreenLoading />
      <Notificacao />
    </>
  );

  return (
    <>
      <ThemeProvider theme={temaProjeto}>
        <CssBaseline />
        <Cabecalho />
        {userData ? <Conteudo conteudo={conteudo} /> : <Loading />}
      </ThemeProvider>
    </>
  );
}

export default App;
