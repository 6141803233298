import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import moment from 'moment';
import { React, useEffect, useRef, useState } from 'react';
import {
  Calendar,
  Check,
  Edit3,
  PhoneOutgoing,
  Trash2,
  User,
} from 'react-feather';
import { connect } from 'react-redux';
import CardTitle from '../../../../shared/componentes/CardTitle';
import Contador from '../../../../shared/componentes/Contador';
import DropdownDateTimePicker from '../../../../shared/componentes/DropdownDateTimePicker';
import ModalConfirmacao from '../../../../shared/componentes/ModalConfirmacao';
import SelectSimples from '../../../../shared/componentes/SelectSimples';
import {
  iconesNotificacao,
  informacoesModais,
  mensagensNotificacaoAlerta,
} from '../../../../shared/util/Constantes';
import {
  formatarDataParaTimestamp,
  ordenarPorParticaoDeHifen,
  placeholderDateTimer,
} from '../../../../shared/util/Utils';
import {
  DARK_CYAN,
  FONT_FAMILY_REGULAR,
  GRAY,
  LIGHT_GRAY,
  LIGHT_SEA_GREEN,
  LIGHT_SEA_GREEN_HOVER,
  TOMATO,
  TOMATO_HOVER,
  WHITE,
} from '../../../../theme';
import {
  exibirNotificacaoAlerta,
  exibirNotificacaoSucesso,
} from '../../../notificacao/Store';
import CriarCampanhaManager from '../../CriarCampanhaManager';
import ModalConfirmarCriarCampanha from '../ModalConfirmarCriarCampanha';
import ObjetivosCampanha from '../ObjetivosCampanha';
import ParametrosIES from '../ParametrosIES';
import PreVisualizarWhatsapp from './PreVisualizarWhatsapp';
import UploadCampanha from '../UploadCampanha';

const useStyles = makeStyles(() => ({
  containerInputs: {
    overflowY: 'scroll',
    maxHeight: 'calc(90vh - 195px)',
    scrollbarColor: '#bbbbbe white',
    scrollbarWidth: 'thin',
  },
  modal: {
    height: '90vh',
    margin: '5vh',
  },
  scrollModal: {
    overflowY: 'hidden',
  },
  conteudo: {
    height: 'calc(100% - 50px)',
    overflowY: 'hidden',
  },
  container: {
    height: '100%',
    width: '100%',
  },
  containerHeader: {
    marginBottom: '12px',
    paddingRight: '16px',
  },
  titulo: {
    width: '60%',
  },
  containerContador: {
    width: '40%',
  },
  iconeSubtitulo: {
    marginTop: '4px',
  },
  filtro: {
    borderRight: `1px solid ${LIGHT_GRAY}`,
    margin: '4px 18px 12px 0',
    width: '75%',
  },
  containerPreVisualizar: {
    width: '30%',
    marginBottom: '12px',
  },
  backgroundProps: {
    background: 'rgba(0,0,0,0.3)',
  },
  botaoCancelar: {
    backgroundColor: TOMATO,
    color: WHITE,
    height: '100%',
    float: 'right',
    marginRight: '32px',
    '&:hover': {
      backgroundColor: TOMATO_HOVER,
    },
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  botaoAplicar: {
    backgroundColor: LIGHT_SEA_GREEN,
    color: WHITE,
    height: '100%',
    whiteSpace: 'nowrap',
    marginRight: '1.5rem',
    '&:hover': {
      backgroundColor: LIGHT_SEA_GREEN_HOVER,
    },
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  textoBotao: {
    fontSize: '14px',
    color: WHITE,
  },
  textoBotaoCancelar: {
    fontSize: '14px',
    color: WHITE,
    marginTop: '2px',
  },
  inputLabel: {
    fontSize: '14px',
    color: GRAY,
    fontWeight: '300',
    fontFamily: FONT_FAMILY_REGULAR,
    marginRight: '20px',
    textAlign: 'end',
  },
  iconeLabelTituloSelect: {
    color: DARK_CYAN,
    marginRight: '4px',
  },
  labelTituloSelect: {
    fontSize: '14px',
    color: DARK_CYAN,
  },
  titleField: {
    width: '99%',
    padding: '8px 8px 8px 0px',
  },
  margemContainer: {
    marginTop: '14px',
  },
  containerDropdownDateTimePickerInicio: {
    width: '17rem',
    marginTop: '10px',
    marginBottom: '12px',
  },
  iconeLabelWhatsappSelect: {
    color: DARK_CYAN,
    marginRight: '4px',
  },
  labelWhatsappSelect: {
    fontSize: '14px',
    color: DARK_CYAN,
  },
  switchMarcas: {
    width: '48px',
    height: '22px',
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(25px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#067A74',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#048a83',
        border: '6px solid #fff',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 19,
      height: 19,
    },
    '& .MuiSwitch-track': {
      borderRadius: 28 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
    },
  },
  linhaSelecao: {
    margin: '4px 20px 4px 0px',
    '& .MuiPopover-paper': {
      marginTop: '38px',
    },
    display: 'inline-flex',
  },
  linhaMarcas: {
    justifyContent: 'left',
    '& .MuiTypography-root': {
      marginRight: '8px',
    },
    '& .MuiGrid-root': {
      marginRight: '8px',
    },
    '& .MuiSwitch-root': {
      marginRight: '8px',
    },
  },
}));

const CriarCampanhaWhatsApp = (props) => {
  const classes = useStyles();

  const {
    handleFecharModal,
    attListaItens,
    notificarAlerta,
    notificarSucesso,
    criarCampanhaUpload,
  } = props;

  const retornaDataHoraMinima = () =>
    moment(new Date()).second(0).millisecond(0).add(30, 'm').toDate();

  const [tituloCampanha, setTituloCampanha] = useState('');
  const [modalCancelar, setModalCancelar] = useState(false);
  const [modalConfirmarCriarCampanha, setModalConfirmarCriarCampanha] =
    useState(false);
  const [carregando, setCarregando] = useState(false);
  const dataHoraMinima = useRef(retornaDataHoraMinima());
  const [carregandoQuantidade, setCarregandoQuantidade] = useState();
  const [quantidadeAlunos, setQuantidadeAlunos] = useState();

  const [categoria, setCategoria] = useState('');
  const [situacao, setSituacao] = useState('');
  const [perfilAluno, setPerfilAluno] = useState('');
  const [formaDeIngresso, setFormaDeIngresso] = useState([]);
  const [marca, setMarca] = useState([]);
  const [campus, setCampus] = useState([]);
  const [curso, setCurso] = useState([]);
  const [modalidade, setModalidade] = useState([]);
  const [turno, setTurno] = useState([]);

  const [dataInicioCampanha, setDataInicioCampanha] = useState(null);
  const [dataInicioMatricula, setDataInicioMatricula] = useState(null);
  const [dataTerminoMatricula, setDataTerminoMatricula] = useState(null);

  const [arquivoUpload, setArquivoUpload] = useState(false);

  const [marcaWhatsapp, setMarcaWhatsapp] = useState('');
  const [listaMarcasWhatsapp, setListaMarcasWhatsapp] = useState([]);
  const [switchMarca, setSwitchMarca] = useState(false);

  const [listaMensagens, setListaMensagens] = useState([]);
  const [mensagemWhatsapp, setMensagemWhatsapp] = useState('');
  const [dateTimePickerOpen, setDateTimePickerOpen] = useState(false);
  const [tituloMarca, setTituloMarca] = useState('Selecione uma marca');

  // eslint-disable-next-line prettier/prettier
  const validTitle = /^[ ]+$/gm;

  const [listInputFilter, setListInputFilter] = useState({
    parametrosIES: {
      Modalidade: [],
      Marca: [],
      Campus: [],
      Curso: [],
      Turno: [],
    },
    objetivosCampanha: {
      Aluno: [],
      Categoria: [],
      Situacao: [],
      FormaDeIngresso: [],
    },
    impactosDaCampanha: [],
    gruposDeVisualizacao: [],
  });

  const podeCriarCampanha = () => {
    if (!criarCampanhaUpload && quantidadeAlunos < 1) {
      notificarAlerta(
        mensagensNotificacaoAlerta.CampanhaDevePossuirAlunos,
        null,
        true
      );
      return;
    }
    if (
      dataInicioMatricula &&
      dataTerminoMatricula &&
      moment(dataInicioMatricula).unix() > moment(dataTerminoMatricula).unix()
    ) {
      notificarAlerta(
        mensagensNotificacaoAlerta.CampanhaDataMatriculaInvalida,
        null,
        true
      );
      return;
    }
    if (!mensagemWhatsapp) {
      notificarAlerta(
        mensagensNotificacaoAlerta.MensagemWhatsappInexistente,
        null,
        true
      );
      return;
    }
    if (!marcaWhatsapp && !switchMarca) {
      notificarAlerta(
        mensagensNotificacaoAlerta.MarcaWhatsappInexistente,
        null,
        true
      );
      return;
    }
    return true;
  };

  const criarCampanha = async () => {
    if (!podeCriarCampanha()) return;
    setCarregando(true);
    const objetivos = {
      categoria,
      situacao,
      formaDeIngresso,
      perfilAluno,
      dataInicioCampanha: formatarDataParaTimestamp(dataInicioCampanha),
      dataInicioMatricula: formatarDataParaTimestamp(dataInicioMatricula),
      dataTerminoMatricula: formatarDataParaTimestamp(dataTerminoMatricula),
    };
    const parametrosIES = {
      modalidade,
      marca,
      campus,
      curso,
      turno,
    };
    const dadosWhatsapp = {
      marcaWhatsapp,
      mensagemWhatsapp,
    };
    const success = criarCampanhaUpload
      ? await CriarCampanhaManager.createCampaignWhatsappUpload(
          tituloCampanha,
          formatarDataParaTimestamp(dataInicioCampanha),
          arquivoUpload,
          dadosWhatsapp
        )
      : await CriarCampanhaManager.createCampaignWhatsapp(
          tituloCampanha,
          objetivos,
          parametrosIES,
          dadosWhatsapp
        );
    if (success) {
      handleFecharModal();
      setTituloCampanha('');
      notificarSucesso(
        'Campanha criada com sucesso',
        iconesNotificacao.MarcaVerificacao
      );
    }
    attListaItens(true);
    setCarregando(false);
  };

  function cancelarCriacao() {
    handleFecharModal();
    setModalCancelar(false);
  }

  function verificarModalCancelar() {
    if (tituloCampanha) {
      setModalCancelar(true);
    } else {
      cancelarCriacao();
    }
  }

  const validarAbrirModalConfirmarCriarCampanha = () => {
    if (podeCriarCampanha()) {
      setModalConfirmarCriarCampanha(true);
    }
  };

  useEffect(() => {
    const buscarDadosIniciaisDaTela = async () => {
      setCarregando(true);
      const resultFiltros = await CriarCampanhaManager.buscarFiltros();
      if (resultFiltros) {
        setListInputFilter(resultFiltros);
      }
      const resultMensagens =
        await CriarCampanhaManager.buscarMensagensWhatsapp();
      if (resultMensagens) {
        setListaMensagens(ordenarPorParticaoDeHifen(resultMensagens));
      }
      const resultMarcas = await CriarCampanhaManager.buscarMarcasWhatsapp();
      resultMarcas.sort();
      if (resultMarcas) {
        setListaMarcasWhatsapp(
          resultMarcas.map((ies) => {
            return { Title: ies };
          })
        );
      }
      setCarregando(false);
    };
    buscarDadosIniciaisDaTela();
  }, [criarCampanhaUpload]);

  useEffect(() => {
    const getData = async () => {
      const parametrosIES = {
        modalidade,
        marca,
        campus,
        curso,
        turno,
      };

      const objetivos = {
        categoria,
        situacao,
        formaDeIngresso,
        perfilAluno,
        dataInicioMatricula: formatarDataParaTimestamp(dataInicioMatricula),
        dataTerminoMatricula: formatarDataParaTimestamp(dataTerminoMatricula),
      };
      setCarregandoQuantidade(true);
      const result = await CriarCampanhaManager.buscarQuantidadeAlunos(
        parametrosIES,
        objetivos
      );
      if (result || result === 0) {
        setQuantidadeAlunos(result);
      }
      setCarregandoQuantidade(false);
    };
    if (!criarCampanhaUpload) {
      getData();
    }
  }, [
    categoria,
    situacao,
    formaDeIngresso,
    perfilAluno,
    modalidade,
    marca,
    campus,
    curso,
    turno,
    dataInicioMatricula,
    dataTerminoMatricula,
    criarCampanhaUpload,
  ]);

  const handleSwitchChange = () => {
    if (switchMarca === false) {
      setTituloMarca('IES do Destinatário');
      setMarcaWhatsapp('');
    } else {
      setTituloMarca('Selecione uma marca');
    }
    setSwitchMarca(!switchMarca);
  };

  function habilitarBotaoCriarCampanha() {
    return (
      !validTitle.test(tituloCampanha) &&
      tituloCampanha &&
      (categoria || arquivoUpload) &&
      dataInicioCampanha &&
      !carregando &&
      !carregandoQuantidade &&
      mensagemWhatsapp &&
      (marcaWhatsapp || switchMarca)
    );
  }

  const handleOnClose = (_event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false;
    }
    if (typeof handleFecharModal === 'function') {
      handleFecharModal();
    }
    return undefined;
  };

  const obtemMarcaPrevisualizar = () => {
    if (switchMarca) {
      return ['IES Destinatário'];
    }
    if (marcaWhatsapp && marcaWhatsapp.Title) {
      return [marcaWhatsapp.Title];
    }
    return '';
  };

  return (
    <>
      <Dialog
        open
        fullWidth
        maxWidth="lg"
        onClose={handleOnClose}
        aria-labelledby="modal-titulo"
        aria-describedby="modal-descricao"
        scroll="body"
        classes={{ paper: classes.modal, scrollBody: classes.scrollModal }}
        BackdropProps={{
          classes: {
            root: classes.backgroundProps,
          },
        }}
      >
        <DialogContent className={classes.container}>
          <Grid container wrap="nowrap" className={classes.conteudo}>
            <Grid
              item
              container
              direction="row"
              alignItems="flex-start"
              alignContent="space-between"
              className={classes.filtro}
            >
              <Grid item container>
                <Grid item container alignItems="flex-start">
                  <Grid container className={classes.containerHeader}>
                    <Grid container className={classes.titulo}>
                      <CardTitle
                        text="Nova Campanha"
                        icon={<PhoneOutgoing />}
                      />
                    </Grid>
                    {!criarCampanhaUpload && (
                      <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                        className={classes.containerContador}
                      >
                        <Contador
                          icon={<User />}
                          total={quantidadeAlunos ?? 0}
                          loading={carregandoQuantidade}
                          afterNumber="Alunos"
                          labelUpperNumber="Alcance"
                          minNumber={0}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item container className={classes.containerInputs}>
                  <Grid
                    item
                    container
                    className={classes.margemContainer}
                    data-testid="criar-campanha-exclusiva-whatsapp"
                  >
                    <Grid item container>
                      <Grid item container xs={10}>
                        <Edit3
                          size={20}
                          className={classes.iconeLabelTituloSelect}
                        />
                        <Typography
                          variant="h5"
                          className={classes.labelTituloSelect}
                        >
                          Título
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h6" className={classes.inputLabel}>
                          {tituloCampanha.length}/100
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container>
                      <TextField
                        autoFocus
                        variant="filled"
                        size="small"
                        placeholder=" Insira um título para sua campanha"
                        inputProps={{
                          style: {
                            fontWeight: 'normal',
                            fontSize: '14px',
                            paddingTop: '10px',
                            paddingBottom: '8px',
                          },
                          maxLength: 100,
                          'data-testid':
                            'criar-campanha-scape-zero-titulo-input',
                        }}
                        className={classes.titleField}
                        onChange={(e) => setTituloCampanha(e.target.value)}
                        value={tituloCampanha}
                      />
                    </Grid>

                    {!criarCampanhaUpload && (
                      <ObjetivosCampanha
                        filtroObjetivos={listInputFilter.objetivosCampanha}
                        carregando={carregando}
                        categoria={categoria}
                        setCategoria={setCategoria}
                        situacao={situacao}
                        setSituacao={setSituacao}
                        perfilAluno={perfilAluno}
                        setPerfilAluno={setPerfilAluno}
                        formaDeIngresso={formaDeIngresso}
                        setFormaDeIngresso={setFormaDeIngresso}
                        dataInicioMatricula={dataInicioMatricula}
                        setDataInicioMatricula={setDataInicioMatricula}
                        dataTerminoMatricula={dataTerminoMatricula}
                        setDataTerminoMatricula={setDataTerminoMatricula}
                      />
                    )}

                    {criarCampanhaUpload && (
                      <UploadCampanha
                        arquivo={arquivoUpload}
                        setArquivo={setArquivoUpload}
                      />
                    )}

                    {!criarCampanhaUpload && (
                      <ParametrosIES
                        filtroParametrosIES={listInputFilter.parametrosIES}
                        carregando={carregando}
                        modalidade={modalidade}
                        setModalidade={setModalidade}
                        marca={marca}
                        setMarca={setMarca}
                        campus={campus}
                        setCampus={setCampus}
                        curso={curso}
                        setCurso={setCurso}
                        turno={turno}
                        setTurno={setTurno}
                      />
                    )}

                    <Grid item container direction="row" alignItems="center">
                      <Calendar
                        size={20}
                        className={`${classes.iconeLabelTituloSelect} ${classes.margemContainer}`}
                      />
                      <Typography
                        variant="h5"
                        className={`${classes.labelTituloSelect} ${classes.margemContainer}`}
                      >
                        Data e Hora - Início
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid
                        item
                        container
                        className={
                          classes.containerDropdownDateTimePickerInicio
                        }
                      >
                        <DropdownDateTimePicker
                          mostrarDropdown={dateTimePickerOpen}
                          setMostrarDropdown={setDateTimePickerOpen}
                          setDataHora={setDataInicioCampanha}
                          dataHora={dataInicioCampanha}
                          dataHoraMinima={dataHoraMinima}
                          inputValueOnInit={false}
                          tamanhoTexto="17rem"
                          top="32px"
                          left="-272px"
                          placeholder={placeholderDateTimer()}
                          bloquearDataNoPassado
                        />
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item container xs={10} alignItems="center">
                        <WhatsAppIcon
                          size={20}
                          className={`${classes.iconeLabelWhatsappSelect} ${classes.margemContainer}`}
                        />
                        <Typography
                          variant="h5"
                          className={`${classes.labelWhatsappSelect} ${classes.margemContainer}`}
                        >
                          Whatsapp
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      display="inline"
                      alignItems="center"
                      className={`${classes.linhaSelecao} ${classes.linhaMarcas}`}
                    >
                      <Typography
                        display="inline"
                        variant="h5"
                        className={classes.labelWhatsappSelect}
                      >
                        Marca do Remetente
                      </Typography>
                      <SelectSimples
                        carregando={carregando}
                        titulo={tituloMarca}
                        listaOpcoes={listaMarcasWhatsapp}
                        opcao={marcaWhatsapp || ''}
                        setOpcao={setMarcaWhatsapp}
                        disabled={switchMarca}
                        width="49%"
                        height="35px"
                        listHeight="25vh"
                      />
                      <Switch
                        className={classes.switchMarcas}
                        onChange={handleSwitchChange}
                        checked={switchMarca}
                        data-testid="switch-ies-destinatario"
                      />
                      <Typography
                        display="inline"
                        variant="h5"
                        className={classes.labelWhatsappSelect}
                      >
                        Utilizar IES do Destinatário
                      </Typography>
                    </Grid>

                    <Grid container alignItems="center">
                      <Typography
                        variant="h5"
                        className={`${classes.labelTituloSelect} ${classes.margemContainer}`}
                      >
                        Título da Mensagem
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      className={classes.linhaSelecao}
                    >
                      <SelectSimples
                        carregando={carregando}
                        titulo="Título da mensagem de WhatsApp"
                        listaOpcoes={listaMensagens}
                        opcao={mensagemWhatsapp}
                        setOpcao={setMensagemWhatsapp}
                        width="100%"
                        height="35px"
                        listHeight="21vh"
                        disabled={!(switchMarca || marcaWhatsapp)}
                        removalButton={!!mensagemWhatsapp}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              className={classes.containerPreVisualizar}
            >
              <PreVisualizarWhatsapp
                titulo={tituloCampanha}
                categoria={categoria}
                situacao={situacao}
                perfilAluno={perfilAluno}
                formaDeIngresso={formaDeIngresso}
                modalidade={modalidade}
                marca={marca}
                campus={campus}
                curso={curso}
                turno={turno}
                dataInicioCampanha={dataInicioCampanha}
                dataInicioMatricula={dataInicioMatricula}
                dataTerminoMatricula={dataTerminoMatricula}
                criarCampanhaUpload={criarCampanhaUpload}
                marcaWhatsapp={obtemMarcaPrevisualizar()}
                mensagemWhatsapp={
                  mensagemWhatsapp
                    ? `${mensagemWhatsapp.Message1}\n\n${mensagemWhatsapp.Message2}\n\n${mensagemWhatsapp.Message3}`
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            wrap="nowrap"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Button
              variant="contained"
              type="submit"
              disableElevation
              fullWidth
              startIcon={<Trash2 size={18} />}
              className={`${classes.botaoCancelar} "Login-Btn"`}
              onClick={() => verificarModalCancelar()}
            >
              <Typography className={classes.textoBotaoCancelar} variant="h6">
                Descartar
              </Typography>
            </Button>
            <Button
              variant="contained"
              type="submit"
              disableElevation
              fullWidth
              startIcon={<Check size={18} />}
              onClick={() => validarAbrirModalConfirmarCriarCampanha()}
              className={`${classes.botaoAplicar} "Login-Btn"`}
              disabled={!habilitarBotaoCriarCampanha()}
            >
              <Typography className={classes.textoBotao} variant="h6">
                Cadastrar campanha
              </Typography>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <ModalConfirmacao
        modalAberto={modalCancelar}
        setModalAberto={setModalCancelar}
        tituloModal={informacoesModais.ModalCancelarCriacaoDeCampanha.Titulo}
        textoBotaoDireito={
          informacoesModais.ModalCancelarCriacaoDeCampanha.TextoBotaoContinuar
        }
        descricao={informacoesModais.ModalCancelarCriacaoDeCampanha.Descricao}
        segundaDescricao={informacoesModais.SegundaDescricao}
        handleBotaoDireito={cancelarCriacao}
        textoBotaoEsquerdo={informacoesModais.TextoBotaoCancelar}
        handleBotaoEsquerdo={() => setModalCancelar(false)}
      />

      <ModalConfirmarCriarCampanha
        modalAberto={modalConfirmarCriarCampanha}
        setModalAberto={setModalConfirmarCriarCampanha}
        handleBotaoDireito={criarCampanha}
      />
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  notificarSucesso: exibirNotificacaoSucesso,
  notificarAlerta: exibirNotificacaoAlerta,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CriarCampanhaWhatsApp);
