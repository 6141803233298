/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Icon, Button, Typography } from '@material-ui/core';
import { Trash2, Filter, Download } from 'react-feather';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import FiltroDesempenhoManager from '../FiltroDesempenhoManager';
import CardFiltro from '../../../shared/componentes/CardFiltro';
import FiltroDesempenhoItem from './FiltroDesempenhoItem';
import FiltroDesempenhoItemDatePicker from './FiltroDesempenhoItemDatePicker';
import {
  opcoesFiltroDesempenho,
  statusAtendimento,
  mensagensNotificacaoAlerta,
  textoBotaoNotificacao,
  idNotificacoesFixas,
  informacoesModais,
  iconesNotificacao,
  mensagensNotificacaoSucesso,
} from '../../../shared/util/Constantes';
import {
  INITIAL_STATE,
  atualizarDataInicial,
  atualizarDataFinal,
} from '../Store';
import {
  exibirNotificacaoSucesso,
  exibirNotificacaoAlerta,
} from '../../notificacao/Store';
import {
  deepEqual,
  deepCopy,
  inicializarDataInicialDesempenho,
} from '../../../shared/util/Utils';
import {
  TOMATO_HOVER,
  LIGHT_SEA_GREEN_HOVER,
  DARK_CYAN,
  TOMATO,
  WHITE,
  LIGHT_GRAY,
  BORDA_FILTRO,
  EXPORT_CSV_TEXT_BUTTON,
  DARK_GRAY_OP_1,
} from '../../../theme';
import { ocultarMensagemAlerta } from '../../notificacao/NotificacaoManager';
import ModalConfirmacao from '../../../shared/componentes/ModalConfirmacao';

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    top: '4rem',
    width: '99%',
    zIndex: '5',
  },
  containerCard: {
    flexWrap: 'nowrap',
  },
  containerbotao: {
    height: '62px',
  },
  iconeContainer: {
    padding: '0.5rem',
    borderRight: '1px solid',
    borderColor: `${LIGHT_GRAY} !important`,
  },
  opcoes: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 50px)',
  },
  itemOpcao: {
    borderRight: `1px solid ${BORDA_FILTRO}`,
    paddingRight: '1rem',
    position: 'relative',
    cursor: 'pointer',
  },
  botaoAplicar: {
    backgroundColor: DARK_CYAN,
    color: WHITE,
    height: '100%',
    paddingLeft: '28px',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: LIGHT_SEA_GREEN_HOVER,
    },
  },
  textoBotaoAplicar: {
    fontSize: '14px',
    color: WHITE,
  },
  botaoRemover: {
    backgroundColor: TOMATO,
    color: WHITE,
    height: '100%',
    float: 'right',
    paddingLeft: '27px',
    '&:hover': {
      backgroundColor: TOMATO_HOVER,
    },
  },
  icone: {
    marginBottom: '2px',
    marginLeft: '0.8px',
  },
  botaoExportarCSV: {
    backgroundColor: WHITE,
    height: '100%',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'rgb(252, 252, 252)',
    },
    boxShadow: '0px 2px 4px rgba(0,0,0,0.05), 0px 2px 5px rgba(0,0,0,0.1)',
  },
  textoBotaoExportarCSV: {
    fontSize: '14px',
    color: EXPORT_CSV_TEXT_BUTTON,
  },
  textoBotaoExportarCSVDisable: {
    fontSize: '14px',
    color: WHITE,
  },
  customTooltip: {
    color: WHITE,
    backgroundColor: DARK_GRAY_OP_1,
  },
});

const Filtro = (props) => {
  const classes = useStyles();

  const {
    atualizaDataInicial,
    atualizaDataFinal,
    atualizarFiltro,
    filtroAplicado,
    setFiltroAplicado,
    filtroDesempenho,
    exibeNotificacaoAlerta,
    notificarSucesso,
  } = props;

  const [estadoLimparFiltro, setEstadoLimparFiltro] = useState(true);
  const [carregando, setCarregando] = useState(false);
  const [carregandoCSV, setCarregandoCSV] = useState(false);
  const [listaCampanhas, setListaCampanhas] = useState([]);
  const [listaAtendentes, setListaAtendentes] = useState([]);
  const [listaSituacoes, setListaSituacoes] = useState([]);
  const [listaProblemas, setListaProblemas] = useState([]);
  const [listaRemedios, setListaRemedios] = useState([]);
  const [modalConfirmacaoDownload, setModalConfirmacaoDownload] =
    useState(false);

  const opcoesFiltro = [
    opcoesFiltroDesempenho.Inicio,
    opcoesFiltroDesempenho.Fim,
    opcoesFiltroDesempenho.Campanha,
    opcoesFiltroDesempenho.Atendente,
    opcoesFiltroDesempenho.Situacao,
    opcoesFiltroDesempenho.CategoriaProblema,
    opcoesFiltroDesempenho.Remedio,
  ];

  const getCsv = async () => {
    setModalConfirmacaoDownload(false);
    const filtroCsv = deepCopy(filtroAplicado);
    setCarregandoCSV(true);
    filtroCsv.atendenteNome = [];
    filtroCsv.campanhaNome = [];
    filtroCsv.problemaNome = [];
    filtroCsv.remedioNome = [];

    listaAtendentes.forEach((item) => {
      if (item.checked === true) {
        filtroCsv.atendenteNome.push(item.title);
      }
    });

    listaCampanhas.forEach((item) => {
      if (item.checked === true) {
        filtroCsv.campanhaNome.push(item.title);
      }
    });

    listaProblemas.forEach((item) => {
      if (item.checked === true) {
        filtroCsv.problemaNome.push(item.title);
      }
    });

    listaRemedios.forEach((item) => {
      if (item.checked === true) {
        filtroCsv.remedioNome.push(item.title);
      }
    });

    const response = await FiltroDesempenhoManager.getCsvURL(filtroCsv);
    if (!response) {
      notificarSucesso(
        mensagensNotificacaoSucesso.ExtracaoRealizadaComSucesso,
        iconesNotificacao.AtendimentoFinalizado
      );
    }
    setCarregandoCSV(false);
  };

  const handleClickButton = () => {
    setModalConfirmacaoDownload(!modalConfirmacaoDownload);
  };

  useEffect(() => {
    function converterFiltrosParaDropdown(array) {
      return array.map((item) => {
        const title = item && item.name ? item.name : statusAtendimento[item];
        const id = item && item.id ? item.id : item;
        return { title, id, checked: false };
      });
    }

    const inicializaFiltros = async () => {
      setCarregando(true);
      const retorno = await FiltroDesempenhoManager.buscarFiltros();
      if (retorno) {
        setListaCampanhas(converterFiltrosParaDropdown(retorno.Campaigns));
        setListaAtendentes(converterFiltrosParaDropdown(retorno.Operators));
        setListaSituacoes(converterFiltrosParaDropdown(retorno.Status));
        setListaProblemas(
          converterFiltrosParaDropdown(retorno.ProblemsCategories)
        );
        setListaRemedios(converterFiltrosParaDropdown(retorno.Remedies));
      }
      setCarregando(false);
      setEstadoLimparFiltro(false);
    };
    if (estadoLimparFiltro) {
      inicializaFiltros();
    }
  }, [atualizaDataFinal, atualizaDataInicial, estadoLimparFiltro]);

  useEffect(() => {
    ocultarMensagemAlerta(idNotificacoesFixas.AlertaAtualizarDados);
    if (!deepEqual(filtroDesempenho, filtroAplicado)) {
      exibeNotificacaoAlerta(
        mensagensNotificacaoAlerta.DadosDesatualizados,
        null,
        false,
        textoBotaoNotificacao.AtualizarDados,
        () => {
          setFiltroAplicado(deepCopy(filtroDesempenho));
        },
        idNotificacoesFixas.AlertaAtualizarDados
      );
    }
  }, [
    filtroDesempenho,
    filtroAplicado,
    setFiltroAplicado,
    exibeNotificacaoAlerta,
  ]);

  function aplicarFiltro() {
    setFiltroAplicado(deepCopy(filtroDesempenho));
  }

  function limparFiltro() {
    setEstadoLimparFiltro(true);
    opcoesFiltro.forEach((name) => {
      if (name === opcoesFiltroDesempenho.Inicio) {
        return atualizaDataInicial(inicializarDataInicialDesempenho());
      }
      if (name === opcoesFiltroDesempenho.Fim) {
        return atualizaDataFinal(new Date());
      }
      return atualizarFiltro([], name);
    });
  }

  function obtemCorBotaoCSV() {
    return !deepEqual(filtroDesempenho, filtroAplicado)
      ? WHITE
      : EXPORT_CSV_TEXT_BUTTON;
  }
  return (
    <Grid
      container
      spacing={1}
      className={classes.container}
      justifyContent="center"
    >
      <Grid
        item
        xs={9}
        className={classes.containerCard}
        id="filtro-desempenho"
      >
        <CardFiltro>
          <Grid item className={classes.iconeContainer}>
            <Box
              mr={0}
              p={0.5}
              borderRadius={4}
              className="cardIconContainerFiltro"
            >
              <Icon className="cardIcon">
                <Filter size={20} className={classes.icone} />
              </Icon>
            </Box>
          </Grid>

          <Grid item className={classes.opcoes}>
            <FiltroDesempenhoItemDatePicker
              titulo={opcoesFiltroDesempenho.Inicio}
              carregando={carregando}
            />
            <FiltroDesempenhoItemDatePicker
              titulo={opcoesFiltroDesempenho.Fim}
              carregando={carregando}
            />
            <FiltroDesempenhoItem
              titulo={opcoesFiltroDesempenho.Campanha}
              listaItens={listaCampanhas}
              setlistaItens={setListaCampanhas}
              carregando={carregando}
            >
              {opcoesFiltroDesempenho.Campanha}
            </FiltroDesempenhoItem>
            <FiltroDesempenhoItem
              titulo={opcoesFiltroDesempenho.Atendente}
              listaItens={listaAtendentes}
              setlistaItens={setListaAtendentes}
              carregando={carregando}
            >
              {opcoesFiltroDesempenho.Atendente}
            </FiltroDesempenhoItem>
            <FiltroDesempenhoItem
              titulo={opcoesFiltroDesempenho.Situacao}
              listaItens={listaSituacoes}
              setlistaItens={setListaSituacoes}
              carregando={carregando}
            >
              {opcoesFiltroDesempenho.Situacao}
            </FiltroDesempenhoItem>
            <FiltroDesempenhoItem
              titulo={opcoesFiltroDesempenho.CategoriaProblema}
              listaItens={listaProblemas}
              setlistaItens={setListaProblemas}
              carregando={carregando}
            >
              {opcoesFiltroDesempenho.CategoriaProblema}
            </FiltroDesempenhoItem>
            <FiltroDesempenhoItem
              titulo={opcoesFiltroDesempenho.Remedio}
              listaItens={listaRemedios}
              setlistaItens={setListaRemedios}
              carregando={carregando}
            >
              {opcoesFiltroDesempenho.Remedio}
            </FiltroDesempenhoItem>
          </Grid>
        </CardFiltro>
      </Grid>
      <Grid container spacing={2} item xs={3} direction="row">
        <Grid
          item
          xs={3}
          className={classes.containerbotao}
          id="aplicar-filtro"
        >
          <Button
            variant="contained"
            startIcon={<Filter size={18} />}
            type="submit"
            fullWidth
            disableElevation
            className={`${classes.botaoAplicar} "Login-Btn"`}
            onClick={() => aplicarFiltro()}
            disabled={deepEqual(filtroDesempenho, filtroAplicado)}
          />
        </Grid>
        <Grid item xs={3} className={classes.containerbotao} id="limpar-filtro">
          <Tooltip
            title={
              deepEqual(INITIAL_STATE, filtroDesempenho) ? '' : 'Limpar Filtros'
            }
            classes={{
              tooltip: classes.customTooltip,
            }}
          >
            <Button
              variant="contained"
              startIcon={<Trash2 size={18} />}
              type="submit"
              fullWidth
              disableElevation
              className={`${classes.botaoRemover} "Login-Btn"`}
              onClick={() => limparFiltro()}
              disabled={deepEqual(INITIAL_STATE, filtroDesempenho)}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={6} className={classes.containerbotao}>
          <Button
            id="button-csv"
            onClick={() => handleClickButton()}
            variant="contained"
            fullWidth
            className={`${classes.botaoExportarCSV} "Login-Btn"`}
            disabled={!deepEqual(filtroDesempenho, filtroAplicado)}
          >
            {carregandoCSV && (
              <ReactLoading
                type="spin"
                color={DARK_CYAN}
                height={25}
                width={25}
              />
            )}
            {!carregandoCSV && (
              <Grid container justifyContent="center" alignItems="center">
                <Download
                  size={18}
                  color={obtemCorBotaoCSV()}
                  className="mr-1"
                />
                <Typography
                  className={
                    !deepEqual(filtroDesempenho, filtroAplicado)
                      ? classes.textoBotaoExportarCSVDisable
                      : classes.textoBotaoExportarCSV
                  }
                  variant="h6"
                >
                  csv
                </Typography>
              </Grid>
            )}
          </Button>
        </Grid>
      </Grid>
      <ModalConfirmacao
        modalAberto={modalConfirmacaoDownload}
        setModalAberto={setModalConfirmacaoDownload}
        tituloModal={informacoesModais.ModalExportarCSV.Titulo}
        textoBotaoDireito={informacoesModais.ModalExportarCSV.TextoBotaoDireito}
        descricao={informacoesModais.ModalExportarCSV.Descricao}
        textoBotaoEsquerdo={informacoesModais.TextoBotaoCancelar}
        handleBotaoEsquerdo={() => setModalConfirmacaoDownload(false)}
        handleBotaoDireito={() => getCsv()}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    estadoFiltroAplicado: state.filtroDesempenho.estadoFiltroAplicado,
    filtroDesempenho: state.filtroDesempenho,
  };
};

const mapDispatchToProps = {
  atualizaDataInicial: atualizarDataInicial,
  atualizaDataFinal: atualizarDataFinal,
  atualizarFiltro: (lista, descricao) =>
    FiltroDesempenhoManager.atualizarFiltro(lista, descricao),
  exibeNotificacaoAlerta: exibirNotificacaoAlerta,
  notificarSucesso: exibirNotificacaoSucesso,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filtro);
