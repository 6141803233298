import { Grid, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Search } from 'react-feather';
import { normalizeText } from '../../../shared/util/Utils';
import { GRAY } from '../../../theme';
import FiltroGrupos from './FiltroGrupos';
import TabelaGruposVisualizacao from './TabelaGruposVisualizacao';

const useStyles = makeStyles(() => ({
  searchField: {
    width: '395px',
    minWidth: '50px',

    '& .MuiFilledInput-inputMarginDense': {
      paddingTop: '12px',
      paddingBottom: '11px',
    },
  },
  containerTextFielButton: {
    marginTop: '20px',
    marginBottom: '10px',
    maxHeight: '37px',
    overflowY: 'visible',
    alignItems: 'flex-start',
  },
}));

const AbaGruposVisualizacao = (props) => {
  const classes = useStyles();

  const {
    listaUsuarios,
    listaGruposVisualizacao,
    usuarioLogado,
    carregando,
    buscaGrupos,
    setBuscaGrupos,
    setGrupoGerenciado,
    filtroGruposVisualizacaoDropdown,
    setfiltroGruposVisualizacaoDropdown,
    itensSelecionadosDropdown,
    setItensSelecionadosDropdown,
  } = props;

  const [listaGruposFiltrados, setListaGruposFiltrados] = useState(
    listaGruposVisualizacao
  );
  const [listaUsuariosFiltradosGrupos, setListaUsuariosFiltradosGrupos] =
    useState([]);

  useEffect(() => {
    if (
      buscaGrupos &&
      buscaGrupos.length &&
      buscaGrupos.length > 0 &&
      listaUsuarios &&
      listaGruposVisualizacao
    ) {
      const listaFiltrada = listaUsuarios.filter(
        (element) =>
          normalizeText(element.Login).indexOf(normalizeText(buscaGrupos)) !==
          -1
      );

      setListaUsuariosFiltradosGrupos(listaFiltrada);
    } else {
      setListaUsuariosFiltradosGrupos(listaUsuarios);
    }
  }, [buscaGrupos, listaUsuarios, listaGruposVisualizacao]);

  useEffect(() => {
    if (
      buscaGrupos &&
      buscaGrupos.length &&
      buscaGrupos.length > 0 &&
      listaGruposVisualizacao &&
      listaUsuariosFiltradosGrupos
    ) {
      const newListaGruposFiltrados = [];
      for (const usuario of listaUsuariosFiltradosGrupos) {
        usuario.VisualizationGroup.forEach((visualizationGroup) => {
          if (newListaGruposFiltrados.indexOf(visualizationGroup) === -1) {
            newListaGruposFiltrados.push(visualizationGroup);
          }
        });
      }

      if (itensSelecionadosDropdown.length > 0) {
        const newListaGruposDropdown = [];
        itensSelecionadosDropdown.forEach((item) =>
          newListaGruposDropdown.push(item.title)
        );
        setListaGruposFiltrados(
          newListaGruposFiltrados
            .filter((grupoFiltrado) =>
              newListaGruposDropdown.includes(grupoFiltrado)
            )
            .sort()
        );
      } else {
        newListaGruposFiltrados.sort();
        setListaGruposFiltrados(newListaGruposFiltrados);
      }
    } else if (
      itensSelecionadosDropdown &&
      itensSelecionadosDropdown.length &&
      itensSelecionadosDropdown.length > 0
    ) {
      const newListaGruposDropdown = [];
      itensSelecionadosDropdown.forEach((item) =>
        newListaGruposDropdown.push(item.title)
      );
      newListaGruposDropdown.sort();
      setListaGruposFiltrados(newListaGruposDropdown);
    } else {
      listaGruposVisualizacao.sort();
      setListaGruposFiltrados(listaGruposVisualizacao);
    }
  }, [
    buscaGrupos,
    listaGruposVisualizacao,
    listaUsuariosFiltradosGrupos,
    itensSelecionadosDropdown,
  ]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        display="inline"
        className={classes.containerTextFielButton}
      >
        <TextField
          variant="filled"
          value={buscaGrupos}
          onInput={(e) => {
            setBuscaGrupos(e.target.value);
          }}
          size="small"
          placeholder="Pesquise um Nome de Usuário ou Login..."
          InputProps={{
            style: {
              fontWeight: 'normal',
              backgroundColor: 'rgba(158, 171, 181, 0.05)',
              fontSize: '16px',
            },
            endAdornment: <Search size={20} color={GRAY} />,
            'data-testid': 'textfield-modal-permissao-grupos',
          }}
          className={classes.searchField}
        />
        <FiltroGrupos
          carregando={carregando}
          listaItens={filtroGruposVisualizacaoDropdown}
          setlistaItens={setfiltroGruposVisualizacaoDropdown}
          itensSelecionados={itensSelecionadosDropdown}
          setItensSelecionados={setItensSelecionadosDropdown}
          width="395px"
        />
      </Grid>
      <TabelaGruposVisualizacao
        listaUsuarios={listaUsuarios}
        setListaGruposFiltrados={setListaGruposFiltrados}
        listaUsuariosFiltrados={listaUsuariosFiltradosGrupos}
        listaGruposFiltrados={listaGruposFiltrados}
        usuarioLogado={usuarioLogado}
        showFooter={listaGruposFiltrados && listaGruposFiltrados.length > 0}
        carregando={carregando}
        setGrupoGerenciado={setGrupoGerenciado}
      />
    </>
  );
};

export default AbaGruposVisualizacao;
