import React from 'react';
import Chart from 'react-google-charts';
import './GraficoLinha.css';
import { DARK_CYAN, LIGHT_CYAN } from '../../../../theme';
import DadosNaoEncontrados from '../../../../shared/componentes/DadosNaoEncontrados';

const GraficoLinha = (props) => {
  const { dadosGrafico, maximoEixoY, minimoEixoY, maximoEixoX, minimoEixoX } =
    props;

  return (
    <>
      {dadosGrafico && dadosGrafico.length > 0 && (
        <Chart
          chartLanguage="pt-br"
          chartType="LineChart"
          height="100%"
          width="100%"
          data={dadosGrafico}
          formatters={[
            {
              type: 'NumberFormat',
              column: 0,
              options: {
                pattern: '#',
              },
            },
          ]}
          options={{
            curveType: 'function',
            focusTarget: 'category',
            backgroundColor: {
              fill: 'transparent',
            },
            tooltip: {
              isHtml: true,
            },
            chartArea: {
              width: '80%',
              height: '80%',
              top: 10,
            },
            animation: {
              duration: 1000,
              easing: 'out',
              startup: true,
            },
            vAxis: {
              baselineColor: 'transparent',
              gridlines: {
                color: 'transparent',
              },
              viewWindow: {
                max: maximoEixoY,
                min: minimoEixoY,
              },
              title: 'Quantidade de Atendimentos',
            },
            hAxis: {
              baselineColor: 'transparent',
              gridlines: {
                color: 'transparent',
              },
              viewWindow: {
                max: maximoEixoX,
                min: minimoEixoX,
              },
              format: '#,##',
              title: 'Duração do Atendimento em Minutos',
            },
            legend: 'none',
            lineWidth: 4,
            series: {
              0: { color: DARK_CYAN, visibleInLegend: false },
            },
            crosshair: {
              trigger: 'both',
              orientation: 'vertical',
              color: LIGHT_CYAN,
              opacity: 0.2,
            },
          }}
        />
      )}
      {dadosGrafico && dadosGrafico.length === 0 && (
        <DadosNaoEncontrados chaveamentoEstadoVazio={1} />
      )}
    </>
  );
};

export default GraficoLinha;
