import React from 'react';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import { Button, Grid } from '@material-ui/core';
import { LogIn } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import LogoAnima from '../../../shared/img/logo_anima.svg';

import { DARK_CYAN, WHITE } from '../../../theme';

import './Logout.css';

const useStyles = makeStyles({
  botaoLogout: {
    backgroundColor: DARK_CYAN,
    color: WHITE,
    fontSize: '14px',
    fontWeight: 'bold',
  },
});

const Logout = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    history.push('/');
  };

  return (
    <>
      <Grid className="container-logout" id="container-logout">
        <Grid className="caixa-logout">
          <img src={LogoAnima} className="imagem-logout" alt="Logo" />
          Você saiu da aplicação
          <Formik initialValues={{}}>
            <Form className="grupo-logout" onSubmit={handleSubmit}>
              <Button
                id="container-logout-enter"
                variant="contained"
                className={classes.botaoLogout}
                startIcon={<LogIn size={18} />}
                type="submit"
                fullWidth
                disableElevation
              >
                Entrar
              </Button>
            </Form>
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
