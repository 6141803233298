import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { Clock } from 'react-feather';
import Tooltip from '@material-ui/core/Tooltip';
import { GRAY, DARK_CYAN } from '../../../../theme';
import { convertMinutesToHoursAndMinutes } from '../../../../shared/util/Utils';

const useStyles = makeStyles({
  icone: {
    width: '16px',
    height: '16px',
    color: GRAY,
    marginRight: '9px',
  },
  totalDeAtendimentoTitulo: {
    marginBottom: '10px',
    lineHeight: '18px',
  },
  tempoTotalAtendimento: {
    color: DARK_CYAN,
    fontWeight: 'Bold',
    fontSize: '14px',
    textAlign: 'end',
  },
  tempoMedioPorcentagem: {
    fontSize: '14px',
    textAlign: 'end',
  },
  gridTitulo: {
    marginTop: '2px',
    width: '105px',
  },
  containerPerfilInfo: {
    marginBottom: '12px',
    display: 'grid',
    gridTemplateColumns: 'min-content auto min-content 65px',
    width: '100%',
  },
});

const TempoTotalAtendimentoPerfil = (props) => {
  const {
    tempoTotalAtendimentoAtendente,
    tempoMedioPorcentagem,
    operatorName,
  } = props;

  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      className={classes.containerPerfilInfo}
    >
      <Clock className={classes.icone} style={{ color: GRAY }} />
      <Typography className={classes.mediaPorAtendimentoTitulo}>
        Tempo Total de Atendimento
      </Typography>
      <Grid item>
        <Tooltip title={`Tempo total de atendimento de ${operatorName}.`}>
          <Typography
            noWrap
            className={classes.tempoTotalAtendimento}
            variant="body1"
          >
            {convertMinutesToHoursAndMinutes(tempoTotalAtendimentoAtendente)}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={`Percentual de tempo de atendimento de ${operatorName} em relação ao total de tempo de todos os atendimentos do time.`}
        >
          <Typography noWrap className={classes.tempoMedioPorcentagem}>
            {tempoMedioPorcentagem}%
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default TempoTotalAtendimentoPerfil;
