import React, { lazy } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Callback, makeAuthenticator } from 'react-oidc';
import { connect, useSelector } from 'react-redux';
import EsperarComponente from './shared/layout/EsperarComponente';

import { atualizarUsuario } from './features/login/Store';
import { GetUserData } from './features/login/LoginManager';
import Logout from './features/logout';
import CockpitHome from './features/cockpit/home/views/CockpitHome';
import MensagensHome from './features/whatsapp/home/views/MensagensHome';
import DesempenhoHome from './features/performance/home/views/DesempenhoHome';
import CampanhasWhatsappHome from './features/gestaoCampanhasWhatsapp/home/views/CampanhasWhatsappHome';

const AtendimentoHome = lazy(() => import('./features/home'));
const ErrorPage2 = lazy(() => import('./shared/componentes/ErrorPage'));

export const CallBackLogin = ({
  routeProps,
  userManager,
  atualizarUsuarioHandler,
}) => {
  return (
    <Callback
      onSuccess={(user) => {
        atualizarUsuarioHandler(GetUserData(user));
        routeProps.history.push('/');
      }}
      userManager={userManager}
    />
  );
};

export const AppWithAuth = (component) => {
  const userManager = useSelector((state) => state.login.userManager);
  if (userManager) {
    return makeAuthenticator({
      userManager,
    })(EsperarComponente(component));
  }
  return () => <></>;
};

export const Routes = (props) => {
  const { atualizarUsuarioHandler, userManager } = props;

  const AppRoutes = withRouter(() => (
    <Switch>
      <Route
        exact
        path="/login"
        render={(routeProps) => (
          <CallBackLogin
            routeProps={routeProps}
            userManager={userManager}
            atualizarUsuarioHandler={atualizarUsuarioHandler}
          />
        )}
      />
      <Route exact path="/" component={AppWithAuth(AtendimentoHome)} />
      <Route
        exact
        path="/performance"
        component={AppWithAuth(DesempenhoHome)}
      />
      <Route exact path="/cockpit" component={AppWithAuth(CockpitHome)} />
      <Route
        exact
        path="/whatsapp-message"
        component={AppWithAuth(MensagensHome)}
      />
      <Route
        exact
        path="/whatsapp-campaign"
        component={AppWithAuth(CampanhasWhatsappHome)}
      />
      <Route path="/logout" render={() => <Logout />} />
      <Route
        exact
        path="/errorPage"
        component={EsperarComponente(ErrorPage2)}
      />
      <Route render={() => <Redirect to={{ pathname: '/errorPage' }} />} />
    </Switch>
  ));
  return <> {AppRoutes && <AppRoutes />} </>;
};

const mapStateToProps = (state) => ({
  userManager: state.login.userManager,
});

const mapDispatchToProps = {
  atualizarUsuarioHandler: (user) => atualizarUsuario(user),
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
