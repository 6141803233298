import React, { useEffect } from 'react';
import { Snackbar, Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

import {
  OPAQUE_LIGHT_ORANGE,
  ORANGE,
  FONT_FAMILY_REGULAR,
} from '../../../theme';

const NotificacaoAlerta = (props) => {
  const {
    mensagemAlerta,
    ocultarMensagemAlerta,
    classeSnackBar,
    classeSnackBarText,
    iconeAlerta,
    ocultaAutomaticamente,
    textoBotaoAlerta,
    handleSnackBarButton,
    posicaoAlerta,
    timestamp,
  } = props;

  const useStyles = makeStyles(() => ({
    snackBar: {
      backgroundColor: OPAQUE_LIGHT_ORANGE,
      color: `${ORANGE} !important`,
      fontSize: '14px',
      fontWeight: 'bold',
      height: '48px',
      fill: 'solid',
      boxShadow: '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
    },
    alertSnackbarText: {
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: FONT_FAMILY_REGULAR,
      color: ORANGE,
      marginRight: '40px',
    },
    snackButton: {
      align: 'right',
      color: ORANGE,
      fontSize: '14px',
      fontWeight: 'bold',
      marginTop: '-7px',
    },
    posicao: {
      bottom: `${24 + posicaoAlerta * 56}px !important`,
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const getExpireTime = (timestampParam) => {
      const nowDate = new Date().getTime();
      if (nowDate - timestampParam < 5000)
        return 5000 - (nowDate - timestampParam);
      return 1;
    };
    const interval = setTimeout(() => {
      if (mensagemAlerta && ocultaAutomaticamente) {
        ocultarMensagemAlerta(timestamp);
      }
    }, getExpireTime(timestamp));
    return () => {
      clearTimeout(interval);
    };
  }, [mensagemAlerta, ocultaAutomaticamente, ocultarMensagemAlerta, timestamp]);

  const mensagemAberta = (mensagemAlerta && mensagemAlerta.trim().length) > 0;

  function handleClick(timestampParam) {
    handleSnackBarButton(timestampParam);
    ocultarMensagemAlerta(timestampParam);
  }

  return (
    <Snackbar
      className={classes.posicao}
      open={mensagemAberta}
      onClose={() => ocultarMensagemAlerta(timestamp)}
      ClickAwayListenerProps={{
        mouseEvent: false,
      }}
    >
      <MuiAlert
        elevation={0}
        icon={iconeAlerta}
        severity="success"
        variant="filled"
        className={`${classes.snackBar} ${classeSnackBar}`}
      >
        <Grid container item direction="row" xs={12}>
          <Typography
            className={`${classeSnackBarText} ${classes.alertSnackbarText}`}
          >
            {mensagemAlerta}
          </Typography>
          {textoBotaoAlerta && (
            <Button
              className={classes.snackButton}
              onClick={() => handleClick(timestamp)}
            >
              {textoBotaoAlerta}
            </Button>
          )}
        </Grid>
      </MuiAlert>
    </Snackbar>
  );
};

export default NotificacaoAlerta;
