import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useRef, useState, useEffect } from 'react';
import { Calendar, Check } from 'react-feather';
import { connect } from 'react-redux';
import DropdownDateTimePicker from '../../../shared/componentes/DropdownDateTimePicker';
import TagStatus from '../../../shared/componentes/TagStatus';
import Loading from '../../../shared/componentes/Loading';
import {
  conversaoNomenclaturaStatus,
  informacoesModais,
  iconesNotificacao,
  mensagemAlterarStatus,
} from '../../../shared/util/Constantes';
import {
  BLACK,
  DARK_CYAN,
  DARK_GRAY,
  GRAY,
  LIGHT_SEA_GREEN_HOVER,
  MEDIUM_GRAY,
  STAR_COMMAND_BLUE,
  TOMATO,
  WHITE,
} from '../../../theme';
import { exibirNotificacaoSucesso } from '../../notificacao/Store';
import GestaoCampanhasManager, {
  buscarInformacaoModalCampanha,
} from '../GestaoCampanhasManager';
import { formatarDataParaTimestamp } from '../../../shared/util/Utils';

const useStyles = makeStyles(() => ({
  modal: {
    width: '465px',
    maxHeight: '524px',
    overflowY: 'visible',
  },
  modalDialogContent: {
    padding: '25px 32px',
    '&:first-child': {
      paddingTop: '25px',
    },
    overflowY: 'visible',
  },
  containerDescricao: {
    marginBottom: '22px',
  },
  text: {
    color: BLACK,
  },
  info: {
    color: STAR_COMMAND_BLUE,
    marginBottom: '22px',
  },
  titulo: {
    marginBottom: '22px',
  },
  pedidoConfirmacao: {
    color: DARK_GRAY,
    fontSize: '16px',
    marginBottom: '22px',
  },
  botaoEsquerdo: {
    height: '45px',
    width: '130px',
  },
  textoBotaoEsquerdo: {
    color: MEDIUM_GRAY,
  },
  botaoDireito: {
    backgroundColor: DARK_CYAN,
    height: '45px',
    width: '210px',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: LIGHT_SEA_GREEN_HOVER,
    },
  },
  textoBotaoDireito: {
    color: WHITE,
  },
  textoBotaoDireitoDisabled: {
    color: GRAY,
  },
  labelTituloSelect: {
    fontSize: '14px',
  },
  iconLabelTituloSelect: {
    marginRight: '7px',
    marginBottom: '3px',
  },
  containerSelect: {
    marginBottom: '22px',
  },
  containerLabelSelect: {
    marginBottom: '5px',
  },
  descricaoVermelho: {
    color: TOMATO,
  },
  containerPrimeiraDescricao: {
    marginBottom: '20px',
  },
  InformacaoCampanhaColor: {
    color: TOMATO,
  },
}));

const ModalAlterarStatusCampanha = (props) => {
  const {
    modalAberto,
    setModalAberto,
    notificarSucesso,
    statusConvertido,
    statusAtualCampanha,
    idCampanha,
    handleAlterarStatusCampanha,
    mensagensWhatsapp,
  } = props;
  const classes = useStyles(props);

  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
  const [dataHora, setDataHora] = useState(false);

  const [carregando, setCarregando] = useState(false);
  const [InformacaoCampanha, setInformacaoCampanha] = useState({});
  const isUmounted = useRef(false);

  const alterarStatusCampanha = async () => {
    setCarregando(true);
    const success = await GestaoCampanhasManager.alterarStatusCampanha(
      idCampanha,
      statusConvertido.status.toLowerCase(),
      formatarDataParaTimestamp(dataHora)
    );
    if (success === null) {
      notificarSucesso(
        'Status da Campanha Alterado com Sucesso',
        iconesNotificacao.MarcaVerificacao
      );
      setModalAberto(false);
      handleAlterarStatusCampanha(
        idCampanha,
        statusConvertido.status,
        dataHora
      );
    }
    if (isUmounted.current) {
      setCarregando(false);
    }
  };

  const handleOnCloseAsync = async () => {
    if (!carregando) await setModalAberto(false);
  };

  useEffect(() => {
    setCarregando(true);
    const buscarInformacaoCampanha = async () => {
      const resultInformacaoCampanha = await buscarInformacaoModalCampanha(
        idCampanha
      );
      if (resultInformacaoCampanha) {
        setInformacaoCampanha(resultInformacaoCampanha);
      }
      setCarregando(false);
    };
    buscarInformacaoCampanha();
  }, [idCampanha, setCarregando, setInformacaoCampanha]);

  const buscarGatilhoFimCampanha = () => {
    if (mensagensWhatsapp) {
      return mensagensWhatsapp.find((mensagens) => mensagens.gatilho === 0);
    }
  };

  useEffect(() => {
    isUmounted.current = true;
    return () => {
      isUmounted.current = false;
    };
  }, []);

  return (
    <Dialog
      open={modalAberto}
      onClose={handleOnCloseAsync}
      data-testid="modal-confirm-dialog"
      classes={{ paper: classes.modal }}
    >
      {carregando && <Loading />}
      <DialogContent classes={{ root: classes.modalDialogContent }} dividers>
        <Grid className={classes.containerConteudoDialog}>
          <Typography variant="h3" className={classes.titulo}>
            {statusConvertido.status} Campanha
          </Typography>

          <Grid container>
            <Grid className={classes.containerPrimeiraDescricao}>
              <Typography
                variant="body2"
                display="inline"
                className={classes.text}
              >
                As edições realizadas alteram o STATUS da campanha de
              </Typography>

              <TagStatus
                status={statusAtualCampanha.status[0]}
                corSigla={statusAtualCampanha.caractereColor}
                corBackground={statusAtualCampanha.backgroundColor}
                mostrarTooltip={false}
              />

              <Typography
                variant="body2"
                display="inline"
                className={classes.text}
              >
                {statusAtualCampanha.status}{' '}
              </Typography>

              <Typography
                variant="body2"
                display="inline"
                className={classes.text}
              >
                para
              </Typography>

              <TagStatus
                status={conversaoNomenclaturaStatus[statusConvertido.status]}
                corSigla={statusConvertido.caractereColor}
                corBackground={statusConvertido.backgroundColor}
                mostrarTooltip={false}
              />

              <Typography
                variant="body2"
                display="inline"
                className={classes.text}
              >
                {conversaoNomenclaturaStatus[statusConvertido.status]}.
              </Typography>
            </Grid>

            {statusConvertido.status !== 'Expirar' && (
              <Grid className={classes.containerDescricao}>
                <Typography
                  display="inline"
                  variant="body2"
                  className={classes.text}
                >
                  Para {statusConvertido.status} esta campanha é necessário
                  redefinir uma <b>Data de Término</b>.
                </Typography>
                <Typography
                  display="inline"
                  variant="body2"
                  className={classes.descricaoVermelho}
                >
                  {' '}
                  A campanha terá Início imediato.
                </Typography>
              </Grid>
            )}

            {statusConvertido.status !== 'Expirar' ? (
              <Typography variant="body2" className={classes.info}>
                As demais configurações serão mantidas e algumas podem ser
                alteradas no menu de Editar Campanhas.
              </Typography>
            ) : (
              <Typography variant="body2" className={classes.info}>
                A campanha poderá ser reativada no menu de opções.
              </Typography>
            )}
          </Grid>

          {statusConvertido.status !== 'Expirar' && (
            <Grid container className={classes.containerSelect}>
              <Grid
                container
                direction="row"
                alignItems="center"
                className={classes.containerLabelSelect}
              >
                <Calendar
                  size={20}
                  color={DARK_CYAN}
                  className={classes.iconLabelTituloSelect}
                />
                <Typography
                  variant="subtitle2"
                  className={classes.labelTituloSelect}
                >
                  Data e Hora - Término
                </Typography>
              </Grid>

              <DropdownDateTimePicker
                mostrarDropdown={openDateTimePicker}
                setMostrarDropdown={setOpenDateTimePicker}
                setDataHora={setDataHora}
                dataHora={dataHora}
                tamanhoTexto="272px"
                top="-362px"
                left="-352px"
                inputValueOnInit={false}
                placeholder="Ex: 21/07/2022 12:00"
              />
            </Grid>
          )}

          {!buscarGatilhoFimCampanha() &&
            InformacaoCampanha &&
            InformacaoCampanha.mensagemFimDeCampanhaQueReceberao === 0 && (
              <Typography variant="h4" className={classes.pedidoConfirmacao}>
                Tem certeza que deseja {statusConvertido.status} a campanha?
              </Typography>
            )}

          {buscarGatilhoFimCampanha() &&
            InformacaoCampanha &&
            InformacaoCampanha.mensagemFimDeCampanhaQueReceberao === 0 && (
              <Typography variant="h4" className={classes.pedidoConfirmacao}>
                Tem certeza que deseja {statusConvertido.status} a campanha?
              </Typography>
            )}

          {!buscarGatilhoFimCampanha() &&
            InformacaoCampanha &&
            InformacaoCampanha.mensagemFimDeCampanhaQueReceberao > 0 && (
              <Typography variant="h4" className={classes.pedidoConfirmacao}>
                Tem certeza que deseja {statusConvertido.status} a campanha?
              </Typography>
            )}

          {InformacaoCampanha &&
            statusConvertido.status === 'Reativar' &&
            InformacaoCampanha.mensagemFimDeCampanhaRecebida > 0 && (
              <Typography variant="h4" className={classes.pedidoConfirmacao}>
                A campanha será reativada e{' '}
                <span className={classes.InformacaoCampanhaColor}>
                  {InformacaoCampanha.mensagemFimDeCampanhaRecebida}
                </span>{' '}
                {InformacaoCampanha.mensagemFimDeCampanhaRecebida > 1
                  ? mensagemAlterarStatus.ModalReativarCampanhaPlural
                  : mensagemAlterarStatus.ModalReativarCampanhaSingular}
              </Typography>
            )}
          {buscarGatilhoFimCampanha() &&
            InformacaoCampanha &&
            statusConvertido.status === 'Expirar' &&
            InformacaoCampanha.mensagemFimDeCampanhaQueReceberao > 0 && (
              <Typography variant="h4" className={classes.pedidoConfirmacao}>
                A campanha será encerrada e{' '}
                <span className={classes.InformacaoCampanhaColor}>
                  {InformacaoCampanha.mensagemFimDeCampanhaQueReceberao}
                </span>{' '}
                {InformacaoCampanha.mensagemFimDeCampanhaQueReceberao > 1
                  ? mensagemAlterarStatus.ModalExpirarCampanhaPlural
                  : mensagemAlterarStatus.ModalExpirarCampanhaSingular}
              </Typography>
            )}

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              className={classes.botaoEsquerdo}
              data-testid="button-cancelar-modal-opcoes-campanhas"
              onClick={() => setModalAberto(false)}
            >
              <Typography className={classes.textoBotaoEsquerdo} variant="h4">
                {informacoesModais.TextoBotaoCancelar}
              </Typography>
            </Button>
            <Button
              disableElevation
              startIcon={
                <Check
                  size={20}
                  color={
                    (dataHora && !openDateTimePicker) ||
                    statusConvertido.status === 'Expirar'
                      ? WHITE
                      : GRAY
                  }
                />
              }
              className={`${classes.botaoDireito} "Login-Btn"`}
              disabled={
                (!dataHora && statusConvertido.status !== 'Expirar') ||
                openDateTimePicker
              }
              data-testid="button-confirmar-modal-opcoes-campanhas"
              onClick={() => alterarStatusCampanha()}
            >
              <Typography
                className={
                  (dataHora && !openDateTimePicker) ||
                  statusConvertido.status === 'Expirar'
                    ? classes.textoBotaoDireito
                    : classes.textoBotaoDireitoDisabled
                }
                variant="h4"
              >
                {statusConvertido.status} Campanha
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const mapStateToProps = () => ({});

const mapDispatchToProps = {
  notificarSucesso: exibirNotificacaoSucesso,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalAlterarStatusCampanha);
