import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useRef, useState, useEffect } from 'react';
import { Trash2 } from 'react-feather';
import { connect } from 'react-redux';
import Loading from './Loading';
import { informacoesModais, iconesNotificacao } from '../util/Constantes';
import {
  WHITE,
  BLACK,
  DARK_CYAN,
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
  MEDIUM_GRAY,
  TOMATO,
  TOMATO_HOVER,
} from '../../theme';
import { exibirNotificacaoSucesso } from '../../features/notificacao/Store';
import GestaoCampanhasManager from '../../features/gestaoCampanhas/GestaoCampanhasManager';
import GestaoCampanhasWhatsappManager from '../../features/gestaoCampanhasWhatsapp/GestaoCampanhasWhatsappManager';

const useStyles = makeStyles(() => ({
  botaoEsquerdo: {
    color: MEDIUM_GRAY,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px',
  },
  titulo: {
    color: DARK_CYAN,
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '25px',
  },
  botaoDireito: {
    color: WHITE,
    backgroundColor: TOMATO,
    fontFamily: FONT_FAMILY_BOLD,
    fontSize: '14px',
    lineHeight: '52px',
    padding: '0px 26px',
    '&:hover': {
      backgroundColor: TOMATO_HOVER,
    },
  },
  backgroundProps: {
    background: 'rgba(192,198,214,0.4)',
  },
  modal: {
    maxWidth: '400px',
    backgroundColor: WHITE,
    boxShadow: '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
    borderRadius: '8px',
    minWidth: '350px',
  },
  containerConteudoDialog: {
    padding: '20px 15px 20px 15px',
  },
  aviso: {
    fontSize: '16px',
    color: TOMATO,
    fontFamily: FONT_FAMILY_REGULAR,
    marginBottom: '20px',
  },
  descricao: {
    fontSize: '16px',
    color: BLACK,
    fontFamily: FONT_FAMILY_REGULAR,
    marginBottom: '20px',
  },
  closeButton: {
    position: 'absolute',
    right: '16px',
    top: '16px',
    color: '#9e9e9e',
  },
}));

const ModalExclusaoCampanha = (props) => {
  const {
    modalAberto,
    setModalAberto,
    notificarSucesso,
    idCampanha,
    handleExclusao,
    exclusaoCampanhaWhatsapp = false,
  } = props;
  const classes = useStyles();

  const [carregando, setCarregando] = useState(false);
  const isUmounted = useRef(false);

  const excluirCampanha = async () => {
    setCarregando(true);
    const success = exclusaoCampanhaWhatsapp
      ? await GestaoCampanhasWhatsappManager.excluirCampanha(idCampanha)
      : await GestaoCampanhasManager.excluirCampanha(idCampanha);
    if (success === null) {
      notificarSucesso(
        'Campanha excluída com sucesso',
        iconesNotificacao.Exclusao
      );
      setModalAberto(false);
      handleExclusao(idCampanha);
    }

    if (isUmounted.current) {
      setCarregando(false);
    }
  };

  const handleOnCloseAsync = async () => {
    if (!carregando) await setModalAberto(false);
  };

  useEffect(() => {
    isUmounted.current = true;
    return () => {
      isUmounted.current = false;
    };
  }, []);

  return (
    <Dialog
      open={modalAberto}
      onClose={handleOnCloseAsync}
      aria-labelledby="modal-titulo"
      aria-describedby="modal-descricao"
      data-testid="modal-confirm-dialog"
      scroll="body"
      classes={{ paper: classes.modal }}
      BackdropProps={{
        classes: {
          root: classes.backgroundProps,
        },
      }}
    >
      {carregando && <Loading />}
      <DialogContent>
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.containerConteudoDialog}
        >
          <Typography className={classes.titulo}>
            {informacoesModais.ModalExclusaoCampanha.Titulo}
          </Typography>
          <Grid>
            <Typography className={classes.aviso}>
              {informacoesModais.ModalExclusaoCampanha.Aviso}
            </Typography>
            <Typography className={classes.descricao}>
              {informacoesModais.ModalExclusaoCampanha.Descricao}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Button
              className={classes.botaoEsquerdo}
              onClick={() => setModalAberto(false)}
            >
              {informacoesModais.TextoBotaoCancelar}
            </Button>
            <Button
              className={classes.botaoDireito}
              disableElevation
              startIcon={<Trash2 size={20} />}
              onClick={() => excluirCampanha()}
            >
              {informacoesModais.ModalExclusaoCampanha.TextoBotaoDireito}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const mapStateToProps = () => ({});

const mapDispatchToProps = {
  notificarSucesso: exibirNotificacaoSucesso,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalExclusaoCampanha);
