import { Grid, TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Trash2 } from 'react-feather';
import { deepCopy } from '../../../shared/util/Utils';
import Loading from '../../../shared/componentes/Loading';
import {
  DARK_CYAN,
  GRAY,
  MEDIUM_GRAY,
  LIGHT_SEA_GREEN_HOVER,
} from '../../../theme';
import ConfiguracoesUsuarioManager from '../../configuracoes/ConfiguracoesUsuarioManager';

const useStyles = makeStyles(() => ({
  row: {
    height: '40px',
  },
  tableBody: {
    padding: '7px 0px 7px 0px',
  },
  containerExcluirUsuario: {
    width: '160px',
    float: 'right',
  },
  textoGrupo: {
    color: MEDIUM_GRAY,
    padding: '2px 0px',
  },
  textoLogado: {
    color: DARK_CYAN,
    padding: '2px 0px',
  },
  textoHabilitado: {
    color: DARK_CYAN,
    '&:hover': {
      color: LIGHT_SEA_GREEN_HOVER,
    },
  },
  radio: {
    color: DARK_CYAN,
  },
  containerClicavel: {
    cursor: 'pointer',
    paddingRight: '25px',
    position: 'relative',
  },
  iconeLixeira: {
    marginRight: '5px',
    color: GRAY,
  },
  iconeLixeiraHabilitado: {
    marginRight: '5px',
    color: DARK_CYAN,
    '&:hover': {
      color: `${LIGHT_SEA_GREEN_HOVER} !important`,
    },
  },
  loading: {
    margin: '0px 15px 0px 0px',
  },
}));

const LinhaTabelaGerenciarMembros = (props) => {
  const {
    usuarioLogado,
    listaPermissoes,
    nomeGrupoVisualizacao,
    usuarioGerenciado,
    listaUsuarios,
    setListaUsuarios,
  } = props;

  const [carregando, setCarregando] = useState(false);

  const classes = useStyles(carregando);
  const ehUsuarioLogado = usuarioLogado.Usuario.Sub === usuarioGerenciado.Sub;

  const verificarPermissaoUsuario = () => {
    return (
      usuarioLogado.Permissao.HierarchyLevel >
      listaPermissoes.find(
        (element) =>
          element.PermissionGroupId === usuarioGerenciado.PermissionGroupId
      ).HierarchyLevel
    );
  };

  const handleExclusao = async () => {
    setCarregando(true);
    const resp =
      await ConfiguracoesUsuarioManager.removerGrupoVisualizacaoUsuario(
        usuarioGerenciado.OperatorId,
        usuarioGerenciado.VisualizationGroup.filter(
          (grupo) => grupo !== nomeGrupoVisualizacao
        )
      );
    if (resp) {
      const newListaUsuarios = [...listaUsuarios];
      newListaUsuarios.splice(newListaUsuarios.indexOf(usuarioGerenciado), 1);
      const newUsuario = usuarioGerenciado;
      newUsuario.VisualizationGroup =
        usuarioGerenciado.VisualizationGroup.filter(
          (grupo) => grupo !== nomeGrupoVisualizacao
        );
      newListaUsuarios.push(newUsuario);
      setListaUsuarios(deepCopy(newListaUsuarios));
    }
    setCarregando(false);
  };

  return (
    <>
      <TableRow
        hover
        className={classes.row}
        data-testid="linha-tabela-gerenciar-membros"
      >
        <TableCell classes={{ body: classes.tableBody }}>
          <Typography
            variant="body1"
            align="left"
            className={
              ehUsuarioLogado ? classes.textoLogado : classes.textoGrupo
            }
            data-testid={ehUsuarioLogado ? 'textoLogado' : 'textoGrupo'}
          >
            {usuarioGerenciado.Login}
          </Typography>
        </TableCell>
        <TableCell
          classes={{
            body: classes.tableBody,
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            display="inline"
            className={classes.containerExcluirUsuario}
          >
            {verificarPermissaoUsuario() ? (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                className={classes.containerClicavel}
              >
                <Trash2 size={20} className={classes.iconeLixeira} />
                <Typography variant="h4">Excluir do Grupo</Typography>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                onClick={() => !carregando && handleExclusao()}
                className={`${classes.containerClicavel} ${classes.containerExcluirUsuario}`}
              >
                {carregando && (
                  <Loading iconSize={25} className={classes.loading} />
                )}
                <Trash2
                  size={20}
                  color={DARK_CYAN}
                  className={classes.iconeLixeiraHabilitado}
                />
                <Typography
                  className={classes.textoHabilitado}
                  variant="h4"
                  data-testid="textoHabilitado"
                >
                  Excluir do Grupo
                </Typography>
              </Grid>
            )}
          </Grid>
        </TableCell>
      </TableRow>
    </>
  );
};

export default LinhaTabelaGerenciarMembros;
