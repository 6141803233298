import PermissaoUsuarioService from '../../services/PermissaoUsuarioService';

class PerfilManager {
  async getUsuario(perfil) {
    const perfilFormatado = perfil.replaceAll('@', '-').replaceAll('.', '-');
    return PermissaoUsuarioService.getUsuario(perfilFormatado);
  }

  async editarRamal(email, ramal) {
    const emailFormatado = email.replaceAll('@', '-').replaceAll('.', '-');
    return PermissaoUsuarioService.changeUser(emailFormatado, { Ramal: ramal });
  }
}

export default new PerfilManager();
