import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { File } from 'react-feather';
import CardTitle from '../../../shared/componentes/CardTitle';
import TabelaRepositorioPerformance from './TabelaRepositorioPerformance';
import PerformanceManager from '../PerformanceManager';
import {
  exibirNotificacaoAlerta,
  exibirNotificacaoSucesso,
} from '../../notificacao/Store';
import Loading from '../../../shared/componentes/Loading';
import {
  iconesNotificacao,
  notificacoesRepositorioPerformance,
} from '../../../shared/util/Constantes';
import { DARK_CYAN, WHITE } from '../../../theme';

const useStyles = makeStyles(() => ({
  cardRepositorio: {
    backgroundColor: WHITE,
    minHeight: '500px',
    marginBottom: '16px',
    padding: '16px 21px',
    boxShadow: '0 0 18px -1px #e8e8e8',
    borderRadius: '8px',
    position: 'relative',
  },
  subTituloRepositorio: {
    color: DARK_CYAN,
    padding: '8px 0',
    marginTop: '8px',
  },
}));

const CardRepositorioPerformance = (props) => {
  const classes = useStyles();

  const { notificarSucesso, notificarAlerta } = props;

  const [listaArquivosPerformance, setListaArquivosPerformance] = useState([]);
  const [carregando, setCarregando] = useState(false);

  const handleAtualizarListaRepositorio = async () => {
    setCarregando(true);
    const data = await PerformanceManager.buscarListaArquivosPerformance();
    if (data && data.length > 0) {
      notificarSucesso(
        notificacoesRepositorioPerformance.DadosAtualizados,
        iconesNotificacao.AtendimentoFinalizado
      );
      setListaArquivosPerformance(data);
    } else {
      notificarAlerta(
        notificacoesRepositorioPerformance.DadosNaoEncontrados,
        null,
        true
      );
    }
    setCarregando(false);
  };

  useEffect(() => {
    const getData = async () => {
      setCarregando(true);
      const data = await PerformanceManager.buscarListaArquivosPerformance();
      if (data) {
        setListaArquivosPerformance(data);
      }
      setCarregando(false);
    };
    getData();
  }, []);

  return (
    <Grid
      container
      alignContent="flex-start"
      className={classes.cardRepositorio}
    >
      {carregando && <Loading />}
      <CardTitle text="Repositório de Arquivos CSV" icon={<File />} />
      <Typography variant="body1" className={classes.subTituloRepositorio}>
        Últimos Arquivos
      </Typography>
      <TabelaRepositorioPerformance
        listaArquivos={listaArquivosPerformance}
        handleAtualizarListaRepositorio={handleAtualizarListaRepositorio}
      />
    </Grid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  notificarSucesso: exibirNotificacaoSucesso,
  notificarAlerta: exibirNotificacaoAlerta,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardRepositorioPerformance);
