import React, { useEffect } from 'react';
import { Button, makeStyles, Grid, Typography, Icon } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import moment from 'moment';
import {
  WHITE,
  GRAY,
  FONT_FAMILY_REGULAR,
  LIGHT_GRAY,
  DARK_GRAY,
  DODGER_BLUE,
  TEA_GREEN,
} from '../../../theme';
import fundoWhatsApp from '../../../shared/img/fundo_whatsapp.png';
import VidroInclicavel from '../../../shared/componentes/VidroInclicavel';
import { placeholderMensagemWhatsapp } from '../../../shared/util/Constantes';
import Loading from '../../../shared/componentes/Loading';

const PreVisualizarWhatsApp = (props) => {
  const {
    paragrafo1,
    paragrafo2,
    paragrafo3,
    carregando,
    zenviaTemplate,
    mensagemOriginal,
  } = props;

  const obtemCorEdicaoMensagemWhatsapp = (paragrafo, mensagem) => {
    if (!paragrafo) {
      return GRAY;
    }
    return paragrafo !== mensagem ? DARK_GRAY : GRAY;
  };

  const useStyles = makeStyles(() => ({
    mensagem: {
      fontSize: '16px',
      lineHeight: '24px',
      color: DARK_GRAY,
      fontFamily: FONT_FAMILY_REGULAR,
      padding: '8px 10px 8px 10px',
      WebkitUserModify: 'read-write-plaintext-only',
      wordBreak: 'break-all',
    },
    mensagemRodapeCabecalho: {
      fontSize: '16px',
      lineHeight: '24px',
      color: DARK_GRAY,
      fontFamily: FONT_FAMILY_REGULAR,
      padding: '8px 10px 8px 10px',
      WebkitUserModify: 'read-write-plaintext-only',
    },
    container: {
      flex: '1',
      maxHeight: '100%',
      alignItems: 'end',
    },
    preVisualizar: {
      width: '100%',
      height: '98%',
      border: `1px solid ${LIGHT_GRAY}`,
      borderRadius: '6px',
      padding: '15px 12px 16px 12px',
      overflow: 'auto',
    },
    preVisualizarFundo: {
      width: '100%',
      height: '100%',
      border: `1px solid ${LIGHT_GRAY}`,
      borderRadius: '6px',
      padding: '2.3rem 1rem 0.5rem 1rem',
      backgroundImage: `url(${fundoWhatsApp})`,
      backgroundSize: 'cover',
      overflow: 'auto',
    },
    containerMensagem: {
      backgroundColor: TEA_GREEN,
      borderRadius: '6px',
      borderTopRightRadius: '0',
      marginBottom: '8px',
      position: 'relative',
      overflowWrap: 'break-word',
    },
    trianguloMensagem: {
      width: '0',
      height: '0',
      borderTop: `15px solid ${TEA_GREEN}`,
      borderRight: '13px solid transparent',
      position: 'relative',
      bottom: '3px',
      right: '-98%',
      transform: 'translate(-50%, -50%)',
    },
    textoHorario: {
      fontSize: '12px',
      color: GRAY,
      fontFamily: FONT_FAMILY_REGULAR,
      margin: '2px 4px',
    },
    botaoAtendimento: {
      fontFamily: FONT_FAMILY_REGULAR,
      backgroundColor: WHITE,
      color: DODGER_BLUE,
      padding: '8px 0',
      margin: '15px 21px 0 21px',
      borderRadius: '8px',
      boxShadow: 'none',
      fontSize: '14px',
    },
    tituloPrevia: {
      fontSize: '18px',
      color: GRAY,
      fontFamily: FONT_FAMILY_REGULAR,
      fontWeight: '800',
      textAlign: 'center',
      marginBottom: '24px',
      marginTop: '16px',
    },
  }));

  const classes = useStyles();

  function obterHorarioEnvioMsg() {
    return moment().format('HH:mm');
  }

  function formatarTextoDaMensagem(text) {
    return text
      .replace(/(?:\*)([^*<\n]+)(?:\*)/gm, '<b>$1</b>')
      .replace(/(?:_)([^_<\n]+)(?:_)/gm, '<i>$1</i>')
      .replace(/(?:~)([^~<\n]+)(?:~)/gm, '<s>$1</s>')
      .replace(/(?:```)([^```<\n]+)(?:```)/gm, '<tt>$1</tt>');
  }

  const obterCabecalhoZenviaTemplate = () => {
    return zenviaTemplate && zenviaTemplate.Message.split('\n')[0];
  };

  const obterRodapeZenviaTemplate = () => {
    const rodape = zenviaTemplate && zenviaTemplate.Message.split('\n');
    return rodape[rodape.length - 1];
  };

  useEffect(() => {
    document.getElementById('paragrafo1').innerHTML = formatarTextoDaMensagem(
      paragrafo1 || placeholderMensagemWhatsapp
    );
    document.getElementById('paragrafo2').innerHTML = formatarTextoDaMensagem(
      paragrafo2 || placeholderMensagemWhatsapp
    );
    document.getElementById('paragrafo3').innerHTML = formatarTextoDaMensagem(
      paragrafo3 || placeholderMensagemWhatsapp
    );

    if (zenviaTemplate) {
      document.getElementById('cabecalho').innerHTML = formatarTextoDaMensagem(
        obterCabecalhoZenviaTemplate()
      );
      document.getElementById('rodape').innerHTML = formatarTextoDaMensagem(
        obterRodapeZenviaTemplate()
      );
    }
  });

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-end"
      justifyContent="flex-end"
      className={classes.preVisualizar}
    >
      <Grid>
        <Grid>
          <Typography className={classes.tituloPrevia} variant="h3">
            Prévia da Mensagem
          </Typography>
        </Grid>
        <Grid item container className={classes.preVisualizarFundo}>
          <Grid item container className={classes.container}>
            <Grid
              item
              container
              direction="column"
              wrap="nowrap"
              className={classes.containerMensagem}
            >
              <>
                {carregando && <Loading />}
                <VidroInclicavel />
                <Typography
                  align="left"
                  id="cabecalho"
                  className={classes.mensagemRodapeCabecalho}
                />
                <Typography
                  align="left"
                  id="paragrafo1"
                  className={classes.mensagem}
                  style={{
                    color: obtemCorEdicaoMensagemWhatsapp(
                      paragrafo1,
                      mensagemOriginal?.Message1
                    ),
                  }}
                />
                <Typography
                  align="left"
                  id="paragrafo2"
                  className={classes.mensagem}
                  style={{
                    color: obtemCorEdicaoMensagemWhatsapp(
                      paragrafo2,
                      mensagemOriginal?.Message2
                    ),
                  }}
                />
                <Typography
                  align="left"
                  id="paragrafo3"
                  className={classes.mensagem}
                  style={{
                    color: obtemCorEdicaoMensagemWhatsapp(
                      paragrafo3,
                      mensagemOriginal?.Message3
                    ),
                  }}
                />
                <Typography
                  align="left"
                  id="rodape"
                  className={classes.mensagemRodapeCabecalho}
                />
                <Button
                  variant="contained"
                  type="submit"
                  className={`${classes.botaoAtendimento} "Login-Btn"`}
                >
                  {zenviaTemplate &&
                    zenviaTemplate.Buttons &&
                    zenviaTemplate.Buttons.map((item) => item)}
                </Button>
              </>
              <Grid
                item
                container
                wrap="nowrap"
                justifyContent="flex-end"
                className={classes.containerMensagem}
              >
                <Typography
                  align="left"
                  className={classes.textoHorario}
                  data-testid="tipografia-horario-envio-mensagem"
                >
                  {obterHorarioEnvioMsg()}
                </Typography>
                <Icon>
                  <DoneAllIcon
                    style={{
                      color: DODGER_BLUE,
                      height: '16px',
                      width: '16px',
                    }}
                    data-testid="icone-confirmacao"
                  />
                </Icon>
              </Grid>
            </Grid>
            <Grid item className={classes.trianguloMensagem} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PreVisualizarWhatsApp;
