import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tree, TreeNode } from 'react-organizational-chart';
import {
  Users,
  AlertCircle,
  PhoneOutgoing,
  UserCheck,
  UserX,
  PhoneMissed,
  CheckCircle,
} from 'react-feather';
import NodeContainer from './NodeContainer';
import { GRAY } from '../../../../theme';

const useStyles = makeStyles({
  fatherNode: {
    width: '96px !important',
  },
});

const SemContato = (props) => {
  const { titulo, valorPrimario, valorSecundario, tooltip } = props;
  return (
    <NodeContainer
      icon={<PhoneMissed />}
      color="yellow"
      titulo={titulo}
      valorPrimario={valorPrimario}
      valorSecundario={valorSecundario}
      tooltip={tooltip}
    />
  );
};

const Contatados = (props) => {
  const { titulo, valorPrimario, valorSecundario, tooltip } = props;
  return (
    <NodeContainer
      icon={<PhoneOutgoing />}
      color="blue"
      titulo={titulo}
      valorPrimario={valorPrimario}
      valorSecundario={valorSecundario}
      tooltip={tooltip}
    />
  );
};

const Ativo = (props) => {
  const { titulo, valorPrimario, valorSecundario, tooltip } = props;
  return (
    <NodeContainer
      icon={<UserCheck />}
      color="green"
      titulo={titulo}
      valorPrimario={valorPrimario}
      valorSecundario={valorSecundario}
      tooltip={tooltip}
    />
  );
};

const Desistente = (props) => {
  const { titulo, valorPrimario, valorSecundario, tooltip } = props;
  return (
    <NodeContainer
      icon={<UserX />}
      color="red"
      titulo={titulo}
      valorPrimario={valorPrimario}
      valorSecundario={valorSecundario}
      tooltip={tooltip}
    />
  );
};

const AltoRisco = (props) => {
  const { titulo, valorPrimario, valorSecundario, tooltip } = props;
  return (
    <NodeContainer
      icon={<AlertCircle />}
      color="yellow"
      titulo={titulo}
      valorPrimario={valorPrimario}
      valorSecundario={valorSecundario}
      tooltip={tooltip}
    />
  );
};

const BaixoRisco = (props) => {
  const { titulo, valorPrimario, valorSecundario, tooltip } = props;
  return (
    <NodeContainer
      icon={<CheckCircle />}
      color="blue"
      titulo={titulo}
      valorPrimario={valorPrimario}
      valorSecundario={valorSecundario}
      tooltip={tooltip}
    />
  );
};

const NoPai = (props, className) => {
  const { titulo, valorPrimario, valorSecundario, tooltip } = props;
  return (
    <NodeContainer
      icon={<Users />}
      color="green"
      className={className}
      titulo={titulo}
      valorPrimario={valorPrimario}
      valorSecundario={valorSecundario}
      tooltip={tooltip}
    />
  );
};

const ArvoreRetencao = (props) => {
  const { treeData } = props;
  const classes = useStyles();

  return (
    treeData.length === 15 && (
      <Tree
        lineColor={GRAY}
        lineBorderRadius="0px"
        lineWidth="1px"
        lineHeight="10px"
        nodePadding="8px"
      >
        <TreeNode label={NoPai(treeData[0], classes.fatherNode)}>
          <TreeNode label={AltoRisco(treeData[1])}>
            <TreeNode label={Contatados(treeData[3])}>
              <TreeNode label={Ativo(treeData[7])} />
              <TreeNode label={Desistente(treeData[8])} />
            </TreeNode>

            <TreeNode label={SemContato(treeData[4])}>
              <TreeNode label={Ativo(treeData[9])} />
              <TreeNode label={Desistente(treeData[10])} />
            </TreeNode>
          </TreeNode>

          <TreeNode label={BaixoRisco(treeData[2])}>
            <TreeNode label={Contatados(treeData[5])}>
              <TreeNode label={Ativo(treeData[11])} />
              <TreeNode label={Desistente(treeData[12])} />
            </TreeNode>

            <TreeNode label={SemContato(treeData[6])}>
              <TreeNode label={Ativo(treeData[13])} />
              <TreeNode label={Desistente(treeData[14])} />
            </TreeNode>
          </TreeNode>
        </TreeNode>
      </Tree>
    )
  );
};

export default ArvoreRetencao;
