import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Check, X } from 'react-feather';
import { connect } from 'react-redux';
import { mensagensNotificacaoAlerta } from '../../../shared/util/Constantes';
import {
  formatarDataParaString,
  formatarStringParaData,
  deepEqual,
} from '../../../shared/util/Utils';
import {
  LIGHT_SEA_GREEN,
  LIGHT_SEA_GREEN_HOVER,
  TOMATO,
  TOMATO_HOVER,
  WHITE,
} from '../../../theme';
import { exibirNotificacaoAlerta } from '../../notificacao/Store';
import ModalConfirmacaoEdicaoCampanha from './ModalConfirmacaoEdicaoCampanha';

const useStyles = makeStyles(() => ({
  botaoCancelar: {
    backgroundColor: TOMATO,
    color: WHITE,
    height: '52px',
    float: 'right',
    marginRight: '32px',
    '&:hover': {
      backgroundColor: TOMATO_HOVER,
    },
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  botaoAplicar: {
    backgroundColor: LIGHT_SEA_GREEN,
    color: WHITE,
    height: '52px',
    whiteSpace: 'nowrap',
    marginRight: '24px',
    '&:hover': {
      backgroundColor: LIGHT_SEA_GREEN_HOVER,
    },
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  textoBotao: {
    fontSize: '14px',
    color: WHITE,
  },
  textoBotaoCancelar: {
    fontSize: '14px',
    color: WHITE,
    marginTop: '2px',
  },
}));

const BotoesEdicaoCampanha = (props) => {
  const classes = useStyles();

  const {
    idCampanha,
    setModalAberto,
    descricaoCampanhaOriginal,
    orientacoesAtendimento,
    setOrientacoesAtendimento,
    dataInicioCampanha,
    setDataInicioCampanha,
    dataInicioOriginal,
    dataTerminoOriginal,
    setDataTerminoCampanha,
    dataTerminoCampanha,
    notificarAlerta,
    handleEdicaoCampanha,
    datePickerAberto = false,
    impactoDaCampanhaOriginal,
    impactoDaCampanhaEditado,
    grupoDeVisualizacaoOriginal,
    grupoDeVisualizacaoEditado,
    switchMarca,
    marcaWhatsapp,
    primeiraMensagem,
    primeiroContato,
    segundaMensagem,
    segundoContato,
    terceiraMensagem,
    marcaWhatsappOriginal,
    primeiroContatoOriginal,
    segundoContatoOriginal,
    primeiraMensagemOriginal,
    segundaMensagemOriginal,
    terceiraMensagemOriginal,
    setMarcaWhatsapp,
    setPrimeiroContato,
    setSegundoContato,
    setPrimeiraMensagem,
    setSegundaMensagem,
    setTerceiraMensagem,
  } = props;

  const [modalConfirmarEditarCampanha, setModalConfirmarEditarCampanha] =
    useState(false);

  function habilitarBotaoEditarCampanha() {
    const mudancaDataTermino =
      formatarDataParaString(dataTerminoCampanha, true) !== dataTerminoOriginal;

    const mudancaOrientacoesAtendimento =
      orientacoesAtendimento !== descricaoCampanhaOriginal;

    const mudancaImpactoDaCampanha =
      impactoDaCampanhaOriginal !== impactoDaCampanhaEditado;

    const mudancaGrupoVisualizacao = !deepEqual(
      grupoDeVisualizacaoOriginal.sort(),
      grupoDeVisualizacaoEditado.sort()
    );

    const mudancaDataInicio =
      formatarDataParaString(dataInicioCampanha, true) !== dataInicioOriginal;

    const mudancaMarcaWhatsapp =
      marcaWhatsapp !== marcaWhatsappOriginal &&
      (primeiraMensagem || segundaMensagem || terceiraMensagem);
    const mudancaPrimeiroContato =
      primeiroContato !== primeiroContatoOriginal && primeiraMensagem;
    const mudancaSegundoContato =
      segundoContato !== segundoContatoOriginal && segundaMensagem;
    const mudancaPrimeiraMensagem = !deepEqual(
      primeiraMensagem,
      primeiraMensagemOriginal
    );
    const mudancaSegundaMensagem = !deepEqual(
      segundaMensagem,
      segundaMensagemOriginal
    );
    const mudancaTerceiraMensagem = !deepEqual(
      terceiraMensagem,
      terceiraMensagemOriginal
    );

    const habilitarPorWhatsapp =
      ((primeiraMensagem || segundaMensagem || terceiraMensagem) &&
        (switchMarca || marcaWhatsapp)) ||
      (!(primeiraMensagem || segundaMensagem || terceiraMensagem) &&
        (switchMarca || marcaWhatsapp)) ||
      (!(primeiraMensagem || segundaMensagem || terceiraMensagem) &&
        !(switchMarca || marcaWhatsapp));
    if (
      dataInicioCampanha &&
      dataTerminoCampanha &&
      orientacoesAtendimento &&
      impactoDaCampanhaEditado &&
      grupoDeVisualizacaoEditado.length > 0 &&
      habilitarPorWhatsapp
    ) {
      return (
        mudancaDataTermino ||
        mudancaOrientacoesAtendimento ||
        mudancaImpactoDaCampanha ||
        mudancaGrupoVisualizacao ||
        mudancaDataInicio ||
        mudancaMarcaWhatsapp ||
        mudancaPrimeiroContato ||
        mudancaSegundoContato ||
        mudancaPrimeiraMensagem ||
        mudancaSegundaMensagem ||
        mudancaTerceiraMensagem
      );
    }
    return false;
  }

  const validarEdicaoCampanha = () => {
    if (
      formatarDataParaString(dataTerminoCampanha, true) !== dataTerminoOriginal
    ) {
      if (
        dataInicioCampanha >= dataTerminoCampanha ||
        new Date() >= dataTerminoCampanha
      ) {
        notificarAlerta(
          mensagensNotificacaoAlerta.CampanhaDataTerminoInvalida,
          null,
          true
        );
        return false;
      }
    }
    if (
      formatarDataParaString(dataInicioCampanha, true) !== dataInicioOriginal
    ) {
      if (
        dataInicioCampanha >= dataTerminoCampanha ||
        new Date() >= dataInicioCampanha
      ) {
        notificarAlerta(
          mensagensNotificacaoAlerta.CampanhaDataInicioInvalida,
          null,
          true
        );
        return false;
      }
    }
    return true;
  };

  const handleBotaoEdicao = () => {
    if (!validarEdicaoCampanha()) return;
    return setModalConfirmarEditarCampanha(true);
  };

  const handleBotaoCancelar = () => {
    setOrientacoesAtendimento(descricaoCampanhaOriginal);
    setDataInicioCampanha(formatarStringParaData(dataInicioOriginal));
    setDataTerminoCampanha(formatarStringParaData(dataTerminoOriginal));
    setMarcaWhatsapp(marcaWhatsappOriginal);
    setPrimeiroContato(primeiroContatoOriginal);
    setSegundoContato(segundoContatoOriginal);
    setPrimeiraMensagem(primeiraMensagemOriginal);
    setSegundaMensagem(segundaMensagemOriginal);
    setTerceiraMensagem(terceiraMensagemOriginal);
    return setModalAberto(false);
  };

  return (
    <>
      <Grid
        item
        container
        wrap="nowrap"
        alignItems="flex-end"
        justifyContent="space-between"
        className={classes.container}
      >
        <Button
          variant="contained"
          type="submit"
          disableElevation
          fullWidth
          startIcon={<X size={18} />}
          disabled={datePickerAberto}
          className={`${classes.botaoCancelar} "Login-Btn"`}
          onClick={() => handleBotaoCancelar()}
        >
          <Typography className={classes.textoBotaoCancelar} variant="h6">
            Cancelar Edição
          </Typography>
        </Button>
        <Button
          variant="contained"
          type="submit"
          disableElevation
          data-testid="botao-concluir-edicao"
          fullWidth
          startIcon={<Check size={18} />}
          className={`${classes.botaoAplicar} "Login-Btn"`}
          disabled={!habilitarBotaoEditarCampanha() || datePickerAberto}
          onClick={() => handleBotaoEdicao()}
        >
          <Typography className={classes.textoBotao} variant="h6">
            Concluir Edição
          </Typography>
        </Button>
      </Grid>
      <ModalConfirmacaoEdicaoCampanha
        idCampanha={idCampanha}
        dataInicioCampanha={
          formatarDataParaString(dataInicioCampanha) !== dataInicioOriginal
            ? dataInicioCampanha
            : null
        }
        dataTerminoCampanha={
          formatarDataParaString(dataTerminoCampanha) !== dataTerminoOriginal
            ? dataTerminoCampanha
            : null
        }
        impactoDaCampanha={
          impactoDaCampanhaOriginal !== impactoDaCampanhaEditado
            ? impactoDaCampanhaEditado
            : null
        }
        grupoDeVisualizacao={
          !deepEqual(
            grupoDeVisualizacaoOriginal.sort(),
            grupoDeVisualizacaoEditado.sort()
          )
            ? grupoDeVisualizacaoEditado
            : null
        }
        orientacoesAtendimento={
          orientacoesAtendimento !== descricaoCampanhaOriginal
            ? orientacoesAtendimento
            : null
        }
        modalConfirmacaoEdicaoAberto={modalConfirmarEditarCampanha}
        setModalConfirmacaoEdicaoAberto={setModalConfirmarEditarCampanha}
        setModalAberto={setModalAberto}
        handleEdicaoCampanha={handleEdicaoCampanha}
        marcaWhatsapp={marcaWhatsapp}
        primeiraMensagem={primeiraMensagem}
        primeiroContato={primeiroContato}
        segundaMensagem={segundaMensagem}
        segundoContato={segundoContato}
        terceiraMensagem={terceiraMensagem}
      />
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  notificarAlerta: exibirNotificacaoAlerta,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BotoesEdicaoCampanha);
