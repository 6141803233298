import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LIGHT_CYAN, WHITE } from '../../../../theme';
import InformacaoItemProdutividade from './InformacaoItemProdutividade';
import { labelsGraficoProdutividade } from '../../../../shared/util/Constantes';
import MediaAtendimentoPerfil from './MediaAtendimentoPerfil';
import TempoTotalAtendimentoPerfil from './TempoTotalAtendimentoPerfil';

const useStyles = makeStyles({
  box: {
    backgroundColor: LIGHT_CYAN,
    width: '72px',
    height: '72px',
    padding: '16px',
  },
  OutsideBox: {
    backgroundColor: WHITE,
    width: '80px',
    height: '80px',
    boxShadow: '0 2px 12px 0 rgba(0,0,0,0.07), 0 9px 9px 0 rgba(0,0,0,0.05)',
    padding: '4px',
  },
  nomeAtendente: {
    marginTop: '8px',
    width: '80%',
  },
  containerTempoAtendimento: {
    width: '100%',
  },
  boxAvatar: {
    margin: '15px 0px',
  },
  containerAtendimentoPerfil: {
    margin: '0px 0px 15px 0',
  },
  containerMetricas: {
    width: '100%',
  },
});

const ItemGraficoPerfilProdutividade = (props) => {
  const classes = useStyles();

  const {
    operatorName,
    notFinishedAttemptsCount,
    notFinishedAttemptsPercent,
    remedyCount,
    remedyPercent,
    finishedCount,
    finishedPercent,
    generalServiceAverage,
    operatorServiceAverage,
    operatorTotalTime,
    operatorTotalTimePercent,
  } = props;

  return (
    <Grid
      container
      justifyContent="space-around"
      alignItems="flex-start"
      id="item-perfomance-operador"
      className={classes.containerMetricas}
    >
      <Grid
        container
        direction="column"
        className={classes.containerAtendimentoPerfil}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          className={classes.containerTempoAtendimento}
        >
          <MediaAtendimentoPerfil
            operatorName={operatorName}
            tempoMedioAtendente={operatorServiceAverage}
            tempoMedioTodosAtendimentos={generalServiceAverage}
          />
          <TempoTotalAtendimentoPerfil
            operatorName={operatorName}
            tempoTotalAtendimentoAtendente={operatorTotalTime}
            tempoMedioPorcentagem={operatorTotalTimePercent}
          />
        </Grid>
        <InformacaoItemProdutividade
          tipo={labelsGraficoProdutividade.Remedios}
          porcentagem={remedyPercent}
          valorReal={remedyCount}
          perfilProdutividade
        />
        <InformacaoItemProdutividade
          tipo={labelsGraficoProdutividade.Atendimentos}
          porcentagem={finishedPercent}
          valorReal={finishedCount}
          perfilProdutividade
        />
        <InformacaoItemProdutividade
          tipo={labelsGraficoProdutividade.Tentativas}
          porcentagem={notFinishedAttemptsPercent}
          valorReal={notFinishedAttemptsCount}
          perfilProdutividade
        />
      </Grid>
    </Grid>
  );
};

export default ItemGraficoPerfilProdutividade;
