import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { User } from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';
import { LIGHT_CYAN, WHITE, LIGHT_GRAY } from '../../../../theme';
import InformacaoItemProdutividade from './InformacaoItemProdutividade';
import { labelsGraficoProdutividade } from '../../../../shared/util/Constantes';
import MediaAtendimento from './MediaAtendimento';
import TempoTotalAtendimento from './TempoTotalAtendimento';
import NomeAtendenteGraficoDesempenho from './NomeAtendenteGraficoDesempenho';

const useStyles = makeStyles({
  container: {
    borderBottom: `1px solid ${LIGHT_GRAY}`,
    padding: '30px 15px',
  },
  box: {
    backgroundColor: LIGHT_CYAN,
    width: '72px',
    height: '72px',
    padding: '16px',
  },
  OutsideBox: {
    backgroundColor: WHITE,
    width: '80px',
    height: '80px',
    boxShadow: '0 2px 12px 0 rgba(0,0,0,0.07), 0 9px 9px 0 rgba(0,0,0,0.05)',
    padding: '4px',
  },
  nomeAtendente: {
    marginTop: '8px',
    width: '80%',
  },
  containerTempoAtendimento: {
    width: '100%',
    marginTop: '10px',
  },
  itemAtendenteProdutividade: {
    display: 'grid',
    gridTemplateColumns: '38% 62%',
    borderTop: '1px solid',
    borderColor: LIGHT_GRAY,
    '&:first-child': {
      borderTop: '0px',
    },
  },
  boxAvatar: {
    margin: '15px 0px',
  },
  containerAtendimento: {
    margin: '15px 0px',
  },
});

const ItemGraficoProdutividade = (props) => {
  const classes = useStyles();

  const {
    operatorName,
    notFinishedAttemptsCount,
    notFinishedAttemptsPercent,
    remedyCount,
    remedyPercent,
    finishedCount,
    finishedPercent,
    inelegibleCount,
    inelegiblePercent,
    generalServiceAverage,
    operatorServiceAverage,
    operatorTotalTime,
    operatorTotalTimePercent,
  } = props;

  return (
    <Grid container className={classes.container}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.boxAvatar}
      >
        <Box borderRadius={50} className={classes.OutsideBox}>
          <Box borderRadius={50} className={classes.box}>
            <User style={{ height: 40, width: 40 }} />
          </Box>
        </Box>

        <NomeAtendenteGraficoDesempenho
          title={operatorName}
          characterLimit={46}
        />
      </Grid>
      <Grid item container justifyContent="center" xs={6}>
        <MediaAtendimento
          operatorName={operatorName}
          tempoMedioAtendente={operatorServiceAverage}
          tempoMedioTodosAtendimentos={generalServiceAverage}
        />
      </Grid>
      <Grid item container justifyContent="center" xs={6}>
        <TempoTotalAtendimento
          operatorName={operatorName}
          tempoTotalAtendimentoAtendente={operatorTotalTime}
          tempoMedioPorcentagem={operatorTotalTimePercent}
        />
      </Grid>
      <Grid item container className="mt-3">
        <InformacaoItemProdutividade
          tipo={labelsGraficoProdutividade.Tentativas}
          porcentagem={notFinishedAttemptsPercent}
          valorReal={notFinishedAttemptsCount}
        />
        <InformacaoItemProdutividade
          tipo={labelsGraficoProdutividade.Atendimentos}
          porcentagem={finishedPercent}
          valorReal={finishedCount}
        />
        <InformacaoItemProdutividade
          tipo={labelsGraficoProdutividade.Remedios}
          porcentagem={remedyPercent}
          valorReal={remedyCount}
        />
        <InformacaoItemProdutividade
          tipo={labelsGraficoProdutividade.Inelegiveis}
          porcentagem={inelegiblePercent}
          valorReal={inelegibleCount}
        />
      </Grid>
    </Grid>
  );
};

export default ItemGraficoProdutividade;
