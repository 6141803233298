import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Filter, ChevronDown } from 'react-feather';
import ReactLoading from 'react-loading';
import Dropdown from '../../../shared/componentes/Dropdown';
import {
  DARK_CYAN,
  FONT_FAMILY_EXTRABOLD,
  FONT_FAMILY_SEMIBOLD,
  GRAY,
} from '../../../theme';

const useStyles = makeStyles({
  tooltipIcon: {
    width: '20px',
    height: '20px',
  },
  selectedTypography: {
    color: DARK_CYAN,
    fontFamily: FONT_FAMILY_EXTRABOLD,
  },
  openDropdown: {
    fontFamily: FONT_FAMILY_SEMIBOLD,
    color: DARK_CYAN,
  },
  searchField: {
    width: (props) => props.width,
    minWidth: '50px',

    '& .MuiFilledInput-inputMarginDense': {
      paddingTop: '12px',
      paddingBottom: '11px',
      cursor: 'pointer',
    },
    '& .MuiInputBase-root': {
      cursor: 'pointer',
    },
  },
});

const FiltroGrupos = (props) => {
  const {
    carregando,
    listaItens,
    setlistaItens,
    itensSelecionados,
    setItensSelecionados,
    width,
    iconeChevronDown,
    placeholder,
    showOnTop,
    top = '0px',
  } = props;

  const classes = useStyles(props);
  const [abrirDropdown, setDropdown] = useState(false);
  const [tituloGrupoSelecionado, setTituloGrupoSelecionado] = useState('');

  useEffect(() => {
    if (itensSelecionados && itensSelecionados.length > 0) {
      setTituloGrupoSelecionado(
        `${itensSelecionados.length} Grupos de Visualização`
      );
      if (listaItens.length === itensSelecionados.length) {
        if (parseInt(width, 10) >= parseInt('280px', 10)) {
          setTituloGrupoSelecionado('Todos os Grupos de Visualização');
        } else {
          setTituloGrupoSelecionado('Todos os Grupos');
        }
      }
      if (itensSelecionados.length === 1) {
        setTituloGrupoSelecionado('1 Grupo de Visualização');
      }
    } else {
      setTituloGrupoSelecionado(placeholder || 'Filtro de Grupos');
    }
  }, [itensSelecionados, listaItens, width, placeholder]);

  const escolherIcone = () => {
    if (iconeChevronDown) {
      return (
        <ChevronDown
          size={20}
          color={itensSelecionados.length > 0 ? DARK_CYAN : GRAY}
        />
      );
    }
    return (
      <Filter
        size={20}
        color={itensSelecionados.length > 0 ? DARK_CYAN : GRAY}
      />
    );
  };

  return (
    <>
      {carregando && (
        <ReactLoading type="spin" color={DARK_CYAN} height={25} width={25} />
      )}

      {!carregando && (
        <Grid style={{ zIndex: 10 }}>
          <TextField
            variant="filled"
            size="small"
            InputProps={{
              style: {
                fontWeight: itensSelecionados.length > 0 ? 'bold' : 'normal',
                color: itensSelecionados.length > 0 ? DARK_CYAN : GRAY,
                backgroundColor: 'rgba(158, 171, 181, 0.05)',
                fontSize: '16px',
                cursor: 'pointer !important',
              },
              endAdornment: escolherIcone(),
              readOnly: true,
            }}
            className={classes.searchField}
            value={tituloGrupoSelecionado}
            onClick={() => setDropdown(true)}
            data-testid="textfield-filtro-grupos"
          />
          <Dropdown
            mostrarDropdown={abrirDropdown}
            setMostrarDropdown={setDropdown}
            listaItensInseridos={itensSelecionados}
            setListaItensInseridos={setItensSelecionados}
            listaItens={listaItens}
            setListaItens={setlistaItens}
            descricaoItem="Grupos"
            top={top}
            showOnTop={showOnTop}
            left="0px"
            width={width}
          />
        </Grid>
      )}
    </>
  );
};

export default FiltroGrupos;
