import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logoAnima from '../img/logo.png';

const useStyles = makeStyles({
  container: {
    textAlign: 'center',
    width: '60px',
  },
  logo: {
    height: '30px',
  },
});

const Logo = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img src={logoAnima} className={classes.logo} alt="Logo" />
    </div>
  );
};

export default Logo;
