import React from 'react';
import { UserX, BookOpen, Users } from 'react-feather';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';

export const tituloPagina = 'Anima - Scape0';

export const mappingPrioridades = {
  5: 'Prioridade Muito Alta',
  4: 'Prioridade Alta',
  3: 'Prioridade Média',
  2: 'Prioridade Baixa',
  1: 'Prioridade Muito Baixa',
};

export const filtroDesempenhoApi = {
  Atendente: 'operator_id',
  Campanha: 'campaign_id',
  Situacao: 'status',
  Problema: 'problem',
  Remedio: 'remedy',
};

export const filtroItens = {
  PluralCurso: 'Cursos',
  PluralMarca: 'Marcas',
  PluralSituacao: 'Situações',
  PerfilAluno: 'Perfil Aluno',
  PluralPerfilAluno: 'Perfis',
  Aluno: 'Perfil do Aluno',
  Marca: 'Marca',
  Campus: 'Campus',
  Curso: 'Curso',
  Modalidade: 'Modalidade',
  Turno: 'Turno',
  PeriodoLetivo: 'Período Letivo',
  Ingresso: 'Ingresso', // REMOVER
  PluralCampus: 'Campi',
  PluralSituação: 'Situações',
  PluralChatbot: 'Ações do Chatbot',
  Situacao: 'Situação',
  Chatbot: 'Ação Chatbot',
  Problema: 'Problema',
  DebitoFinanceiro: 'DebitoFinanceiro',
  DataInicioMatricula: 'DataInicioMatricula',
  DataFinalMatricula: 'DataFinalMatricula',
  HasBlocklist: 'HasBlocklist',
  HasBrokenDeal: 'HasBrokenDeal',
  CurrentAcademicTermName: 'CurrentAcademicTermName',
  Modality: 'Modality',
  ReenrollmentDebtType: 'ReenrollmentDebtType',
  Categoria: 'Categoria',
  FormaDeIngresso: 'Forma de Ingresso',
  Preencher: 'Preencher',
};

export const opcoesFiltroDesempenho = {
  Inicio: 'Início',
  Fim: 'Fim',
  Atendente: 'Atendente',
  Campanha: 'Campanha',
  Situacao: 'Situação',
  CategoriaProblema: 'Categoria do Problema',
  Remedio: 'Remédio',
  PluralSituacao: 'Situações',
  PluralPeriodo: 'Período',
  PluralAtendente: 'Atendentes',
  PluralCampanha: 'Campanhas',
  PluralCategoriaProblema: 'Categorias',
  PluralRemedio: 'Remédios',
};

export const opcoesSelectInelegivel = {
  Titulo: 'Selecione o Motivo',
  Opcoes: [
    { Title: 'Não possui Débitos' },
    { Title: 'Trancado' },
    { Title: 'Cancelado' },
    { Title: 'Outros' },
  ],
};

export const estadoInicialFiltroDesempenho = {
  [opcoesFiltroDesempenho.Periodo]: 'Todo o período',
  [opcoesFiltroDesempenho.Atendente]: 'Todos os atendentes',
  [opcoesFiltroDesempenho.Campanha]: 'Todas as campanhas',
  [opcoesFiltroDesempenho.CategoriaProblema]: 'Todas as categorias',
  [opcoesFiltroDesempenho.Remedio]: 'Todos os remédios',
  [opcoesFiltroDesempenho.Situacao]: 'Todas as situações',
};
export const pluraisFiltroDesempenho = {
  [opcoesFiltroDesempenho.Periodo]: 'Período',
  [opcoesFiltroDesempenho.Atendente]: 'Atendentes',
  [opcoesFiltroDesempenho.Campanha]: 'Campanhas',
  [opcoesFiltroDesempenho.CategoriaProblema]: 'Categorias',
  [opcoesFiltroDesempenho.Remedio]: 'Remédios',
  [opcoesFiltroDesempenho.Situacao]: 'Situações',
};

export const singularesFiltroDesempenho = {
  [opcoesFiltroDesempenho.Periodo]: 'Período',
  [opcoesFiltroDesempenho.Atendente]: 'Atendente',
  [opcoesFiltroDesempenho.Campanha]: 'Campanha',
  [opcoesFiltroDesempenho.CategoriaProblema]: 'Categoria do problema',
  [opcoesFiltroDesempenho.Remedio]: 'Remédio',
  [opcoesFiltroDesempenho.Situacao]: 'Situação',
};

export const situacaoAluno = {
  Agendado: 'Agendado',
  Aguardando: 'Aguardando',
  Atendido: 'Atendido',
  EmAtendimento: 'Em Atendimento',
  Evadiu: 'Evadiu',
  Inelegivel: 'Inelegível',
  Retomada: 'Retomada',
  Bloqueado: 'Bloqueado',
};

export const situacaoCampanha = {
  Bloqueado: 'Bloqueado',
};

export const mensagensObservacao = {
  Titulo: 'Observações do Atendimento',
  Descricao:
    'Realize observações para complementar as informações e registrar os principais pontos levantados durante o atendimento.',
};

export const opcaoAvaliacao = {
  MuitoSatisfeito: 'Muito Satisfeito',
  Satisfeito: 'Satisfeito',
  Neutro: 'Neutro',
  Insatisfeito: 'Insatisfeito',
  MuitoInsatisfeito: 'Muito Insatisfeito',
  Na: 'N/A',
  MuitoSatisfeitoFormatado: 'MuitoSatisfeito',
  MuitoInsatisfeitoFormatado: 'MuitoInsatisfeito',
  NAFormatado: 'NA',
  DuracaoAtendimento: 'Duração do Atendimento',
};

export const opcaoItensIconeTitulo = {
  Atendeu: 'Atendeu',
  NaoAtendeu: 'Não Atendeu',
  NumeroErrado: 'Número Errado',
  EmAtend: 'Em Atend.',
  RetornarDepois: 'Retornar Depois',
  Financeiro: 'Financeiro',
  Academico: 'Acadêmico',
  Institucional: 'Institucional',
  Pessoal: 'Pessoal',
  NaoQuerFalar: 'Não Quer Falar',
  EmAtendFormatado: 'Em Atendimento',
  NumeroBloqueado: 'Número Bloqueado',
  AvaliarAtendimento: 'Avaliar Atendimento',
  NovoProblema: 'Novo Problema',
};

export const conversationStatusEnum = {
  Finalizado: 'Finalizado',
  RetornarDepois: 'RetornarDepois',
  NaoAtendeu: 'NaoAtendeu',
  NaoQuerFalar: 'NaoQuerFalar',
  TelefoneErrado: 'TelefoneErrado',
  NumeroBloqueado: 'NumeroBloqueado',
};

export const headerAtendimento = {
  InicioLigacao: 'Início da Ligação',
  ConversaComAluno: 'Conversa Com Aluno',
  Problema: 'Problema',
  Remedio: 'Remédio',
  Avaliacao: 'Avaliação de Atendimento',
  DetalhamentoProblema: 'Detalhamento do Problema',
  CategoriaProblema: 'Categoria do Problema',
};

export const iconesNotificacao = {
  LigacaoIniciada: 'LigacaoIniciada',
  AtendimentoFinalizado: 'AtendimentoFinalizado',
  ErroPadrao: 'ErroPadrao',
  TentarNovamente: 'TentarNovamente',
  ErroLigacao: 'ErroLigacao',
  CopiaFeita: 'CopiaFeita',
  Exclusao: 'Exclusao',
  MarcaVerificacao: 'MarcaVerificacao',
};

export const mensagensNotificacaoSucesso = {
  FinalizadoComSucesso: 'Atendimento finalizado com sucesso!',
  RegistradoComSucesso: 'Atendimento registrado com sucesso!',
  LigacaoFeitaComSucesso: 'Ligação feita com sucesso!',
  DadosCopiadosComSucesso: 'Dados copiados para área de transferência',
  AgendadoComSucesso: 'Atendimento agendado com sucesso!',
  InelegibilidadeFeitaComSucesso:
    'Inelegibilidade do aluno registrada com sucesso!',
  ExtracaoRealizadaComSucesso:
    'Extração realizada com sucesso. Por favor, atualize o repositório de arquivos.',
};

export const mensagensNotificacaoErro = {
  UsuarioSemPermissaoDeAcesso: 'Usuário não possui acesso ao recurso',
  FalhaAoListarCampanhas: 'Falha ao listar campanhas',
  FalhaAoListarAlunos: 'Falha ao listar alunos',
  FalhaAoRecuperarFiltro: 'Falha ao recuperar filtro',
  FalhaAoObterInformacaoSensivelDoAluno: 'Falha ao obter dado do aluno. ',
  FalhaAoBloquearAluno: 'Falha ao bloquear aluno.',
  AlunoBloqueado: 'Este aluno já está sendo atendido por outro atendente!',
  FalhaAoLiberarAluno: 'Falha ao liberar aluno',
  FalhaAoLigarParaAluno: 'Falha ao ligar para aluno',
  FalhaAoListarHistorico: 'Falha ao listar histórico do aluno',
  FalhaAoListarFiltros: 'Falha ao listar filtros disponíveis',
  FalhaAoRegistrarAtendimento: 'Falha ao registrar atendimento',
  AlunoDesbloqueado: 'Aluno está desbloqueado. Selecione o aluno novamente.',
  AlunoBloqueadoPorOutroAtendente:
    'Aluno está sendo atendido por outra pessoa.',
  FalhaConsultaDados: 'Falha ao consultar dados',
  ErroPadrao: 'Ocorreu um erro ao tentar executar esta ação.',
  FalhaAoBuscarDadosAtendente: 'Falha ao buscar os dados dos atendentes',
  FalhaAoBuscarDadosGrafico: 'Falha ao buscar os dados do gráfico.',
  FalhaAoBuscarAtendimentoEmAndamento:
    'Falha ao buscar as informações do atendimento em andamento.',
  StartSession: 'Retome e encerre o atendimento atual antes de iniciar um novo',
  StartCall: 'Erro ao registrar a ligação para o aluno',
  RegisterCall: 'Erro ao encerrar a sessão',
  LeaveSession: 'Erro ao sair da sessão',
  ErroListaRemedio: 'Falha ao obter a lista de remédios',
  ErroAoCopiarDados: 'Erro ao copiar os dados.',
  FalhaAoObterCampanha: 'Falha ao obter campanha',
};

export const textoBotaoNotificacao = {
  AtualizarDados: 'ATUALIZAR DADOS',
  Fechar: 'FECHAR',
};

export const mensagensNotificacaoAlerta = {
  DadosDesatualizados: 'Os dados exibidos estão desatualizados',
  ArquivoExcedeLimite: 'O arquivo excede o limite de 4,5MB permitido',
  ArquivoFormatoIncorreto:
    'O arquivo importado está com formato incorreto. Formato aceito: CSV',
  CampanhaDevePossuirAlunos: 'A campanha deve incluir pelo menos um aluno.',
  CampanhaDataTerminoInvalida: 'A campanha possui data de término inválida.',
  CampanhaDataInicioInvalida: 'A campanha possui data de início inválida.',
  CampanhaOrientacoesInvalida:
    'A campanha deve possuir orientações de atendimento.',
  CampanhaDataMatriculaInvalida:
    'A campanha possui data de término da matricula inválida.',
  MarcaWhatsappInexistente:
    'A marca para o envio das mensagens de Whatsapp não foi selecionada.',
  MensagemWhatsappInexistente:
    'A mensagen para o envio das mensagens de Whatsapp não foi selecionada.',
};

export const limiteArquivo4mb = 4718592;

export const mensagemBoasVindas = {
  Dia: 'Bom dia,',
  Tarde: 'Boa tarde,',
  Noite: 'Boa Noite,',
};

export const headerAtendimentoSelecionado = {
  Aluno: {
    Atendeu: 'Atendeu',
    NaoAtendeu: 'Não Atendeu',
    NumeroErrado: 'Número Errado',
    NumeroBloqueado: 'Número Bloqueado',
  },
  ConversaComAluno: {
    EmAtend: 'Em Atend.',
    RetornarDepois: 'Retornar Depois',
    NaoQuerFalar: 'Não Quer Falar',
  },
  Problema: {
    Financeiro: 'Financeiro',
    Academico: 'Acadêmico',
    Institucional: 'Institucional',
    Pessoal: 'Pessoal',
  },
};

export const informacoesModais = {
  SegundaDescricao: 'O que deseja fazer?',
  TextoBotaoCancelar: 'Cancelar',
  ModalCancelarCriacaoDeCampanha: {
    Titulo: 'Descartar Campanha?',
    Descricao:
      'Caso descarte esta campanha, você vai perder todas as informações configuradas.',
    TextoBotaoContinuar: 'Descartar Campanha',
  },
  ModalClonarCampanha: {
    Titulo: 'Deseja clonar a campanha?',
    TextoBotaoContinuar: 'Sim',
  },
  ModalAtendimentoBloqueado: {
    Titulo: 'Atendimento Bloqueado',
    Descricao:
      'Você tem um atendimento em andamento, logo não é permitido que inicie outro.',
    TextoBotaoContinuar: 'Retomar Atendimento',
  },
  ModalInicarAtendimento: {
    Titulo: 'Iniciar o atendimento',
    Descricao:
      'Ao iniciar um atendimento, você não poderá inciar um novo antes de terminar o atual.',
    TextoBotaoContinuar: 'Iniciar Atendimento',
  },
  ModalAtendimentoEmAndamento: {
    Titulo: 'Atendimento em andamento',
    Descricao:
      'Este atendimento não foi finalizado e você não poderá atender outro aluno.',
    TextoBotaoContinuar: 'Sair do Atendimento',
  },
  ModalNaoAtendeu: {
    Titulo: 'O aluno não atendeu?',
    Descricao:
      'Caso o aluno não tenha atendido, ele voltará para a lista de atendimentos para ser contatado mais tarde.',
    TextoBotaoContinuar: 'Tentar Mais Tarde',
  },
  ModalNumeroErrado: {
    Titulo: 'Número errado?',
    Descricao:
      'Caso o número do aluno esteja errado, o atendimento deve ser finalizado.',
    TextoBotaoContinuar: 'Finalizar Atendimento',
  },
  ModalNumeroBloqueado: {
    Titulo: 'Número bloqueado?',
    Descricao:
      'Caso o número do aluno esteja bloqueado, o atendimento deve ser finalizado.',
    TextoBotaoContinuar: 'Finalizar Atendimento',
  },
  ModalRetornarDepois: {
    Titulo: 'Deseja retornar depois?',
    Descricao: 'Selecione data e hora para retornar o atendimento deste aluno:',
    TextoBotaoContinuar: 'Agendar',
  },
  ModalNaoQuerFalar: {
    Titulo: 'O aluno não deseja conversar?',
    Descricao:
      'Caso o aluno não deseje conversar, o atendimento deve ser finalizado e ele será considerado como atendido.',
    TextoBotaoContinuar: 'Finalizar Atendimento',
  },
  ModalAvaliacao: {
    Titulo: 'Deseja finalizar o atendimento?',
    TextoBotaoContinuar: 'Finalizar Atendimento',
  },
  ModalSair: {
    Titulo: 'Deseja Sair?',
    TextoBotaoContinuar: 'Sair',
  },
  ModalCampanhaBloqueada: {
    Titulo: 'Campanha Bloqueada',
    Descricao:
      'Você está atendendo um aluno de outra campanha. Dessa forma, não é permitido que inicie outro.',
    TextoBotaoContinuar: 'Retomar Atendimento',
  },
  ModalExcluirProblema: {
    Titulo: 'Deseja excluir o problema?',
    Descricao:
      'Caso o problema descrito esteja errado, é necessário excluí-lo da base de atendimento.',
    TextoBotaoContinuar: 'Excluir o Problema',
  },
  ModalRegistrarInelegibilidade: {
    Titulo: 'O aluno é inelegível?',
    Descricao:
      'Caso o aluno seja inelegível, escolha o motivo e finalize o atendimento.',
    TextoBotaoDireito: 'Sair do Atendimento',
    Aviso:
      'Atenção, após esta ação não será possível realizar o contato com este aluno nesta campanha.',
  },
  ModalExclusaoCampanha: {
    Titulo: 'Confirmação de Exclusão de Campanha',
    Descricao: 'Tem certeza que deseja excluir a campanha?',
    TextoBotaoDireito: 'Excluir Campanha',
    Aviso:
      'ATENÇÃO: Esta ação apaga completamente a campanha. A campanha sairá da listagem da Gestão de Campanhas e os seus dados não serão mais acessáveis.',
  },
  ModalExcluirMensagensWhatsapp: {
    titulo: 'Confirmação de Exclusão de Mensagem',
    aviso:
      'ATENÇÃO: Esta ação apaga completamente a mensagem. A mensagem sairá da listagem da Gestão de Mensagens e os seus dados não serão mais acessíveis.',
    descricao: 'Tem certeza que deseja excluir a mensagem?',
    textoBotaoEsquerdo: 'Cancelar',
    textoBotaoDireito: 'Excluir Mensagem',
  },
  ModalExclusaoMensagemWhatsappVinculada: {
    titulo: 'Não foi possível excluir mensagem',
    textoBotaoEsquerdo: `Fechar`,
  },
  ModalConfirmacaoDownload: {
    Titulo: 'Confirmação de Download',
    Descricao: 'Tem certeza que deseja fazer o dowload do arquivo ',
    TextoBotaoDireito: 'Fazer Download',
  },

  ModalExportarCSV: {
    Titulo: 'Confirmação de Extração',
    Descricao:
      'Tem certeza que deseja extrair os dados para o repositório de arquivos?',
    TextoBotaoDireito: 'Confirmar',
  },

  ModalExtracaoDeDados: {
    Titulo: 'Confirmação de Extração',
    Descricao: 'Tem certeza que deseja extrair os dados da campanha ',
    SegundaDescricao: ' para o repositório de arquivos?',
    TextoBotaoDireito: 'Confirmar',
  },

  ModalAlterarPermissao: {
    Titulo: 'Alteração de Permissão',
    TextoBotaoDireito: 'Alterar',
    Descricao: (login, permissaoAntiga, novaPermissao) => {
      return `Tem certeza que deseja alterar a permissão do usuário ${login} de ${permissaoAntiga} para ${novaPermissao}?`;
    },
  },

  ModalExclusao: {
    Titulo: 'Confirmação de Exclusão',
    Descricao: (login) => {
      return `Tem certeza que deseja excluir o usuário ${login}?`;
    },
    TextoBotaoDireito: 'Excluir Usuário',
  },
};

export const statusAtendimento = {
  Atendeu: 'Atendeu',
  NaoAtendeu: 'Não Atendeu',
  NumeroErrado: 'Número Errado',
  EmAtend: 'Em Atend.',
  RetornarDepois: 'Retornar Depois',
  NaoQuerFalar: 'Não Quer Falar',
  Finalizado: 'Finalizado',
  NumeroBloqueado: 'Número Bloqueado',
  TelefoneErrado: 'Número Errado',
  Inelegível: 'Inelegível',
};

export const statusAtendimentoWhatsapp = {
  Enviada: 'Whatsapp',
  Whatsapp: 'Enviada',
};

export const labels = {
  NenhumaTentativa: 'Nenhuma tentativa',
  AtendimentoAtivo: 'Atendimento Ativo',
};

export const labelsGraficoProdutividade = {
  Tentativas: 'Tentativas',
  Atendimentos: 'Atendimentos',
  Remedios: 'Remédios',
  MediaDeAtendimento: 'TMA',
  TotalAtend: 'Total Atendimento.',
  Inelegiveis: 'Inelegíveis',
};

export const todosComGeneroIdentificado = {
  Masculino: 'Todos ',
  Feminino: 'Todas ',
};

export const labelsGraficoTempoMedio = {
  Quantidade: 'Quantidade de Atendimentos',
  TempoMedio: 'Tempo médio',
};

export const labelsFiltroDesempenho = {
  Hoje: 'Hoje',
};

export const tipoDadoGrafico = {
  Numerico: 'number',
  Texto: 'string',
};

export const papelLinhaGrafico = {
  Dominio: 'domain',
  Tooltip: 'tooltip',
};

export const idNotificacoesFixas = {
  AlertaAtualizarDados: 1,
  AlertaAtualizarDadosCockpit: 2,
};

export const tempoMaximoDesempenho = parseInt(
  process.env.REACT_APP_LIMIT_DURATION_CALL,
  10
);

const plurais = {};
plurais[filtroItens.Aluno] = 'Tipos de Aluno';
plurais[filtroItens.Marca] = 'Marcas';
plurais[filtroItens.Campus] = 'Campi';
plurais[filtroItens.Curso] = 'Cursos';
plurais[filtroItens.Modalidade] = 'Modalidades';
plurais[filtroItens.Turno] = 'Turnos';
plurais[filtroItens.PeriodoLetivo] = 'Períodos Letivos';
plurais[filtroItens.Ingresso] = 'Períodos de Ingresso';
plurais[filtroItens.Situacao] = 'Situações';
plurais[filtroItens.Problema] = 'Problemas';
plurais[filtroItens.CurrentAcademicTermName] = 'TermName';
plurais[filtroItens.Modality] = 'Modalidades';

export const pluralItens = plurais;

const singulares = {};
singulares[filtroItens.Aluno] = 'Tipo de Aluno';
singulares[filtroItens.Marca] = 'Marca';
singulares[filtroItens.Campus] = 'Campus';
singulares[filtroItens.Curso] = 'Curso';
singulares[filtroItens.Modalidade] = 'Modalidade';
singulares[filtroItens.Turno] = 'Turno';
singulares[filtroItens.PeriodoLetivo] = 'Período Letivo';
singulares[filtroItens.Ingresso] = 'Período de Ingresso';
singulares[filtroItens.Situacao] = 'Situação';
singulares[filtroItens.Problema] = 'Problema';

export const singularesItems = singulares;

export const Icons = {
  'react-feather/dist/icons/user-x': <UserX />,
  'react-feather/dist/icons/book-open': <BookOpen />,
  '@material-ui/icons/AccountBalanceWalletOutlined': (
    <AccountBalanceWalletOutlinedIcon />
  ),
  'react-feather/dist/icons/users': <Users />,
};

export const opcaoCampanha = {
  WhatsApp: 'WhatsApp',
  CRM: 'CRM',
};

export const opcaoChatbot = {
  numero70: {
    valor: '70',
    descricao: '70 - Detalhes da condição',
    mensagem: 'Para receber mais informações sobre a campanha, digite 70.',
  },
  numero71: {
    valor: '71',
    descricao: '71 - Aceitar condição',
    mensagem: '',
  },
  numero99: {
    valor: '99',
    descricao: '99 - Falar com atendente',
    mensagem: 'Se preferir falar com um atendente humano, digite 99.',
  },
};

const apiTerms = {};

apiTerms.marca = 'institution';
apiTerms.campi = 'campus';
apiTerms.curso = 'course';
apiTerms.modalidade = 'modality';
apiTerms.turno = 'shift';
apiTerms.aluno = 'seniority_level';
apiTerms.ingresso = 'intake_cycle';

export const termosApi = apiTerms;

export const filtroItensRecipients = [
  'problema',
  'situacao',
  'aluno',
  'marca',
  'campi',
  'modalidade',
  'curso',
  'turno',
  'ingresso',
];

export const limiteCaracterWhatsapp = {
  mensagensWhatsapp: 80,
};

export const menuNavegacaoDeTelas = {
  atendimento: 'Atendimento',
  desempenho: 'Desempenho',
  gestaoDeCampanhas: 'Gestão de Campanhas',
  gestaoMensagensWhatsapp: 'Mensagens de WhatsApp',
  gestaoCampanhasWhatsapp: 'Campanha via WhatsApp',
};

export const placeholderDatePicker = {
  placeholderInicio: 'Data de Matrícula Inicial',
  placeholderTermino: 'Data de Matrícula Final',
};

export const LabelModeloDeRisco = {
  RiscoDurante: 'Modelo de Risco Durante',
  RiscoRematricula: 'Modelo de Risco Rematrícula',
  RiscoPlanilha: 'Ordenação da Planilha',
};

export const listaOpcoesModeloDeRisco = [
  {
    Description: LabelModeloDeRisco.RiscoDurante,
    Id: '015be026-5ab7-44f8-b4c2-c835a104e741',
    CategoryType: ['Acolhimento', 'Personalizada'],
    CampaignType: ['Upload', 'Filtragem'],
  },
  {
    Description: LabelModeloDeRisco.RiscoRematricula,
    Id: '74ef2417-9634-4247-b715-3c97dfa8f9d7',
    CategoryType: [
      'Personalizada',
      'Rematrícula Tipo 1',
      'Rematrícula Tipo 2',
      'Rematrícula Tipo 3',
    ],
    CampaignType: ['Upload', 'Filtragem'],
  },
  {
    Description: LabelModeloDeRisco.RiscoPlanilha,
    Id: '74ef2417-9634-4247-b715-3c97dfa8f9r7',
    CategoryType: [''],
    CampaignType: ['Upload'],
  },
];

export const LabelObjetivosCampanha = {
  Aluno: {
    Todos: 'Todos perfis de alunos',
    Calouro: 'Calouro',
    Veterano: 'Veterano',
  },
  Categoria: {
    DocumentoPendente: 'Documento Pendente',
    Rematricula: 'Rematrícula',
    RematriculaTipo1: 'Rematrícula Tipo 1',
    RematriculaTipo2: 'Rematrícula Tipo 2',
    RematriculaTipo3: 'Rematrícula Tipo 3',
    Acolhimento: 'Acolhimento',
    Personalizada: 'Personalizada',
  },
  Situacao: {
    Todos: 'Todas situações fianceiras',
    PossuiDebito: 'Possui débito financeiro',
    NaoPossuiDebito: 'Não possui débito financeiro',
  },
};

export const MappingObjetivosCampanha = {
  Aluno: {
    'Todos perfis de alunos': null,
    Calouro: 'Calouro',
    Veterano: 'Veterano',
  },
  Categoria: {
    Decola: 'Decola',
    'Acordo Quebrado': 'AcordoQuebrado',
    Rematrícula: 'Rematrícula',
    'Rematrícula Tipo 1': 'Rematrícula',
    'Rematrícula Tipo 2': 'Rematrícula',
    'Rematrícula Tipo 3': 'Rematrícula',
    Acolhimento: 'Acolhimento',
    Personalizada: 'Personalizada',
  },
  Situacao: {
    'Todas situações fianceiras': null,
    'Possui débito financeiro': true,
    'Não possui débito financeiro': false,
  },
  TipoCategoria: {
    Decola: null,
    'Acordo Quebrado': null,
    'Rematrícula Tipo 1': 'DebitoAnterior',
    'Rematrícula Tipo 2': 'DebitoMatriculaEmAberto',
    'Rematrícula Tipo 3': 'DebitoAnteriorEMatriculaEmAberto',
    Acolhimento: null,
    Personalizada: null,
  },
};

export const labelSelectCriarCampanha = {
  Aluno: 'Perfil do Aluno',
  Categoria: 'Categoria',
  Situacao: 'Situação',
  FormaDeIngresso: 'Forma de Ingresso',
  Modalidade: 'Modalidade',
  Marca: 'Marca',
  Campus: 'Campus',
  Curso: 'Curso',
  Turno: 'Turno',
};

export const riskTypeValue = {
  'Modelo de Risco Durante': 'Durante',
  'Modelo de Risco Rematrícula': 'Rematrícula',
  'Ordenação da Planilha': 'Planilha',
};

export const hierarquiaPermissoesUsuarios = {
  Administrador: 0,
  Gestor: 1,
  Lider: 2,
  Operador: 3,
};

export const conversaoNomenclaturaStatus = {
  Ativar: 'Ativa',
  Reativar: 'Ativa',
  Expirar: 'Expirada',
};

export const mensagemAlterarStatus = {
  ModalReativarCampanhaSingular:
    'aluno já recebeu a mensagem de final de campanha. Você confirma a reativação?',
  ModalReativarCampanhaPlural:
    'alunos já receberam a mensagem de final de campanha. Você confirma a reativação?',
  ModalExpirarCampanhaSingular:
    'aluno receberá a mensagem de final de campanha. Você confirma o encerramento?',
  ModalExpirarCampanhaPlural:
    'alunos receberão a mensagem de final de campanha. Você confirma o encerramento?',
};

export const impactosDaCampanha = {
  MuitoBaixo: 'Muito Baixo',
  Baixo: 'Baixo',
  Medio: 'Médio',
  Alto: 'Alto',
  MuitoAlto: 'Muito Alto',
};

export const listaOpcoesImpactoCampanha = [
  {
    checked: false,
    Description: impactosDaCampanha.MuitoBaixo,
    Id: 1,
  },
  {
    checked: false,
    Description: impactosDaCampanha.Baixo,
    Id: 2,
  },
  {
    checked: false,
    Description: impactosDaCampanha.Medio,
    Id: 3,
  },
  {
    checked: false,
    Description: impactosDaCampanha.Alto,
    Id: 4,
  },
  {
    checked: false,
    Description: impactosDaCampanha.MuitoAlto,
    Id: 5,
  },
];

export const nivelDePrioridade = {
  'Muito Baixo': 1,
  Baixo: 2,
  Médio: 3,
  Alto: 4,
  'Muito Alto': 5,
};

export const arrayOpcoesContato = [
  { Title: '1' },
  { Title: '2' },
  { Title: '3' },
  { Title: '4' },
  { Title: '5' },
  { Title: '6' },
  { Title: '7' },
  { Title: '8' },
  { Title: '9' },
  { Title: '10' },
];

export const placeholderMensagemWhatsapp =
  'Digite o texto - não é permitido quebra de linhas';

export const mensagemAtualizarMensagemWhatsapp =
  'Mensagem de WhatsApp atualizada com sucesso';

export const limiteCaractereMensagemZenvia = 1048;

export const folgaSegurancaCaracter = 100;

export const noficacaoMensagemWhatsappRemovida =
  'Mensagem de Whatsapp removida com sucesso.';

export const notificacoesRepositorioPerformance = {
  DadosAtualizados:
    'Dados atualizados para o repositório de arquivos com sucesso',
  DadosNaoEncontrados: 'Dados não encontrados. Por favor, atualize a tabela',
};
