import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Check } from 'react-feather';
import { connect } from 'react-redux';
import { exibirNotificacaoSucesso } from '../../notificacao/Store';
import Loading from '../../../shared/componentes/Loading';
import {
  informacoesModais,
  iconesNotificacao,
  nivelDePrioridade,
} from '../../../shared/util/Constantes';
import {
  DARK_CYAN,
  DARK_GRAY,
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
  MEDIUM_GRAY,
  STAR_COMMAND_BLUE,
  WHITE,
  LIGHT_SEA_GREEN_HOVER,
} from '../../../theme';
import { formatarDataParaTimestamp } from '../../../shared/util/Utils';
import EditarCampanhaManager from '../EditarCampanhaManager';

const useStyles = makeStyles(() => ({
  botaoEsquerdo: {
    color: MEDIUM_GRAY,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px',
  },
  titulo: {
    color: DARK_CYAN,
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '25px',
  },
  botaoDireito: {
    backgroundColor: DARK_CYAN,
    color: WHITE,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px',
    '&:hover': {
      backgroundColor: LIGHT_SEA_GREEN_HOVER,
    },
  },
  backgroundProps: {
    background: 'rgba(192,198,214,0.4)',
  },
  modal: {
    maxWidth: '410px',
    backgroundColor: WHITE,
    boxShadow: '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
    borderRadius: '8px',
    minWidth: '350px',
  },
  containerConteudoDialog: {
    padding: '20px 15px 20px 15px',
  },
  descricao: {
    fontSize: '16px',
    color: STAR_COMMAND_BLUE,
    fontFamily: FONT_FAMILY_REGULAR,
    marginBottom: '20px',
  },
  segundaDescricao: {
    fontSize: '16px',
    color: DARK_GRAY,
    fontFamily: FONT_FAMILY_BOLD,
    marginTop: '24px',
    marginBottom: '24px',
  },
  closeButton: {
    position: 'absolute',
    right: '16px',
    top: '16px',
    color: '#9e9e9e',
  },
}));

const ModalConfirmacaoEdicaoCampanha = (props) => {
  const classes = useStyles();

  const [carregando, setCarregando] = useState(false);

  const {
    setModalAberto,
    modalConfirmacaoEdicaoAberto,
    setModalConfirmacaoEdicaoAberto,
    idCampanha,
    dataInicioCampanha,
    dataTerminoCampanha,
    impactoDaCampanha,
    grupoDeVisualizacao,
    orientacoesAtendimento,
    notificarSucesso,
    handleEdicaoCampanha,
    marcaWhatsapp,
    primeiraMensagem,
    primeiroContato,
    segundaMensagem,
    segundoContato,
    terceiraMensagem,
  } = props;

  const handleBotaoDireitoAsync = async () => {
    setCarregando(true);
    const dadosWhatsapp = {
      marcaWhatsapp,
      mensagens: [],
    };
    if (primeiraMensagem) {
      dadosWhatsapp.mensagens.push({
        Id: primeiraMensagem.SK,
        Gatilho: parseInt(primeiroContato.Title, 10),
      });
    }
    if (segundaMensagem) {
      dadosWhatsapp.mensagens.push({
        Id: segundaMensagem.SK,
        Gatilho: parseInt(segundoContato.Title, 10),
      });
    }
    if (terceiraMensagem) {
      dadosWhatsapp.mensagens.push({ Id: terceiraMensagem.SK, Gatilho: 0 });
    }
    const success = await EditarCampanhaManager.editarDadosCampanha(
      idCampanha,
      dataInicioCampanha,
      dataTerminoCampanha,
      nivelDePrioridade[`${impactoDaCampanha}`],
      grupoDeVisualizacao,
      orientacoesAtendimento,
      dadosWhatsapp
    );
    if (success === null) {
      handleEdicaoCampanha(
        idCampanha,
        orientacoesAtendimento,
        formatarDataParaTimestamp(dataInicioCampanha),
        formatarDataParaTimestamp(dataTerminoCampanha),
        grupoDeVisualizacao,
        nivelDePrioridade[`${impactoDaCampanha}`],
        dadosWhatsapp
      );
      setModalConfirmacaoEdicaoAberto(false);
      setModalAberto(false);
      notificarSucesso(
        'Edição cadastrada com sucesso',
        iconesNotificacao.AtendimentoFinalizado
      );
    }
    setCarregando(false);
  };

  return (
    <Dialog
      open={modalConfirmacaoEdicaoAberto}
      aria-labelledby="modal-titulo"
      aria-describedby="modal-descricao"
      data-testid="modal-confirm-dialog"
      scroll="body"
      classes={{ paper: classes.modal }}
      BackdropProps={{
        classes: {
          root: classes.backgroundProps,
        },
      }}
    >
      {carregando && <Loading />}
      <DialogContent>
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.containerConteudoDialog}
        >
          <Typography className={classes.titulo}>
            Confirmação de Edição de Campanha
          </Typography>
          <Grid>
            <Typography
              className={classes.descricao}
              component="span"
              display="inline"
            >
              Utilize a
              <Typography
                className={classes.descricao}
                style={{ fontFamily: FONT_FAMILY_BOLD }}
                display="inline"
              >
                {' '}
                Vizualização Prévia{' '}
              </Typography>{' '}
              para conferir todas informações antes de confirmar a edição da
              campanha.
            </Typography>
            <Typography className={classes.segundaDescricao}>
              Tem certeza que deseja editar a campanha?
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Button
              className={classes.botaoEsquerdo}
              onClick={() => setModalConfirmacaoEdicaoAberto(false)}
            >
              {informacoesModais.TextoBotaoCancelar}
            </Button>
            <Button
              className={classes.botaoDireito}
              startIcon={<Check size={18} />}
              onClick={() => {
                handleBotaoDireitoAsync();
              }}
            >
              Editar Campanha
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export const mapStateToProps = () => ({});

const mapDispatchToProps = {
  notificarSucesso: exibirNotificacaoSucesso,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalConfirmacaoEdicaoCampanha);
