// Types
export const ATUALIZAR_ABA_SELECIONADA = 'app/home/ATUALIZAR_ABA_SELECIONADA';

// Reducer
export const INITIAL_STATE = {
  abaSelecionada: 0,
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  if (action.type === ATUALIZAR_ABA_SELECIONADA) {
    return {
      ...state,
      abaSelecionada: action.payload,
    };
  }
  return state;
}

// Action creators
export const atualizarAbaSelecionada = (abaSelecionada) => {
  return {
    type: ATUALIZAR_ABA_SELECIONADA,
    payload: abaSelecionada,
  };
};
