// Types
export const INSERIR_USUARIO = 'app/login/INSERIR_USUARIO';
export const INSERIR_USER_MANAGER = 'app/login/INSERIR_USER_MANAGER';
export const INSERIR_USER_SCAPE0 = 'app/login/INSERIR_USER_SCAPE0';
// Reducer
export const INITIAL_STATE = {
  usuario: null,
  userManager: null,
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  if (action.type === INSERIR_USUARIO) {
    return {
      ...state,
      usuario: action.payload,
    };
  }
  if (action.type === INSERIR_USER_MANAGER) {
    return {
      ...state,
      userManager: action.payload,
    };
  }
  if (action.type === INSERIR_USER_SCAPE0) {
    return {
      ...state,
      usuarioScape0: action.payload,
    };
  }
  return state;
}

// Action creators
export const atualizarUsuario = (usuario) => ({
  type: INSERIR_USUARIO,
  payload: usuario,
});
export const atualizarUserManager = (userManager) => ({
  type: INSERIR_USER_MANAGER,
  payload: userManager,
});
export const atualizarUserScape0 = (userScape0) => ({
  type: INSERIR_USER_SCAPE0,
  payload: userScape0,
});
