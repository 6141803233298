import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { makeStyles } from '@material-ui/core/styles';
import { buscarDadosIndicadores } from '../EvasaoPorMotivoManager';
import CardTitle from '../../../../shared/componentes/CardTitle';
import IndicadorIlustradoCockpit from '../../../../shared/componentes/IndicadorIlustradoCockpit';
import { Icons } from '../../../../shared/util/Constantes';
import LoadingDashboard from '../../../../shared/componentes/LoadingDashboard';
import DadosNaoEncontrados from '../../../../shared/componentes/DadosNaoEncontrados';

const useStyles = makeStyles({
  containerEvasaoPorMotivo: {
    minHeight: '130px',
    position: 'relative',
  },
  containerIndicador: {
    width: '200px',
    margin: '8px',
    height: '72px',
  },
});

const EvasaoPorMotivo = (props) => {
  const { filtro, codigoIes } = props;
  const classes = useStyles();
  const [evasaoPorMotivo, setEvasaoPorMotivo] = React.useState(null);
  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setCarregando(true);

      const dadosIndicadores = await buscarDadosIndicadores(codigoIes, filtro);

      setEvasaoPorMotivo(dadosIndicadores);

      setCarregando(false);
    };
    getData();
  }, [filtro, codigoIes]);

  return (
    <>
      <CardTitle text="Evasão por Motivo" icon={<HelpOutlineIcon />} />
      <Grid
        container
        item
        direction="row"
        alignContent="space-around"
        justifyContent="space-around"
        className={classes.containerEvasaoPorMotivo}
      >
        {carregando && <LoadingDashboard />}
        {evasaoPorMotivo && evasaoPorMotivo.length > 0 && (
          <>
            {evasaoPorMotivo.map((el) => (
              <Grid item className={classes.containerIndicador} key={el.titulo}>
                <IndicadorIlustradoCockpit
                  horizontal
                  titulo={el.titulo}
                  icon={Icons[el.icon]}
                  color={el.color}
                  valorPrimario={el.valorPrimario}
                  valorSecundario={el.valorSecundario}
                />
              </Grid>
            ))}
          </>
        )}
        {evasaoPorMotivo && evasaoPorMotivo.length === 0 && (
          <DadosNaoEncontrados chaveamentoEstadoVazio={3} />
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    codigoIes: parseInt(state.login.usuario.iesCode, 10),
  };
};
export default connect(mapStateToProps)(EvasaoPorMotivo);
