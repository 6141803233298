import { Grid, makeStyles, Typography } from '@material-ui/core';
import WhatsappIcon from '@material-ui/icons/WhatsApp';
import React from 'react';
import { Calendar, Inbox } from 'react-feather';
import { connect } from 'react-redux';
import CardTitle from '../../../../shared/componentes/CardTitle';
import FiltrosPreVisualizar from '../../../../shared/componentes/FiltrosPreVisualizar';
import ItemFiltrosPreVisualizar from '../../../../shared/componentes/ItemFiltrosPreVisualizar';
import preVisualizarImagem from '../../../../shared/img/pre_visualizar_imagem.png';
import { formatarDataParaString } from '../../../../shared/util/Utils';
import {
  DARK_CYAN,
  DARK_GRAY,
  FONT_FAMILY,
  FONT_FAMILY_REGULAR,
  GRAY,
  LIGHT_GRAY,
} from '../../../../theme';

const PreVisualizarWhatsapp = (props) => {
  const {
    titulo,
    orientacoes,
    categoria,
    situacao,
    formaDeIngresso,
    perfilAluno,
    marca,
    campus,
    curso,
    modalidade,
    turno,
    dataInicioCampanha,
    dataInicioMatricula,
    dataTerminoMatricula,
    criarCampanhaUpload,
    marcaWhatsapp,
    mensagemWhatsapp,
  } = props;

  const useStyles = makeStyles({
    subtitulo: {
      fontSize: '14px',
      color: DARK_GRAY,
      fontFamily: FONT_FAMILY_REGULAR,
      padding: '8px 7px 0 7px',
    },
    container: {
      flex: '1',
      maxHeight: '100%',
    },
    containerTexto: {
      position: 'relative',
      overflowWrap: 'break-word',
      marginLeft: '16px',
      marginTop: '4px',
    },
    preVisualizar: {
      width: '100%',
      height: '100%',
      border: `1px solid ${LIGHT_GRAY}`,
      borderRadius: '6px',
      overflowY: 'auto',
      padding: '12px',
    },
    preVisualizarVazio: {
      width: '100%',
      height: '100%',
      border: `1px solid ${LIGHT_GRAY}`,
      borderRadius: '6px',
      padding: '2.3rem',
    },
    labelTitulo: {
      fontSize: '13px',
      color: DARK_CYAN,
    },
    imagemVazio: {
      height: '6rem',
      marginBottom: '1rem',
    },
    tituloVazio: {
      fontSize: '18px',
      color: GRAY,
      fontFamily: FONT_FAMILY,
      marginBottom: '0.6rem',
      fontWeight: '600',
    },
    subtituloVazio: {
      fontSize: '14px',
      color: GRAY,
      fontFamily: FONT_FAMILY_REGULAR,
    },
    tituloPrevia: {
      fontSize: '18px',
      color: GRAY,
      fontFamily: FONT_FAMILY_REGULAR,
      fontWeight: '800',
      textAlign: 'center',
      marginBottom: '32px',
      marginTop: '16px',
    },
    iconWhatsapp: {
      color: DARK_CYAN,
    },
  });

  const verificarSeCampoEstaPreenchido = () => {
    return (
      !titulo &&
      !orientacoes &&
      !categoria &&
      !situacao &&
      !perfilAluno &&
      formaDeIngresso.length === 0 &&
      !dataInicioMatricula &&
      !dataTerminoMatricula &&
      modalidade.length === 0 &&
      marca.length === 0 &&
      campus.length === 0 &&
      curso.length === 0 &&
      turno.length === 0 &&
      !dataInicioCampanha &&
      !marcaWhatsapp &&
      !mensagemWhatsapp
    );
  };

  const classes = useStyles();

  return (
    <>
      {verificarSeCampoEstaPreenchido() && (
        <Grid
          item
          container
          direction="column"
          wrap="nowrap"
          alignItems="center"
          justifyContent="center"
          className={classes.preVisualizarVazio}
        >
          <img
            alt="Pré-visualização"
            className={classes.imagemVazio}
            src={preVisualizarImagem}
          />
          <Typography noWrap align="center" className={classes.tituloVazio}>
            Pré-visualizar Campanha
          </Typography>
          <Typography align="center" className={classes.subtituloVazio}>
            Defina os filtros e conteúdo da mensagem para pré-visualizar a
            campanha
          </Typography>
        </Grid>
      )}
      {!verificarSeCampoEstaPreenchido() && (
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="row"
          className={`p-3 ${classes.preVisualizar}`}
        >
          <Grid container justifyContent="center">
            <Grid>
              <Typography className={classes.tituloPrevia} variant="h3">
                Prévia da Campanha
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item>
                <CardTitle text={titulo} icon={<Inbox />} />
              </Grid>
              <FiltrosPreVisualizar
                categoria={categoria ? [categoria] : []}
                situacao={situacao ? [situacao] : []}
                perfilAluno={perfilAluno ? [perfilAluno] : []}
                formaDeIngresso={
                  formaDeIngresso
                    ? formaDeIngresso.map((item) => {
                        return item.title;
                      })
                    : []
                }
                dataInicioMatricula={dataInicioMatricula}
                dataTerminoMatricula={dataTerminoMatricula}
                modalidade={
                  modalidade
                    ? modalidade.map((item) => {
                        return item.title;
                      })
                    : []
                }
                marca={
                  marca
                    ? marca.map((item) => {
                        return item.title;
                      })
                    : []
                }
                campus={
                  campus
                    ? campus.map((item) => {
                        return item.title;
                      })
                    : []
                }
                curso={
                  curso
                    ? curso.map((item) => {
                        return item.title;
                      })
                    : []
                }
                turno={
                  turno
                    ? turno.map((item) => {
                        return item.title;
                      })
                    : []
                }
                criarCampanhaUpload={criarCampanhaUpload}
              />
            </Grid>

            <Grid item container xs={12}>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  className="mt-4"
                  alignItems="center"
                >
                  <Calendar size={20} color={DARK_CYAN} />
                  <Typography
                    className={`${classes.labelTitulo} pl-2 pt-1`}
                    variant="h6"
                  >
                    Data e Hora
                  </Typography>
                </Grid>
              </Grid>
              <ItemFiltrosPreVisualizar
                descricao="Data e Hora Início"
                valores={formatarDataParaString(dataInicioCampanha, true)}
                naoExpandir
              />
            </Grid>
            <Grid item container xs={12}>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  className="mt-4"
                >
                  <WhatsappIcon size={20} className={classes.iconWhatsapp} />
                  <Typography
                    className={`${classes.labelTitulo} pl-1 pt-1`}
                    variant="h6"
                  >
                    WhatsApp
                  </Typography>
                </Grid>
                <ItemFiltrosPreVisualizar
                  descricao="Marca"
                  valores={marcaWhatsapp}
                />
                <ItemFiltrosPreVisualizar
                  descricao="Mensagem Whatsapp"
                  valores={mensagemWhatsapp ? [mensagemWhatsapp] : []}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    filtroCampanha: state.criarCampanha,
  };
};

export default connect(mapStateToProps)(PreVisualizarWhatsapp);
