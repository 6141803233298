import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from '@material-ui/core';

const useStyles = makeStyles({
  row: {
    height: '49px',
  },
});

const LinhaVaziaTabelaPermissaoUsuario = (props) => {
  const { numeroColunas } = props;

  const classes = useStyles();

  const tableCell = () => {
    const colunas = [];
    let index = 0;

    for (let i = 0; i < numeroColunas; i++) {
      colunas.push(<TableCell key={index++} />);
    }

    return colunas;
  };

  return (
    <TableRow
      data-testid="linha-vazia-tabela-permissao-usuario"
      className={classes.row}
    >
      {tableCell()}
    </TableRow>
  );
};

export default LinhaVaziaTabelaPermissaoUsuario;
