import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import { Dialog, makeStyles } from '@material-ui/core';
import React from 'react';
import { DARK_CYAN } from '../../../theme';

const useStyles = makeStyles(() => ({
  modal: {
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none',
  },
  backgroundProps: {
    background: 'rgba(192,198,214,0.4)',
  },
  reactLoading: {
    margin: '30px',
  },
}));

const FullScreenLoading = (props) => {
  const { stateIsLoading } = props;
  const classes = useStyles();
  return (
    <>
      {stateIsLoading ? (
        <>
          <Dialog
            open
            classes={{ paper: classes.modal }}
            BackdropProps={{
              classes: {
                root: classes.backgroundProps,
              },
            }}
          >
            <ReactLoading
              type="spin"
              color={DARK_CYAN}
              height={80}
              width={80}
              className={classes.reactLoading}
            />
          </Dialog>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    stateIsLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps)(FullScreenLoading);
