import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Loading from '../../../shared/componentes/Loading';
import {
  WHITE,
  BLACK,
  DARK_CYAN,
  DARK_GRAY,
  FONT_FAMILY_REGULAR,
  STAR_COMMAND_BLUE,
} from '../../../theme';

const useStyles = makeStyles(() => ({
  botaoEsquerdo: {
    color: DARK_GRAY,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px',
  },
  titulo: {
    color: DARK_CYAN,
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '25px',
  },
  botaoDireito: {
    color: DARK_CYAN,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px',
  },
  backgroundProps: {
    background: 'rgba(192,198,214,0.4)',
  },
  modal: {
    maxWidth: '530px',
    backgroundColor: WHITE,
    boxShadow: '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
    borderRadius: '8px',
    minWidth: '350px',
  },
  containerConteudoDialog: {
    padding: '20px 15px 20px 15px',
  },
  descricao: {
    fontSize: '16px',
    color: BLACK,
    fontFamily: FONT_FAMILY_REGULAR,
    marginBottom: '20px',
  },
  segundaDescricao: {
    color: STAR_COMMAND_BLUE,
    fontSize: '16px',
    fontFamily: FONT_FAMILY_REGULAR,
    marginBottom: '20px',
  },
  closeButton: {
    position: 'absolute',
    right: '16px',
    top: '16px',
    color: '#9e9e9e',
  },
}));

const ModalConfirmacaoEdicaoRamal = (props) => {
  const classes = useStyles();

  const [carregando, setCarregando] = useState(false);
  const isUmounted = useRef(false);

  const {
    modalAberto,
    setModalAberto,
    handleBotaoEsquerdo,
    handleBotaoDireito,
  } = props;

  useEffect(() => {
    return () => {
      isUmounted.current = true;
    };
  }, []);

  const handleBotaoEsquerdoAsync = async () => {
    setCarregando(true);
    await handleBotaoEsquerdo();
    if (!isUmounted.current) {
      setCarregando(false);
    }
  };

  const handleBotaoDireitoAsync = async () => {
    setCarregando(true);
    await handleBotaoDireito();
    if (!isUmounted.current) {
      setCarregando(false);
    }
  };

  const handleOnCloseAsync = async () => {
    if (!carregando) await setModalAberto(false);
  };

  return (
    <Dialog
      open={modalAberto}
      onClose={handleOnCloseAsync}
      aria-labelledby="modal-titulo"
      aria-describedby="modal-descricao"
      data-testid="modal-confirm-dialog"
      scroll="body"
      classes={{ paper: classes.modal }}
      BackdropProps={{
        classes: {
          root: classes.backgroundProps,
        },
      }}
    >
      {carregando && <Loading />}
      <DialogContent>
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.containerConteudoDialog}
        >
          <Typography className={classes.titulo}>Sair sem Salvar?</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              setModalAberto(false);
            }}
            data-testid="close-button"
          >
            <CloseIcon />
          </IconButton>
          <Grid>
            <Typography
              className={classes.descricao}
              data-testid="primeira-descricao-ramal"
            >
              As alterações realizadas no <b>Ramal</b> não serão salvas.
            </Typography>
            <Typography
              className={classes.segundaDescricao}
              data-testid="segunda-descricao-ramal"
            >
              Para salvar a edição do <b>Ramal</b>, utilize o ícone de Check
              detro do campo edição.
            </Typography>
            <Typography
              className={classes.descricao}
              data-testid="terceira-descricao-ramal"
            >
              Tem certeza que deseja sair?
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Button
              className={classes.botaoEsquerdo}
              onClick={() => {
                handleBotaoEsquerdoAsync();
              }}
            >
              Sair
            </Button>
            <Button
              className={classes.botaoDireito}
              onClick={() => {
                handleBotaoDireitoAsync();
              }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalConfirmacaoEdicaoRamal;
